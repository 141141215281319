import { Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';

interface Props {
  readonly firstInterval: number;
  readonly secondInterval: number;
  readonly thirdInterval: number;
  readonly onFirstIntervalElapsed: () => void;
  readonly onSecondIntervalElapsed: () => void;
  readonly onSubmit: (duration: number) => void;
  readonly disabled?: boolean;
}

export function Timer({
  firstInterval,
  secondInterval,
  thirdInterval,
  onFirstIntervalElapsed,
  onSecondIntervalElapsed,
  onSubmit,
  disabled = false
}: Props) {
  const startTimerLabel = 'Spustiť STOPKY';
  const lightGreyColor = '#c5c5c5';
  const lightGreyBorder = `2px solid ${lightGreyColor}`;
  const lightGreenColor = '#c2deba';

  const [startedAt, setStartedAt] = useState<number | undefined>();
  const [notifiedOneMin, setNotifiedOneMin] = useState<boolean>(false);
  const [notifiedTwoMin, setNotifiedTwoMin] = useState<boolean>(false);
  const [showSubmitBtn, setShowSubmitBtn] = useState<boolean>(true);
  const [offset, setOffset] = useState<number>(0);

  useEffect(() => {
    let intervalId;

    if (startedAt != null) {
      intervalId = setInterval(() => {
        const mutatedOffset = Date.now() - startedAt;
        setOffset(mutatedOffset);

        if (notifiedOneMin === false && mutatedOffset >= firstInterval * 1_000) {
          onFirstIntervalElapsed();
          setNotifiedOneMin(true);
        }

        if (notifiedTwoMin === false && mutatedOffset >= secondInterval * 1_000) {
          onSecondIntervalElapsed();
          setNotifiedTwoMin(true);
        }

        if (mutatedOffset >= thirdInterval * 1_000) {
          setShowSubmitBtn(false);
          toggleTimer();
          onSubmit(Math.floor(mutatedOffset / 1_000));
        }
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [startedAt, notifiedOneMin, notifiedTwoMin]);

  function toggleTimer() {
    if (startedAt == null && offset === 0) {
      setStartedAt(Date.now());
    } else {
      setStartedAt(undefined);
    }
  }

  function getMinutes(): string {
    const minutes = String(Math.floor(offset / 60_000));
    return minutes.length === 1 ? `0${minutes}` : minutes;
  }

  function getSeconds(): string {
    const seconds = String(Math.floor((offset / 1_000) % 60));
    return seconds.length === 1 ? `0${seconds}` : seconds;
  }

  return (
    <>
      <Box mb={5}>
        {startedAt == null && offset === 0 && showSubmitBtn && (
          <StartButton onClick={toggleTimer} disabled={disabled}>
            {startTimerLabel}
          </StartButton>
        )}
        {startedAt != null && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="50px"
            width="100%"
            border={lightGreyBorder}
            bgcolor={lightGreenColor}
          >
            <span>{getMinutes()}</span>:<span>{getSeconds()}</span>
          </Box>
        )}
      </Box>
      <Box mt={3}>
        {showSubmitBtn && (
          <SubmitButton
            onClick={() => {
              if (startedAt != null) toggleTimer();
              setShowSubmitBtn(false);
              onSubmit(Math.floor(offset / 1_000));
            }}
            disabled={disabled}
          >
            Vyhodnotiť
          </SubmitButton>
        )}
      </Box>
    </>
  );
}

const StartButton = styled(Button)`
  height: 50px;
  width: 100%;
  background: #e2e4e6;
  &:hover {
    background: #e2e4e6;
  }
`;

const SubmitButton = styled(Button)`
  background: #a0b7db;
  color: white;
  height: 30px;
  width: 100%;
  &:hover {
    background: #224782;
    color: white;
  }
`;
