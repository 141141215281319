import { Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';

interface Props {
  readonly onSubmit: (duration: number) => void;
}

export function Timer({ onSubmit }: Props) {
  const startTimerLabel = 'SPUSTIŤ TEST';
  const stopTimerLabel = 'UKONČIŤ TEST';
  const lightGreyColor = '#c5c5c5';
  const darkGreyColor = '#707070';
  const lightGreyBorder = `1px solid ${lightGreyColor}`;
  const darkGreyBorder = `1px solid ${darkGreyColor}`;

  const [startedAt, setStartedAt] = useState<number | undefined>();
  const [offset, setOffset] = useState<number>(0);

  useEffect(() => {
    let intervalId;

    if (startedAt != null) {
      intervalId = setInterval(() => {
        setOffset(Date.now() - startedAt);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [startedAt]);

  function toggleTimer() {
    if (startedAt == null && offset === 0) {
      setStartedAt(Date.now());
    } else {
      setStartedAt(undefined);
    }
  }

  function getMinutes(): string {
    const minutes = String(Math.floor(offset / 60_000));
    return minutes.length === 1 ? `0${minutes}` : minutes;
  }

  function getSeconds(): string {
    const seconds = String(Math.floor((offset / 1_000) % 60));
    return seconds.length === 1 ? `0${seconds}` : seconds;
  }

  return (
    <Box padding={3} width={300} border={lightGreyBorder}>
      <Box padding={2} border={darkGreyBorder} bgcolor={lightGreyColor}>
        <Box padding={2} mb={1} color="white" textAlign="center" bgcolor={darkGreyColor}>
          <span>{getMinutes()}</span>:<span>{getSeconds()}</span>
        </Box>
        {startedAt == null && offset === 0 && (
          <GreenButton onClick={toggleTimer}>{startTimerLabel}</GreenButton>
        )}
        {startedAt != null && <RedButton onClick={toggleTimer}>{stopTimerLabel}</RedButton>}
      </Box>

      {startedAt == null && offset !== 0 && (
        <Box px={2} mt={3}>
          <GreenButton onClick={() => onSubmit(Math.floor(offset / 1_000))}>Vyhodnotiť</GreenButton>
        </Box>
      )}
    </Box>
  );
}

const GreenButton = styled(Button)`
  background: #61ae56;
  color: white;
  &:hover {
    background: #61ae56;
    color: white;
  }
`;

const RedButton = styled(Button)`
  background: red;
  color: white;
  &:hover {
    background: red;
    color: white;
  }
`;
