import { gql, useMutation, useQuery } from '@apollo/client';
import { Loading } from '@everlutionsk/ui';
import { Box, Container } from '@mui/material';
import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import {
  SubmitT239Page1Input,
  SubmitT239Page2Input,
  SubmitT239Page3Input,
  SubmitT239Page4Input,
  SubmitT239Page5Input,
  SubmitT239Page6Input,
  SubmitT239Page8Input
} from '../../../graphql/types';
import { Complete } from '../Complete';
import { Error } from '../Error';
import { Identification } from '../Identification';
import { Identification as T239Identification } from './Identification';
import { Diagnostic as Diagnostic1, t239Page1Fragment } from './page1/Diagnostic';
import { Diagnostic as Diagnostic2, t239Page2Fragment } from './page2/Diagnostic';
import { Diagnostic as Diagnostic3, t239Page3Fragment } from './page3/Diagnostic';
import { Diagnostic as Diagnostic4, t239Page4Fragment } from './page4/Diagnostic';
import { Diagnostic as Diagnostic5, t239Page5Fragment } from './page5/Diagnostic';
import { Diagnostic as Diagnostic6, t239Page6Fragment } from './page6/Diagnostic';
import { Diagnostic as Diagnostic7, t239Page7Fragment } from './page7/Diagnostic';
import { Diagnostic as Diagnostic8, t239Page8Fragment } from './page8/Diagnostic';
import { Content } from '../components';
import { useToken } from '../useToken';

const t239AnswersFragment = gql`
  fragment T239AnswersFragment on T239Answers {
    t239AnswerPage1 {
      differentiation {
        id
        value
      }
      synthesis {
        id
        value
      }
      analysis {
        id
        value
      }
    }
    t239AnswerPage2 {
      dropdowns {
        id
        value
      }
      textInputs {
        id
        value
      }
    }
    t239AnswerPage3 {
      plhs {
        id
        value
      }
      phs {
        id
        value
      }
      lhs {
        id
        value
      }
    }
    t239AnswerPage4 {
      perception {
        id
        value
        answer
      }
      reproduction {
        id
        value
      }
    }
    t239AnswerPage5 {
      answers {
        id
        value
      }
    }
    t239AnswerPage6 {
      answers {
        id
        crossed
      }
    }
    t239AnswerPage8 {
      table1 {
        id
        crossed
      }
      table2 {
        id
        crossed
      }
      conclusion {
        id
        value
      }
      answers2 {
        id
        value
      }
    }
  }
`;

const query = gql<T239DiagnosticQueryGQL>`
  query T239DiagnosticQuery($token: String!) {
    t239Diagnostics(token: $token) {
      isValid
      isDemo
      code
      label
      status
      originalToken
      page1 {
        ...T239Page1Fragment
      }
      page2 {
        ...T239Page2Fragment
      }
      page3 {
        ...T239Page3Fragment
      }
      page4 {
        ...T239Page4Fragment
      }
      page5 {
        ...T239Page5Fragment
      }
      page6 {
        ...T239Page6Fragment
      }
      page7 {
        ...T239Page7Fragment
      }
      page8 {
        ...T239Page8Fragment
      }
      answers {
        ...T239AnswersFragment
      }
    }
  }
  ${t239Page1Fragment}
  ${t239Page2Fragment}
  ${t239Page3Fragment}
  ${t239Page4Fragment}
  ${t239Page5Fragment}
  ${t239Page6Fragment}
  ${t239Page7Fragment}
  ${t239Page8Fragment}
  ${t239AnswersFragment}
`;

export function T239() {
  const location = useLocation();
  const token = useToken();

  const { data, refetch } = useQuery(query, {
    variables: { token }
  });
  const navigate = useNavigate();

  const [mutatePage1] = useMutation(mutationPage1);
  const [mutatePage2] = useMutation(mutationPage2);
  const [mutatePage3] = useMutation(mutationPage3);
  const [mutatePage4] = useMutation(mutationPage4);
  const [mutatePage5] = useMutation(mutationPage5);
  const [mutatePage6] = useMutation(mutationPage6);
  const [mutatePage8] = useMutation(mutationPage8);

  useEffect(() => {
    refetch();
  }, [location.pathname]);

  if (data == null) return <Loading />;
  const { t239Diagnostics } = data;
  if (t239Diagnostics == null) return <Error></Error>;

  const {
    code,
    status,
    originalToken,
    answers,
    isDemo,
    isValid,
    label,
    page1,
    page2,
    page3,
    page4,
    page5,
    page6,
    page7,
    page8
  } = t239Diagnostics;

  if (!isValid) return <Error></Error>;
  if (status === 'finished') return <Complete></Complete>;

  const isReadOnly = status === 'readonly' && originalToken != null;

  return (
    <Content>
      <Container maxWidth="xl">
        <Box my={3}>
          <Routes>
            <Route
              path=""
              element={
                <Identification
                  isDemo={isDemo}
                  isReadOnly={isReadOnly}
                  code={code}
                  token={token}
                  submitLink={() => navigate('./identification')}
                  label="Diagnostika špecifických porúch učenia"
                />
              }
            />
            <Route
              path="identification"
              element={
                <T239Identification
                  isDemo={isDemo}
                  isReadOnly={isReadOnly}
                  code={code}
                  token={token}
                  label="Diagnostika špecifických porúch učenia"
                />
              }
            />
            <Route
              path="pages/1/test"
              element={
                <Diagnostic1
                  token={token}
                  isDemo={isDemo}
                  code={code}
                  label={label}
                  onSubmit={(values: SubmitT239Page1Input) => {
                    if (isDemo || isReadOnly) return navigate('pages/2/test');
                    mutatePage1({
                      variables: {
                        input: values
                      }
                    })
                      .then(() => navigate('pages/2/test'))
                      .catch(error => console.error(error));
                  }}
                  onBackClick={() => navigate('./identification')}
                  page={page1}
                  isReadOnly={isReadOnly}
                  answers={answers ?? undefined}
                />
              }
            />
            <Route
              path="pages/2/test"
              element={
                <Diagnostic2
                  token={token}
                  isDemo={isDemo}
                  code={code}
                  label={label}
                  onSubmit={(values: SubmitT239Page2Input) => {
                    if (isDemo || isReadOnly) return navigate('pages/3/test');
                    mutatePage2({
                      variables: {
                        input: values
                      }
                    })
                      .then(() => navigate('pages/3/test'))
                      .catch(error => console.error(error));
                  }}
                  onBackClick={() => navigate('pages/1/test')}
                  page={page2}
                  isReadOnly={isReadOnly}
                  answers={answers ?? undefined}
                />
              }
            />
            <Route
              path="pages/3/test"
              element={
                <Diagnostic3
                  token={token}
                  isDemo={isDemo}
                  code={code}
                  label={label}
                  onSubmit={(values: SubmitT239Page3Input) => {
                    if (isDemo || isReadOnly) return navigate('pages/4/test');
                    mutatePage3({
                      variables: {
                        input: values
                      }
                    })
                      .then(() => navigate('pages/4/test'))
                      .catch(error => console.error(error));
                  }}
                  onBackClick={() => navigate('pages/2/test')}
                  page={page3}
                  isReadOnly={isReadOnly}
                  answers={answers ?? undefined}
                />
              }
            />
            <Route
              path="pages/4/test"
              element={
                <Diagnostic4
                  token={token}
                  isDemo={isDemo}
                  code={code}
                  label={label}
                  onSubmit={(values: SubmitT239Page4Input) => {
                    if (isDemo || isReadOnly) return navigate('pages/5/test');
                    mutatePage4({
                      variables: {
                        input: values
                      }
                    })
                      .then(() => navigate('pages/5/test'))
                      .catch(error => console.error(error));
                  }}
                  onBackClick={() => navigate('pages/3/test')}
                  page={page4}
                  isReadOnly={isReadOnly}
                  answers={answers ?? undefined}
                />
              }
            />
            <Route
              path="pages/5/test"
              element={
                <Diagnostic5
                  token={token}
                  isDemo={isDemo}
                  code={code}
                  label={label}
                  onSubmit={(values: SubmitT239Page5Input) => {
                    if (isDemo || isReadOnly) return navigate('pages/6/test');
                    mutatePage5({
                      variables: {
                        input: values
                      }
                    })
                      .then(() => navigate('pages/6/test'))
                      .catch(error => console.error(error));
                  }}
                  onBackClick={() => navigate('pages/4/test')}
                  page={page5}
                  isReadOnly={isReadOnly}
                  answers={answers?.t239AnswerPage5?.answers ?? []}
                />
              }
            />
            <Route
              path="pages/6/test"
              element={
                <Diagnostic6
                  token={token}
                  isDemo={isDemo}
                  code={code}
                  label={label}
                  onSubmit={(values: SubmitT239Page6Input) => {
                    if (isDemo || isReadOnly) return navigate('pages/7/test');
                    mutatePage6({
                      variables: {
                        input: values
                      }
                    })
                      .then(() => navigate('pages/7/test'))
                      .catch(error => console.error(error));
                  }}
                  onBackClick={() => navigate('pages/5/test')}
                  page={page6}
                  isReadOnly={isReadOnly}
                  answers={answers?.t239AnswerPage6?.answers ?? []}
                />
              }
            />
            <Route
              path="pages/7/test"
              element={
                <Diagnostic7
                  isDemo={isDemo}
                  code={code}
                  label={label}
                  onSubmit={() => navigate('pages/8/test')}
                  onBackClick={() => navigate('pages/6/test')}
                  page={page7}
                />
              }
            />
            <Route
              path="pages/8/test"
              element={
                <Diagnostic8
                  token={token}
                  code={code}
                  label={label}
                  onSubmit={(values: SubmitT239Page8Input) => {
                    if (isDemo) return navigate('complete');
                    mutatePage8({
                      variables: {
                        input: values
                      }
                    })
                      .then(() => navigate('complete'))
                      .catch(error => console.error(error));
                  }}
                  onBackClick={() => navigate('pages/7/test')}
                  page={page8}
                  isReadOnly={isReadOnly}
                  answers={answers?.t239AnswerPage8 ?? undefined}
                />
              }
            />
            <Route path="complete" element={<Complete />} />
          </Routes>
        </Box>
      </Container>
    </Content>
  );
}

const mutationPage1 = gql<SubmitT239Page1GQL>`
  mutation SubmitT239Page1($input: SubmitT239Page1Input!) {
    submitT239Page1(input: $input)
  }
`;

const mutationPage2 = gql<SubmitT239Page2GQL>`
  mutation SubmitT239Page2($input: SubmitT239Page2Input!) {
    submitT239Page2(input: $input)
  }
`;

const mutationPage3 = gql<SubmitT239Page3GQL>`
  mutation SubmitT239Page3($input: SubmitT239Page3Input!) {
    submitT239Page3(input: $input)
  }
`;

const mutationPage4 = gql<SubmitT239Page4GQL>`
  mutation SubmitT239Page4($input: SubmitT239Page4Input!) {
    submitT239Page4(input: $input)
  }
`;

const mutationPage5 = gql<SubmitT239Page5GQL>`
  mutation SubmitT239Page5($input: SubmitT239Page5Input!) {
    submitT239Page5(input: $input)
  }
`;

const mutationPage6 = gql<SubmitT239Page6GQL>`
  mutation SubmitT239Page6($input: SubmitT239Page6Input!) {
    submitT239Page6(input: $input)
  }
`;

const mutationPage8 = gql<SubmitT239Page8GQL>`
  mutation SubmitT239Page8($input: SubmitT239Page8Input!) {
    submitT239Page8(input: $input)
  }
`;
