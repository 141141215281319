import { gql, useMutation, useQuery } from '@apollo/client';
import { Loading } from '@everlutionsk/ui';
import { useNavigate } from '@everlutionsk/ui-router';
import { Box, Container } from '@mui/material';
import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Complete } from '../Complete';
import { Error } from '../Error';
import { Diagnostic, smkzdTableFragment } from './Diagnostic';
import { SmkzdIdentification } from './Identification';
import { Instructions } from './Instructions';
import { Content } from '../components';
import { useToken } from '../useToken';

export function SMKZD() {
  const location = useLocation();
  const token = useToken();

  const { data, refetch } = useQuery(query, {
    variables: { token }
  });

  const navigate = useNavigate();

  const [mutate] = useMutation(mutation);

  useEffect(() => {
    refetch();
  }, [location.pathname]);

  if (data == null) return <Loading />;

  const { smkzdDiagnostics } = data;
  if (smkzdDiagnostics == null) return <Error></Error>;

  const { code, status, originalToken, answers, instructions, isDemo, isValid, label } =
    smkzdDiagnostics;

  if (!isValid) return <Error></Error>;
  if (status === 'finished') return <Complete />;

  const isReadOnly = status === 'readonly' && originalToken != null;

  return (
    <Content>
      <Container maxWidth="xl">
        <Box my={3}>
          <Routes>
            <Route
              path=""
              element={
                <SmkzdIdentification
                  token={token}
                  isDemo={isDemo}
                  code={code}
                  label={label}
                  isReadOnly={isReadOnly}
                />
              }
            />
            <Route
              path="instructions"
              element={
                <Instructions
                  code={code}
                  instructions={instructions}
                  label={label}
                  onBackClick={() => navigate('')}
                  onSubmit={() => navigate('./test')}
                />
              }
            />
            <Route
              path="test"
              element={
                <Diagnostic
                  code={code}
                  isDemo={isDemo}
                  table={smkzdDiagnostics}
                  label={label}
                  token={token}
                  isReadOnly={isReadOnly}
                  onBackClick={() => navigate('./instructions')}
                  onSubmit={input => {
                    mutate({ variables: { input } })
                      .then(() => navigate('./complete'))
                      .catch(error => console.error(error));
                  }}
                  initialValues={
                    isDemo
                      ? Object.assign(
                          {},
                          ...Array.from(smkzdDiagnostics.questions, (question, i) => ({
                            [`answer-${question.id}`]: 1
                          }))
                        )
                      : isReadOnly
                        ? Object.assign(
                            {},
                            ...Array.from(smkzdDiagnostics.questions, (question, i) => ({
                              [`answer-${question.id}`]: answers.find(
                                item => item.id === question.id
                              )?.radioValue
                            }))
                          )
                        : {}
                  }
                />
              }
            />
            <Route path="complete" element={<Complete />} />
          </Routes>
        </Box>
      </Container>
    </Content>
  );
}

const query = gql<SmkzdDiagnosticQueryGQL>`
  query SmkzdDiagnosticQuery($token: String!) {
    smkzdDiagnostics(token: $token) {
      isValid
      isDemo
      code
      label
      instructions
      status
      originalToken
      answers {
        id
        radioValue
      }
      ...SmkzdQuestionTable
    }
  }
  ${smkzdTableFragment}
`;

const mutation = gql<SubmitTestSmkzdGQL>`
  mutation SubmitTestSmkzd($input: SubmitSmkzdInput!) {
    submitSmkzd(input: $input)
  }
`;
