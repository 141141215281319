import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import { useFixedId } from '../../../../components/helpers';

interface Props {
  readonly initialValue?: string;
  readonly disabled?: boolean;
  readonly templateFigure: { img: string };
  readonly options: Array<{ value: string; img: string; letter: string }>;
  readonly onChange: (value: string) => void;
}

// TODO: refactor, avoid using radio groups, instead use buttons, similar to APlusBSelector
export function FigureSelector({
  initialValue,
  options,
  templateFigure,
  onChange,
  disabled = false
}: Props) {
  const id = useFixedId();
  const [selected, setSelected] = useState<string | undefined>();

  useEffect(() => {
    const initialOption = options.find(option => option.value === initialValue);
    updateState(initialOption?.value);
  }, [initialValue]);

  function updateState(value: string | undefined) {
    if (value == null) return;
    setSelected(value);
    onChange(value);
  }

  return (
    <RadioWrapper spacing={2} container>
      {templateFigure && (
        <Grid item xs={12} lg={3}>
          <Box my={2} className="task-wrapper-row">
            <img src={templateFigure.img} alt="X" />
          </Box>
        </Grid>
      )}
      <Grid item xs={12} lg={9}>
        <ul className={`options-grid-row`}>
          {options.map(item => {
            return (
              <li className="options-item" key={item.value}>
                <input
                  name={id}
                  type="radio"
                  disabled={disabled}
                  id={item.value}
                  checked={item.value === selected}
                  onChange={() => updateState(item.value)}
                />
                <label htmlFor={item.value}>
                  <img src={item.img} alt={item.value} />
                  <Letter>({item.letter})</Letter>
                </label>
              </li>
            );
          })}
        </ul>
      </Grid>
    </RadioWrapper>
  );
}

const Letter = styled('span')`
  display: block;
  padding-bottom: 0.5rem;
`;

const RadioWrapper = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  .task-wrapper-row {
    text-align: center;
    position: relative;
    padding: 0.5rem;
    border: 3px solid #111;
    img {
      max-width: 150px;
      width: 100%;
    }
  }
  .options-grid-row {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    list-style-type: none;
    padding-right: 1rem;
    justify-content: center;
    @media (max-width: 991px) {
      flex-wrap: wrap;
    }
    .options-item {
      margin: 0 0.5rem;
      &:hover {
        label {
          position: relative;
          &:before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(255, 78, 78, 0.8);
            z-index: 2;
            cursor: pointer;
            transition: all ease 150ms;
            border-radius: 10px;
            border: 3px solid rgba(255, 78, 78, 0.95);
          }
        }
      }
      label {
        font-size: 1rem;
        display: block;
        height: 100%;
        width: 100%;
        text-align: center;
        img {
          max-width: 150px;
          max-height: 150px;
          margin: 0 auto;
        }
      }
      [type='radio']:checked,
      [type='radio']:not(:checked) {
        position: absolute;
        left: -9999px;
      }
      [type='radio']:not(:checked) + label {
        position: relative;
        cursor: pointer;
      }
      [type='radio']:checked + label {
        position: relative;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: rgba(129, 175, 138, 0.8);
          z-index: 2;
          cursor: pointer;
          transition: all ease 150ms;
          border-radius: 10px;
          border: 3px solid rgba(129, 175, 138, 0.95);
        }
      }
    }
  }
`;
