import { useNavigate } from '@everlutionsk/ui-router';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { PaperContent } from '../../../components/PaperContent';

interface Props {
  readonly label: string;
  readonly code: string;
  readonly onBackClick: () => void;
  readonly onSubmit: () => void;
}

export function Version({ code, label, onBackClick, onSubmit }: Props) {
  const navigate = useNavigate();
  return (
    <PaperContent title={label} testNumber={code}>
      <Box mt={{ xs: 3, md: 9 }} mb={{ xs: 3, md: 6 }} position="relative">
        <Typography component="h3" variant="h4">
          Vyberte variant
        </Typography>
        <Box my={3}>
          <Button
            variant="contained"
            color="primary"
            endIcon={<ChevronRight />}
            onClick={() => navigate('../test/v1')}
          >
            Variant A
          </Button>
        </Box>
        <Box my={3}>
          <Button
            variant="contained"
            color="primary"
            endIcon={<ChevronRight />}
            onClick={() => navigate('../test/v2')}
          >
            Variant B
          </Button>
        </Box>
        <Box my={3}>
          <Button
            variant="contained"
            color="primary"
            endIcon={<ChevronRight />}
            onClick={() => navigate('../test/v3')}
          >
            Variant C
          </Button>
        </Box>
        <Box mt={4}>
          <Box my={3}>
            <Button variant="outlined" onClick={onBackClick} color="primary">
              Krok späť
            </Button>
          </Box>
        </Box>
      </Box>
    </PaperContent>
  );
}
