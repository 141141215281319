import { Box, Typography } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material';
import { T309ChoiceSelector } from '../components/ChoiceSelector';

export function Instructions() {
  const choices1 = [
    { value: 'i-5-1', label: 'vzdialený' },
    { value: 'i-5-2', label: 'šikroký' },
    { value: 'i-5-3', label: 'vysoký' },
    { value: 'i-5-4', label: 'hlboký' },
    { value: 'i-5-5', label: 'blízky' }
  ];
  const choices2 = [
    { value: 'i-6-1', label: 'les' },
    { value: 'i-6-2', label: 'konár' },
    { value: 'i-6-3', label: 'strom' },
    { value: 'i-6-4', label: 'lístie' },
    { value: 'i-6-5', label: 'zelený' }
  ];

  return (
    <>
      <Box my={3}>
        <Typography component="h5" variant="h5">
          Odhaľovanie vzťahov - inštrukcie
        </Typography>
      </Box>
      <Box my={3}>
        <Typography component="p" variant="body2">
          V prvom kroku Vám budú predložené tri slová. Medzi prvým z nich a druhým existuje istý
          vzťah. Medzi tretím z predložených slov a jedným z piatich voliteľných slov A až E je
          takisto podobný vzťah. Vašou úlohou je z piatich možností vybrať takéto slovo.
        </Typography>
      </Box>
      <Box my={3}>
        <Typography component="p" variant="body2">
          Príklad:
        </Typography>
      </Box>
      <TableDiv my={3} display="flex">
        <Box className="cell">
          <Typography component="h5" variant="h5">
            B9.
          </Typography>
        </Box>
        <Box className="cell">
          <T309ChoiceSelector
            initialValue="i-5-5"
            options={choices1}
            disabled
            sentenceText="veľký : malý = ďaleký : #placeholder#."
            onChange={() => {}}
          ></T309ChoiceSelector>
        </Box>
      </TableDiv>
      <Box my={3}>
        <Typography component="p" variant="body2">
          Keďže ”malý” je protikladom k pojmu "veľký”, musí sa k slovu "ďaleký” nájsť takisto
          protiklad. Správnym riešením je teda "blízky”. Preto je vo Vašom Odpoveďovom hárku pri
          úlohe B9 zaznačené písmeno E.
        </Typography>
      </Box>
      <Box mb={3} mt={5}>
        <Typography component="p" variant="body2">
          Ďalší príklad:
        </Typography>
      </Box>
      <TableDiv my={3} display="flex">
        <Box className="cell">
          <Typography component="h5" variant="h5">
            B10.
          </Typography>
        </Box>
        <Box className="cell">
          <T309ChoiceSelector
            initialValue="i-6-3"
            options={choices2}
            disabled
            sentenceText="špenát : zelenina = breza : #placeholder#."
            onChange={() => {}}
          ></T309ChoiceSelector>
        </Box>
      </TableDiv>
      <Box my={3}>
        <Typography component="p" variant="body2">
          Špenát je zelenina, t.j. "zelenina” je nadradeným pojmom slova "špenát”. K "breze” teda
          treba nájsť tiež nadradený pojem. Správnym riešením je preto "strom” (C).
        </Typography>
      </Box>
      <Box my={3}>
        <Typography component="p" variant="body2">
          Zaznačte preto, prosím, vždy to písmeno, ktoré označuje správnu odpoveď.
        </Typography>
      </Box>
      <Box my={3}>
        <Typography component="p" variant="body2">
          Na riešenie nasledujúcich <strong>12 úloh</strong> máte čas <strong>4 minúty</strong>.{' '}
          <strong>Počkajte na vyzvanie stlačiť tlačidlo ĎALEJ.</strong>
        </Typography>
      </Box>
    </>
  );
}

const TableDiv = styled(Box)`
  border: 1px solid #ccc;
  .cell {
    &:first-child {
      padding: 1rem;
    }
    &:last-child {
      border-left: 1px solid #ccc;
      padding: 0 1rem 1rem 1rem;
    }
  }
`;
