import { gql } from '@apollo/client';
import { useParams } from '@everlutionsk/ui-router';
import { Box, Button } from '@mui/material';
import React from 'react';
import { PaperContent } from '../../../components/PaperContent';
import { SubmitT202Page1Input, T202ArticleFragment } from '../../../graphql/types';
import { Error } from '../Error';
import { WordsSelectorWrapper } from './components/WordsSelectorWrapper';
import { toDocumentUrl } from './documents';


interface Props {
  readonly code: string;
  readonly token: string;
  readonly firstInterval: number;
  readonly secondInterval: number;
  readonly thirdInterval: number;
  readonly onBackClick: (articleId: string) => void;
  readonly onSubmit: (values: Omit<SubmitT202Page1Input, 'articleId'>, articleId: string) => void;
  readonly articles: T202ArticleFragment[];
  readonly isReadOnly: boolean;
  readonly answers:
    | {
        firstMinuteWord: number;
        secondMinuteWord: number;
        thirdMinuteWord: number;
        duration: number;
        mistakes: number[];
      }
    | undefined;
  isDemo: boolean;
}

export const t202ArticleFragment = gql`
  fragment T202ArticleFragment on T202Article {
    id
    order
    title
    label
    rows {
      words {
        index
        value
      }
      length
    }
    description
    wordsCount
    documents {
      printTemplate {
        key
        label
      }
      printControlText {
        key
        label
      }
    }
  }
`;

export function Diagnostic({
  token,
  code,
  firstInterval,
  secondInterval,
  thirdInterval,
  onBackClick,
  onSubmit,
  articles,
  isReadOnly,
  answers,
  isDemo
}: Props) {
  const { articleId } = useParams(['articleId']);

  const article = articles.find(article => article.id === articleId);
  if (article == null) return <Error></Error>;

  return (
    <PaperContent title={article.title} testNumber={code}>
      <WordsSelectorWrapper
        isDemo={isDemo}
        firstInterval={firstInterval}
        secondInterval={secondInterval}
        thirdInterval={thirdInterval}
        token={token}
        rows={article.rows}
        printTemplate={article.documents.printTemplate}
        printControlText={article.documents.printControlText}
        toDocumentUrl={toDocumentUrl}
        onSubmit={values => onSubmit(values, articleId)}
        isReadOnly={isReadOnly}
        answers={answers}
      ></WordsSelectorWrapper>
      <Box my={3}>
        <Button variant="outlined" onClick={() => onBackClick(articleId)} color="primary">
          Krok späť
        </Button>
      </Box>
    </PaperContent>
  );
}
