import { gql, useMutation, useQuery } from '@apollo/client';
import { project } from '@everlutionsk/helpers';
import { Button, Loading } from '@everlutionsk/ui';
import { createFormSpec, SelectField, TextField } from '@everlutionsk/ui-formik';
import { useParams } from '@everlutionsk/ui-router';
import Check from '@mui/icons-material/Check';
import { Box, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import * as yup from 'yup';
import { DatePickerField } from '../../../components/forms/DatePickerField';
import { PaperContent } from '../../../components/PaperContent';
import { Gender, T41DiagnosticVersion } from '../../../graphql/types';
import { InputList } from '../Identification/InputList';
import { InputWrapper } from '../Identification/InputWrapper';
import { toVersionLabel } from './helpers';
import { identificationQuery } from './Identification';

interface Props {
  readonly token: string;
  readonly isDemo: boolean;
  readonly isReadOnly: boolean;
  readonly code: string;
  readonly label: string;
  readonly onBackClick: () => void;
  readonly answers: { duration: number; version: string } | undefined;
}

interface FormValues {
  fullName: string;
  birthMonth: Date;
  birthYear: Date;
  gender: Gender;
  grade: number;
  date: Date;
  diagnosticVersion: string;
  minutes: string;
  seconds: string;
}

export function InsertResults({ code, isDemo, token, label, onBackClick, isReadOnly }: Props) {
  const navigate = useNavigate();
  const { version } = useParams(['version']);
  const [minDate] = useState<Date | undefined>();
  const { data } = useQuery(identificationQuery, { variables: { token } });
  const [mutate] = useMutation(t41Mutation);

  if (data == null) return <Loading />;

  return (
    <PaperContent title={label} testNumber={code}>
      <Typography component="h3" variant="h4">
        Identifikácia
      </Typography>
      <Typography component="p" variant="body2">
        Prosíme, aby ste vyplnili nasledujúce údaje:
      </Typography>
      <Box my={3}>
        <Formik
          {...spec({
            fullName: data?.studentOneByToken.fullName,
            birthMonth: new Date(data?.studentOneByToken.dateOfBirth) ?? new Date(),
            birthYear: new Date(data?.studentOneByToken.dateOfBirth) ?? new Date(),
            gender: data?.studentOneByToken.gender ?? undefined,
            grade: data?.studentOneByToken.grade ?? 9,
            date: new Date(),
            diagnosticVersion: toVersionLabel(version),
            minutes: '',
            seconds: ''
          })}
          onSubmit={async ({ minutes, seconds, grade }) => {
            const duration = parseInt(minutes) * 60 + parseInt(seconds);
            if (isNaN(duration)) {
              console.error('Invalid input, diagnostic duration is not a number.');
              return;
            }
            await mutate({
              variables: {
                input: {
                  token,
                  duration,
                  version: project(version, T41DiagnosticVersion),
                  grade
                }
              }
            });
            navigate('../../complete');
          }}
        >
          <Form>
            <InputList>
              <InputWrapper label={`Identifikátor klienta`}>
                <TextField disabled name="fullName" type="firstName" variant="standard" />
              </InputWrapper>
              <InputWrapper label={`Mesiac narodenia`}>
                <DatePickerField
                  disabled
                  name="birthMonth"
                  views={['month']}
                  size={'small'}
                  format={'MMMM'}
                />
              </InputWrapper>
              <InputWrapper label={`Rok narodenia`}>
                <DatePickerField
                  disabled
                  name="birthYear"
                  views={['year']}
                  minDate={minDate}
                  size={'small'}
                  format={'YYYY'}
                />
              </InputWrapper>
              <InputWrapper label={`Pohlavie`}>
                <SelectField
                  disabled
                  options={genderOptions}
                  name="gender"
                  variant="standard"
                  className={`disabled-select`}
                />
              </InputWrapper>
              <InputWrapper label={`Trieda`}>
                <SelectField
                  options={gradeOptions}
                  name="grade"
                  variant="standard"
                  disabled={Boolean(isDemo) || isReadOnly}
                />
              </InputWrapper>
              <InputWrapper label={`Dátum posudzovania žiaka`}>
                <DatePickerField
                  name="date"
                  minDate={minDate}
                  size={'small'}
                  // views={['date']}
                  disabled={isReadOnly}
                />
              </InputWrapper>
              <InputWrapper label={`Verzia testu`}>
                <TextField disabled name="diagnosticVersion" type="text" variant="standard" />
              </InputWrapper>
              <InputWrapper label={`Čas testovania`}>
                <Box display="flex">
                  <Box mr={3}>
                    <TextField
                      disabled={isReadOnly}
                      name="minutes"
                      label="minút"
                      type="number"
                      variant="standard"
                    />
                  </Box>
                  <Box>
                    <TextField
                      disabled={isReadOnly}
                      name="seconds"
                      label="sekúnd"
                      type="number"
                      InputProps={{ inputProps: { min: 0, max: 60 } }}
                      variant="standard"
                    />
                  </Box>
                </Box>
              </InputWrapper>
            </InputList>
            <Box my={3}>
              <Button size="large" color="secondary" onClick={onBackClick}>
                Krok späť
              </Button>
              <Button
                type="submit"
                size="medium"
                variant="contained"
                startIcon={<Check />}
                style={{ marginLeft: '10px' }}
                disabled={isReadOnly}
              >
                Vyhodnotiť
              </Button>
            </Box>
          </Form>
        </Formik>
      </Box>
    </PaperContent>
  );
}

const spec = createFormSpec<FormValues>({
  fullName: yup.string().nullable().required('Vyberte identifikátor klienta'),
  birthMonth: yup.date().nullable().required('Vyberte mesiac narodenia'),
  birthYear: yup.date().nullable().required('Vyberte rok narodenia'),
  gender: yup.string().nullable().required('Vyberte pohlavie klienta'),
  grade: yup.number().required('Prosím, zadajte triedu').min(3, 'Test dostupný len od 3. triedy'),
  date: yup.date().nullable().required('Dátum posudzovania žiaka'),
  diagnosticVersion: yup.string().nullable().required(),
  minutes: yup.string().required('Prosím, zadaj minúty'),
  seconds: yup.string().required('Prosím, zadaj sekundy')
});

const genderOptions = [
  { value: 'male', label: 'Mužské' },
  { value: 'female', label: 'Ženské' }
];

const gradeOptions = [
  { label: '1.', value: 1 },
  { label: '2.', value: 2 },
  { label: '3.', value: 3 },
  { label: '4.', value: 4 },
  { label: '5.', value: 5 },
  { label: '6.', value: 6 },
  { label: '7.', value: 7 },
  { label: '8.', value: 8 },
  { label: '9.', value: 9 }
];

export const t41Mutation = gql<SubmitT41GQL>`
  mutation SubmitT41($input: SubmitT41Input!) {
    submitT41(input: $input)
  }
`;
