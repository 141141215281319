import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { InputLabel, SxProps, Theme, Typography } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateView } from '@mui/x-date-pickers/models';
import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import sk from 'dayjs/locale/sk';

dayjs.locale(sk);

interface InputDateProps {
  /**
   * Placeholder of the input
   * */
  placeholder?: string;
  /**
   * Label of the input
   * */
  label?: string;
  /**
   * Disabled state of the input
   * */
  disabled?: boolean;
  /**
   * Name of the input
   * */
  name?: string;
  /**
   * Value of the input
   *  */
  value?: any;
  /**
   * On change handler of the input
   * */
  onChange?: (value: any) => void;
  /**
   * Is input required ?
   * */
  required?: boolean;
  /**
   * Type of the autocomplete for input
   * */
  autoComplete?: string;
  /**
   * Type of the input
   * */
  type?: React.HTMLInputTypeAttribute;
  /**
   * Min value of the input
   * */
  min?: number;
  /**
   * Max value of the input
   * */
  max?: number;
  /**
   * Sx props of the input
   * */
  sx?: SxProps<Theme>;
  /**
   * Error of the input
   * */
  error?: string;
  /**
   * Max date of the input
   */
  maxDate?: Date | Dayjs;
  /**
   * Min date of the input
   */
  minDate?: Date | Dayjs;

  views?: readonly DateView[];
  format?: string;

  open?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}

export function InputDate({
  placeholder,
  label,
  sx,
  error,
  format = 'DD/MM/YYYY',
  views,
  ...props
}: InputDateProps) {
  const inputStyles = props.disabled ? inputDisabledStyles : inputEnabledStyles;
  const inputError = error ? inputErrorStyles : null;
  const mergedStyles = { ...inputBaseStyles, ...inputStyles, ...inputError };

  return (
    <InputLabel sx={{ maxWidth: '100%', display: 'block', ...sx }}>
      <LabelTypography label={label} />
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="sk">
        <DatePicker
          slots={{ openPickerIcon: CalendarTodayOutlinedIcon, switchViewIcon: ExpandMoreIcon }}
          sx={mergedStyles}
          format={format}
          views={views}
          {...props}
          slotProps={{ textField: { placeholder } }}
        />
      </LocalizationProvider>
      <ErrorTypography error={error} />
    </InputLabel>
  );
}

function LabelTypography({ label }: { label?: string }) {
  if (!label) return null;

  return <Typography /* variant=""*/ component="p">{label}</Typography>;
}

function ErrorTypography({ error }: { error?: string }) {
  if (!error) return null;

  return (
    <Typography
      // variant="bodyXsEmphasis"
      component="p"
      sx={{
        // color: theme => theme.brandPalette.foreground.negativeOffset,
        mt: 2,
        maxWidth: '100%',
        whiteSpace: 'normal'
      }}
    >
      {error}
    </Typography>
  );
}

const inputBaseStyles: SxProps<Theme> = {
  width: '100%',

  input: {
    margin: '-15px',
    color: 'neutral.main'
  }
};

const inputErrorStyles: SxProps<Theme> = {
  // borderColor: theme => theme.brandPalette.foreground.negativeOffset
};

const inputDisabledStyles: SxProps<Theme> = {
  border: '1px solid transparent',

  '.MuiInputBase-root': {
    // background: theme => theme.brandPalette.background.disabled,
    // borderRadius: theme => theme.radius.m + 'px',
    cursor: 'disabled',

    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '0px !important'
    }
  },

  'input.Mui-disabled': {
    // WebkitTextFillColor: theme => theme.brandPalette.foreground.disabled
  }
};

const inputEnabledStyles: SxProps<Theme> = {
  '.MuiInputBase-root': {
    // borderRadius: theme => theme.radius.m + 'px',
    border: '1px solid transparent',
    // background: theme => theme.brandPalette.background.secondary,
    // color: theme => theme.brandPalette.foreground.neutral,
    transition: 'all 0.2s ease-in-out',

    svg: {
      // color: theme => theme.brandPalette.foreground.inputIcon
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '0px !important'
    }
  },

  '.MuiPickersDay-root': {
    color: 'transparent'
  },

  '&:focus-within': {
    // background: theme => theme.brandPalette.background.neutral
  },

  'input:placeholder': {
    // color: theme => theme.brandPalette.foreground.placeholder
  },

  fieldset: {
    display: 'none'
  }
};
