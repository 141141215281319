import { gql, useMutation, useQuery } from '@apollo/client';
import { Button, Loading } from '@everlutionsk/ui';
import { createFormSpec, SelectField } from '@everlutionsk/ui-formik';
import { useNavigate } from '@everlutionsk/ui-router';
import Check from '@mui/icons-material/Check';
import { Box, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { PaperContent } from '../../../components/PaperContent';
import { InputList } from '../Identification/InputList';
import { InputWrapper } from '../Identification/InputWrapper';

interface Props {
  readonly code: string;
  readonly isDemo: boolean;
  readonly label: string;
  readonly token: string;
  readonly isReadOnly: boolean;
}

const mutation = gql<SubmitT252IdentificationGQL>`
  mutation SubmitT252Identification($input: UpdateStudentByTokenInput!) {
    updateStudentByToken(input: $input) {
      id
      birthIdentificationNumber
      firstName
      lastName
      fullName
      dateOfBirth
      gender
      education
    }
  }
`;

const query = gql<T252StudentOneByTokenGQL>`
  query T252StudentOneByToken($token: String!) {
    studentOneByToken(token: $token) {
      id
      birthIdentificationNumber
      firstName
      lastName
      fullName
      dateOfBirth
      gender
      education
    }
  }
`;

interface FormValues {
  education: string | undefined;
}

export function Identification({ isDemo, code, label, token, isReadOnly }: Props) {
  const navigate = useNavigate();

  const { data } = useQuery(query, { variables: { token } });
  const [mutate] = useMutation(mutation);

  if (data == null) return <Loading />;

  const spec = createFormSpec<FormValues>({
    education: yup
      .string()
      .notRequired()
      .when('demo', () => {
        if (isDemo) return yup.mixed();
        return yup.string().required('Vyberte vzdelanie klienta');
      })
  });

  return (
    <PaperContent title={label} testNumber={code}>
      <Typography component="h3" variant="h4">
        Identifikácia
      </Typography>
      <Typography component="p" variant="body2">
        Prosíme, aby ste vyplnili nasledujúce údaje:
      </Typography>
      <Box my={3}>
        <Formik
          {...spec({
            education: data.studentOneByToken.education
              ? data.studentOneByToken.education
              : isReadOnly
                ? '-'
                : undefined
          })}
          onSubmit={async ({ education }) => {
            if (isDemo) {
              navigate('../pages/1/test');
              return;
            }

            await mutate({
              variables: {
                input: {
                  token,
                  education
                }
              }
            });

            navigate('../pages/1/test');
          }}
        >
          <Form>
            <InputList>
              <InputWrapper label={`Vzdelanie`}>
                <SelectField
                  variant="standard"
                  options={educationOptions}
                  name="education"
                  disabled={Boolean(isDemo) || isReadOnly}
                />
              </InputWrapper>
            </InputList>
            <Box mt={3}>
              <Box my={3}>
                <Button type="submit" size="medium" color="primary" startIcon={<Check />}>
                  Pokračovať na test
                </Button>
              </Box>
              <Box my={3}>
                <Button
                  variant="outlined"
                  onClick={() => navigate(`../instructions`)}
                  color="primary"
                >
                  Krok späť
                </Button>
              </Box>
            </Box>
          </Form>
        </Formik>
      </Box>
    </PaperContent>
  );
}

const educationOptions = [
  { label: 'Ukončená ZŠ', value: 'Ukončená ZŠ' },
  { label: 'Ukončená SŠ', value: 'Ukončená SŠ' },
  { label: 'Ukončená VŠ', value: 'Ukončená VŠ' }
];
