import { gql } from '@apollo/client';
import {
  Box,
  Button,
  Table as MaterialTable,
  MenuItem,
  Paper,
  Select,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import { styled } from '@mui/material';
import { PaperContent } from '../../../../components/PaperContent';
import {
  SubmitT239Page1Input,
  T239AnswersFragment,
  T239Page1Fragment
} from '../../../../graphql/types';
import { computePercentile, getMaxValue } from '../helpers';

interface Props {
  readonly token: string;
  readonly label: string;
  readonly code: string;
  readonly page: T239Page1Fragment;
  readonly onSubmit: (values: SubmitT239Page1Input) => void;
  readonly onBackClick: () => void;
  readonly isDemo: boolean;
  readonly isReadOnly: boolean;
  readonly answers: T239AnswersFragment | undefined;
}

export const t239Page1Fragment = gql`
  fragment T239Page1Fragment on T239Page1 {
    id
    label
    header1 {
      label1
      label2
      label3
      label4
      label5
    }
    header2 {
      label1
      label2
      label3
      label4
      label5
      label6
      label7
    }
    questions {
      differentiation {
        id
        defaultValue
        label
      }
      synthesis {
        id
        defaultValue
        label
      }
      analysis {
        id
        defaultValue
        label
      }
    }
    choices {
      value
      label
    }
  }
`;

export function Diagnostic({
  token,
  code,
  label,
  page,
  onSubmit,
  onBackClick,
  isDemo,
  isReadOnly,
  answers
}: Props) {
  const [differentiation, setDifferentiation] = useState<number[]>(() =>
    page.questions.differentiation.map(question =>
      isReadOnly
        ? (answers?.t239AnswerPage1?.differentiation.find(item => item.id === question.id)?.value ??
          question.defaultValue)
        : question.defaultValue
    )
  );
  const [synthesis, setSynthesis] = useState<number[]>(() =>
    page.questions.synthesis.map(question =>
      isReadOnly
        ? (answers?.t239AnswerPage1?.synthesis.find(item => item.id === question.id)?.value ??
          question.defaultValue)
        : question.defaultValue
    )
  );
  const [analysis, setAnalysis] = useState<number[]>(() =>
    page.questions.analysis.map(question =>
      isReadOnly
        ? (answers?.t239AnswerPage1?.analysis.find(item => item.id === question.id)?.value ??
          question.defaultValue)
        : question.defaultValue
    )
  );

  function getResults(
    specsArray: Array<{ id: string }>,
    values: number[]
  ): Array<{ id: string; value: number }> {
    return specsArray.map((spec, index) => {
      return {
        id: spec.id,
        value: values[index]
      };
    });
  }

  const maxValueOption = getMaxValue([...page.choices]);

  return (
    <PaperContent title={label} testNumber={code}>
      <div>{page.label}</div>
      <Box mt={{ xs: 3, md: 3 }} mb={{ xs: 3, md: 3 }} position="relative">
        <TableContainer component={Paper}>
          <MaterialTable>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>
                  <Box display="flex" justifyContent="space-around">
                    <Box>
                      <Typography component="span" variant="h6">
                        {page.header1.label1}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography component="div" variant="body2">
                        {page.header1.label2}
                      </Typography>
                      <Typography component="div" variant="body2">
                        {page.header1.label3}
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell colSpan={2}>{page.header1.label4}</TableCell>
                <TableCell colSpan={2}>{page.header1.label5}</TableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>
                  <Box>
                    <Typography component="h6" variant="h6">
                      {page.header2.label1}
                    </Typography>
                    <Typography component="span" variant="body2">
                      {page.header2.label2}
                    </Typography>
                  </Box>
                </StyledTableCell>
                <StyledTableCell>
                  <Box>
                    <Typography component="h6" variant="h6">
                      {page.header2.label3}
                    </Typography>
                  </Box>
                </StyledTableCell>
                <StyledTableCell>
                  <Box>
                    <Typography component="h6" variant="h6">
                      {page.header2.label4}
                    </Typography>
                  </Box>
                </StyledTableCell>
                <StyledTableCell>
                  <Box>
                    <Typography component="h6" variant="h6">
                      {page.header2.label5}
                    </Typography>
                  </Box>
                </StyledTableCell>
                <StyledTableCell>
                  <Box>
                    <Typography component="h6" variant="h6">
                      {page.header2.label6}
                    </Typography>
                  </Box>
                </StyledTableCell>
                <StyledTableCell>
                  <Box>
                    <Typography component="h6" variant="h6">
                      {page.header2.label7}
                    </Typography>
                  </Box>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {page.questions.differentiation.map((diffQuestion, index) => {
                const synthesisQuestion = page.questions.synthesis[index];
                const analysisQuestion = page.questions.analysis[index];

                return (
                  <TableRow key={diffQuestion.id}>
                    <TableCell>{diffQuestion.label}</TableCell>
                    <TableCell>
                      <Select
                        id={diffQuestion.id}
                        variant="standard"
                        onChange={event => {
                          if (
                            event.target.value != null &&
                            typeof event.target.value === 'number'
                          ) {
                            const value = event.target.value as number;
                            const mutatedDifferentiation = [...differentiation];
                            mutatedDifferentiation[index] = value;
                            setDifferentiation(mutatedDifferentiation);
                          }
                        }}
                        disabled={isReadOnly}
                        value={differentiation[index]}
                      >
                        {page.choices.map((option, i) => {
                          return (
                            <MenuItem
                              value={option.value}
                              key={option.label + i}
                              disabled={isReadOnly}
                            >
                              {option.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </TableCell>
                    <TableCell>{synthesisQuestion.label}</TableCell>
                    <TableCell>
                      <Select
                        id={synthesisQuestion.id}
                        variant="standard"
                        onChange={event => {
                          if (
                            event.target.value != null &&
                            typeof event.target.value === 'number'
                          ) {
                            const { value } = event.target;
                            const mutatedSynthesis = [...synthesis];
                            mutatedSynthesis[index] = value;
                            setSynthesis(mutatedSynthesis);
                          }
                        }}
                        value={synthesis[index]}
                        disabled={isReadOnly}
                      >
                        {page.choices.map((option, i) => {
                          return (
                            <MenuItem
                              value={option.value}
                              key={option.label + i}
                              disabled={isReadOnly}
                            >
                              {option.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </TableCell>
                    <TableCell>{analysisQuestion.label}</TableCell>
                    <TableCell>
                      <Select
                        id={analysisQuestion.id}
                        variant="standard"
                        onChange={event => {
                          if (
                            event.target.value != null &&
                            typeof event.target.value === 'number'
                          ) {
                            const { value } = event.target;
                            const mutatedAnalysis = [...analysis];
                            mutatedAnalysis[index] = value;
                            setAnalysis(mutatedAnalysis);
                          }
                        }}
                        value={analysis[index]}
                        disabled={isReadOnly}
                      >
                        {page.choices.map((option, i) => {
                          return (
                            <MenuItem
                              value={option.value}
                              key={option.label + i}
                              disabled={isReadOnly}
                            >
                              {option.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell>
                  <Box>
                    <Typography component="h6" variant="h6">
                      Úspešnosť {computePercentile(differentiation, maxValueOption)} Σ HS
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <Box>
                    <Typography component="h6" variant="h6">
                      Úspešnosť {computePercentile(synthesis, maxValueOption)} Σ HS
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <Box>
                    <Typography component="h6" variant="h6">
                      Úspešnosť {computePercentile(analysis, maxValueOption)} Σ HS
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </MaterialTable>
        </TableContainer>
        <Box my={3} textAlign="right">
          <Button size="large" color="secondary" onClick={onBackClick}>
            Krok späť
          </Button>
          <Button
            onClick={() =>
              onSubmit({
                token,
                differentiation: getResults(page.questions.differentiation, differentiation),
                synthesis: getResults(page.questions.synthesis, synthesis),
                analysis: getResults(page.questions.analysis, analysis)
              })
            }
            variant="contained"
            type="submit"
            size="large"
            color="primary"
            style={{ marginLeft: '10px' }}
          >
            Nasledujúca strana
          </Button>
        </Box>
      </Box>
    </PaperContent>
  );
}

const StyledTableCell = styled(TableCell)`
  background: #eee;
`;
