import { createAppRouting, prefixRouting as prefix } from '@everlutionsk/ui-router';
import { otprRouting } from './pages/Diagnostic/OTPR/routing';
import { smkzdRouting } from './pages/Diagnostic/SMKZD/routing';
import { t184Routing } from './pages/Diagnostic/T184';
import { t21Routing } from './pages/Diagnostic/T21/routing';
import { t216Routing } from './pages/Diagnostic/T216';
import { t239Routing } from './pages/Diagnostic/T239/routing';
import { t252Routing } from './pages/Diagnostic/T252/routing';
import { t309Routing } from './pages/Diagnostic/T309/routing';
import { t41Routing } from './pages/Diagnostic/T41';
import { t8Routing } from './pages/Diagnostic/T8/routing';

export const routing = createAppRouting(() => ({
  home: '',
  t8: prefix('t8', t8Routing),
  t21: prefix('t21', t21Routing),
  t252: prefix('t252', t252Routing),
  t309: prefix('t309', t309Routing),
  t239: prefix('t239', t239Routing),
  t216: prefix('t216', t216Routing),
  t184: prefix('t184', t184Routing),
  t202: prefix('t202', t184Routing),
  t41: prefix('t41', t41Routing),
  smkzd: prefix('smkzd', smkzdRouting),
  otpr: prefix('otpr', otprRouting)
}));

export const routingPatterns = createAppRouting(() => ({
  home: '',
  t8: 't8/:token/*',
  t21: 't21/:token/*',
  t252: 't252/:token/*',
  t309: 't309/:token/*',
  t239: 't239/:token/*',
  t216: 't216/:token/*',
  t184: 't184/:token/*',
  t202: 't202/:token/*',
  t41: 't41/:token/*',
  smkzd: 'smkzd/:token/*',
  otpr: 'otpr/:token/*'
}));
