import { TableRow } from '@mui/material';
import { FormikErrors, FormikValues } from 'formik';
import { styled } from '@mui/material';

export const TableRowWithError = styled(TableRow)<{ ['data-has-error']: boolean }>`
  background: ${({ 'data-has-error': hasError }) => (hasError ? '#ff5a5a' : 'inherit')};
`;

// todo add recursion
export function hasError(
  error: FormikErrors<FormikValues> | string | undefined,
  fieldName: string | undefined
): boolean {
  if (error == null) return false;
  if (typeof error === 'string') return true;

  return Object.keys(error).find(key => key === fieldName) != null;
}
