import { Button, Grid } from '@mui/material';
import React, { useState } from 'react';
import { styled } from '@mui/material';
import { toImageUrl } from './images';

interface Props {
  readonly initialState?: Image[];
  readonly disabled?: boolean;
  readonly options: Array<{ value: string; img: any }>;
  readonly onChange: (values: Image[]) => void;
}

interface Image {
  value: string;
  crossed: boolean;
  img: string;
}

export function CrossedSelector({ options, onChange, initialState = [], disabled = false }: Props) {
  const [items, setItems] = useState<Image[]>(
    options.map(item => {
      return { value: item.value, img: item.img, crossed: isCrossed(item.value, initialState) };
    })
  );

  function isCrossed(value: string, array: Image[]): boolean {
    const item = array.find(item => item.value === value);
    if (item == null) return false;

    return item.crossed;
  }

  function toggleCrossed(itemIndex: number): void {
    if (disabled) return;
    if (itemIndex === -1) return;

    const item = items[itemIndex];
    const mutatedItems = [...items];

    mutatedItems[itemIndex].crossed = !item.crossed;

    setItems(mutatedItems);
    onChange(mutatedItems);
  }

  return (
    <StyledGrid container>
      {items.map((item, itemIndex) => {
        return (
          <Grid item xs={12} md={4} key={item.value}>
            <Wrapper
              disableRipple
              className={item?.crossed ? 'crossed' : ''}
              disabled={disabled}
              onClick={() => toggleCrossed(itemIndex)}
            >
              <img src={toImageUrl(item.img)} alt={item.value} />
            </Wrapper>
          </Grid>
        );
      })}
    </StyledGrid>
  );
}

const Wrapper = styled(Button)`
  cursor: pointer;
  position: relative;
  overflow: hidden;
  height: 140px;
  width: 262px;
  img {
    width: 100%;
    z-index: 0;
    position: relative;
  }
  &.crossed {
    &:before {
      content: '';
      background: red;
      width: 100%;
      min-width: 700px;
      height: 2px;
      right: calc(50% - 350px);
      top: calc(50% - 1px);
      z-index: 1;
      position: absolute;
      transform: rotate(-25deg);
    }
    &:after {
      content: '';
      background: red;
      width: 100%;
      min-width: 700px;
      height: 2px;
      left: calc(50% - 350px);
      top: calc(50% - 1px);
      z-index: 1;
      position: absolute;
      transform: rotate(25deg);
    }
  }
`;

const StyledGrid = styled(Grid)`
  max-width: 786px;
  margin: 0 auto;
`;
