import { useFormikContext } from 'formik';
import { useEffect } from 'react';

interface Props {
  readonly remainingSeconds: number;
  readonly isReadOnly: boolean;
}

export function CountdownChangeListener({ remainingSeconds, isReadOnly }: Props) {
  const { submitForm } = useFormikContext();

  useEffect(() => {
    if (remainingSeconds === 0 && !isReadOnly) {
      submitForm();
    }
  }, [remainingSeconds, submitForm]);

  return null;
}
