import ChevronRight from '@mui/icons-material/ChevronRight';
import { Box, Button } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material';
import { PaperContent } from '../../../components/PaperContent';

interface Props {
  readonly instructions: any;
  readonly label: string;
  readonly code: string;
  readonly onClick: () => void;
}

export function InstructionsPage({ code, instructions, label, onClick }: Props) {
  return (
    <>
      <PaperContent title={label} testNumber={code}>
        <Box mt={{ xs: 3, md: 3 }} mb={{ xs: 3, md: 3 }} position="relative">
          <InstructionsWrapper my={3}>{instructions}</InstructionsWrapper>
          <Box mt={4}>
            <Box my={3}>
              <Button
                variant="contained"
                color="primary"
                endIcon={<ChevronRight />}
                onClick={onClick}
              >
                Ďalej
              </Button>
            </Box>
          </Box>
        </Box>
      </PaperContent>
    </>
  );
}

const InstructionsWrapper = styled(Box)`
  p {
    span {
      color: #5d6ff7;
    }
  }
`;
