import { Typography } from '@mui/material';
import React from 'react';

interface Props {
  value: string;
  state: Array<'first' | 'second' | 'third' | 'mistake'>;
  onClick: () => void;
}

export function Word({ value, state, onClick }: Props) {
  return (
    <>
      <Typography variant="body1" display="inline">
        <span
          style={
            state.includes('mistake')
              ? { cursor: 'pointer', textDecoration: 'line-through', color: 'red' }
              : { cursor: 'pointer' }
          }
          onClick={onClick}
        >
          {value}{' '}
        </span>
      </Typography>

      {state.includes('first') && <span style={{ color: '#89eb34' }}>1 </span>}
      {state.includes('second') && <span style={{ color: '#e5eb34' }}>2 </span>}
      {state.includes('third') && <span style={{ color: '#349ceb' }}>3 </span>}
    </>
  );
}
