import imgVersionA from './images/versionA.png';
import imgVersionB from './images/versionB.png';

const images = {
  imgVersionA,
  imgVersionB
};

export function toImageUrl(key: string): string {
  if (key in images === false) throw new Error(`Image with key: ${key} was not found`);
  return images[key];
}
