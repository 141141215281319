import { Box, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material';
import { BaseList } from '../../../../components/BaseList';
import { StyledMaterialTable } from '../../../../components/StyledMaterialTable';
import { T309ChoiceSelector } from '../components/ChoiceSelector';

export function Instructions() {
  const choices1 = [
    { value: 'i-3-1', label: 'Francúzska' },
    { value: 'i-3-2', label: 'Monaka' },
    { value: 'i-3-3', label: 'Dánska' },
    { value: 'i-3-4', label: 'Holandska' },
    { value: 'i-3-5', label: 'Andory' }
  ];
  const choices2 = [
    { value: 'i-4-1', label: 'Solonin' },
    { value: 'i-4-2', label: 'Tabater' },
    { value: 'i-4-3', label: 'Jibli' },
    { value: 'i-4-4', label: 'Etam' },
    { value: 'i-4-5', label: 'Lenux' }
  ];
  return (
    <>
      <Box my={3}>
        <Typography component="h5" variant="h5">
          Zapamätávanie si tovarov (fáza vštepovania) - inštrukcie
        </Typography>
      </Box>
      <Box my={3}>
        <Typography component="p" variant="body2">
          V nasledujúcich úlohách pôjde o preverenie Vašich schopností, ako si dokážete zapamätať
          predložený materiál. V tejto prvej časti pôjde o zapamätanie si niektorých údajov o
          niekoľkých druhoch tovarov. Nesmiete si však pritom robiť žiadne poznámky. Odložte preto
          Vašu ceruzku nabok. <br />
          Údaje, ktoré si máte zapamätať, sú zoradené na nasledujúcej strane. Pôjde o 15 rôznych
          tovarov z ponuky obchodného domu. Uvedený bude vždy vymyslený názov daného tovaru, druh
          tovaru, jeho cena a krajina jeho výroby.
        </Typography>
      </Box>
      <Box my={3}>
        <Typography component="p" variant="body2">
          Niekoľko príkladov:
        </Typography>
      </Box>
      <TableWrapper my={3}>
        <StyledMaterialTable>
          <TableHead>
            <TableRow>
              <TableCell>Názov tovaru</TableCell>
              <TableCell>Tovar</TableCell>
              <TableCell>Cena</TableCell>
              <TableCell>Krajina výroby</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Solonin</TableCell>
              <TableCell>škrabka na zemiaky</TableCell>
              <TableCell>23,49 €</TableCell>
              <TableCell>Holandsko</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Levander</TableCell>
              <TableCell>nočná košeľa</TableCell>
              <TableCell>27,49 €</TableCell>
              <TableCell>Dánsko</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Kebatin</TableCell>
              <TableCell>misa na tortu</TableCell>
              <TableCell>23,49 €</TableCell>
              <TableCell>Holandsko</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Tabater</TableCell>
              <TableCell>kravata</TableCell>
              <TableCell>27,49 €</TableCell>
              <TableCell>Dánsko</TableCell>
            </TableRow>
          </TableBody>
        </StyledMaterialTable>
      </TableWrapper>
      <Box my={3}>
        <Typography component="p" variant="body2">
          Po fáze vštepovania (zapamätávania si) budete riešiť ďalšie úlohy. Po uplynutí viac ako 20
          minút budete potom musieť odpovedať na otázky týkajúce sa konkrétnych názvov tovarov, ich
          druhu, cien a krajín ich výroby.
        </Typography>
      </Box>

      <Box my={3}>
        <Typography component="p" variant="body2">
          Napríklad:
        </Typography>
      </Box>
      <TableDiv my={3} display="flex">
        <Box className="cell">
          <Typography component="h5" variant="h5">
            B5.
          </Typography>
        </Box>
        <Box className="cell">
          <T309ChoiceSelector
            initialValue="i-3-3"
            options={choices1}
            disabled
            onChange={() => {}}
            sentenceText={`Tovar s názvom Kebatin pochádza z #placeholder#`}
          ></T309ChoiceSelector>
        </Box>
      </TableDiv>
      <Box mb={3} mt={1}>
        <Typography component="p" variant="body2">
          Správna odpoveď na otázku krajiny pôvodu je „Holandsko“. V príklade B5 je správnym
          riešením písmeno D.
        </Typography>
      </Box>
      <Box mb={3} mt={5}>
        <Typography component="p" variant="body2">
          Ďalší príklad:
        </Typography>
      </Box>
      <TableDiv my={3} display="flex">
        <Box className="cell">
          <Typography component="h5" variant="h5">
            B6.
          </Typography>
        </Box>
        <Box className="cell">
          <T309ChoiceSelector
            initialValue="i-4-2"
            options={choices2}
            disabled
            onChange={() => {}}
            sentenceText={`Jeden z tovarov v cene 27,49 € má názov #placeholder# .`}
          ></T309ChoiceSelector>
        </Box>
      </TableDiv>
      <Box my={3}>
        <Typography component="p" variant="body2">
          Správna odpoveď je "Tabater". V príklade B6 je správnym riešením písmeno B.
        </Typography>
      </Box>
      <Box my={3}>
        <Typography component="p" variant="body2">
          Zapamätávanie si môžete uľahčiť (zjednodušiť), keď zohľadníte, že:
        </Typography>
        <BaseList className="">
          <li>
            <Typography component="p" variant="body2">
              15 predložených tovarov možno zoradiť do piatich rôznych skupín. Sú to domáce potreby,
              odevy, potraviny, autopríslušenstvo a hračky. Každú skupinu tvoria tri tovary.
            </Typography>
          </li>
          <li>
            <Typography component="p" variant="body2">
              Pre všetky tri tovary jednej skupiny platí, že končia na rovnaké dve písmená, ich cena
              je vždy rovnaká a vždy pochádzajú z tej istej krajiny.
            </Typography>
          </li>
        </BaseList>
      </Box>
      <Box my={3}>
        <Typography component="p" variant="body2">
          Nerobte si, prosím, žiadne poznámky.
        </Typography>
      </Box>
      <Box my={3}>
        <Typography component="p" variant="body2">
          Na zapamätávanie (vštepovanie) predložených <strong>15 tovarov</strong> máte čas{' '}
          <strong>7 minút</strong>. <strong>Počkajte na vyzvanie stlačiť tlačidlo ĎALEJ.</strong>
        </Typography>
      </Box>
    </>
  );
}

const TableDiv = styled(Box)`
  border: 1px solid #ccc;
  .cell {
    &:first-child {
      padding: 1rem;
    }
    &:last-child {
      border-left: 1px solid #ccc;
      padding: 0rem 1rem 1rem 1rem;
    }
  }
`;

const TableWrapper = styled(Box)`
  thead {
    tr {
      th {
        background: #f8f8f8;
      }
    }
  }
`;
