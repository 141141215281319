import { gql } from '@apollo/client';
import { Box, Button, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React, { useEffect } from 'react';
import { styled } from '@mui/material';
import { StyledMaterialTable } from '../../../../components/StyledMaterialTable';
import { T309Page3Fragment } from '../../../../graphql/types';
import { PaperContent } from '../components/PaperContent';
import { useCountdown } from '../useCountdown';

interface Props {
  readonly label: string;
  readonly code: string;
  readonly page: T309Page3Fragment;
  readonly onSubmit: () => void;
  readonly isDemo: boolean;
}

export const t309Page3Fragment = gql`
  fragment T309Page3Fragment on T309Page3 {
    id
    label
    table
    questions {
      id
    }
    timeLimitInSeconds
  }
`;

export function Diagnostic({ code, label, page, onSubmit, isDemo }: Props) {
  const remainingSeconds = useCountdown(page.timeLimitInSeconds);

  useEffect(() => {
    if (remainingSeconds === 0) {
      onSubmit();
    }
  }, [remainingSeconds]);

  const emptyRow = (
    <TableRow>
      <TableCell>&nbsp;</TableCell>
      <TableCell>&nbsp;</TableCell>
      <TableCell>&nbsp;</TableCell>
      <TableCell>&nbsp;</TableCell>
    </TableRow>
  );

  function tableRow(items: string[]) {
    if (items.length === 0) return null;
    return (
      <TableRow>
        {items.map((item, index) => (
          <TableCell key={index}>{item}</TableCell>
        ))}
      </TableRow>
    );
  }

  return (
    <PaperContent title={label} testNumber={code} remainingSeconds={remainingSeconds}>
      <div>{page.label}</div>
      <Box my={3} position="relative">
        <TableWrapper my={3}>
          <StyledMaterialTable>
            <TableHead>{tableRow(['Názov tovaru', 'Tovar', 'Cena', 'Krajina výroby'])}</TableHead>
            <TableBody>
              {tableRow(['Galene', 'panvica na pečenie', '18,99 €', 'Belgicko'])}
              {tableRow(['Fraban', 'športové ponožky', '14,99 €', 'Švajčiarsko'])}
              {tableRow(['Boreto', 'syr', '17,99 €', 'Nemecko'])}
              {tableRow(['Enor', 'odťahovacie lano', '12,99 €', 'Luxembursko'])}
              {tableRow(['Simas', 'kocky', '15,99 €', 'Rakúsko'])}
              {emptyRow}
              {tableRow(['Spilane', 'kuchynská váha', '18,99 €', 'Belgicko'])}
              {tableRow(['Hagolan', 'pančuchové nohavice', '14,99 €', 'Švajčiarsko'])}
              {tableRow(['Nafato', 'losos', '17,99 €', 'Nemecko'])}
              {tableRow(['Pador', 'zmes do ostrekovača okien', '12,99 €', 'Luxembursko'])}
              {tableRow(['Retas', 'bábika', '15,99 €', 'Rakúsko'])}
              {emptyRow}
              {tableRow(['Dogane', 'forma na bábovku', '18,99 €', 'Belgicko'])}
              {tableRow(['Uman', 'šál', '14,99 €', 'Švajčiarsko'])}
              {tableRow(['Molito', 'šunka', '17,99 €', 'Nemecko'])}
              {tableRow(['Kandor', 'motorový olej', '12,99 €', 'Luxembursko'])}
              {tableRow(['Libas', 'plastelína', '15,99 €', 'Rakúsko'])}
            </TableBody>
          </StyledMaterialTable>
        </TableWrapper>
        {isDemo && (
          <Box my={3} textAlign="right">
            <Button
              onClick={onSubmit}
              variant="contained"
              type="submit"
              size="large"
              color="primary"
              style={{ marginLeft: '10px' }}
            >
              Ďalej
            </Button>
          </Box>
        )}
      </Box>
    </PaperContent>
  );
}

const TableWrapper = styled(Box)`
  thead {
    tr {
      th {
        background: #f8f8f8;
      }
    }
  }
`;
