import { gql, useMutation, useQuery } from '@apollo/client';
import { Loading } from '@everlutionsk/ui';
import { useNavigate } from '@everlutionsk/ui-router';
import { Box, Container } from '@mui/material';
import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Complete } from '../Complete';
import { Error } from '../Error';
import { Identification } from '../Identification';
import { ChooseVersion } from './ChooseVersion';
import { Diagnostic, table1Fragment, table2Fragment } from './Diagnostic';
import { Instructions } from './Instructions';
import { Content } from '../components';
import { useToken } from '../useToken';

export function T8() {
  const token = useToken();
  const location = useLocation();

  const { data, refetch } = useQuery(t8Query, {
    variables: { token }
  });

  const navigate = useNavigate();

  const [mutateV1] = useMutation(mutationV1);
  const [mutateV2] = useMutation(mutationV2);

  useEffect(() => {
    refetch();
  }, [location.pathname]);

  if (data == null) return <Loading />;
  const { t8Diagnostics } = data;
  if (t8Diagnostics == null) return <Error />;

  const { code, status, answers, originalToken, isDemo, isValid, label, v1, v2 } = t8Diagnostics;

  if (!isValid) return <Error />;
  if (status === 'finished') return <Complete />;

  const isReadOnly = status === 'readonly' && originalToken != null;

  return (
    <Content>
      <Container maxWidth="xl">
        <Box my={3}>
          <Routes>
            <Route
              path=""
              element={
                <Identification
                  token={token}
                  isDemo={isDemo}
                  code={code}
                  label={label}
                  submitLink={() => navigate('./instructions')}
                  isReadOnly={isReadOnly}
                />
              }
            />
            <Route
              path="instructions"
              element={
                <ChooseVersion
                  code={code}
                  label={label}
                  v1={v1}
                  v2={v2}
                  onBackClick={() => navigate('')}
                  onSubmit={version => {
                    if (version === 'v1') {
                      navigate('./v1/instructions');
                    }
                    if (version === 'v2') {
                      navigate('./v2/instructions');
                    }
                  }}
                />
              }
            />
            <Route
              path="v1/instructions"
              element={
                <Instructions
                  code={code}
                  instructions={v1.instructions}
                  label={v1.label}
                  onBackClick={() => navigate('./instructions')}
                  onSubmit={() => navigate('./v1/test')}
                />
              }
            />
            <Route
              path="v2/instructions"
              element={
                <Instructions
                  code={code}
                  instructions={v2.instructions}
                  label={v2.label}
                  onBackClick={() => navigate('./instructions')}
                  onSubmit={() => navigate('./v2/test')}
                />
              }
            />
            <Route
              path="v1/test"
              element={
                <Diagnostic
                  code={code}
                  table1={v1.questionGroups.table1}
                  table2={undefined}
                  isDemo={isDemo}
                  label={label}
                  token={token}
                  version="v1"
                  onBackClick={() => navigate('./v1/instructions')}
                  onSubmitV1={input => {
                    mutateV1({ variables: { input } })
                      .then(() => navigate('./complete'))
                      .catch(error => console.error(error));
                  }}
                  onSubmitV2={() => {}}
                  isReadOnly={isReadOnly}
                  initialValues={Object.assign(
                    {},
                    ...Array.from(v1.questionGroups.table1.questions, (question, i) => ({
                      [`rating-${question.id}`]: answers?.v1?.answersTable1[i]?.radioValue
                    })),
                    ...Array.from(v1.questionGroups.table1.questions, (question, i) => ({
                      [`answer-${question.id}`]: answers?.v1?.answersTable1[i]?.answer
                    }))
                  )}
                />
              }
            />
            <Route
              path="v2/test"
              element={
                <Diagnostic
                  code={code}
                  table1={v2.questionGroups.table1}
                  table2={v2.questionGroups.table2}
                  isDemo={isDemo}
                  label={label}
                  token={token}
                  version="v2"
                  onBackClick={() => navigate('./v2/instructions')}
                  onSubmitV1={() => {}}
                  onSubmitV2={input => {
                    mutateV2({
                      variables: { input },
                      update: cache => {
                        cache.evict({ id: 'ROOT_QUERY', fieldName: 'T8DiagnosticQuery' });
                      }
                    })
                      .then(() => navigate('./complete'))
                      .catch(error => console.error(error));
                  }}
                  isReadOnly={isReadOnly}
                  initialValues={Object.assign(
                    {},
                    ...Array.from(v2.questionGroups.table1.questions, (question, i) => ({
                      [`rating-${question.id}`]: answers?.v2?.answersTable1[i]?.radioValue
                    })),
                    ...Array.from(v2.questionGroups.table1.questions, (question, i) => ({
                      [`answer-${question.id}`]: answers?.v2?.answersTable1[i]?.answer
                    })),
                    ...Array.from(v2.questionGroups.table2.questions, (question, i) => ({
                      [`match-${question.id}`]: answers?.v2?.answersTable2[i]?.match ?? false
                    })),
                    ...Array.from(v2.questionGroups.table2.questions, (question, i) => ({
                      [`not-match-${question.id}`]: answers?.v2?.answersTable2[i]?.notMatch ?? ''
                    }))
                  )}
                />
              }
            />
            <Route path="complete" element={<Complete />} />
          </Routes>
        </Box>
      </Container>
    </Content>
  );
}

export const t8AnswerManyFragment = gql`
  fragment T8AnswerMany on T8AnswerMany {
    v1 {
      answersTable1 {
        id
        radioValue
        answer
      }
    }
    v2 {
      answersTable1 {
        id
        radioValue
        answer
      }
      answersTable2 {
        id
        match
        notMatch
      }
    }
  }
`;

export const t8Query = gql<T8DiagnosticQueryGQL>`
  query T8DiagnosticQuery($token: String!) {
    t8Diagnostics(token: $token) {
      isValid
      isDemo
      code
      label
      status
      originalToken
      answers {
        ...T8AnswerMany
      }
      v1 {
        label
        instructions
        questionGroups {
          table1 {
            ...T8QuestionTable1
          }
        }
      }
      v2 {
        label
        instructions
        questionGroups {
          table1 {
            ...T8QuestionTable1
          }
          table2 {
            ...T8QuestionTable2
          }
        }
      }
    }
  }
  ${table1Fragment}
  ${table2Fragment}
  ${t8AnswerManyFragment}
`;

const mutationV1 = gql<SubmitTestT8V1GQL>`
  mutation SubmitTestT8V1($input: SubmitT8V1Input!) {
    submitT8V1(input: $input)
  }
`;

const mutationV2 = gql<SubmitTestT8V2GQL>`
  mutation SubmitTestT8V2($input: SubmitT8V2Input!) {
    submitT8V2(input: $input)
  }
`;
