import { useEffect, useRef, useState } from 'react';

export function useCountdown(timeLimitInSeconds: number) {
  const startAt = useRef(Date.now());
  const [remainingSeconds, setRemaining] = useState(timeLimitInSeconds);

  useEffect(() => {
    const interval = setInterval(() => {
      const remaining = Math.max(
        0,
        Math.round(timeLimitInSeconds - (Date.now() - startAt.current) / 1000)
      );
      if (remaining === 0) {
        clearInterval(interval);
      }
      setRemaining(remaining);
    }, 1000);

    return () => clearInterval(interval);
  }, [timeLimitInSeconds]);

  return remainingSeconds;
}
