import { createUiTheme } from '@everlutionsk/ui';
import { createUiFormikTheme } from '@everlutionsk/ui-formik';
import { TransitionProps } from '@mui/material/transitions';
import { createTheme, Slide, Theme } from '@mui/material';
import { skSK } from '@mui/material/locale';
import React, { useMemo } from 'react';

export function useTheme({ isProd, dark = false }: { isProd: boolean; dark?: boolean }) {
  const spacing = 8;

  return useMemo(() => {
    const muiTheme: Theme = createTheme(
      {
        palette: {
          background: {
            default: '#f5f5f5'
          },
          primary: {
            main: '#7158E2'
          },
          secondary: {
            main: '#2F3542'
          }
        },
        spacing,
        typography: {
          h1: {
            fontSize: '3rem',
            marginBottom: spacing * 2
          },
          h2: {
            fontSize: '2rem',
            marginBottom: spacing * 2
          },
          h3: {
            fontSize: '1.5rem',
            marginBottom: spacing
          },
          fontFamily: ['Roboto', 'sans-serif'].join(',')
        },
        components: {
          MuiTextField: {
            defaultProps: { variant: 'outlined', fullWidth: true }
          },
          MuiSnackbar: {
            defaultProps: { TransitionComponent: SlideTransition }
          },
          MuiButton: {
            defaultProps: { variant: 'contained' }
          }
        }
      },
      skSK
    );

    function SlideTransition(props: TransitionProps) {
      return (
        <Slide {...props} direction="up">
          <></>
        </Slide>
      );
    }

    const uiTheme = createUiTheme(muiTheme, {
      LocaleDate: {
        defaultProps: {},
        formatters: {
          dateTime: new Intl.DateTimeFormat(['en-GB'], {
            dateStyle: 'short',
            timeStyle: 'short'
          }),
          date: new Intl.DateTimeFormat(['en-GB'], {
            dateStyle: 'short'
          })
        }
      }
    });

    const formikTheme = createUiFormikTheme(muiTheme, {
      Fields: { fieldStyle: { marginBottom: muiTheme.spacing(3) + 'px' } }
    });

    const theme = {
      ...muiTheme,
      ...uiTheme,
      ...formikTheme
    };

    return theme;
  }, [dark, isProd]);
}
