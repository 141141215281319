import React from 'react';
import { useMemoizedField } from '../../../../components/forms/useMemoizedField';
import { FigureSelector } from './FigureSelector';

interface Props {
  readonly question: {
    id: string;
    order: number;
    templateFigure: { img: string };
    choices: Array<{ id: string; img: string; letter: string }>;
  };
  readonly toImageUrl: (key: string) => string;
  readonly isReadOnly: boolean;
}

export function FigureSelectorField({ question, toImageUrl, isReadOnly }: Props) {
  const [{ value }, , { setValue }] = useMemoizedField<string | undefined>(question.id);

  return (
    <FigureSelector
      initialValue={value}
      templateFigure={{
        img: toImageUrl(question.templateFigure.img)
      }}
      options={question.choices.map(choice => ({
        value: choice.id,
        img: toImageUrl(choice.img),
        letter: choice.letter
      }))}
      onChange={setValue}
      disabled={isReadOnly}
    ></FigureSelector>
  );
}
