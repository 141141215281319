import { gql, useMutation, useQuery } from '@apollo/client';
import { Loading } from '@everlutionsk/ui';
import { useParams } from '@everlutionsk/ui-router';
import { Box, Container } from '@mui/material';
import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { SubmitT252Input } from '../../../graphql/types';
import { Complete } from '../Complete';
import { Error } from '../Error';
import { Identification } from '../Identification';
import { Diagnostic, t252PageFragment } from './Diagnostic';
import { Identification as T252Identification } from './Identification';
import { Instructions } from './Instructions';
import { Content } from '../components';

const t252AnswerFragment = gql`
  fragment T252AnswerFragment on T252PageAnswerMany {
    id
    choices {
      id
      value
    }
  }
`;

const query = gql<T252DiagnosticQueryGQL>`
  query T252DiagnosticQuery($token: String!) {
    t252Diagnostics(token: $token) {
      isValid
      isDemo
      code
      label
      status
      originalToken
      answers {
        page1 {
          ...T252AnswerFragment
        }
        page2 {
          ...T252AnswerFragment
        }
        page3 {
          ...T252AnswerFragment
        }
      }
      page1 {
        ...T252PageFragment
      }
      page2 {
        ...T252PageFragment
      }
      page3 {
        ...T252PageFragment
      }
    }
  }
  ${t252PageFragment}
  ${t252AnswerFragment}
`;

export function T252() {
  const { token } = useParams(['token']);
  const location = useLocation();

  const { data, refetch } = useQuery(query, {
    variables: { token }
  });
  const navigate = useNavigate();

  const [mutatePage1] = useMutation(mutationPage1);
  const [mutatePage2] = useMutation(mutationPage2);
  const [mutatePage3] = useMutation(mutationPage3);

  useEffect(() => {
    refetch();
  }, [location.pathname]);

  if (data == null) return <Loading />;
  const { t252Diagnostics } = data;
  if (t252Diagnostics == null) return <Error></Error>;

  const { code, status, originalToken, answers, isValid, isDemo, label, page1, page2, page3 } =
    t252Diagnostics;

  if (!isValid) return <Error></Error>;
  if (status === 'finished') return <Complete />;

  const isReadOnly = status === 'readonly' && originalToken != null;

  return (
    <Content>
      <Container maxWidth="xl">
        <Box my={3}>
          <Routes>
            <Route
              path=""
              element={
                <Identification
                  code={code}
                  isDemo={isDemo}
                  label={label}
                  token={token}
                  submitLink={() => navigate('./instructions')}
                  isReadOnly={isReadOnly}
                />
              }
            />
            <Route
              path="instructions"
              element={
                <Instructions
                  code={code}
                  instructions={page1.instructions}
                  label={label}
                  onClick={() => navigate(`./identification`)}
                  onBackClick={() => navigate('')}
                />
              }
            />
            <Route
              path="identification"
              element={
                <T252Identification
                  code={code}
                  isDemo={isDemo}
                  label={label}
                  token={token}
                  isReadOnly={isReadOnly}
                />
              }
            />
            <Route
              path="pages/1/test"
              element={
                <Diagnostic
                  code={code}
                  page={page1}
                  label={label}
                  token={token}
                  onSubmit={values => {
                    if (isReadOnly) {
                      navigate('pages/2/instructions');
                      return;
                    }

                    mutatePage1({
                      variables: {
                        input: {
                          token,
                          answers: toAnswersInput(values)
                        }
                      }
                    }).then(() => {
                      navigate('pages/2/instructions');
                    });
                  }}
                  onBackClick={() => navigate('./identification')}
                  isDemo={isDemo}
                  isReadOnly={isReadOnly}
                  initialValues={
                    isDemo
                      ? page1.questions.reduce((initial, question) => {
                          let i = 1;
                          initial[question.id] = question.choices.reduce((values, choice) => {
                            values[choice.id] = i++;

                            return values;
                          }, {});

                          return initial;
                        }, {})
                      : isReadOnly
                        ? page1.questions.reduce((initial, question) => {
                            const answer = answers?.page1.find(item => item.id === question.id);

                            initial[question.id] = answer?.choices.reduce((values, choice) => {
                              values[choice.id] = choice.value;

                              return values;
                            }, {});

                            return initial;
                          }, {})
                        : {}
                  }
                />
              }
            />
            <Route
              path="pages/2/instructions"
              element={
                <Instructions
                  code={code}
                  instructions={page2.instructions}
                  label={label}
                  onClick={() => navigate(`pages/2/test`)}
                  onBackClick={() => navigate(`pages/1/test`)}
                />
              }
            />
            <Route
              path="pages/2/test"
              element={
                <Diagnostic
                  code={code}
                  page={page2}
                  label={label}
                  token={token}
                  onSubmit={values => {
                    if (isReadOnly) {
                      navigate('pages/3/instructions');
                      return;
                    }

                    mutatePage2({
                      variables: {
                        input: {
                          token,
                          answers: toAnswersInput(values)
                        }
                      }
                    }).then(response => {
                      navigate('pages/3/instructions');
                    });
                  }}
                  onBackClick={() => navigate('pages/2/instructions')}
                  isDemo={isDemo}
                  isReadOnly={isReadOnly}
                  initialValues={
                    isDemo
                      ? page2.questions.reduce((initial, question) => {
                          let i = 1;
                          initial[question.id] = question.choices.reduce((values, choice) => {
                            values[choice.id] = i++;

                            return values;
                          }, {});

                          return initial;
                        }, {})
                      : isReadOnly
                        ? page2.questions.reduce((initial, question) => {
                            const answer = answers?.page2.find(item => item.id === question.id);

                            initial[question.id] = answer?.choices.reduce((values, choice) => {
                              values[choice.id] = choice.value;

                              return values;
                            }, {});

                            return initial;
                          }, {})
                        : {}
                  }
                />
              }
            />
            <Route
              path="pages/3/instructions"
              element={
                <Instructions
                  code={code}
                  instructions={page3.instructions}
                  label={label}
                  onClick={() => navigate(`pages/3/test`)}
                  onBackClick={() => navigate(`pages/2/test`)}
                />
              }
            />
            <Route
              path="pages/3/test"
              element={
                <Diagnostic
                  lastPage
                  disabled={isDemo}
                  code={code}
                  page={page3}
                  label={label}
                  token={token}
                  onSubmit={values => {
                    if (isReadOnly || isDemo) {
                      return;
                    }

                    mutatePage3({
                      variables: {
                        input: {
                          token,
                          answers: toAnswersInput(values)
                        }
                      }
                    }).then(response => {
                      navigate('complete');
                    });
                  }}
                  onBackClick={() => navigate('pages/3/instructions')}
                  isDemo={isDemo}
                  isReadOnly={isReadOnly}
                  initialValues={
                    isDemo
                      ? page3.questions.reduce((initial, question) => {
                          let i = 1;
                          initial[question.id] = question.choices.reduce((values, choice) => {
                            values[choice.id] = i++;

                            return values;
                          }, {});

                          return initial;
                        }, {})
                      : isReadOnly
                        ? page3.questions.reduce((initial, question) => {
                            const answer = answers?.page3.find(item => item.id === question.id);

                            initial[question.id] = answer?.choices.reduce((values, choice) => {
                              values[choice.id] = choice.value;

                              return values;
                            }, {});

                            return initial;
                          }, {})
                        : {}
                  }
                />
              }
            />
            <Route path="complete" element={<Complete />} />
          </Routes>
        </Box>
      </Container>
    </Content>
  );
}

function toAnswersInput(
  values: Record<string, Record<string, number>>
): SubmitT252Input['answers'] {
  return Object.keys(values).map(questionId => {
    const choiceValues = values[questionId];
    const choices = Object.keys(choiceValues).map(choiceId => ({
      id: choiceId,
      value: choiceValues[choiceId]
    }));

    return {
      id: questionId,
      choices
    };
  });
}

const mutationPage1 = gql<SubmitT252Page1GQL>`
  mutation SubmitT252Page1($input: SubmitT252Input!) {
    submitT252Page1(input: $input)
  }
`;

const mutationPage2 = gql<SubmitT252Page2GQL>`
  mutation SubmitT252Page2($input: SubmitT252Input!) {
    submitT252Page2(input: $input)
  }
`;

const mutationPage3 = gql<SubmitT252Page3GQL>`
  mutation SubmitT252Page3($input: SubmitT252Input!) {
    submitT252Page3(input: $input)
  }
`;
