import { Box, Typography } from '@mui/material';
import React from 'react';

export function DiagnosticErrorLabel() {
  return (
    <Box mb={3} mt={2}>
      <Typography color="error" variant="subtitle2">
        Pre vyhodnotenie je nutné odpovedať na všetky otázky.
      </Typography>
    </Box>
  );
}
