import CheckOutlined from '@mui/icons-material/CheckOutlined';
import { Box, styled, Typography } from '@mui/material';
import React from 'react';

export function Complete() {
  return (
    <Box textAlign="center">
      <IconWrapper>
        <CheckOutlined />
      </IconWrapper>
      <Typography component="h2" variant="h3">
        Ďakujeme
      </Typography>
      <Box my={3}>
        <Typography component="p" variant="body2">
          Test ukončený
        </Typography>
      </Box>
    </Box>
  );
}

const IconWrapper = styled('div')`
  color: ${({ theme }) => theme.palette.success.main};
  svg {
    font-size: 5rem;
  }
`;
