import { gql, useMutation, useQuery } from '@apollo/client';
import { Button, Loading } from '@everlutionsk/ui';
import { createFormSpec, SelectField, TextField } from '@everlutionsk/ui-formik';
import Check from '@mui/icons-material/Check';
import { Box, Typography } from '@mui/material';
import { format } from 'date-fns';
import { Form, Formik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router';
import * as yup from 'yup';
import { DatePickerField } from '../../../components/forms/DatePickerField';
import { PaperContent } from '../../../components/PaperContent';
import { Gender } from '../../../graphql/types';
import { InputList } from '../Identification/InputList';
import { InputWrapper } from '../Identification/InputWrapper';

interface Props {
  readonly token: string;
  readonly isDemo: boolean;
  readonly isReadOnly: boolean;
  readonly code: string;
  readonly label: string;
}

interface FormValues {
  fullName: string;
  birthMonth: Date;
  birthYear: Date;
  gender: Gender;
  date: Date;
}

const mutation = gql<SubmitSmkzdIdentificationGQL>`
  mutation SubmitSmkzdIdentification($input: UpdateStudentByTokenInput!) {
    updateStudentByToken(input: $input) {
      id
      birthIdentificationNumber
      firstName
      lastName
      fullName
      dateOfBirth
      gender
    }
  }
`;

const query = gql<SmkzdStudentOneByTokenGQL>`
  query SmkzdStudentOneByToken($token: String!) {
    studentOneByToken(token: $token) {
      id
      birthIdentificationNumber
      firstName
      lastName
      fullName
      dateOfBirth
      gender
    }
  }
`;

export function SmkzdIdentification({ code, isDemo, token, label, isReadOnly }: Props) {
  const navigate = useNavigate();

  const { data } = useQuery(query, { variables: { token } });

  const [mutate] = useMutation(mutation);

  if (data == null) return <Loading />;

  return (
    <PaperContent title={label} testNumber={code}>
      <Typography component="h3" variant="h4">
        Identifikácia
      </Typography>
      <Typography component="p" variant="body2">
        Prosíme, aby ste potvrdili nasledujúce údaje:
      </Typography>
      <Box my={3}>
        <Formik
          {...spec({
            fullName: data?.studentOneByToken.fullName,
            birthMonth: new Date(data?.studentOneByToken.dateOfBirth) ?? new Date(),
            birthYear: new Date(data?.studentOneByToken.dateOfBirth) ?? new Date(),
            gender: data?.studentOneByToken.gender ?? undefined,
            date: new Date()
          } as any)}
          onSubmit={async ({ fullName, birthMonth, birthYear, gender, date }: FormValues) => {
            await mutate({
              variables: {
                input: {
                  token,
                  birthMonth: birthMonth.getMonth() + 1,
                  birthYear: birthYear.getFullYear(),
                  gender,
                  date: format(date, 'yyyy-MM-dd'),
                  fullName
                }
              }
            }).then(response => {
              navigate('./instructions');
            });
          }}
        >
          {({ values }) => (
            <Form>
              <InputList>
                <InputWrapper label={`Identifikátor klienta`}>
                  <TextField disabled name="fullName" type="firstName" variant="standard" />
                </InputWrapper>
                <InputWrapper label={`Mesiac narodenia`}>
                  {/* <InputDateField
                    disabled
                    name="birthMonth"
                    format="MMMM"
                    views={['month']}
                    size="small"
                  /> */}
                  <DatePickerField
                    disabled
                    // disableToolbar
                    name="birthMonth"
                    views={['month']}
                    size={'small'}
                    format={'MMMM'}
                  />
                </InputWrapper>
                <InputWrapper label={`Rok narodenia`}>
                  {/* <InputDateField
                    disabled
                    name="birthYear"
                    format="YYYY"
                    views={['year']}
                    size="small"
                  /> */}
                  <DatePickerField
                    disabled
                    name="birthYear"
                    views={['year']}
                    size={'small'}
                    format={'YYYY'}
                  />
                </InputWrapper>
                <InputWrapper label={`Pohlavie`}>
                  <SelectField
                    disabled
                    options={genderOptions}
                    name="gender"
                    variant="standard"
                    className={`disabled-select`}
                  />
                </InputWrapper>
                <InputWrapper label={`Dátum posudzovania žiaka`}>
                  {/* <InputDateField name="date" disabled={isReadOnly} /> */}
                  <DatePickerField
                    name="date"
                    size={'small'}
                    // views={['date']}
                    disabled={isReadOnly}
                  />
                </InputWrapper>
              </InputList>
              <Box my={3}>
                <Button type="submit" size="medium" color="primary" startIcon={<Check />}>
                  Ďalej
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </PaperContent>
  );
}

const genderOptions = [
  { value: 'male', label: 'Muž' },
  { value: 'female', label: 'Žena' }
];

const spec = createFormSpec<FormValues>({
  fullName: yup.string().nullable().required('Vyberte identifikátor klienta'),
  birthMonth: yup.date().nullable().required('Vyberte mesiac narodenia'),
  birthYear: yup.date().nullable().required('Vyberte rok narodenia'),
  gender: yup.string().nullable().required('Vyberte pohlavie klienta'),
  date: yup.date().nullable().required('Dátum posudzovania žiaka')
});
