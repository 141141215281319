import { gql, useQuery } from '@apollo/client';
import { Loading } from '@everlutionsk/ui';
import { CheckboxesField, RadioField, SelectField, TextField } from '@everlutionsk/ui-formik';
import { useParams } from '@everlutionsk/ui-router';
import {
  Box,
  Button,
  Table as MaterialTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import { styled } from '@mui/material';
import { PaperContent } from '../../../components/PaperContent';
import { SubmitT202Page2Input, T202ArticleFragment } from '../../../graphql/types';
import { Error } from '../Error';

const t202Page2TableFragment = gql`
  fragment T202Page2Table on T202Page2Table {
    title
    header {
      labels
    }
    body {
      labels
    }
  }
`;

const t202Page2Table4Fragment = gql`
  fragment T202Page2Table4 on T202Page2Table4 {
    title
    header {
      label
      legend
    }
  }
`;

const query = gql<T202DiagnosticPage2QueryGQL>`
  query T202DiagnosticPage2Query($token: String!) {
    t202DiagnosticsPage2(token: $token) {
      isValid
      isDemo
      code
      label
      answers {
        wayOfReading
        readingReproduction
        readingReproductionScale
        readingSense
        readingLocalizationOne
        readingLocalizationTwo
        readingLocalizationThree
        readingLocalizationFour
        readingLocalizationFive
        readingLocalizationSix
        readingLocalizationSeven
        errorShapes
        durationEntry
      }
      table3 {
        ...T202Page2Table
      }
      table4 {
        ...T202Page2Table4
      }
    }
  }
  ${t202Page2TableFragment}
  ${t202Page2Table4Fragment}
`;

interface Props {
  readonly token: string;
  readonly articles: T202ArticleFragment[];
  readonly onSubmit: (values: SubmitT202Page2Input) => void;
  readonly isReadOnly: boolean;
}

interface FormValues {
  readonly durationEntryVariant: number;
  readonly durationEntryMinutes: string;
  readonly durationEntrySeconds: string;
  readonly wayOfReading: number;
  readonly readingReproduction: number;
  readonly readingReproductionScale: number;
  readonly readingLocalizationOne: string;
  readonly readingLocalizationTwo: string;
  readonly readingLocalizationThree: string;
  readonly readingLocalizationFour: string;
  readonly readingLocalizationFive: string;
  readonly readingLocalizationSix: string;
  readonly readingLocalizationSeven: string;
  readonly errorShapes: number[];
  readonly readingSense: number;
}

export function DiagnosticResults({ token, articles, onSubmit, isReadOnly }: Props) {
  const { articleId } = useParams(['articleId']);
  const article = articles.find(article => article.id === articleId);

  const { data } = useQuery(query, {
    variables: { token }
  });

  if (data == null) return <Loading />;
  const { t202DiagnosticsPage2 } = data;
  if (t202DiagnosticsPage2 == null || article == null || !t202DiagnosticsPage2.isValid)
    return <Error></Error>;

  const { code, label, table3, table4, answers } = t202DiagnosticsPage2;

  const initialValues: FormValues = {
    durationEntryVariant: 0,
    durationEntryMinutes: '0',
    durationEntrySeconds: '0',
    wayOfReading: answers?.wayOfReading ?? 0,
    readingReproduction: answers?.readingReproduction ?? 0,
    readingReproductionScale: answers?.readingReproductionScale ?? 0,
    readingLocalizationOne: answers?.readingLocalizationOne ?? '',
    readingLocalizationTwo: answers?.readingLocalizationTwo ?? '',
    readingLocalizationThree: answers?.readingLocalizationThree ?? '',
    readingLocalizationFour: answers?.readingLocalizationFour ?? '',
    readingLocalizationFive: answers?.readingLocalizationFive ?? '',
    readingLocalizationSix: answers?.readingLocalizationSix ?? '',
    readingLocalizationSeven: answers?.readingLocalizationSeven ?? '',
    errorShapes: answers?.errorShapes ?? [],
    readingSense: answers?.readingSense ?? 0
  };

  function DurationSection({
    durationEntryVariant
  }: {
    durationEntryVariant: FormValues['durationEntryVariant'];
  }) {
    return (
      <Box display="flex" mb={3} mt={3}>
        <Box mr={3}>
          <RadioField
            label="Dĺžka trvania testu"
            name="durationEntryVariant"
            options={[
              { label: 'Použiť čas zo stopiek', value: 0 },
              { label: 'Zadať vlastný:', value: 1 }
            ]}
            row
            disabled={isReadOnly}
          />
        </Box>
        <Box display="flex" mr={3} alignSelf="flex-start">
          <TextField
            name="durationEntryMinutes"
            label="minút"
            type="number"
            variant="standard"
            disabled={isReadOnly || durationEntryVariant === 0}
          />
        </Box>
        <Box>
          <TextField
            name="durationEntrySeconds"
            label="sekúnd"
            type="number"
            InputProps={{ inputProps: { min: 0, max: 60 } }}
            variant="standard"
            disabled={isReadOnly || durationEntryVariant === 0}
          />
        </Box>
      </Box>
    );
  }

  const dropdownsTable = (
    <Box mb={3}>
      <StyledTable style={{ tableLayout: 'fixed' }}>
        <TableBody>
          <TableRow>
            <TableCell width="25%">
              <SelectField
                label="Spôsob čítania"
                name="wayOfReading"
                options={[
                  { label: 'Vyberte', value: 0 },
                  { label: 'skupiny slov, intonácia', value: 1 },
                  { label: 'slová, bez zarážok', value: 2 },
                  { label: 'slová, neisté', value: 3 },
                  { label: 'slabikuje, plynulé', value: 4 },
                  { label: 'slabikuje, neisté', value: 5 },
                  { label: 'hláskuje', value: 6 }
                ]}
                disabled={isReadOnly}
              />
            </TableCell>
            <TableCell width="25%">
              <SelectField
                label="Reprodukcia"
                name="readingReproduction"
                options={[
                  { label: 'Vyberte', value: 0 },
                  { label: 'dokonalá, detaily', value: 1 },
                  { label: 'dokonalá, stručne', value: 2 },
                  { label: 'dobrá', value: 3 },
                  { label: 'podstatné časti', value: 4 },
                  { label: 'nespoľahlivá', value: 5 },
                  { label: 'útržky, bez spojitosti', value: 6 },
                  { label: 'celkom bez porozumenia', value: 7 }
                ]}
                disabled={isReadOnly}
              />
            </TableCell>
            <TableCell width="50%">
              <SelectField
                label="Pomocná stupnica k hodnoteniu reprodukcie"
                name="readingReproductionScale"
                options={[
                  { label: 'Vyberte', value: 0 },
                  { label: 'rozpráva spontánne a samostatne', value: 1 },
                  { label: 'rozhovorí sa po pomocnej otázke', value: 2 },
                  { label: 'potrebné častejšie pomáhať otázkami', value: 3 },
                  { label: 'rozpráva len podľa pomocných otázok, odpovedá vo vetách', value: 4 },
                  { label: 'rozpráva len podľa pomocných otázok, odpovedá jednoslovne', value: 5 }
                ]}
                disabled={isReadOnly}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </StyledTable>
    </Box>
  );

  const mistakesStatsTable = (
    <Box mb={3}>
      <StyledTable>
        <TableHead>
          <TableRow>
            {table3.header.labels.map((label, index) => (
              <TableCell key={`t2-h${index}`}>
                <strong>{label}</strong>{' '}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {table3.body.map((row, rowIndex) => (
            <TableRow key={`t2-r${rowIndex}`}>
              {row.labels.map((label, index) => (
                <TableCell key={`t2-r${rowIndex}l${index}`}>{label}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>
    </Box>
  );

  const localizationTable = (
    <>
      <Box mt={3}>
        <Typography>{table4.title}</Typography>
      </Box>
      <Box mb={3}>
        <StyledTable>
          <TableBody>
            <TableRow>
              {table4.header.map((item, index) => (
                <TableCell key={`t4-h-${index}`} align="center">
                  {item.label}
                </TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell>
                <TextField
                  name="readingLocalizationOne"
                  type="text"
                  variant="standard"
                  disabled={isReadOnly}
                />
              </TableCell>
              <TableCell>
                <TextField
                  name="readingLocalizationTwo"
                  type="text"
                  variant="standard"
                  disabled={isReadOnly}
                />
              </TableCell>
              <TableCell>
                <TextField
                  name="readingLocalizationThree"
                  type="text"
                  variant="standard"
                  disabled={isReadOnly}
                />
              </TableCell>
              <TableCell>
                <TextField
                  name="readingLocalizationFour"
                  type="text"
                  variant="standard"
                  disabled={isReadOnly}
                />
              </TableCell>
              <TableCell>
                <TextField
                  name="readingLocalizationFive"
                  type="text"
                  variant="standard"
                  disabled={isReadOnly}
                />
              </TableCell>
              <TableCell>
                <TextField
                  name="readingLocalizationSix"
                  type="text"
                  variant="standard"
                  disabled={isReadOnly}
                />
              </TableCell>
              <TableCell>
                <TextField
                  name="readingLocalizationSeven"
                  type="text"
                  variant="standard"
                  disabled={isReadOnly}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </StyledTable>
      </Box>
    </>
  );

  const errorShapesCheckboxes = (
    <GroupWrapper mb={3}>
      <CheckboxesField
        label="Tvary chýb"
        name="errorShapes"
        options={[
          { label: 'Zámeny tvar. podob. písmen', value: 0 },
          { label: 'Zámeny zvuk. podob. písmen', value: 1 },
          { label: 'Zámeny oboch podob.', value: 2 },
          { label: 'Nepodobné písmená', value: 3 },
          { label: 'Inverzia písmen', value: 4 },
          { label: 'Inverzia slabík', value: 5 },
          { label: 'Vynechanie písmen', value: 6 },
          { label: 'Vynechanie slabík', value: 7 },
          { label: 'Vynechanie slov', value: 8 },
          { label: 'Pridanie písmena', value: 9 },
          { label: 'Pridanie slabiky', value: 10 },
          { label: 'Pridanie slova', value: 11 }
        ]}
        disabled={isReadOnly}
        row
      />
    </GroupWrapper>
  );

  const readingSenseSection = (
    <Box mb={3}>
      <Typography>Zmysel chýb</Typography>
      <RadioField
        name="readingSense"
        options={[
          { label: 'Nešpecifikovaný', value: 0 },
          { label: 'Odvodené', value: 1 },
          { label: 'Blízke', value: 2 },
          { label: 'Vzdialené', value: 3 },
          { label: 'Nezmyselné', value: 4 }
        ]}
        row
        disabled={isReadOnly}
      ></RadioField>
    </Box>
  );

  function getResults(values: FormValues): SubmitT202Page2Input {
    return {
      token,
      wayOfReading: values.wayOfReading,
      readingReproduction: values.readingReproduction,
      readingReproductionScale: values.readingReproductionScale,
      readingLocalizationOne: values.readingLocalizationOne,
      readingLocalizationTwo: values.readingLocalizationTwo,
      readingLocalizationThree: values.readingLocalizationThree,
      readingLocalizationFour: values.readingLocalizationFour,
      readingLocalizationFive: values.readingLocalizationFive,
      readingLocalizationSix: values.readingLocalizationSix,
      readingLocalizationSeven: values.readingLocalizationSeven,
      readingSense: values.readingSense,
      errorShapes: values.errorShapes,
      durationEntry:
        values.durationEntryVariant === 1
          ? Number(values.durationEntryMinutes) * 60 + Number(values.durationEntrySeconds)
          : undefined
    };
  }

  return (
    <PaperContent title={article.title} testNumber={code}>
      <Box my={3}>
        <Box>
          <Typography variant="h4">{label}</Typography>
        </Box>

        <Formik
          initialValues={initialValues}
          onSubmit={formikValues => onSubmit(getResults(formikValues))}
        >
          {({ values }) => {
            return (
              <Form>
                <>
                  <DurationSection durationEntryVariant={values.durationEntryVariant} />
                  {dropdownsTable}
                  {mistakesStatsTable}
                  {localizationTable}
                  {errorShapesCheckboxes}
                  {readingSenseSection}
                  <Box my={3}>
                    <Button size="medium" type="submit" color="primary" variant="contained">
                      Pokračovať
                    </Button>
                  </Box>
                </>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </PaperContent>
  );
}

const StyledTable = styled(MaterialTable)`
  .MuiTableRow-root {
    .MuiTableCell-root {
      border: 1px solid #e2e4e6;
    }
  }
`;

const GroupWrapper = styled(Box)`
  .MuiFormLabel-root {
    color: #000;
    font-weight: 400;
    font-size: 1rem;
    margin-bottom: 1rem;
  }
`;
