import { gql, useMutation, useQuery } from '@apollo/client';
import { Button, Loading } from '@everlutionsk/ui';
import { createFormSpec, TextField } from '@everlutionsk/ui-formik';
import { useNavigate } from '@everlutionsk/ui-router';
import Check from '@mui/icons-material/Check';
import { Box, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { PaperContent } from '../../../components/PaperContent';
import { InputList } from '../Identification/InputList';
import { InputWrapper } from '../Identification/InputWrapper';

interface Props {
  readonly code: string;
  readonly isDemo: boolean;
  readonly isReadOnly: boolean;
  readonly label: string;
  readonly subLabel?: string;
  readonly token: string;
  readonly version: number;
}

const mutation = gql<SubmitT184IdentificationGQL>`
  mutation SubmitT184Identification($input: UpdateStudentByTokenInput!) {
    updateStudentByToken(input: $input) {
      id
      t184subject
    }
  }
`;

const query = gql<T184StudentOneByTokenGQL>`
  query T184StudentOneByToken($token: String!) {
    studentOneByToken(token: $token) {
      id
      t184subject
    }
  }
`;

interface FormValues {
  subject: string;
}

export function Identification({
  isDemo,
  isReadOnly,
  code,
  label,
  subLabel,
  token,
  version
}: Props) {
  const navigate = useNavigate();

  const { data } = useQuery(query, { variables: { token } });
  const [mutate] = useMutation(mutation);

  if (data == null) return <Loading />;

  const spec = createFormSpec<FormValues>({
    subject: yup.string().when('demo', () => {
      if (isDemo) return yup.mixed();
      return yup.string().required('Prosím, zadajte vyučovací predmet');
    })
  });

  return (
    <PaperContent title={label} testNumber={code} subTitle={subLabel}>
      <Typography component="h3" variant="h4">
        Identifikácia
      </Typography>
      <Typography component="p" variant="body2">
        Prosíme, aby ste vyplnili nasledujúce údaje:
      </Typography>
      <Box my={3}>
        <Formik
          {...spec({
            subject: data?.studentOneByToken.t184subject
              ? data?.studentOneByToken.t184subject
              : undefined
          })}
          onSubmit={async ({ subject }) => {
            await mutate({
              variables: {
                input: {
                  token,
                  t184subject: subject
                }
              }
            });
            navigate(`../../v${version}/test`);
          }}
        >
          <Form>
            <InputList>
              <InputWrapper label={`Vyučovací predmet`}>
                <TextField
                  disabled={Boolean(isDemo) || isReadOnly}
                  name="subject"
                  variant="standard"
                />
              </InputWrapper>
            </InputList>
            <Box my={3}>
              <Button type="submit" size="medium" startIcon={<Check />}>
                Spustiť test
              </Button>
            </Box>
          </Form>
        </Formik>
      </Box>
    </PaperContent>
  );
}
