import { Card, CardContent, Typography } from '@mui/material';
import React, { ReactNode } from 'react';

interface Props {
  title: string;
  children: ReactNode;
}

export function PageWrapper({ title, children }: Props) {
  return (
    <>
      <Typography variant="h1">{title}</Typography>
      <Card>
        <CardContent>{children}</CardContent>
      </Card>
    </>
  );
}
