import T41_harok_deti_A from './documents/T41_harok_deti_A.pdf';
import T41_harok_deti_B from './documents/T41_harok_deti_B.pdf';
import T41_harok_dospeli_A from './documents/T41_harok_dospeli_A.pdf';
import T41_harok_dospeli_B from './documents/T41_harok_dospeli_B.pdf';

const documents = {
  T41_harok_deti_A,
  T41_harok_deti_B,
  T41_harok_dospeli_A,
  T41_harok_dospeli_B
};

export function toDocumentUrl(key: string): string {
  if (key in documents === false) throw new Error(`Document with key: ${key} was not found`);
  return documents[key];
}
