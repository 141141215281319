import { gql } from '@apollo/client';
import PrintIcon from '@mui/icons-material/Print';
import {
  Box,
  Button,
  Table as MaterialTable,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import React from 'react';
import { styled } from '@mui/material';
import { PaperContent } from '../../../../components/PaperContent';
import { T239Page7Fragment } from '../../../../graphql/types';
import { toDocumentUrl } from './documents';

interface Props {
  readonly label: string;
  readonly code: string;
  readonly page: T239Page7Fragment;
  readonly onSubmit: (values) => void;
  readonly onBackClick: () => void;
  readonly isDemo?: boolean;
}

export const t239Page7Fragment = gql`
  fragment T239Page7Fragment on T239Page7 {
    id
    label
    items {
      label1
      label2
      labelPrint
      document
    }
  }
`;

export function Diagnostic({ code, label, page, onSubmit, onBackClick, isDemo }: Props) {
  const borderStyle = '1px solid #e2e4e6';

  return (
    <PaperContent title={label} testNumber={code}>
      <div>{page.label}</div>
      <Box mt={{ xs: 3, md: 3 }} mb={{ xs: 3, md: 3 }} position="relative">
        <TableContainer component={Paper}>
          <MaterialTable>
            <TableBody>
              <TableRow>
                {page.items.map((item, index) => (
                  <TableCell key={`cell-${index}`} height={150} style={{ border: borderStyle }}>
                    <PageItem>
                      <Box>
                        <Typography>{item.label1}</Typography>
                      </Box>
                      <Box>
                        <Typography>{item.label2}</Typography>
                      </Box>
                      {!isDemo && (
                        <PrintLink
                          href={`${toDocumentUrl(item.document)}`}
                          rel="noopener"
                          target="_blank"
                        >
                          <PrintIcon />
                          {item.labelPrint}
                        </PrintLink>
                      )}
                    </PageItem>
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          </MaterialTable>
        </TableContainer>
        <Box my={3} textAlign="right">
          <Button size="large" color="secondary" onClick={onBackClick}>
            Krok späť
          </Button>
          <Button
            disabled={isDemo}
            onClick={onSubmit}
            variant="contained"
            type="submit"
            size="large"
            color="primary"
            style={{ marginLeft: '10px' }}
          >
            Nasledujúca strana
          </Button>
        </Box>
      </Box>
    </PaperContent>
  );
}

const PrintLink = styled('a')`
  text-decoration-line: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 0.5rem;
  svg {
    font-size: 1rem;
    padding-right: 0.25rem;
    display: block;
  }
`;

const PageItem = styled('div')`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
`;
