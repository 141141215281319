import { gql, useMutation, useQuery } from '@apollo/client';
import { Loading } from '@everlutionsk/ui';
import { useNavigate, useParams } from '@everlutionsk/ui-router';
import { Box, Container } from '@mui/material';
import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Complete } from '../Complete';
import { Error } from '../Error';
import { DiagnosticT95 } from './Diagnostic';
import { T95Identification } from './identification';
import { Content } from '../components';

export function T95() {
  const location = useLocation();
  const { token } = useParams(['token']);

  const { data, refetch } = useQuery(query, {
    variables: { token }
  });

  const navigate = useNavigate();

  const [mutate] = useMutation(mutation);

  useEffect(() => {
    refetch();
  }, [location.pathname]);

  if (data == null) return <Loading />;

  const { t95Diagnostics } = data;
  if (t95Diagnostics == null) return <Error></Error>;

  const { code, status, originalToken, isDemo, isValid, label } = t95Diagnostics;

  if (!isValid) return <Error></Error>;
  if (status === 'finished') return <Complete />;

  const isReadOnly = status === 'readonly' && originalToken != null;
  return (
    <Content>
      <Container maxWidth="xl">
        <Box my={3}>
          <Routes>
            <Route
              path=""
              element={
                <T95Identification
                  token={token}
                  isDemo={isDemo}
                  code={code}
                  label={label}
                  isReadOnly={isReadOnly}
                />
              }
            />
            <Route
              path="test"
              element={
                <DiagnosticT95
                  code={code}
                  isDemo={isDemo}
                  label={label}
                  token={token}
                  isReadOnly={isReadOnly}
                  onSubmit={input => {
                    mutate({ variables: { input } })
                      .then(() => navigate('./complete'))
                      .catch(error => console.error(error));
                  }}
                />
              }
            />

            <Route path="complete" element={<Complete />} />
          </Routes>
        </Box>
      </Container>
    </Content>
  );
}

const query = gql<T95DiagnosticQueryGQL>`
  query T95DiagnosticQuery($token: String!) {
    t95Diagnostics(token: $token) {
      isValid
      isDemo
      code
      label
      status
      originalToken
    }
  }
`;

const mutation = gql<SubmitTestT95GQL>`
  mutation SubmitTestT95($input: SubmitT95Input!) {
    submitT95(input: $input)
  }
`;
