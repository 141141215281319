export const t252Routing = (token: string) => ({
  page1: {
    instructions: `${token}/pages/1/instructions`,
    test: `${token}/pages/1/test`
  },
  page2: {
    instructions: `${token}/pages/2/instructions`,
    test: `${token}/pages/2/test`
  },
  page3: {
    instructions: `${token}/pages/3/instructions`,
    test: `${token}/pages/3/test`
  },
  complete: `${token}/complete`
});
