export const t8Routing = (token: string) => ({
  chooseVersion: token,
  v1: {
    identification: `${token}/v2`,
    instructions: `${token}/v1/instructions`,
    test: `${token}/v1/test`
  },
  v2: {
    identification: `${token}/v1`,
    instructions: `${token}/v2/instructions`,
    test: `${token}/v2/test`
  },
  complete: `${token}/complete`
});
