import { styled } from '@mui/material';
import React, { ReactNode } from 'react';

interface Props {
  readonly children: ReactNode;
  readonly className: string;
}

export function BaseList({ children, className }: Props) {
  return <List className={className}>{children}</List>;
}

const List = styled('ul')`
  &.unstyled {
    list-style-type: none;
  }
  &.d-inline {
    li {
      display: inline;
    }
  }
  &.d-inline-block {
    li {
      display: inline-block;
    }
  }
  &.d-block {
    li {
      display: block;
    }
  }
  &.no-spacing {
    margin: 0;
    padding: 0;
  }
`;
