import T202_kt_alena from './documents/kt_alena.pdf';
import T202_kt_doma from './documents/kt_doma.pdf';
import T202_kt_eiaoeuay from './documents/kt_eiaoeuay.pdf';
import T202_kt_jedla_a_dub from './documents/kt_jedla_a_dub.pdf';
import T202_kt_kapor from './documents/kt_kapor.pdf';
import T202_kt_kvocka_a_kocur from './documents/kt_kvocka_a_kocur.pdf';
import T202_kt_latys from './documents/kt_latys.pdf';
import T202_kt_maciatko from './documents/kt_maciatko.pdf';
import T202_kt_o_krtkovi from './documents/kt_o_krtkovi.pdf';
import T202_kt_sykorky from './documents/kt_sykorky.pdf';
import T202_kt_zajacik from './documents/kt_zajacik.pdf';
import T202_predlohy_alena from './documents/predlohy_alena.pdf';
import T202_predlohy_doma from './documents/predlohy_doma.pdf';
import T202_predlohy_eiaoeuay from './documents/predlohy_eiaoeuay.pdf';
import T202_predlohy_jedla_a_dub from './documents/predlohy_jedla_a_dub.pdf';
import T202_predlohy_kapor from './documents/predlohy_kapor.pdf';
import T202_predlohy_kvocka_a_kocur from './documents/predlohy_kvocka_a_kocur.pdf';
import T202_predlohy_latys from './documents/predlohy_latys.pdf';
import T202_predlohy_maciatko from './documents/predlohy_maciatko.pdf';
import T202_predlohy_o_krtkovi from './documents/predlohy_o_krtkovi.pdf';
import T202_predlohy_sykorky from './documents/predlohy_sykorky.pdf';
import T202_predlohy_zajacik from './documents/predlohy_zajacik.pdf';

const documents = {
  T202_predlohy_alena,
  T202_predlohy_zajacik,
  T202_predlohy_kvocka_a_kocur,
  T202_predlohy_kapor,
  T202_predlohy_sykorky,
  T202_predlohy_o_krtkovi,
  T202_predlohy_jedla_a_dub,
  T202_predlohy_latys,
  T202_predlohy_eiaoeuay,
  T202_predlohy_maciatko,
  T202_predlohy_doma,
  T202_kt_alena,
  T202_kt_zajacik,
  T202_kt_kvocka_a_kocur,
  T202_kt_kapor,
  T202_kt_sykorky,
  T202_kt_o_krtkovi,
  T202_kt_jedla_a_dub,
  T202_kt_latys,
  T202_kt_eiaoeuay,
  T202_kt_maciatko,
  T202_kt_doma
};

export function toDocumentUrl(key: string): string {
  if (key in documents === false) throw new Error(`Document with key: ${key} was not found`);
  return documents[key];
}
