import {
  Box,
  Table as MaterialTable,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import React from 'react';
import { FigureSelector } from '../components/FigureSelector';
import { toImageUrl } from './images';

export function Instructions() {
  return (
    <>
      <Typography component="h5" variant="h5">
        Skladanie figúr - inštrukcie
      </Typography>
      <Box my={3}>
        <Typography component="p" variant="body2">
          V nasledujúcich úlohách nájdete zoradené rôzne figúry (tvary), ktoré sú označené písmenami
          A až E.
        </Typography>
      </Box>
      <Box my={3}>
        <Typography component="p" variant="body2">
          V každom z rámčekov v zadaní sa nachádza viacero častí figúry (tvaru), ktoré keď sa
          poskladajú, vytvoria vždy jednu z figúr A až E v danom riadku. Jednotlivé časti figúr
          možno posúvať a otáčať ako pri skladaní puzzle, nemožno ich však "zdvihnúť" a položiť
          hornou časťou nadol.
        </Typography>
      </Box>
      <Box my={3}>
        <Typography component="p" variant="body2">
          Vašou úlohou je teraz zistiť, ktorú z figúr A až E možno zložiť z častí figúr v
          jednotlivých rámčekoch a označiť správnu možnosť.
        </Typography>
      </Box>
      <Box my={3}>
        <Typography component="p" variant="body2">
          <strong>Príklad:</strong>
        </Typography>
      </Box>
      <TableContainer component={Paper}>
        <MaterialTable sx={{ minWidth: 650 }}>
          <TableBody>
            <TableRow>
              <TableCell>
                <strong>B18.</strong>
              </TableCell>
              <TableCell>
                <FigureSelector
                  disabled
                  initialValue="i-1-4"
                  templateFigure={{ img: toImageUrl('figures_demo_1') }}
                  options={[
                    { value: 'i-1-1', letter: 'A', img: toImageUrl('p10_demo_A') },
                    { value: 'i-1-2', letter: 'B', img: toImageUrl('p10_demo_B') },
                    { value: 'i-1-3', letter: 'C', img: toImageUrl('p10_demo_C') },
                    { value: 'i-1-4', letter: 'D', img: toImageUrl('p10_demo_D') },
                    { value: 'i-1-5', letter: 'E', img: toImageUrl('p10_demo_E') }
                  ]}
                  onChange={() => {}}
                ></FigureSelector>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>B19.</strong>
              </TableCell>
              <TableCell>
                <FigureSelector
                  disabled
                  initialValue="i-2-1"
                  templateFigure={{ img: toImageUrl('figures_demo_2') }}
                  options={[
                    { value: 'i-2-1', letter: 'A', img: toImageUrl('p10_demo_A') },
                    { value: 'i-2-2', letter: 'B', img: toImageUrl('p10_demo_B') },
                    { value: 'i-2-3', letter: 'C', img: toImageUrl('p10_demo_C') },
                    { value: 'i-2-4', letter: 'D', img: toImageUrl('p10_demo_D') },
                    { value: 'i-2-5', letter: 'E', img: toImageUrl('p10_demo_E') }
                  ]}
                  onChange={() => {}}
                ></FigureSelector>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>B20.</strong>
              </TableCell>
              <TableCell>
                <FigureSelector
                  disabled
                  initialValue="i-3-1"
                  templateFigure={{ img: toImageUrl('figures_demo_3') }}
                  options={[
                    { value: 'i-3-1', letter: 'A', img: toImageUrl('p10_demo_A') },
                    { value: 'i-3-2', letter: 'B', img: toImageUrl('p10_demo_B') },
                    { value: 'i-3-3', letter: 'C', img: toImageUrl('p10_demo_C') },
                    { value: 'i-3-4', letter: 'D', img: toImageUrl('p10_demo_D') },
                    { value: 'i-3-5', letter: 'E', img: toImageUrl('p10_demo_E') }
                  ]}
                  onChange={() => {}}
                ></FigureSelector>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>B21.</strong>
              </TableCell>
              <TableCell>
                <FigureSelector
                  disabled
                  initialValue="i-4-5"
                  templateFigure={{ img: toImageUrl('figures_demo_4') }}
                  options={[
                    { value: 'i-4-1', letter: 'A', img: toImageUrl('p10_demo_A') },
                    { value: 'i-4-2', letter: 'B', img: toImageUrl('p10_demo_B') },
                    { value: 'i-4-3', letter: 'C', img: toImageUrl('p10_demo_C') },
                    { value: 'i-4-4', letter: 'D', img: toImageUrl('p10_demo_D') },
                    { value: 'i-4-5', letter: 'E', img: toImageUrl('p10_demo_E') }
                  ]}
                  onChange={() => {}}
                ></FigureSelector>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>B22.</strong>
              </TableCell>
              <TableCell>
                <FigureSelector
                  disabled
                  initialValue="i-5-2"
                  templateFigure={{ img: toImageUrl('figures_demo_5') }}
                  options={[
                    { value: 'i-5-1', letter: 'A', img: toImageUrl('p10_demo_A') },
                    { value: 'i-5-2', letter: 'B', img: toImageUrl('p10_demo_B') },
                    { value: 'i-5-3', letter: 'C', img: toImageUrl('p10_demo_C') },
                    { value: 'i-5-4', letter: 'D', img: toImageUrl('p10_demo_D') },
                    { value: 'i-5-5', letter: 'E', img: toImageUrl('p10_demo_E') }
                  ]}
                  onChange={() => {}}
                ></FigureSelector>
              </TableCell>
            </TableRow>
          </TableBody>
        </MaterialTable>
      </TableContainer>
      <Box my={3}>
        <Typography component="p" variant="body2">
          Keď zložíme časti figúry v rámčeku B18, vznikne z nich figúra D. V príklade B19 pri
          otočení jednej z častí vznikne figúra A. Aj v príklade B20 dostaneme po zložení oboch
          častí figúru A. V príklade B21 je riešením E a v B22 je správne písmeno B.
        </Typography>
      </Box>
      <Box my={3}>
        <Typography component="p" variant="body2">
          Na riešenie nasledujúcich <strong>12 úloh</strong> máte čas <strong>5 minút</strong>.{' '}
          <strong>Počkajte na vyzvanie stlačiť tlačidlo ĎALEJ.</strong>
        </Typography>
      </Box>
    </>
  );
}
