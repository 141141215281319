import { gql, useMutation, useQuery } from '@apollo/client';
import { Button, Loading } from '@everlutionsk/ui';
import { createFormSpec, RadioField, SelectField } from '@everlutionsk/ui-formik';
import { useNavigate, useParams } from '@everlutionsk/ui-router';
import Check from '@mui/icons-material/Check';
import { Box, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { PaperContent } from '../../../components/PaperContent';
import { T202ArticleFragment } from '../../../graphql/types';
import { Error } from '../Error';
import { InputList } from '../Identification/InputList';
import { InputWrapper } from '../Identification/InputWrapper';
import { gradeOptionsMatch } from './gradesOptions';

interface Props {
  readonly code: string;
  readonly isDemo: boolean;
  readonly onBackClick: (articleId: string) => void;
  readonly token: string;
  readonly articles: T202ArticleFragment[];
  readonly isReadOnly: boolean;
}

const mutation = gql<SubmitT202IdentificationGQL>`
  mutation SubmitT202Identification($input: UpdateStudentByTokenInput!) {
    updateStudentByToken(input: $input) {
      id
      birthIdentificationNumber
      firstName
      lastName
      fullName
      dateOfBirth
      gender
      grade
      postponement
      repeatedGrade
      ratingSlovakLanguage
      ratingMaths
      ratingForeignLanguage
      readingEvaluation
      motherEducation
      fatherEducation
    }
  }
`;

const query = gql<T202StudentOneByTokenGQL>`
  query T202StudentOneByToken($token: String!) {
    studentOneByToken(token: $token) {
      id
      birthIdentificationNumber
      firstName
      lastName
      fullName
      dateOfBirth
      gender
      grade
      postponement
      repeatedGrade
      ratingSlovakLanguage
      ratingMaths
      ratingForeignLanguage
      readingEvaluation
      motherEducation
      fatherEducation
    }
  }
`;

interface FormValues {
  grade: number | undefined;
  postponement: number | undefined;
  repeatedGrade: number | undefined;
  ratingSlovakLanguage: number | undefined;
  ratingMaths: number | undefined;
  ratingForeignLanguage: number | undefined;
  readingEvaluation: number | undefined;
  motherEducation: number | undefined;
  fatherEducation: number | undefined;
}

const spec = createFormSpec<FormValues>({
  grade: yup.number().nullable().required('Prosím zadajte triedu'),
  postponement: yup.number().nullable().required('Prosím vyberte odpoveď'),
  repeatedGrade: yup.number().nullable().required('Prosím vyberte odpoveď'),
  ratingSlovakLanguage: yup.number().nullable().notRequired(),
  ratingMaths: yup.number().nullable().notRequired(),
  ratingForeignLanguage: yup.number().nullable().notRequired(),
  readingEvaluation: yup.number().nullable().notRequired(),
  motherEducation: yup.number().nullable().notRequired(),
  fatherEducation: yup.number().nullable().notRequired()
});

export function Identification({ isDemo, code, onBackClick, token, articles, isReadOnly }: Props) {
  const { articleId } = useParams(['articleId']);
  const navigate = useNavigate();

  const { data } = useQuery(query, { variables: { token } });
  const [mutate] = useMutation(mutation);

  if (data == null) return <Loading />;

  const article = articles.find(article => article.id === articleId);
  if (article == null) return <Error></Error>;

  return (
    <PaperContent title={article.title} testNumber={code}>
      <Typography component="h3" variant="h4">
        Identifikácia
      </Typography>
      <Typography component="p" variant="body2">
        Prosíme, aby ste vyplnili nasledujúce údaje:
      </Typography>
      <Box my={3}>
        <Formik
          {...spec({
            grade: isDemo ? 9 : data.studentOneByToken.grade,
            postponement: isDemo ? 0 : data.studentOneByToken.postponement,
            repeatedGrade: isDemo ? 0 : data.studentOneByToken.repeatedGrade,
            ratingSlovakLanguage: isDemo ? 1 : data.studentOneByToken.ratingSlovakLanguage,
            ratingMaths: isDemo ? 1 : data.studentOneByToken.ratingMaths,
            ratingForeignLanguage: isDemo ? 1 : data.studentOneByToken.ratingForeignLanguage,
            motherEducation: isDemo ? 5 : data.studentOneByToken.motherEducation,
            fatherEducation: isDemo ? 5 : data.studentOneByToken.fatherEducation,
            readingEvaluation: isDemo ? 1 : data.studentOneByToken.readingEvaluation
          })}
          onSubmit={async ({
            grade,
            postponement,
            repeatedGrade,
            ratingSlovakLanguage,
            ratingMaths,
            ratingForeignLanguage,
            motherEducation,
            fatherEducation,
            readingEvaluation
          }) => {
            if (isDemo || isReadOnly) {
              return navigate(`../../test/${articleId}`);
            }

            await mutate({
              variables: {
                input: {
                  token,
                  grade,
                  postponement,
                  repeatedGrade,
                  ratingSlovakLanguage,
                  ratingMaths,
                  ratingForeignLanguage,
                  motherEducation,
                  fatherEducation,
                  readingEvaluation
                }
              }
            });
            navigate(`../../test/${articleId}`);
          }}
        >
          <Form>
            <InputList>
              <InputWrapper label={`Trieda`}>
                <SelectField
                  options={gradeOptionsMatch[articleId] ?? []}
                  name="grade"
                  variant="standard"
                  disabled={Boolean(isDemo) || isReadOnly}
                />
              </InputWrapper>
              <InputWrapper label={`Odklad školskej dochádzky`}>
                <RadioField
                  name="postponement"
                  options={yesNoOptions}
                  row
                  disabled={Boolean(isDemo) || isReadOnly}
                ></RadioField>
              </InputWrapper>
              <InputWrapper label={`Opakoval triedu`}>
                <RadioField
                  name="repeatedGrade"
                  options={yesNoOptions}
                  row
                  disabled={Boolean(isDemo) || isReadOnly}
                ></RadioField>
              </InputWrapper>
              <InputWrapper label={`Známky na posl. Vysvedčení`}>
                <SelectField
                  label="Slovenčina"
                  options={ratingOptions}
                  name="ratingSlovakLanguage"
                  variant="standard"
                  disabled={Boolean(isDemo) || isReadOnly}
                />
                <SelectField
                  label="Matematika"
                  options={ratingOptions}
                  name="ratingMaths"
                  variant="standard"
                  disabled={Boolean(isDemo) || isReadOnly}
                />
                <SelectField
                  label="Cudzí jazyk"
                  options={ratingOptions}
                  name="ratingForeignLanguage"
                  variant="standard"
                  disabled={Boolean(isDemo) || isReadOnly}
                />
              </InputWrapper>
              <InputWrapper label={`Hodnotenie učiteľom ako čitateľ`}>
                <SelectField
                  options={readingEvaluationOptions}
                  name="readingEvaluation"
                  variant="standard"
                  disabled={Boolean(isDemo) || isReadOnly}
                />
              </InputWrapper>
              <InputWrapper label={`Vzdelanie matky`}>
                <SelectField
                  options={educationOptions}
                  name="motherEducation"
                  variant="standard"
                  disabled={Boolean(isDemo) || isReadOnly}
                />
              </InputWrapper>
              <InputWrapper label={`Vzdelanie otca`}>
                <SelectField
                  options={educationOptions}
                  name="fatherEducation"
                  variant="standard"
                  disabled={Boolean(isDemo) || isReadOnly}
                />
              </InputWrapper>
            </InputList>
            <Box my={3}>
              <Button size="large" color="secondary" onClick={() => onBackClick(articleId)}>
                Krok späť
              </Button>
              <Button
                type="submit"
                size="medium"
                variant="contained"
                startIcon={<Check />}
                style={{ marginLeft: '10px' }}
              >
                Spustiť test
              </Button>
            </Box>
          </Form>
        </Formik>
      </Box>
    </PaperContent>
  );
}

const yesNoOptions = [
  { label: 'áno', value: 1 },
  { label: 'nie', value: 0 }
];

const readingEvaluationOptions = [
  { label: 'výborný', value: 1 },
  { label: 'veľmi dobrý', value: 2 },
  { label: 'priemerný', value: 3 },
  { label: 'podpriemerný', value: 4 },
  { label: 'hlboko podpriemerný', value: 5 }
];

const ratingOptions = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 }
];

const educationOptions = [
  { label: 'základné, neúplné', value: 1 },
  { label: 'základné, nevyučený', value: 2 },
  { label: 'základné, vyučený', value: 3 },
  { label: 'stredné, maturita', value: 4 },
  { label: 'vysokoškolské', value: 5 }
];
