import { styled, Table } from '@mui/material';
import React, { ReactNode } from 'react';

interface Props {
  readonly children: ReactNode;
}

export function StyledMaterialTable({ children }: Props) {
  return <StyledTable>{children}</StyledTable>;
}

const StyledTable = styled(Table)`
  .MuiTableRow-root {
    .MuiTableCell-root {
      &:first-child {
        width: 1%;
        white-space: nowrap;
      }
    }
  }
`;
