import { gql, useMutation, useQuery } from '@apollo/client';
import { Loading } from '@everlutionsk/ui';
import { useParams } from '@everlutionsk/ui-router';
import { Box, Container } from '@mui/material';
import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Complete } from '../Complete';
import { Error } from '../Error';
import { Identification } from './Identification';
import { InstructionsPage } from './InstructionsPage';
import { Diagnostic as Diagnostic1, t309Page1Fragment } from './page1/Diagnostic';
import { Instructions as Instructions1 } from './page1/Instructions';
import { Diagnostic as Diagnostic10, t309Page10Fragment } from './page10/Diagnostic';
import { Instructions as Instructions10 } from './page10/Instructions';
import { Diagnostic as Diagnostic2, t309Page2Fragment } from './page2/Diagnostic';
import { Instructions as Instructions2 } from './page2/Instructions';
import { Diagnostic as Diagnostic3, t309Page3Fragment } from './page3/Diagnostic';
import { DiagnosticDone } from './page3/DiagnosticDone';
import { Instructions as Instructions3 } from './page3/Instructions';
import { Diagnostic as Diagnostic4, t309Page4Fragment } from './page4/Diagnostic';
import { Instructions as Instructions4 } from './page4/Instructions';
import { Diagnostic as Diagnostic5, t309Page5Fragment } from './page5/Diagnostic';
import { Instructions as Instructions5 } from './page5/Instructions';
import { Diagnostic as Diagnostic6, t309Page6Fragment } from './page6/Diagnostic';
import { Instructions as Instructions6 } from './page6/Instructions';
import { Diagnostic as Diagnostic7, t309Page7Fragment } from './page7/Diagnostic';
import { Instructions as Instructions7 } from './page7/Instructions';
import { Diagnostic as Diagnostic8, t309Page8Fragment } from './page8/Diagnostic';
import { Instructions as Instructions8 } from './page8/Instructions';
import { Diagnostic as Diagnostic9, t309Page9Fragment } from './page9/Diagnostic';
import { Instructions as Instructions9 } from './page9/Instructions';
import { Content } from '../components';

const query = gql<T309DiagnosticQueryGQL>`
  query T309DiagnosticQuery($token: String!) {
    t309Diagnostics(token: $token) {
      isValid
      isDemo
      code
      label
      status
      originalToken
      answers {
        page1 {
          id
          answer
        }
        page2 {
          id
          answer
        }
        page3 {
          id
          answer
        }
        page4 {
          id
          answer
        }
        page5 {
          id
          answer
        }
        page6 {
          id
          answer
        }
        page7 {
          id
          answer
        }
        page8 {
          id
          answer
        }
        page9 {
          id
          a
          b
        }
        page10 {
          id
          answer
        }
      }
      page1 {
        ...T309Page1Fragment
      }
      page2 {
        ...T309Page2Fragment
      }
      page3 {
        ...T309Page3Fragment
      }
      page4 {
        ...T309Page4Fragment
      }
      page5 {
        ...T309Page5Fragment
      }
      page6 {
        ...T309Page6Fragment
      }
      page7 {
        ...T309Page7Fragment
      }
      page8 {
        ...T309Page8Fragment
      }
      page9 {
        ...T309Page9Fragment
      }
      page10 {
        ...T309Page10Fragment
      }
    }
  }
  ${t309Page1Fragment}
  ${t309Page2Fragment}
  ${t309Page3Fragment}
  ${t309Page4Fragment}
  ${t309Page5Fragment}
  ${t309Page6Fragment}
  ${t309Page7Fragment}
  ${t309Page8Fragment}
  ${t309Page9Fragment}
  ${t309Page10Fragment}
`;

export function T309() {
  const { token } = useParams(['token']);
  const location = useLocation();

  const { data, refetch } = useQuery(query, {
    variables: { token }
  });
  const navigate = useNavigate();

  const [mutatePage1] = useMutation(mutationPage1);
  const [mutatePage2] = useMutation(mutationPage2);
  const [mutatePage3] = useMutation(mutationPage3);
  const [mutatePage4] = useMutation(mutationPage4);
  const [mutatePage5] = useMutation(mutationPage5);
  const [mutatePage6] = useMutation(mutationPage6);
  const [mutatePage7] = useMutation(mutationPage7);
  const [mutatePage8] = useMutation(mutationPage8);
  const [mutatePage9] = useMutation(mutationPage9);
  const [mutatePage10] = useMutation(mutationPage10);

  useEffect(() => {
    refetch();
  }, [location.pathname]);

  if (data == null) return <Loading />;
  const { t309Diagnostics } = data;
  if (t309Diagnostics == null) return <Error></Error>;

  const {
    code,
    isValid,
    isDemo,
    label,
    status,
    originalToken,
    answers,
    page1,
    page2,
    page3,
    page4,
    page5,
    page6,
    page7,
    page8,
    page9,
    page10
  } = t309Diagnostics;

  if (!isValid) return <Error></Error>;
  if (status === 'finished') return <Complete />;

  const isReadOnly = status === 'readonly' && originalToken != null;

  return (
    <Content>
      <Container maxWidth="xl">
        <Box my={3}>
          <Routes>
            <Route
              path=""
              element={
                <Identification
                  code={code}
                  isDemo={isDemo}
                  label={label}
                  token={token}
                  isReadOnly={isReadOnly}
                />
              }
            />
            <Route
              path="instructions"
              element={
                <InstructionsPage
                  code={code}
                  instructions={<Instructions1 />}
                  label={label}
                  onClick={() => navigate(`pages/1/test`)}
                />
              }
            />
            <Route
              path="pages/1/test"
              element={
                <Diagnostic1
                  code={code}
                  page={page1}
                  label={label}
                  token={token}
                  onSubmit={values => {
                    if (isDemo || isReadOnly) return navigate('pages/2/instructions');
                    mutatePage1({
                      variables: {
                        input: values
                      }
                    })
                      .then(() => navigate('pages/2/instructions'))
                      .catch(error => console.error(error));
                  }}
                  isDemo={isDemo}
                  isReadOnly={isReadOnly}
                  initialValues={
                    isDemo
                      ? page1.questions.reduce((initial, question) => {
                          initial[question.id] = question.choices[0].id;
                          return initial;
                        }, {})
                      : isReadOnly
                        ? page1.questions.reduce((initial, question) => {
                            initial[question.id] = answers?.page1.find(
                              item => item.id === question.id
                            )?.answer;
                            return initial;
                          }, {})
                        : {}
                  }
                />
              }
            />
            <Route
              path="pages/2/instructions"
              element={
                <InstructionsPage
                  code={code}
                  instructions={<Instructions2 />}
                  label={label}
                  onClick={() => navigate(`pages/2/test`)}
                />
              }
            />
            <Route
              path="pages/2/test"
              element={
                <Diagnostic2
                  code={code}
                  page={page2}
                  label={label}
                  token={token}
                  onSubmit={values => {
                    if (isDemo || isReadOnly) return navigate('pages/3/instructions');
                    mutatePage2({
                      variables: {
                        input: values
                      }
                    })
                      .then(() => navigate('pages/3/instructions'))
                      .catch(error => console.error(error));
                  }}
                  isDemo={isDemo}
                  isReadOnly={isReadOnly}
                  initialValues={
                    isDemo
                      ? page2.questions.reduce((initial, question) => {
                          initial[question.id] = question.choices[0].id;
                          return initial;
                        }, {})
                      : isReadOnly
                        ? page2.questions.reduce((initial, question) => {
                            initial[question.id] = answers?.page2.find(
                              item => item.id === question.id
                            )?.answer;
                            return initial;
                          }, {})
                        : {}
                  }
                />
              }
            />
            <Route
              path="pages/3/instructions"
              element={
                <InstructionsPage
                  code={code}
                  instructions={<Instructions3 />}
                  label={label}
                  onClick={() => {
                    if (isReadOnly) {
                      return navigate('pages/3/done');
                    }
                    navigate(`pages/3/test`);
                  }}
                />
              }
            />
            <Route
              path="pages/3/test"
              element={
                <Diagnostic3
                  code={code}
                  page={page3}
                  label={label}
                  onSubmit={() => {
                    if (isDemo || isReadOnly) return navigate('pages/3/done');
                    mutatePage3({
                      variables: {
                        input: { token }
                      }
                    })
                      .then(() => navigate('pages/3/done'))
                      .catch(error => console.error(error));
                  }}
                  isDemo={isDemo}
                />
              }
            />
            <Route
              path="pages/3/done"
              element={
                <DiagnosticDone
                  code={code}
                  label={label}
                  onSubmit={() => navigate('pages/4/instructions')}
                />
              }
            />
            <Route
              path="pages/4/instructions"
              element={
                <InstructionsPage
                  code={code}
                  instructions={<Instructions4 />}
                  label={label}
                  onClick={() => navigate(`pages/4/test`)}
                />
              }
            />
            <Route
              path="pages/4/test"
              element={
                <Diagnostic4
                  code={code}
                  page={page4}
                  label={label}
                  token={token}
                  onSubmit={values => {
                    if (isDemo || isReadOnly) return navigate('pages/5/instructions');
                    mutatePage4({
                      variables: {
                        input: values
                      }
                    })
                      .then(() => navigate('pages/5/instructions'))
                      .catch(error => console.error(error));
                  }}
                  isDemo={isDemo}
                  isReadOnly={isReadOnly}
                  initialValues={
                    isDemo
                      ? page4.questions.reduce((initial, question) => {
                          initial[question.id] = '42';
                          return initial;
                        }, {})
                      : isReadOnly
                        ? page4.questions.reduce((initial, question) => {
                            initial[question.id] = answers?.page4.find(
                              item => item.id === question.id
                            )?.answer;
                            return initial;
                          }, {})
                        : {}
                  }
                />
              }
            />
            <Route
              path="pages/5/instructions"
              element={
                <InstructionsPage
                  code={code}
                  instructions={<Instructions5 />}
                  label={label}
                  onClick={() => navigate(`pages/5/test`)}
                />
              }
            />
            <Route
              path="pages/5/test"
              element={
                <Diagnostic5
                  code={code}
                  page={page5}
                  label={label}
                  token={token}
                  onSubmit={values => {
                    if (isDemo || isReadOnly) return navigate('pages/6/instructions');
                    mutatePage5({
                      variables: {
                        input: values
                      }
                    })
                      .then(() => navigate('pages/6/instructions'))
                      .catch(error => console.error(error));
                  }}
                  isDemo={isDemo}
                  isReadOnly={isReadOnly}
                  initialValues={
                    isDemo
                      ? page5.questions.reduce((initial, question) => {
                          initial[question.id] = question.choices[0].id;
                          return initial;
                        }, {})
                      : isReadOnly
                        ? page5.questions.reduce((initial, question) => {
                            initial[question.id] = answers?.page5.find(
                              item => item.id === question.id
                            )?.answer;
                            return initial;
                          }, {})
                        : {}
                  }
                />
              }
            />
            <Route
              path="pages/6/instructions"
              element={
                <InstructionsPage
                  code={code}
                  instructions={<Instructions6 />}
                  label={label}
                  onClick={() => navigate(`pages/6/test`)}
                />
              }
            />
            <Route
              path="pages/6/test"
              element={
                <Diagnostic6
                  code={code}
                  page={page6}
                  label={label}
                  token={token}
                  onSubmit={values => {
                    if (isDemo || isReadOnly) return navigate('pages/7/instructions');
                    mutatePage6({
                      variables: {
                        input: values
                      }
                    })
                      .then(() => navigate('pages/7/instructions'))
                      .catch(error => console.error(error));
                  }}
                  isDemo={isDemo}
                  isReadOnly={isReadOnly}
                  initialValues={
                    isDemo
                      ? page6.questions.reduce((initial, question) => {
                          initial[question.id] = question.choices[0].id;
                          return initial;
                        }, {})
                      : isReadOnly
                        ? page6.questions.reduce((initial, question) => {
                            initial[question.id] = answers?.page6.find(
                              item => item.id === question.id
                            )?.answer;
                            return initial;
                          }, {})
                        : {}
                  }
                />
              }
            />
            <Route
              path="pages/7/instructions"
              element={
                <InstructionsPage
                  code={code}
                  instructions={<Instructions7 />}
                  label={label}
                  onClick={() => navigate(`pages/7/test`)}
                />
              }
            />
            <Route
              path="pages/7/test"
              element={
                <Diagnostic7
                  code={code}
                  page={page7}
                  label={label}
                  token={token}
                  onSubmit={values => {
                    if (isDemo || isReadOnly) return navigate('pages/8/instructions');
                    mutatePage7({
                      variables: {
                        input: values
                      }
                    })
                      .then(() => navigate('pages/8/instructions'))
                      .catch(error => console.error(error));
                  }}
                  isDemo={isDemo}
                  isReadOnly={isReadOnly}
                  initialValues={
                    isDemo
                      ? page7.questions.reduce((initial, question) => {
                          initial[question.id] = question.choices[0].id;
                          return initial;
                        }, {})
                      : isReadOnly
                        ? page7.questions.reduce((initial, question) => {
                            initial[question.id] = answers?.page7.find(
                              item => item.id === question.id
                            )?.answer;
                            return initial;
                          }, {})
                        : {}
                  }
                />
              }
            />
            <Route
              path="pages/8/instructions"
              element={
                <InstructionsPage
                  code={code}
                  instructions={<Instructions8 />}
                  label={label}
                  onClick={() => navigate(`pages/8/test`)}
                />
              }
            />
            <Route
              path="pages/8/test"
              element={
                <Diagnostic8
                  code={code}
                  page={page8}
                  label={label}
                  token={token}
                  onSubmit={values => {
                    if (isDemo || isReadOnly) return navigate('pages/9/instructions');
                    mutatePage8({
                      variables: {
                        input: values
                      }
                    })
                      .then(() => navigate('pages/9/instructions'))
                      .catch(error => console.error(error));
                  }}
                  isDemo={isDemo}
                  isReadOnly={isReadOnly}
                  initialValues={
                    isDemo
                      ? page8.questions.reduce((initial, question) => {
                          initial[question.id] = question.id;
                          return initial;
                        }, {})
                      : isReadOnly
                        ? page8.questions.reduce((initial, question) => {
                            initial[question.id] = answers?.page8.find(
                              item => item.id === question.id
                            )?.answer;
                            return initial;
                          }, {})
                        : {}
                  }
                />
              }
            />
            <Route
              path="pages/9/instructions"
              element={
                <InstructionsPage
                  code={code}
                  instructions={<Instructions9 />}
                  label={label}
                  onClick={() => navigate(`pages/9/test`)}
                />
              }
            />
            <Route
              path="pages/9/test"
              element={
                <Diagnostic9
                  code={code}
                  page={page9}
                  label={label}
                  token={token}
                  onSubmit={values => {
                    if (isDemo || isReadOnly) return navigate('pages/10/instructions');
                    mutatePage9({
                      variables: {
                        input: values
                      }
                    })
                      .then(() => navigate('pages/10/instructions'))
                      .catch(error => console.error(error));
                  }}
                  isDemo={isDemo}
                  isReadOnly={isReadOnly}
                  initialValues={
                    isDemo
                      ? page9.questions.reduce((initial, question) => {
                          initial[question.id] = question.choices[0].id;
                          return initial;
                        }, {})
                      : isReadOnly
                        ? page9.questions.reduce((initial, question) => {
                            const answer = answers?.page9.find(item => item.id === question.id);
                            initial[question.id] = {
                              a: answer?.a ?? '',
                              b: answer?.b ?? ''
                            };
                            return initial;
                          }, {})
                        : {}
                  }
                />
              }
            />
            <Route
              path="pages/10/instructions"
              element={
                <InstructionsPage
                  code={code}
                  instructions={<Instructions10 />}
                  label={label}
                  onClick={() => navigate(`pages/10/test`)}
                />
              }
            />
            <Route
              path="pages/10/test"
              element={
                <Diagnostic10
                  code={code}
                  page={page10}
                  label={label}
                  token={token}
                  onSubmit={values => {
                    if (isDemo || isReadOnly) return navigate('complete');
                    mutatePage10({
                      variables: {
                        input: values
                      }
                    })
                      .then(() => navigate('complete'))
                      .catch(error => console.error(error));
                  }}
                  isDemo={isDemo}
                  isReadOnly={isReadOnly}
                  initialValues={
                    isDemo
                      ? page10.questions.reduce((initial, question) => {
                          initial[question.id] = question.choices[0].id;
                          return initial;
                        }, {})
                      : isReadOnly
                        ? page10.questions.reduce((initial, question) => {
                            initial[question.id] = answers?.page10.find(
                              item => item.id === question.id
                            )?.answer;
                            return initial;
                          }, {})
                        : {}
                  }
                />
              }
            />
            <Route path="complete" element={<Complete />} />
          </Routes>
        </Box>
      </Container>
    </Content>
  );
}

const mutationPage1 = gql<SubmitT309Page1GQL>`
  mutation SubmitT309Page1($input: SubmitT309Page1Input!) {
    submitT309Page1(input: $input)
  }
`;

const mutationPage2 = gql<SubmitT309Page2GQL>`
  mutation SubmitT309Page2($input: SubmitT309Page2Input!) {
    submitT309Page2(input: $input)
  }
`;

const mutationPage3 = gql<SubmitT309Page3GQL>`
  mutation SubmitT309Page3($input: SubmitT309Page3Input!) {
    submitT309Page3(input: $input)
  }
`;

const mutationPage4 = gql<SubmitT309Page4GQL>`
  mutation SubmitT309Page4($input: SubmitT309Page4Input!) {
    submitT309Page4(input: $input)
  }
`;

const mutationPage5 = gql<SubmitT309Page5GQL>`
  mutation SubmitT309Page5($input: SubmitT309Page5Input!) {
    submitT309Page5(input: $input)
  }
`;

const mutationPage6 = gql<SubmitT309Page6GQL>`
  mutation SubmitT309Page6($input: SubmitT309Page6Input!) {
    submitT309Page6(input: $input)
  }
`;

const mutationPage7 = gql<SubmitT309Page7GQL>`
  mutation SubmitT309Page7($input: SubmitT309Page7Input!) {
    submitT309Page7(input: $input)
  }
`;

const mutationPage8 = gql<SubmitT309Page8GQL>`
  mutation SubmitT309Page8($input: SubmitT309Page8Input!) {
    submitT309Page8(input: $input)
  }
`;

const mutationPage9 = gql<SubmitT309Page9GQL>`
  mutation SubmitT309Page9($input: SubmitT309Page9Input!) {
    submitT309Page9(input: $input)
  }
`;

const mutationPage10 = gql<SubmitT309Page10GQL>`
  mutation SubmitT309Page10($input: SubmitT309Page10Input!) {
    submitT309Page10(input: $input)
  }
`;
