import { gql, useMutation, useQuery } from '@apollo/client';
import { Button, Loading } from '@everlutionsk/ui';
import { createFormSpec, SelectField } from '@everlutionsk/ui-formik';
import { useNavigate } from '@everlutionsk/ui-router';
import Check from '@mui/icons-material/Check';
import { Box, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { PaperContent } from '../../../components/PaperContent';
import { InputList } from '../Identification/InputList';
import { InputWrapper } from '../Identification/InputWrapper';

interface Props {
  readonly code: string;
  readonly isDemo: boolean;
  readonly isReadOnly: boolean;
  readonly label: string;
  readonly token: string;
}

const mutation = gql<SubmitT216IdentificationGQL>`
  mutation SubmitT216Identification($input: UpdateStudentByTokenInput!) {
    updateStudentByToken(input: $input) {
      id
      birthIdentificationNumber
      firstName
      lastName
      fullName
      dateOfBirth
      gender
      ratingSlovakLanguage
      ratingMaths
      ratingArtEducation
      ratingTechnicalEducation
    }
  }
`;

const query = gql<T216StudentOneByTokenGQL>`
  query T216StudentOneByToken($token: String!) {
    studentOneByToken(token: $token) {
      id
      birthIdentificationNumber
      firstName
      lastName
      fullName
      dateOfBirth
      gender
      ratingSlovakLanguage
      ratingMaths
      ratingArtEducation
      ratingTechnicalEducation
    }
  }
`;

interface FormValues {
  ratingSlovakLanguage: number | undefined;
  ratingMaths: number | undefined;
  ratingTechnicalEducation: number | undefined;
  ratingArtEducation: number | undefined;
}

const spec = createFormSpec<FormValues>({
  ratingSlovakLanguage: yup.number().required('Prosím zadajte známku'),
  ratingMaths: yup.number().required('Prosím zadajte známku'),
  ratingTechnicalEducation: yup.number().required('Prosím zadajte známku'),
  ratingArtEducation: yup.number().required('Prosím zadajte známku')
});

export function Identification({ isDemo, code, label, token, isReadOnly }: Props) {
  const navigate = useNavigate();

  const { data } = useQuery(query, { variables: { token } });
  const [mutate] = useMutation(mutation);

  if (data == null) return <Loading />;
  return (
    <PaperContent title={label} testNumber={code}>
      <Typography component="h3" variant="h4">
        Identifikácia
      </Typography>
      <Typography component="p" variant="body2">
        Prosíme, aby ste vyplnili nasledujúce údaje:
      </Typography>
      <Box my={3}>
        <Formik
          {...spec({
            ratingSlovakLanguage: toIdentificationInput({
              isDemo,
              isReadOnly,
              value: data.studentOneByToken.ratingSlovakLanguage
            }),
            ratingMaths: toIdentificationInput({
              isDemo,
              isReadOnly,
              value: data.studentOneByToken.ratingMaths
            }),
            ratingTechnicalEducation: toIdentificationInput({
              isDemo,
              isReadOnly,
              value: data.studentOneByToken.ratingTechnicalEducation
            }),
            ratingArtEducation: toIdentificationInput({
              isDemo,
              isReadOnly,
              value: data.studentOneByToken.ratingArtEducation
            })
          })}
          onSubmit={async ({
            ratingSlovakLanguage,
            ratingMaths,
            ratingTechnicalEducation,
            ratingArtEducation
          }) => {
            await mutate({
              variables: {
                input: {
                  token,
                  ratingSlovakLanguage,
                  ratingMaths,
                  ratingTechnicalEducation,
                  ratingArtEducation
                }
              }
            });
            navigate('../test');
          }}
        >
          <Form>
            <InputList>
              <InputWrapper label={`Slovenský jazyk`}>
                <SelectField
                  variant="standard"
                  options={ratingOptions}
                  name="ratingSlovakLanguage"
                  disabled={Boolean(isDemo) || isReadOnly}
                />
              </InputWrapper>
              <InputWrapper label={`Matematika`}>
                <SelectField
                  variant="standard"
                  options={ratingOptions}
                  name="ratingMaths"
                  disabled={Boolean(isDemo) || isReadOnly}
                />
              </InputWrapper>
              <InputWrapper label={`Pracovná výchova`}>
                <SelectField
                  variant="standard"
                  options={ratingOptions}
                  name="ratingTechnicalEducation"
                  disabled={Boolean(isDemo) || isReadOnly}
                />
              </InputWrapper>
              <InputWrapper label={`Výtvarná výchova`}>
                <SelectField
                  variant="standard"
                  options={ratingOptions}
                  name="ratingArtEducation"
                  disabled={Boolean(isDemo) || isReadOnly}
                />
              </InputWrapper>
            </InputList>
            <Box my={3}>
              <Button type="submit" size="medium" color="primary" startIcon={<Check />}>
                Spustiť test
              </Button>
            </Box>
          </Form>
        </Formik>
      </Box>
    </PaperContent>
  );
}

const ratingOptions = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: 'neviem', value: 0 }
];

function toIdentificationInput({
  isDemo,
  isReadOnly,
  value
}: {
  isDemo: boolean;
  isReadOnly: boolean;
  value: number | null | undefined;
}) {
  if (isDemo) return 1;
  if (value != null) return value;
  if (isReadOnly) return 0;

  return undefined;
}
