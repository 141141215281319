import { gql, useQuery } from '@apollo/client';
import { Loading } from '@everlutionsk/ui';
import { useNavigate, useParams } from '@everlutionsk/ui-router';
import { Box, Container } from '@mui/material';
import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Complete } from '../Complete';
import { Error } from '../Error';
import { Identification } from '../Identification';
import { Diagnostic, t216TableFragment } from './Diagnostic';
import { Identification as T216Identification } from './Identification';
import { Instructions } from './Instructions';
import { Content } from '../components';

export const t216Routing = (token: string) => ({
  identification: token,
  instructions: `${token}/instructions`,
  test: `${token}/test`,
  complete: `${token}/complete`
});

const query = gql<T216DiagnosticQueryGQL>`
  query T216DiagnosticQuery($token: String!) {
    t216Diagnostics(token: $token) {
      isValid
      isDemo
      code
      label
      instructions
      status
      originalToken
      answers {
        id
        radioValue
      }
      ...T216QuestionTable
    }
  }
  ${t216TableFragment}
`;

export function T216() {
  const location = useLocation();
  const navigate = useNavigate();
  const { token } = useParams(['token']);

  const { data, refetch } = useQuery(query, {
    variables: { token }
  });

  useEffect(() => {
    refetch();
  }, [location.pathname]);

  if (data == null) return <Loading />;
  const { t216Diagnostics } = data;
  if (t216Diagnostics == null) return <Error></Error>;

  const { code, status, originalToken, instructions, isDemo, isValid, label, answers } =
    t216Diagnostics;

  if (!isValid) return <Error></Error>;
  if (status === 'finished') return <Complete />;

  const isReadOnly = status === 'readonly' && originalToken != null;

  return (
    <Content>
      <Container maxWidth="xl">
        <Box my={3}>
          <Routes>
            <Route
              path=""
              element={
                <Identification
                  isDemo={isDemo}
                  code={code}
                  label={label}
                  token={token}
                  submitLink={() => navigate('./instructions')}
                  isReadOnly={isReadOnly}
                />
              }
            />
            <Route
              path="instructions"
              element={<Instructions code={code} instructions={instructions} label={label} />}
            />
            <Route
              path="identification"
              element={
                <T216Identification
                  isDemo={isDemo}
                  code={code}
                  label={label}
                  token={token}
                  isReadOnly={isReadOnly}
                />
              }
            />
            <Route
              path="test"
              element={
                <Diagnostic
                  code={code}
                  isDemo={isDemo}
                  table={t216Diagnostics}
                  label={label}
                  token={token}
                  isReadOnly={isReadOnly}
                  initialValues={
                    isDemo
                      ? Object.assign(
                          {},
                          ...Array.from(t216Diagnostics.questions, (question, i) => ({
                            [`answer-${question.id}`]: 1
                          }))
                        )
                      : isReadOnly
                        ? Object.assign(
                            {},
                            ...Array.from(t216Diagnostics.questions, (question, i) => ({
                              [`answer-${question.id}`]: answers?.find(
                                item => item.id === question.id
                              )?.radioValue
                            }))
                          )
                        : {}
                  }
                />
              }
            />
            <Route path="complete" element={<Complete />} />
          </Routes>
        </Box>
      </Container>
    </Content>
  );
}
