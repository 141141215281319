import { gql, useQuery } from '@apollo/client';
import { isNotNil, populated } from '@everlutionsk/helpers';
import { toYupSchema } from '@everlutionsk/helpers-yup';
import { Loading } from '@everlutionsk/ui';
import { Fields, RadioField, TextField } from '@everlutionsk/ui-formik';
import {
  Box,
  Button,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';
import { DiagnosticErrorLabel } from '../../../components/DiagnosticErrorLabel';
import { PaperContent } from '../../../components/PaperContent';
import { StyledMaterialTable } from '../../../components/StyledMaterialTable';
import { scrollToTopOnFormError } from '../../../components/helpers';
import { SubmitOtprInput } from '../../../graphql/types';
import { TableRowWithError, hasError } from '../TableRowWithError';

interface Props {
  readonly token: string;
  readonly label: string;
  readonly code: string;
  readonly isDemo: boolean;
  readonly isReadOnly: boolean;
  readonly onBackClick: () => void;
  readonly onSubmit: (input: SubmitOtprInput) => void;
}

export const otprV1Fragment = gql`
  fragment OtprV1 on OtprV1 {
    instructions1
    questions1 {
      id
      order
      text
      defaultValue
      options {
        value
        label
      }
    }
    instructions2
    questions2 {
      id
      order
      text
      defaultValue
      options {
        value
        label
      }
    }
    instructions3
    questions3 {
      id
      order
      text
      defaultValue
      options {
        value
        label
      }
    }
    interrupted
    interruptionReason
    ended
    endingReason
    communication
    notes
  }
`;

const query = gql<OtprDiagnosticV1QueryGQL>`
  query OtprDiagnosticV1Query($token: String!) {
    otprDiagnostics(token: $token) {
      isValid
      isDemo
      code
      label
      status
      originalToken
      v1 {
        answers1 {
          id
          radioValue
        }
        answers2 {
          id
          radioValue
        }
        answers3 {
          id
          radioValue
        }
        ...OtprV1
      }
    }
  }
  ${otprV1Fragment}
`;

export function DiagnosticV1({
  token,
  code,
  label,
  isDemo,
  onBackClick,
  onSubmit,
  isReadOnly
}: Props) {
  const [wasSubmitted, setWasSubmitted] = useState(false);

  const { data } = useQuery(query, { variables: { token } });

  if (data?.otprDiagnostics?.v1 == null) return <Loading />;

  const fieldsSpecs = {
    ...data.otprDiagnostics.v1.questions1.reduce((spec, question) => {
      spec[`answer-${question.id}`] = yup.number().required('Prosím, vyber odpoveď');

      return spec;
    }, {}),
    ...data.otprDiagnostics.v1.questions2.reduce((spec, question) => {
      spec[`answer-${question.id}`] = yup.number().required('Prosím, vyber odpoveď');

      return spec;
    }, {}),
    ...data.otprDiagnostics.v1.questions3.reduce((spec, question) => {
      spec[`answer-${question.id}`] = yup.number().required('Prosím, vyber odpoveď');

      return spec;
    }, {})
  };

  const validationSchema = toYupSchema({
    ...fieldsSpecs,
    interputed: yup.boolean().required('Prosím, vyber odpoveď'),
    interruptionReason: yup.string(),
    ended: yup.boolean().required('Prosím, vyber odpoveď'),
    endedReason: yup.string(),
    communication: yup.string(),
    notes: yup.string()
  });
  const initialValues = isDemo
    ? Object.assign(
        {},
        ...Array.from(data.otprDiagnostics.v1.questions1, (question, i) => ({
          [`answer-${question.id}`]: question.defaultValue
        })),
        ...Array.from(data.otprDiagnostics.v1.questions2, (question, i) => ({
          [`answer-${question.id}`]: question.defaultValue
        })),
        ...Array.from(data.otprDiagnostics.v1.questions3, (question, i) => ({
          [`answer-${question.id}`]: question.defaultValue
        }))
      )
    : isReadOnly
      ? Object.assign(
          {},
          ...Array.from(data.otprDiagnostics.v1.questions1, (question, i) => ({
            [`answer-${question.id}`]: data.otprDiagnostics?.v1.answers1.find(
              item => item.id === question.id
            )?.radioValue
          })),
          ...Array.from(data.otprDiagnostics.v1.questions2, (question, i) => ({
            [`answer-${question.id}`]: data.otprDiagnostics?.v1.answers2.find(
              item => item.id === question.id
            )?.radioValue
          })),
          ...Array.from(data.otprDiagnostics.v1.questions3, (question, i) => ({
            [`answer-${question.id}`]: data.otprDiagnostics?.v1.answers3.find(
              item => item.id === question.id
            )?.radioValue
          }))
        )
      : {};

  return (
    <PaperContent title={label} testNumber={code}>
      <Box mt={{ xs: 3, md: 3 }} mb={{ xs: 3, md: 3 }} position="relative">
        <Formik
          initialValues={{
            ...initialValues,
            interputed: undefined,
            interruptionReason: '',
            ended: undefined,
            endedReason: '',
            communication: '',
            notes: ''
          }}
          validationSchema={validationSchema}
          onSubmit={values => {
            const keys = Object.keys(values);
            const answers1 = data.otprDiagnostics?.v1.questions1
              .map(question => {
                const radioValueKey = keys.find(item => item === `answer-${question.id}`);

                if (radioValueKey == null) return;

                return {
                  id: question.id,
                  radioValue: values[radioValueKey]
                };
              })
              .filter(isNotNil);

            const answers2 = data.otprDiagnostics?.v1.questions2
              .map(question => {
                const radioValueKey = keys.find(item => item === `answer-${question.id}`);

                if (radioValueKey == null) return;

                return {
                  id: question.id,
                  radioValue: values[radioValueKey]
                };
              })
              .filter(isNotNil);

            const answers3 = data.otprDiagnostics?.v1.questions3
              .map(question => {
                const radioValueKey = keys.find(item => item === `answer-${question.id}`);

                if (radioValueKey == null) return;

                return {
                  id: question.id,
                  radioValue: values[radioValueKey]
                };
              })
              .filter(isNotNil);

            if (answers1 == null || answers2 == null || answers3 == null) {
              return onSubmit({
                token,
                answers1: [],
                answers2: [],
                answers3: [],
                answers4: [],
                answers5: [],
                version: 'a'
              });
            }
            return onSubmit({
              token,
              answers1,
              answers2,
              answers3,
              answers4: [],
              answers5: [],
              version: 'a',
              communication: values.communication,
              ended: values.ended,
              endingReason: values.endingReason,
              interrupted: values.interrupted,
              interruptionReason: values.interruptionReason,
              notes: values.notes
            });
          }}
        >
          {values => (
            <Form>
              {wasSubmitted && populated(values.errors) == null && <DiagnosticErrorLabel />}

              <Box my={3}>
                <Typography variant="h5">Inštrukcie</Typography>
                <Box>
                  <Typography
                    component="p"
                    variant="body2"
                    dangerouslySetInnerHTML={{
                      __html: data.otprDiagnostics?.v1.instructions1 ?? ''
                    }}
                  />
                </Box>
              </Box>
              <TableContainer component={Paper} style={{ marginBottom: '40px' }}>
                <StyledMaterialTable>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography variant="body2">Číslo otázky</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">Otázka</Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="body2">Odpoveď</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.otprDiagnostics?.v1.questions1.map((question, index) => {
                      const mainItem = Math.floor(question.order) === question.order;

                      const cellStyle = mainItem
                        ? { borderBottom: 'unset', borderTop: '1px solid rgba(224, 224, 224, 1)' }
                        : { border: 'unset' };

                      return (
                        <TableRowWithError
                          key={index}
                          data-has-error={
                            wasSubmitted && hasError(values.errors, `answer-${question.id}`)
                          }
                        >
                          <TableCell style={cellStyle} size="small">
                            {mainItem && `${index + 1}.`}
                          </TableCell>

                          <TableCell style={cellStyle} size="small">
                            {question.text}
                          </TableCell>

                          <TableCell style={cellStyle} size="small" align="right">
                            <RadioField
                              row
                              name={`answer-${question.id}`}
                              options={question.options}
                              disabled={isDemo || isReadOnly}
                            />
                          </TableCell>
                        </TableRowWithError>
                      );
                    })}
                  </TableBody>
                </StyledMaterialTable>
              </TableContainer>

              <Box my={3}>
                <Typography variant="h5">Inštrukcie</Typography>
                <Box>
                  <Typography
                    component="p"
                    variant="body2"
                    dangerouslySetInnerHTML={{
                      __html: data.otprDiagnostics?.v1.instructions2 ?? ''
                    }}
                  />
                </Box>
              </Box>

              <TableContainer component={Paper} style={{ marginBottom: '40px' }}>
                <StyledMaterialTable>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography variant="body2">Číslo otázky</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">Otázka</Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="body2">Odpoveď</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.otprDiagnostics?.v1.questions2.map((question, index) => {
                      return (
                        <TableRowWithError
                          key={index}
                          data-has-error={
                            wasSubmitted && hasError(values.errors, `answer-${question.id}`)
                          }
                        >
                          <TableCell size="small">{index + 1}</TableCell>

                          <TableCell size="small">{question.text}</TableCell>

                          <TableCell size="small" align="right">
                            <RadioField
                              row
                              name={`answer-${question.id}`}
                              options={question.options}
                              disabled={isDemo || isReadOnly}
                            />
                          </TableCell>
                        </TableRowWithError>
                      );
                    })}
                  </TableBody>
                </StyledMaterialTable>
              </TableContainer>

              <Box my={3}>
                <Typography variant="h5">Inštrukcie</Typography>
                <Box>
                  <Typography
                    component="p"
                    variant="body2"
                    dangerouslySetInnerHTML={{
                      __html: data.otprDiagnostics?.v1.instructions3 ?? ''
                    }}
                  />
                </Box>
              </Box>

              <TableContainer component={Paper} style={{ marginBottom: '40px' }}>
                <StyledMaterialTable>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography variant="body2">Číslo otázky</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">Otázka</Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="body2">Odpoveď</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.otprDiagnostics?.v1.questions3.map((question, index) => {
                      return (
                        <TableRowWithError
                          key={index}
                          data-has-error={
                            wasSubmitted && hasError(values.errors, `answer-${question.id}`)
                          }
                        >
                          <TableCell size="small">{index + 1}</TableCell>

                          <TableCell size="small">{question.text}</TableCell>

                          <TableCell size="small" align="right">
                            <RadioField
                              row
                              name={`answer-${question.id}`}
                              options={question.options}
                              disabled={isDemo || isReadOnly}
                            />
                          </TableCell>
                        </TableRowWithError>
                      );
                    })}
                  </TableBody>
                </StyledMaterialTable>
              </TableContainer>

              <TableContainer component={Paper} style={{ marginBottom: '40px', padding: '20px' }}>
                <Fields>
                  <RadioField
                    label="Prerušenie testovania"
                    name="interputed"
                    options={[
                      {
                        label: ' áno',
                        value: true
                      },
                      {
                        label: 'nie',
                        value: false
                      }
                    ]}
                  />
                  <TextField fullWidth label="Dôvod" name="interruptionReason" />
                  <RadioField
                    label="Ukončenie testovania"
                    name="ended"
                    options={[
                      {
                        label: ' áno',
                        value: true
                      },
                      {
                        label: 'nie',
                        value: false
                      }
                    ]}
                  />
                  <TextField fullWidth label="Dôvod" name="endedReason" />
                  <TextField
                    fullWidth
                    label="Komunikácia dieťaťa"
                    name="communication"
                    multiline
                    minRows={20}
                  />
                  <TextField
                    fullWidth
                    label="Ďalšie postrehy z priebehu testovania"
                    name="notes"
                    multiline
                    minRows={20}
                  />
                </Fields>
              </TableContainer>
              <Box my={3} textAlign="right">
                <Button size="large" color="secondary" onClick={onBackClick}>
                  Krok späť
                </Button>
                <Button
                  onClick={() => scrollToTopOnFormError(values.errors, setWasSubmitted)}
                  variant="contained"
                  type="submit"
                  size="large"
                  color="primary"
                  style={{ marginLeft: '10px' }}
                  disabled={isDemo || isReadOnly}
                >
                  Vyhodnotiť
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </PaperContent>
  );
}
