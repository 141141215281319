import ChevronRight from '@mui/icons-material/ChevronRight';
import PrintIcon from '@mui/icons-material/Print';
import {
  Box,
  Button,
  Table as MaterialTable,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import React from 'react';
import { styled } from '@mui/material';
import { PaperContent } from '../../../components/PaperContent';
import { T202ArticleFragment } from '../../../graphql/types';
import { toDocumentUrl } from './documents';

interface Props {
  readonly label: string;
  readonly code: string;
  readonly labelTop1: string;
  readonly labelTop2: string;
  readonly articles: T202ArticleFragment[];
  readonly onBackClick: () => void;
  readonly onSubmit: (articleId: string) => void;
  readonly isReadOnly: boolean;
  readonly articleId: string | undefined;
}

export function ChooseVersion({
  label,
  code,
  labelTop1,
  labelTop2,
  articles,
  onBackClick,
  onSubmit,
  articleId,
  isReadOnly
}: Props) {
  return (
    <PaperContent title={label} testNumber={code}>
      <Box mb={3} display="flex">
        <Box mr={6} style={{ width: '50%' }}>
          <Typography variant="body1" dangerouslySetInnerHTML={{ __html: labelTop1 }}></Typography>
        </Box>
        <Box textAlign="right" ml={6} style={{ width: '50%' }}>
          <Typography variant="body1" dangerouslySetInnerHTML={{ __html: labelTop2 }}></Typography>
        </Box>
      </Box>

      <TableContainer>
        <MaterialTable>
          <TableBody>
            {articles.map((article, articleIndex) => (
              <TableRow key={`row-${articleIndex}`}>
                <TableCell style={{ width: '5%' }}>{article.order}.</TableCell>
                <TableCell style={{ width: '45%' }}>
                  <Box>
                    <Typography variant="h6">{article.label}</Typography>
                  </Box>
                  {article.description}
                </TableCell>
                <TableCell style={{ width: '10%' }}>
                  <WordsCounter wordsCount={article.wordsCount}></WordsCounter>
                </TableCell>
                <TableCell style={{ width: '20%' }}>
                  <Box mb={1}>
                    <PrintLink
                      href={`${toDocumentUrl(article.documents.printTemplate.key)}`}
                      rel="noopener"
                      target="_blank"
                    >
                      <PrintIcon />
                      {article.documents.printTemplate.label}
                    </PrintLink>
                  </Box>
                  <Box>
                    <PrintLink
                      href={`${toDocumentUrl(article.documents.printControlText.key)}`}
                      rel="noopener"
                      target="_blank"
                    >
                      <PrintIcon />
                      {article.documents.printControlText.label}
                    </PrintLink>
                  </Box>
                </TableCell>
                <TableCell style={{ width: '20%' }}>
                  <StyledButton
                    onClick={() => onSubmit(article.id)}
                    endIcon={<ChevronRight />}
                    disabled={isReadOnly && articleId !== article.id}
                  >
                    Ďalej
                  </StyledButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </MaterialTable>
      </TableContainer>
      <Box my={3}>
        <Button size="large" color="secondary" onClick={onBackClick}>
          Krok späť
        </Button>
      </Box>
    </PaperContent>
  );
}

const WordsCounter = ({ wordsCount }: { wordsCount: number }) => {
  return (
    <div
      style={{
        width: '40px',
        height: '40px',
        padding: '15px',
        textAlign: 'center',
        border: '1px solid #e2e4e6'
      }}
    >
      <div>
        <strong>{wordsCount}</strong>
        <div>slov</div>
      </div>
    </div>
  );
};

const StyledButton = styled(Button)`
  &&,
  &&:hover {
    min-width: 145px;
    background-color: #e2fde9;
    text-decoration-line: none;
    white-space: nowrap;
  }
`;

const PrintLink = styled('a')`
  text-decoration-line: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.75rem;
  padding-top: 0.5rem;
  white-space: nowrap;
  background-color: #e2e4e6;
  padding: 5px 12px;
  height: 20px;
  svg {
    font-size: 1rem;
    padding-right: 0.25rem;
    display: block;
  }
`;
