import { gql, useMutation } from '@apollo/client';
import { isNotNil, populated } from '@everlutionsk/helpers';
import { toYupSchema } from '@everlutionsk/helpers-yup';
import { RadioField } from '@everlutionsk/ui-formik';
import {
  Box,
  Button,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { DiagnosticErrorLabel } from '../../../components/DiagnosticErrorLabel';
import { scrollToTopOnFormError } from '../../../components/helpers';
import { PaperContent } from '../../../components/PaperContent';
import { StyledMaterialTable } from '../../../components/StyledMaterialTable';
import { TestPersist } from '../../../components/TestPersist';
import { T216QuestionTableFragment } from '../../../graphql/types';
import { hasError, TableRowWithError } from '../TableRowWithError';

interface Props {
  readonly token: string;
  readonly table: T216QuestionTableFragment;
  readonly label: string;
  readonly code: string;
  readonly isDemo: boolean;
  readonly isReadOnly: boolean;
  readonly initialValues: Record<string, number>;
}

export const t216TableFragment = gql`
  fragment T216QuestionTable on T216 {
    questions {
      id
      task
      fields {
        answer {
          validation {
            rules
          }
          options {
            value
            label
          }
        }
      }
    }
  }
`;

export function Diagnostic({
  token,
  table,
  code,
  label,
  isDemo,
  isReadOnly,
  initialValues
}: Props) {
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const navigate = useNavigate();
  const [mutate] = useMutation(mutation);

  const fieldsSpecs = table.questions.reduce((spec, question) => {
    spec[`answer-${question.id}`] = yup.number().required('Prosím, vyber odpoveď');

    return spec;
  }, {});

  const validationSchema = toYupSchema(fieldsSpecs ?? {});

  return (
    <PaperContent title={label} testNumber={code}>
      <Box mt={{ xs: 3, md: 3 }} mb={{ xs: 3, md: 3 }} position="relative">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async values => {
            const keys = Object.keys(values);
            const answers = table.questions
              .map(question => {
                const radioValueKey = keys.find(item => item === `answer-${question.id}`);

                if (radioValueKey == null) return;

                return {
                  id: question.id,
                  radioValue: values[radioValueKey]
                };
              })
              .filter(isNotNil);

            await mutate({
              variables: {
                input: {
                  token,
                  answers: answers ?? []
                }
              }
            });
            navigate('../complete');
          }}
        >
          {({ errors }) => (
            <Form>
              {wasSubmitted && populated(errors) && <DiagnosticErrorLabel />}
              <TableContainer component={Paper}>
                <StyledMaterialTable>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography variant="body2">Číslo otázky</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">Otázka</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">Odpoveď</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {table.questions.map((question, index) => {
                      return (
                        <TableRowWithError
                          key={index}
                          data-has-error={wasSubmitted && hasError(errors, `answer-${question.id}`)}
                        >
                          <TableCell size="small">{question.id}.</TableCell>
                          <TableCell size="small">{question.task}</TableCell>
                          <TableCell size="small">
                            <RadioField
                              name={`answer-${question.id}`}
                              row
                              options={question.fields.answer.options}
                              disabled={isReadOnly}
                            />
                          </TableCell>
                        </TableRowWithError>
                      );
                    })}
                  </TableBody>
                </StyledMaterialTable>
              </TableContainer>

              <Box my={3} textAlign="right">
                <Button
                  size="large"
                  color="secondary"
                  onClick={() => navigate('../identification')}
                >
                  Krok späť
                </Button>

                <Button
                  onClick={() => scrollToTopOnFormError(errors, setWasSubmitted)}
                  variant="contained"
                  type="submit"
                  size="large"
                  color="primary"
                  style={{ marginLeft: '10px' }}
                  disabled={isDemo || isReadOnly}
                >
                  Vyhodnotiť
                </Button>
              </Box>
              <TestPersist isDemo={isDemo} keyName={`${token}`} />
            </Form>
          )}
        </Formik>
      </Box>
    </PaperContent>
  );
}

const mutation = gql<SubmitTestT216GQL>`
  mutation SubmitTestT216($input: SubmitT216Input!) {
    submitT216(input: $input)
  }
`;
