import { gql, useMutation, useQuery } from '@apollo/client';
import { Loading } from '@everlutionsk/ui';
import { useParams } from '@everlutionsk/ui-router';
import { Box, Container } from '@mui/material';
import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Complete } from '../Complete';
import { Error } from '../Error';
import { Identification } from '../Identification';
import { ChooseVersion } from './ChooseVersion';
import { Diagnostic, t202ArticleFragment } from './Diagnostic';
import { DiagnosticResults } from './DiagnosticResults';
import { Identification as T202Identification } from './Identification';
import { Instructions } from './Instructions';
import { Content } from '../components';

export const t202Routing = (token: string, articleId: string) => ({
  chooseVersion: token,
  identification: `${token}/identification/${articleId}`,
  instructions: `${token}/instructions/${articleId}`,
  test: `${token}/test/${articleId}`,
  results: `${token}/results/${articleId}`,
  complete: `${token}/complete`
});

const query = gql<T202DiagnosticQueryGQL>`
  query T202DiagnosticQuery($token: String!) {
    t202Diagnostics(token: $token) {
      isValid
      isDemo
      code
      label
      labelTop1
      labelTop2
      instructions
      firstInterval
      secondInterval
      thirdInterval
      status
      originalToken
      answers {
        articleId
        firstMinuteWord
        secondMinuteWord
        thirdMinuteWord
        duration
        mistakes
      }
      articles {
        ...T202ArticleFragment
      }
    }
  }
  ${t202ArticleFragment}
`;

export function T202() {
  const navigate = useNavigate();
  const location = useLocation();

  const { token } = useParams(['token']);

  const { data, refetch } = useQuery(query, {
    variables: { token }
  });

  const [mutatePage1] = useMutation(mutationPage1);
  const [mutatePage2] = useMutation(mutationPage2);

  useEffect(() => {
    refetch();
  }, [location.pathname]);

  if (data == null) return <Loading />;
  const { t202Diagnostics } = data;
  if (t202Diagnostics == null) return <Error></Error>;

  const {
    code,
    isDemo,
    labelTop1,
    labelTop2,
    articles,
    instructions,
    label,
    firstInterval,
    secondInterval,
    thirdInterval,
    status,
    originalToken,
    answers
  } = t202Diagnostics;

  if (status === 'finished') return <Complete />;

  const isReadOnly = status === 'readonly' && originalToken != null;

  return (
    <Content>
      <Container maxWidth="xl">
        <Box my={3}>
          <Routes>
            <Route
              path=""
              element={
                <Identification
                  label={label}
                  isDemo={isDemo}
                  code={code}
                  token={token}
                  submitLink={() => navigate('./instructions')}
                  isReadOnly={isReadOnly}
                />
              }
            />
            <Route
              path="instructions"
              element={
                <ChooseVersion
                  articles={articles}
                  code={code}
                  labelTop1={labelTop1}
                  labelTop2={labelTop2}
                  label={label}
                  isReadOnly={isReadOnly}
                  onBackClick={() => navigate('')}
                  onSubmit={articleId => navigate(`instructions/${articleId}`)}
                  articleId={answers?.articleId ?? undefined}
                />
              }
            />
            <Route
              path="instructions/:articleId"
              element={
                <Instructions
                  code={code}
                  articles={articles}
                  instructions={instructions}
                  onBackClick={() => navigate('instructions')}
                  onSubmit={articleId => navigate(`identification/${articleId}`)}
                />
              }
            />
            <Route
              path="identification/:articleId"
              element={
                <T202Identification
                  isDemo={isDemo}
                  code={code}
                  articles={articles}
                  onBackClick={articleId => navigate(`instructions/${articleId}`)}
                  token={token}
                  isReadOnly={isReadOnly}
                />
              }
            />
            <Route
              path="test/:articleId"
              element={
                <Diagnostic
                  isDemo={isDemo}
                  firstInterval={firstInterval}
                  secondInterval={secondInterval}
                  thirdInterval={thirdInterval}
                  token={token}
                  code={code}
                  articles={articles}
                  onBackClick={articleId => navigate(`identification/${articleId}`)}
                  onSubmit={(values, articleId) => {
                    if (isDemo || isReadOnly) return navigate(`results/${articleId}`);
                    mutatePage1({
                      variables: {
                        input: { ...values, articleId }
                      }
                    })
                      .then(() => navigate(`results/${articleId}`))
                      .catch(error => console.error(error));
                  }}
                  isReadOnly={isReadOnly}
                  answers={answers ?? undefined}
                />
              }
            />
            <Route
              path="results/:articleId"
              element={
                <DiagnosticResults
                  token={token}
                  articles={articles}
                  onSubmit={values => {
                    if (isReadOnly) {
                      return navigate('complete');
                    }
                    mutatePage2({
                      variables: {
                        input: { ...values }
                      }
                    })
                      .then(() => navigate('complete'))
                      .catch(error => console.error(error));
                  }}
                  isReadOnly={isReadOnly}
                />
              }
            />
            <Route path="complete" element={<Complete />} />
          </Routes>
        </Box>
      </Container>
    </Content>
  );
}

const mutationPage1 = gql<SubmitT202Page1GQL>`
  mutation SubmitT202Page1($input: SubmitT202Page1Input!) {
    submitT202Page1(input: $input)
  }
`;

const mutationPage2 = gql<SubmitT202Page2GQL>`
  mutation SubmitT202Page2($input: SubmitT202Page2Input!) {
    submitT202Page2(input: $input)
  }
`;
