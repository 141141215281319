import React from 'react';
import { useMemoizedField } from '../../../../components/forms/useMemoizedField';
import { CubeSelector } from './CubeSelector';

interface Props {
  readonly question: {
    id: string;
    order: number;
    searchedCube: { img: string; letter: string };
    choices: Array<{ id: string; img: string; letter: string }>;
  };
  readonly toImageUrl: (key: string) => string;
  readonly isReadOnly: boolean;
}

export function CubeSelectorField({ question, toImageUrl, isReadOnly }: Props) {
  const [{ value }, , { setValue }] = useMemoizedField<string | undefined>(question.id);

  return (
    <CubeSelector
      initialValue={value}
      order={`${question.order}.`}
      searchedCube={{
        letter: question.searchedCube.letter,
        img: toImageUrl(question.searchedCube.img)
      }}
      options={question.choices.map(choice => ({
        value: choice.id,
        img: toImageUrl(choice.img),
        letter: choice.letter
      }))}
      onChange={setValue}
      disabled={isReadOnly}
    ></CubeSelector>
  );
}
