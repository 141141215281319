import { gql, useQuery } from '@apollo/client';
import { Loading } from '@everlutionsk/ui';
import { Box, Button, TextField } from '@mui/material';
import React, { useState } from 'react';
import { styled } from '@mui/material';
import { PaperContent } from '../../../../components/PaperContent';
import { T239Page8Fragment, T239Page8TableValue, T239TextValue } from '../../../../graphql/types';
import { Error } from '../../Error';
import { TableSelector, TableSelectorRow } from './TableSelector';

const t239Page8TableFragment = gql`
  fragment T239Page8TableFragment on T239Page8Table {
    topLabels {
      label
      colSpan
    }
    bottomLabels {
      label
      colSpan
    }
    rows {
      label
      options {
        optionId
        rowIndex
        columnIndex
        crossed
        rowSpan
      }
    }
  }
`;

export const t239Page8Fragment = gql`
  fragment T239Page8Fragment on T239Page8 {
    id
    label
    questions {
      table1 {
        ...T239Page8TableFragment
      }
      table2 {
        ...T239Page8TableFragment
      }
      conclusion {
        id
        label
      }
      section2 {
        id
        label
      }
    }
  }
  ${t239Page8TableFragment}
`;

const query = gql<T239DiagnosticsResultsGQL>`
  query T239DiagnosticsResults($token: String!) {
    t239DiagnosticsResults(token: $token) {
      resultsTable {
        ...T239Page8TableFragment
      }
      errorStats {
        labels
      }
    }
  }
  ${t239Page8TableFragment}
`;

interface Props {
  readonly token: string;
  readonly label: string;
  readonly code: string;
  readonly page: T239Page8Fragment;
  readonly onSubmit: (values) => void;
  readonly onBackClick: () => void;
  readonly isReadOnly: boolean;
  readonly answers:
    | {
        table1: Array<{ id: string; crossed: boolean }>;
        table2: Array<{ id: string; crossed: boolean }>;
        conclusion: { id: string; value: string };
        answers2: Array<{ id: string; value: string }>;
      }
    | undefined;
}

export function Diagnostic({
  token,
  code,
  label,
  page,
  onSubmit,
  onBackClick,
  isReadOnly,
  answers
}: Props) {
  const [table1Rows, setTable1Rows] = useState(() =>
    isReadOnly
      ? page.questions.table1.rows.map(row => ({
          ...row,
          options: row.options.map(option => ({
            ...option,
            crossed: answers?.table1.find(answer => answer.id === option.optionId)?.crossed ?? false
          }))
        }))
      : page.questions.table1.rows
  );

  const [table2Rows, setTable2Rows] = useState(() =>
    isReadOnly
      ? page.questions.table2.rows.map(row => ({
          ...row,
          options: row.options.map(option => ({
            ...option,
            crossed: answers?.table2.find(answer => answer.id === option.optionId)?.crossed ?? false
          }))
        }))
      : page.questions.table2.rows
  );
  const [answers2, setAnswers2] = useState<string[]>(() =>
    isReadOnly
      ? page.questions.section2.map(
          question => answers?.answers2.find(answer => answer.id === question.id)?.value ?? ''
        )
      : page.questions.section2.map(() => '')
  );
  const [conclusion, setConclusion] = useState<string>(
    isReadOnly ? (answers?.conclusion.value ?? '') : ''
  );

  const { data } = useQuery(query, {
    variables: { token }
  });

  if (data == null) return <Loading />;
  const { t239DiagnosticsResults } = data;
  if (t239DiagnosticsResults == null) return <Error></Error>;

  const { resultsTable } = t239DiagnosticsResults;

  function getTableResults(tableRows: TableSelectorRow[]) {
    return tableRows.reduce((acc: T239Page8TableValue[], currentRow) => {
      for (const option of currentRow.options) {
        acc.push({ id: option.optionId, crossed: option.crossed });
      }
      return acc;
    }, []);
  }

  function getAnswers2Results(
    specsArray: Array<{ id: string }>,
    answers: string[]
  ): T239TextValue[] {
    return specsArray.map((spec, index) => {
      return {
        id: spec.id,
        value: answers[index]
      };
    });
  }

  return (
    <PaperContent title={label} testNumber={code}>
      <div>{page.label}</div>
      <Box mt={{ xs: 3, md: 3 }} mb={{ xs: 3, md: 3 }} position="relative">
        <Box display="flex">
          <Box margin={{ xs: 3, md: 3 }}>
            <TableSelector
              tableRows={resultsTable.rows}
              topLabels={resultsTable.topLabels}
              bottomLabels={resultsTable.bottomLabels}
              disabled
              onChange={() => {}}
            ></TableSelector>
          </Box>
          <Box margin={{ xs: 3, md: 3 }}>
            <TableSelector
              tableRows={table1Rows}
              topLabels={page.questions.table1.topLabels}
              bottomLabels={page.questions.table1.bottomLabels}
              disabled={isReadOnly}
              onChange={setTable1Rows}
            ></TableSelector>
          </Box>
          <Box margin={{ xs: 3, md: 3 }}>
            <TableSelector
              tableRows={table2Rows}
              topLabels={page.questions.table2.topLabels}
              bottomLabels={page.questions.table2.bottomLabels}
              disabled={isReadOnly}
              onChange={setTable2Rows}
            ></TableSelector>
          </Box>
        </Box>

        <Box margin={3} mb={8}>
          <TextAreaWrapper mb={3}>
            <TextField
              id={`conclusion`}
              name={`conclusion`}
              type="text"
              variant="outlined"
              label={page.questions.conclusion.label}
              multiline
              rows={5}
              disabled={isReadOnly}
              onChange={event => {
                const value =
                  event.target.value && typeof event.target.value === 'string'
                    ? event.target.value
                    : '';

                setConclusion(value);
              }}
              style={{ width: '100%', height: '97px' }}
              value={conclusion}
            ></TextField>
          </TextAreaWrapper>
        </Box>

        <Box mx={3} display="flex" flexWrap="wrap" justifyContent="space-between">
          {page.questions.section2.map((question, index) => (
            <TextAreaWrapper mb={3} key={index}>
              <TextField
                id={`${question.id}-text`}
                name={`${question.id}-text`}
                type="text"
                variant="outlined"
                label={question.label}
                multiline
                rows={5}
                disabled={isReadOnly}
                onChange={event => {
                  const value =
                    event.target.value && typeof event.target.value === 'string'
                      ? event.target.value
                      : '';
                  const mutatedAnswers2 = [...answers2];
                  mutatedAnswers2[index] = value;
                  setAnswers2(mutatedAnswers2);
                }}
                value={answers2[index]}
              ></TextField>
            </TextAreaWrapper>
          ))}
        </Box>

        <Box my={3} textAlign="right">
          <Button size="large" color="secondary" onClick={onBackClick}>
            Krok späť
          </Button>
          <Button
            onClick={() =>
              onSubmit({
                token,
                table1: getTableResults(table1Rows),
                table2: getTableResults(table2Rows),
                conclusion: { id: page.questions.conclusion.id, value: conclusion },
                answers2: getAnswers2Results(page.questions.section2, answers2)
              })
            }
            variant="contained"
            type="submit"
            size="large"
            disabled={isReadOnly}
            color="primary"
            style={{ marginLeft: '10px' }}
          >
            Vyhodnotiť
          </Button>
        </Box>
      </Box>
    </PaperContent>
  );
}

export const TextAreaWrapper = styled(Box)`
  min-width: 28rem;
  textarea {
    height: 6rem;
    min-width: 28rem;
  }
`;
