export const t239Routing = (token: string) => ({
  identification: token,
  page1: {
    test: `${token}/pages/1/test`
  },
  page2: {
    test: `${token}/pages/2/test`
  },
  page3: {
    test: `${token}/pages/3/test`
  },
  page4: {
    test: `${token}/pages/4/test`
  },
  page5: {
    test: `${token}/pages/5/test`
  },
  page6: {
    test: `${token}/pages/6/test`
  },
  page7: {
    test: `${token}/pages/7/test`
  },
  page8: {
    test: `${token}/pages/8/test`
  },
  complete: `${token}/complete`
});
