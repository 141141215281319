export const t309Routing = (token: string) => ({
  page1: {
    instructions: `${token}/pages/1/instructions`,
    test: `${token}/pages/1/test`
  },
  page2: {
    instructions: `${token}/pages/2/instructions`,
    test: `${token}/pages/2/test`
  },
  page3: {
    instructions: `${token}/pages/3/instructions`,
    test: `${token}/pages/3/test`
  },
  page4: {
    instructions: `${token}/pages/4/instructions`,
    test: `${token}/pages/4/test`
  },
  page5: {
    instructions: `${token}/pages/5/instructions`,
    test: `${token}/pages/5/test`
  },
  page6: {
    instructions: `${token}/pages/6/instructions`,
    test: `${token}/pages/6/test`
  },
  page7: {
    instructions: `${token}/pages/7/instructions`,
    test: `${token}/pages/7/test`
  },
  page8: {
    instructions: `${token}/pages/8/instructions`,
    test: `${token}/pages/8/test`
  },
  page9: {
    instructions: `${token}/pages/9/instructions`,
    test: `${token}/pages/9/test`
  },
  complete: `${token}/complete`
});
