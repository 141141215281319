import p6101 from './images/test6_1-1.png';
import p6102 from './images/test6_1-2.png';
import p6103 from './images/test6_1-3.png';
import p6104 from './images/test6_1-4.png';
import p6105 from './images/test6_1-5.png';
import p6106 from './images/test6_1-6.png';
import p6107 from './images/test6_1-7.png';
import p6108 from './images/test6_1-8.png';
import p6109 from './images/test6_1-9.png';
import p6110 from './images/test6_1-10.png';
import p6111 from './images/test6_1-11.png';
import p6112 from './images/test6_1-12.png';
import p6113 from './images/test6_1-13.png';
import p6114 from './images/test6_1-14.png';
import p6201 from './images/test6_2-1.png';
import p6202 from './images/test6_2-2.png';
import p6203 from './images/test6_2-3.png';
import p6204 from './images/test6_2-4.png';
import p6205 from './images/test6_2-5.png';
import p6206 from './images/test6_2-6.png';
import p6207 from './images/test6_2-7.png';
import p6208 from './images/test6_2-8.png';
import p6209 from './images/test6_2-9.png';
import p6210 from './images/test6_2-10.png';
import p6211 from './images/test6_2-11.png';
import p6212 from './images/test6_2-12.png';
import p6213 from './images/test6_2-13.png';
import p6214 from './images/test6_2-14.png';
import p6301 from './images/test6_3-1.png';
import p6302 from './images/test6_3-2.png';
import p6303 from './images/test6_3-3.png';
import p6304 from './images/test6_3-4.png';
import p6305 from './images/test6_3-5.png';
import p6306 from './images/test6_3-6.png';
import p6307 from './images/test6_3-7.png';
import p6308 from './images/test6_3-8.png';
import p6309 from './images/test6_3-9.png';
import p6310 from './images/test6_3-10.png';
import p6311 from './images/test6_3-11.png';
import p6312 from './images/test6_3-12.png';
import p6313 from './images/test6_3-13.png';
import p6314 from './images/test6_3-14.png';

const images = {
  p6101,
  p6102,
  p6103,
  p6104,
  p6105,
  p6106,
  p6107,
  p6108,
  p6109,
  p6110,
  p6111,
  p6112,
  p6113,
  p6114,
  p6201,
  p6202,
  p6203,
  p6204,
  p6205,
  p6206,
  p6207,
  p6208,
  p6209,
  p6210,
  p6211,
  p6212,
  p6213,
  p6214,
  p6301,
  p6302,
  p6303,
  p6304,
  p6305,
  p6306,
  p6307,
  p6308,
  p6309,
  p6310,
  p6311,
  p6312,
  p6313,
  p6314
};

export function toImageUrl(key: string): string {
  if (key in images === false) throw new Error(`Image with key: ${key} was not found`);
  return images[key];
}
