import { Box, Card, Divider, styled, Typography } from '@mui/material';
import React, { ReactNode } from 'react';

interface Props {
  readonly title: string;
  readonly testNumber?: string;
  readonly children: ReactNode;
  readonly subTitle?: string;
}

export function PaperContent({ title, children, testNumber, subTitle }: Props) {
  return (
    <Box my={3}>
      <StyledCard>
        <Box p={{ xs: 1, sm: 2, md: 3 }}>
          <Box mb={3} pr={5} position="relative">
            <Typography component="h1" variant="h2">
              {title}
            </Typography>
            {subTitle && (
              <Typography component="h6" variant="h6">
                {`${subTitle[0]}${subTitle.slice(1).toLowerCase()}`}
              </Typography>
            )}
            {testNumber && <TestNumber>{testNumber}</TestNumber>}
          </Box>
          <Divider />
          <Box my={3}>{children}</Box>
        </Box>
      </StyledCard>
    </Box>
  );
}

const TestNumber = styled('div')`
  background: #ccc;
  color: #fff;
  padding: 0.5rem;
  position: absolute;
  right: 1rem;
  top: -1.5rem;
  @media (max-width: 768px) {
    top: -8px;
  }
`;

const StyledCard = styled(Card)`
  border-radius: 0;
`;
