import {
  Table as MaterialTable,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import { clone } from 'ramda';
import React, { useState } from 'react';
import cross40 from './cross40.png';

interface Props {
  readonly tableRows: TableSelectorRow[];
  readonly topLabels?: TableLabel[];
  readonly bottomLabels?: TableLabel[];
  readonly disabled?: boolean;
  readonly onChange: (values) => void;
}

interface Option {
  __typename: 'T239Page8TableOption';
  optionId: string;
  rowIndex: number;
  columnIndex: number;
  crossed: boolean;
  rowSpan: number;
}

interface TableLabel {
  label: string;
  colSpan: number;
}

export interface TableSelectorRow {
  __typename: 'T239Page8TableRow';
  label: string | undefined;
  options: Option[];
}

export function TableSelector({
  tableRows,
  topLabels = [],
  bottomLabels = [],
  disabled = false,
  onChange
}: Props) {
  const [rows, setRows] = useState<TableSelectorRow[]>(tableRows);
  const classes = useStyles;

  function updateCrossed(item: TableSelectorRow, columnIndex: number, crossed: boolean) {
    const optionItem = item.options.find(item => item.columnIndex === columnIndex);
    if (optionItem != null) optionItem.crossed = crossed;
  }

  function updateState(args: { rowIndex: number; columnIndex: number; crossed: boolean }) {
    if (disabled === true) return;

    const { rowIndex, columnIndex, crossed } = args;

    const mutatedRows = clone(rows);
    updateCrossed(mutatedRows[rowIndex], columnIndex, crossed);

    if (crossed === true) {
      mutatedRows.forEach((mutatedRow, mutatedRowIndex) => {
        if (mutatedRowIndex !== rowIndex) {
          updateCrossed(mutatedRow, columnIndex, false);
        }
      });
    }

    setRows(mutatedRows);
    onChange(mutatedRows);
  }

  return (
    <TableContainer style={{ width: 'max-content' }}>
      {topLabels.map((topLabel, index) => (
        <div
          key={`tl-${index}`}
          style={{
            display: 'inline-block',
            width: topLabel.colSpan * 40,
            height: 40,
            padding: 0,
            textAlign: 'center'
          }}
        >
          {topLabel.label}
        </div>
      ))}
      <MaterialTable>
        <TableBody>
          {rows.map((tableRow, rowIndex) => (
            <TableRow key={`row-${rowIndex}`}>
              <TableCell
                sx={classes.styledCell}
                // className={classes.styledCell}
              >
                {tableRow.label}
              </TableCell>
              {tableRow.options.map((option, optionIndex) => {
                return option.rowSpan ? (
                  <th
                    onClick={() =>
                      updateState({
                        rowIndex: option.rowIndex,
                        columnIndex: option.columnIndex,
                        crossed: !option.crossed
                      })
                    }
                    key={`cell-${rowIndex}-${optionIndex}`}
                    rowSpan={option.rowSpan}
                    style={option.crossed ? { ...classes.thCrossed } : { ...classes.thSimple }}
                    // className={option.crossed ? classes.thCrossed : classes.thSimple}
                  ></th>
                ) : (
                  <TableCell
                    onClick={() =>
                      updateState({
                        rowIndex: option.rowIndex,
                        columnIndex: option.columnIndex,
                        crossed: !option.crossed
                      })
                    }
                    key={`cell-${rowIndex}-${optionIndex}`}
                    sx={option.crossed ? classes.crossedCell : classes.simpleCell}
                    // className={option.crossed ? classes.crossedCell : classes.simpleCell}
                  ></TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </MaterialTable>
      {bottomLabels.map((bottomLabel, index) => (
        <div
          key={`tl-${index}`}
          style={{
            display: 'inline-block',
            width: bottomLabel.colSpan * 40,
            height: 40,
            padding: 0,
            textAlign: 'center',
            fontSize: '0.8em'
          }}
        >
          {bottomLabel.label}
        </div>
      ))}
    </TableContainer>
  );
}

const useStyles = {
  thSimple: {
    border: '1px solid #e2e4e6',
    '&:hover': {
      background: `url(${cross40}) no-repeat center center;`
    }
  },
  thCrossed: {
    border: '1px solid #e2e4e6',
    background: `url(${cross40}) no-repeat center center;`
  },
  crossedCell: {
    width: 40,
    padding: 0,
    border: '1px solid #e2e4e6',
    background: `url(${cross40}) no-repeat center center;`
  },
  simpleCell: {
    width: 40,
    padding: 0,
    border: '1px solid #e2e4e6',
    '&:hover': {
      background: `url(${cross40}) no-repeat center center;`
    }
  },
  styledCell: {
    width: 80,
    height: 40,
    padding: 0,
    background: '#f2f4f6',
    fontWeight: 'bold',
    textAlign: 'center'
  }
};
