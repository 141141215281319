import figures_demo_1 from './images/instructions/figures_demo_1.png';
import figures_demo_2 from './images/instructions/figures_demo_2.png';
import figures_demo_3 from './images/instructions/figures_demo_3.png';
import figures_demo_4 from './images/instructions/figures_demo_4.png';
import figures_demo_5 from './images/instructions/figures_demo_5.png';
import ip10A from './images/instructions/instructions-p10-A.png';
import ip10B from './images/instructions/instructions-p10-B.png';
import ip10C from './images/instructions/instructions-p10-C.png';
import ip10D from './images/instructions/instructions-p10-D.png';
import ip10E from './images/instructions/instructions-p10-E.png';
import figures_1 from './images/tasks/figures_1.png';
import figures_2 from './images/tasks/figures_2.png';
import figures_3 from './images/tasks/figures_3.png';
import figures_4 from './images/tasks/figures_4.png';
import figures_5 from './images/tasks/figures_5.png';
import figures_6 from './images/tasks/figures_6.png';
import figures_7 from './images/tasks/figures_7.png';
import figures_8 from './images/tasks/figures_8.png';
import figures_9 from './images/tasks/figures_9.png';
import figures_10 from './images/tasks/figures_10.png';
import figures_11 from './images/tasks/figures_11.png';
import figures_12 from './images/tasks/figures_12.png';
import p10_demo_A from './images/tasks/p10-demo-A.png';
import p10_demo_B from './images/tasks/p10-demo-B.png';
import p10_demo_C from './images/tasks/p10-demo-C.png';
import p10_demo_D from './images/tasks/p10-demo-D.png';
import p10_demo_E from './images/tasks/p10-demo-E.png';
import p10_options_1A from './images/tasks/p10-options1-A.png';
import p10_options_1B from './images/tasks/p10-options1-B.png';
import p10_options_1C from './images/tasks/p10-options1-C.png';
import p10_options_1D from './images/tasks/p10-options1-D.png';
import p10_options_1E from './images/tasks/p10-options1-E.png';
import p10_options_2A from './images/tasks/p10-options2-A.png';
import p10_options_2B from './images/tasks/p10-options2-B.png';
import p10_options_2C from './images/tasks/p10-options2-C.png';
import p10_options_2D from './images/tasks/p10-options2-D.png';
import p10_options_2E from './images/tasks/p10-options2-E.png';

const images = {
  figures_demo_1,
  figures_demo_2,
  figures_demo_3,
  figures_demo_4,
  figures_demo_5,
  ip10A,
  ip10B,
  ip10C,
  ip10D,
  ip10E,
  figures_1,
  figures_2,
  figures_3,
  figures_4,
  figures_5,
  figures_6,
  figures_7,
  figures_8,
  figures_9,
  figures_10,
  figures_11,
  figures_12,
  p10_demo_A,
  p10_demo_B,
  p10_demo_C,
  p10_demo_D,
  p10_demo_E,
  p10_options_1A,
  p10_options_1B,
  p10_options_1C,
  p10_options_1D,
  p10_options_1E,
  p10_options_2A,
  p10_options_2B,
  p10_options_2C,
  p10_options_2D,
  p10_options_2E
};

export function toImageUrl(key: string): string {
  if (key in images === false) throw new Error(`Image with key: ${key} was not found`);
  return images[key];
}
