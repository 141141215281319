export function sum(values: number[]): number {
  return values.reduce((total, item) => (total += item), 0);
}

export function computePercentile(values: number[], maxValue: number): string {
  const sumOfValues = sum(values);

  const percentile = (sumOfValues / (values.length * maxValue)) * 100;
  return `${percentile.toFixed(0)}%`;
}

export function getMaxValue(choices: Array<{ value: number }>): number {
  const maxValueOption = choices
    .sort((a, b) => b.value - a.value)
    .slice(0, 1)
    .pop();

  if (maxValueOption == null) return 0;

  return maxValueOption.value;
}
