import { Box, Typography } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material';
import { CubeSelector } from '../components/CubeSelector';
import { toImageUrl } from './images';

export function Instructions() {
  return (
    <>
      <Box my={3}>
        <Typography component="h5" variant="h5">
          Rozpoznávanie kociek - inštrukcie
        </Typography>

        <Box my={3}>
          <Typography component="p" variant="body2">
            V nasledujúcich úlohách Vám predložíme vždy sedem kociek, ktoré budú zobrazené
            trojrozmerne (priestorové).
          </Typography>
          <br />
          <Typography component="p" variant="body2">
            Každá kocka má šesť rôznych vzorov, z ktorých tri uvidíte. Skúste na základe vzorov,
            ktoré uvidíte, zistiť, či nie je niektorá z kociek A až F tá istá, ako kocka X zobrazená
            naľavo, alebo či nie je správnou odpoveď G = "Žiadna kocka nie je správna". Môžete si
            pri tom predstaviť, že kocka X bola raz, ale i viackrát otočená, resp. obrátená, čím sa
            môže objaviť nový, doteraz ukrytý vzor.
          </Typography>
          <br />
          <Typography component="p" variant="body2">
            Pri každej úlohe je len jedna správna odpoveď z možností A až G.
          </Typography>
          <br />
          <Typography component="p" variant="body2">
            A teraz príklad B11: Riešením je odpoveď A, preto je v odpoveďovom hárku označené
            písmeno A. Všetky ostatné kocky B až F nie sú správne, pretože vykazujú dokázateľné
            chyby. Preskúmajte, prosím, tento príklad!
          </Typography>
        </Box>
        <TableDiv my={3} p={3}>
          <CubeSelector
            disabled
            order="B11."
            initialValue="i-1-1"
            searchedCube={{ letter: 'X', img: toImageUrl('ip701X') }}
            options={[
              { value: 'i-1-1', letter: 'A', img: toImageUrl('ip701A') },
              { value: 'i-1-2', letter: 'B', img: toImageUrl('ip701B') },
              { value: 'i-1-3', letter: 'C', img: toImageUrl('ip701C') },
              { value: 'i-1-4', letter: 'D', img: toImageUrl('ip701D') },
              { value: 'i-1-5', letter: 'E', img: toImageUrl('ip701E') },
              { value: 'i-1-6', letter: 'F', img: toImageUrl('ip701F') },
              { value: 'i-1-7', letter: 'G', img: toImageUrl('ip701G') }
            ]}
            onChange={() => {}}
          ></CubeSelector>
        </TableDiv>
        <Box my={3}>
          <Typography component="p" variant="body2">
            Teraz sa, prosím, sústreďte na príklad B 12. Je to tá istá úloha ako v príklade B 11,
            ale teraz je kocka A zmenená tak, že to nemôže byť tá istá kocka, ako naľavo zobrazená
            kocka X. Riešením je preto písmeno G - "Žiadna kocka nie je správna". Preskúmajte,
            prosím, toto riešenie!
          </Typography>
        </Box>
        <TableDiv my={3} p={3}>
          <CubeSelector
            disabled
            order="B12."
            initialValue="i-2-7"
            searchedCube={{ letter: 'X', img: toImageUrl('ip702X') }}
            options={[
              { value: 'i-2-1', letter: 'A', img: toImageUrl('ip702A') },
              { value: 'i-2-2', letter: 'B', img: toImageUrl('ip702B') },
              { value: 'i-2-3', letter: 'C', img: toImageUrl('ip702C') },
              { value: 'i-2-4', letter: 'D', img: toImageUrl('ip702D') },
              { value: 'i-2-5', letter: 'E', img: toImageUrl('ip702E') },
              { value: 'i-2-6', letter: 'F', img: toImageUrl('ip702F') },
              { value: 'i-2-7', letter: 'G', img: toImageUrl('ip702G') }
            ]}
            onChange={() => {}}
          ></CubeSelector>
        </TableDiv>
        <Box my={3}>
          <Typography component="p" variant="body2">
            Teraz sa, prosím, sústreďte na ťažší príklad B 13. Tu správna odpoveď znie: "kocka D." V
            tomto príklade je kocka X otočená tak, že na správnej kocke D vidno nový vzor.
          </Typography>
          <Typography component="p" variant="body2">
            Keď teraz preskúmate tento príklad, uvedomte si, že konkrétny vzor sa na jednej kocke
            môže vyskytnúť iba raz.
          </Typography>
        </Box>
        <TableDiv my={3} p={3}>
          <CubeSelector
            disabled
            order="B13."
            initialValue="i-3-4"
            searchedCube={{ letter: 'X', img: toImageUrl('ip703X') }}
            options={[
              { value: 'i-3-1', letter: 'A', img: toImageUrl('ip703A') },
              { value: 'i-3-2', letter: 'B', img: toImageUrl('ip703B') },
              { value: 'i-3-3', letter: 'C', img: toImageUrl('ip703C') },
              { value: 'i-3-4', letter: 'D', img: toImageUrl('ip703D') },
              { value: 'i-3-5', letter: 'E', img: toImageUrl('ip703E') },
              { value: 'i-3-6', letter: 'F', img: toImageUrl('ip703F') },
              { value: 'i-3-7', letter: 'G', img: toImageUrl('ip703G') }
            ]}
            onChange={() => {}}
          ></CubeSelector>
        </TableDiv>
        <Box my={3}>
          <Typography component="p" variant="body2">
            Na riešenie nasledujúcich <strong>12 úloh</strong> máte čas <strong>11 minúty</strong>.{' '}
            <strong>Počkajte na vyzvanie stlačiť tlačidlo ĎALEJ.</strong>
          </Typography>
        </Box>
      </Box>
    </>
  );
}

const TableDiv = styled(Box)`
  border: 1px solid #ccc;
  .cell {
    &:first-child {
      padding: 1rem;
    }
    &:last-child {
      border-left: 1px solid #ccc;
      padding: 0 1rem 1rem 1rem;
    }
  }
`;
