import { TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useField } from 'formik';
import React, { Fragment, useEffect, useState } from 'react';
import { CustomSelectField } from '../../../components/forms/CustomSelectField';
import { useNestedFieldError } from '../../../components/forms/error';
import { hasError, TableRowWithError } from '../TableRowWithError';

interface Props {
  readonly name: string;
  readonly label: string;
  readonly choices: Array<{ id: string; label: string; letter: string }>;
  readonly choicesCount: number;
  readonly isReadOnly: boolean;
}

interface SelectOption {
  label: string;
  isDisabled: boolean;
  value: number;
}

interface Choices {
  [key: string]: number;
}

export function T252QuestionField({ name, label, choices, choicesCount, isReadOnly }: Props) {
  const [field, , { setValue }] = useField(name);

  const error = useNestedFieldError(name);

  const [options, setOptions] = useState<SelectOption[]>(
    Array.from({ length: choicesCount }, (v, i) => {
      return {
        label: (i + 1).toString(),
        value: i + 1,
        isDisabled: false
      };
    })
  );
  const [selectedChoices, setChoicesValues] = useState<Choices>(
    Object.assign(
      {},
      ...Array.from(choices, (v, i) => ({ [v.id]: field.value ? field.value[v.id] ?? 0 : 0 }))
    )
  );

  useEffect(() => {
    const currentChoices = Object.values(selectedChoices);
    const newArr = options.map(item => {
      currentChoices.includes(item.value) ? (item.isDisabled = true) : (item.isDisabled = false);
      return item;
    });
    setOptions(newArr);
  }, [selectedChoices]);

  return (
    <>
      <TableHead>
        <TableRow>
          <TableCell colSpan={2}>
            <Typography variant="h6">{label}</Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {choices.map((choice, index) => {
          const initialValue = field.value ? field.value[choice.id] ?? 0 : 0;

          return (
            <Fragment key={index}>
              <TableRowWithError data-has-error={hasError(error, choice.id)}>
                <TableCell size="small">
                  <CustomSelectField
                    options={options}
                    value={initialValue}
                    onValueChanged={newValue => {
                      setChoicesValues({ ...selectedChoices, [choice.id]: newValue });
                      setValue({ ...selectedChoices, [choice.id]: newValue });
                    }}
                    emptyLabel="Vyberte"
                    disabled={isReadOnly}
                  ></CustomSelectField>
                </TableCell>
                <TableCell>{`${choice.letter}) ${choice.label}`}</TableCell>
              </TableRowWithError>
            </Fragment>
          );
        })}
      </TableBody>
    </>
  );
}
