import {
  Box,
  Table as MaterialTable,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import React from 'react';
import { styled } from '@mui/material';

export function Instructions() {
  return (
    <>
      <Box my={3}>
        <Typography component="h5" variant="h5">
          Doplňovanie číselných radov - inštrukcie
        </Typography>
      </Box>
      <Box my={3}>
        <Typography component="p" variant="body2">
          Každá z nasledujúcich úloh pozostáva z radu čísel, ktorý je zostavený podľa určitého
          pravidla. Vašou úlohou je toto pravidlo odhaliť a použiť - nájdite vždy nasledujúce číslo
          a uveďte správnu odpoveď.
        </Typography>
      </Box>
      <Box my={3}>
        <Typography component="p" variant="body2">
          Príklad:
        </Typography>
      </Box>
      <TableDiv my={3}>
        <MaterialTable>
          <TableBody>
            <TableRow key={1}>
              <TableCell>B7.</TableCell>
              <TableCell>4</TableCell>
              <TableCell>8</TableCell>
              <TableCell>12</TableCell>
              <TableCell>16</TableCell>
              <TableCell>20</TableCell>
              <TableCell>24</TableCell>
              <TableCell>28</TableCell>
              <TableCell size="small">
                <TextField variant="standard" name={`${1}`} type="number" disabled></TextField>
              </TableCell>
            </TableRow>
          </TableBody>
        </MaterialTable>
      </TableDiv>
      <Box my={3}>
        <Typography component="p" variant="body2">
          V tomto číselnom rade je každé číslo o 4 väčšie ako predchádzajúce. Na doplnenie číselného
          radu podľa tohto pravidla by namiesto otáznika malo nasledovať číslo <span>32</span>.
        </Typography>
      </Box>
      <Box my={3}>
        <Typography component="p" variant="body2">
          Ďalší príklad:
        </Typography>
      </Box>
      <TableDiv my={3}>
        <MaterialTable>
          <TableBody>
            <TableRow key={1}>
              <TableCell>B8.</TableCell>
              <TableCell>3</TableCell>
              <TableCell>5</TableCell>
              <TableCell>10</TableCell>
              <TableCell>12</TableCell>
              <TableCell>24</TableCell>
              <TableCell>26</TableCell>
              <TableCell>52</TableCell>
              <TableCell size="small">
                <TextField variant="standard" name={`${1}`} type="number" disabled></TextField>
              </TableCell>
            </TableRow>
          </TableBody>
        </MaterialTable>
      </TableDiv>
      <Box mb={3} mt={1}>
        <Typography component="p" variant="body2">
          V tomto príklade sa striedavo pripočítava 2 a potom sa vynásobí dvomi: 3 + 2 = 5, 5.2 =
          10, 10 + 2 = 12, 12.2 = 24, 24 + 2 = 26, 26.2 = 52. Číselný rad by preto podľa tohto
          pravidla mal pokračovať číslom <span>54</span>.
        </Typography>
      </Box>
      <Typography component="p" variant="body2">
        Všetky pravidlá pozostávajú z jednej alebo viacerých matematických operácií, t.j. sčítania,
        odčítania, násobenia a delenia. Ďalšie matematické operácie nie sú potrebné.
      </Typography>
      <Typography component="p" variant="body2">
        Všetky riešenia sa skladajú z celých čísiel a žiadna číslica sa v riešení nevyskytuje
        dvakrát.
      </Typography>
      <Typography component="p" variant="body2">
        Na vedľajšie výpočty použite na to určený hárok papiera.
      </Typography>
      <Box mb={3} mt={1}>
        <Typography component="p" variant="body2">
          Na riešenie nasledujúcich <strong>12 úloh</strong> máte čas <strong>9 minút</strong>.{' '}
          <strong>Počkajte na vyzvanie stlačiť tlačidlo ĎALEJ.</strong>
        </Typography>
      </Box>
    </>
  );
}

const TableDiv = styled(Box)`
  border: 1px solid #ccc;
  .cell {
    &:first-child {
      padding: 1rem;
    }
    &:last-child {
      border-left: 1px solid #ccc;
      padding: 0rem 1rem 1rem 1rem;
    }
  }
`;
