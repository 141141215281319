import { gql, useMutation, useQuery } from '@apollo/client';
import { Loading } from '@everlutionsk/ui';
import { useNavigate } from '@everlutionsk/ui-router';
import { Box, Container } from '@mui/material';
import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Complete } from '../Complete';
import { Error } from '../Error';
import { Identification } from '../Identification';
import { ChooseVersion } from './ChooseVersion';
import { Diagnostic, t184TableFragment } from './Diagnostic';
import { Identification as T184Identification } from './Identification';
import { Instructions } from './Instructions';
import { Content } from '../components';
import { useToken } from '../useToken';

export const t184Routing = (token: string) => ({
  identification: token,
  chooseVersion: `${token}/instructions`,
  v1: {
    instructions: `${token}/v1/instructions`,
    test: `${token}/v1/test`
  },
  v2: {
    instructions: `${token}/v2/instructions`,
    test: `${token}/v2/test`
  },
  complete: `${token}/complete`
});

const t184AnswerFragment = gql`
  fragment T184AnswerFragment on T184Answer {
    additionalText
    answersTable {
      id
      radioValue
    }
  }
`;

const query = gql<T184DiagnosticQueryGQL>`
  query T184DiagnosticQuery($token: String!) {
    t184Diagnostics(token: $token) {
      isValid
      isDemo
      code
      label
      status
      originalToken
      answers {
        v1 {
          ...T184AnswerFragment
        }
        v2 {
          ...T184AnswerFragment
        }
      }
      v1 {
        ...T184TableFragment
      }
      v2 {
        ...T184TableFragment
      }
    }
  }
  ${t184TableFragment}
  ${t184AnswerFragment}
`;

export function T184() {
  const token = useToken();
  const location = useLocation();

  const { data, refetch } = useQuery(query, {
    variables: { token }
  });

  const navigate = useNavigate();

  const [mutateV1] = useMutation(mutationV1);
  const [mutateV2] = useMutation(mutationV2);

  useEffect(() => {
    refetch();
  }, [location.pathname]);

  if (data == null) return <Loading />;
  const { t184Diagnostics } = data;
  if (t184Diagnostics == null) return <Error></Error>;

  const { code, isDemo, status, originalToken, answers, isValid, label, v1, v2 } = t184Diagnostics;

  if (!isValid) return <Error></Error>;
  if (status === 'finished') return <Complete />;

  const isReadOnly = status === 'readonly' && originalToken != null;

  return (
    <Content>
      <Container maxWidth="xl">
        <Box my={3}>
          <Routes>
            <Route
              path=""
              element={
                <Identification
                  isDemo={isDemo}
                  code={code}
                  label={label}
                  token={token}
                  submitLink={() => navigate('./instructions')}
                  isReadOnly={isReadOnly}
                />
              }
            />
            <Route
              path="instructions"
              element={<ChooseVersion code={code} label={label} v1={v1} v2={v2} />}
            />

            <Route
              path="v1/instructions"
              element={
                <Instructions
                  code={code}
                  instructions={v1.instructions}
                  label={v1.label}
                  onBackClick={() => navigate('')}
                  onSubmit={() => navigate('./v1/identification')}
                />
              }
            />
            <Route
              path="v2/instructions"
              element={
                <Instructions
                  code={code}
                  instructions={v2.instructions}
                  label={v2.label}
                  onBackClick={() => navigate('')}
                  onSubmit={() => navigate('./v2/identification')}
                />
              }
            />
            <Route
              path="v1/identification"
              element={
                <T184Identification
                  version={1}
                  isDemo={isDemo}
                  code={code}
                  label={label}
                  subLabel={v1.label}
                  token={token}
                  isReadOnly={isReadOnly}
                />
              }
            />
            <Route
              path="v2/identification"
              element={
                <T184Identification
                  version={2}
                  isDemo={isDemo}
                  code={code}
                  label={label}
                  subLabel={v2.label}
                  token={token}
                  isReadOnly={isReadOnly}
                />
              }
            />

            <Route
              path="v1/test"
              element={
                <Diagnostic
                  code={code}
                  isDemo={isDemo}
                  table1={v1}
                  table2={undefined}
                  version="v1"
                  token={token}
                  onBackClick={() => navigate('./v1/identification')}
                  onSubmit={input => {
                    mutateV1({
                      variables: {
                        input
                      }
                    })
                      .then(() => navigate('./complete'))
                      .catch(error => console.error(error));
                  }}
                  isReadOnly={isReadOnly}
                  initialValues={Object.assign(
                    {},
                    ...Array.from(v1.questions, (question, i) => ({
                      [question.id]:
                        answers?.v1?.answersTable.find(item => item.id === question.id)
                          ?.radioValue ?? question.defaultValue
                    }))
                  )}
                  initialAdditionalText={answers?.v1?.additionalText}
                />
              }
            />
            <Route
              path="v2/test"
              element={
                <Diagnostic
                  code={code}
                  isDemo={isDemo}
                  table1={undefined}
                  table2={v2}
                  version="v2"
                  token={token}
                  onBackClick={() => navigate('./v2/identification')}
                  onSubmit={input => {
                    mutateV2({
                      variables: {
                        input
                      }
                    })
                      .then(() => navigate('./complete'))
                      .catch(error => console.error(error));
                  }}
                  isReadOnly={isReadOnly}
                  initialValues={Object.assign(
                    {},
                    ...Array.from(v2.questions, (question, i) => ({
                      [question.id]:
                        answers?.v2?.answersTable.find(item => item.id === question.id)
                          ?.radioValue ?? question.defaultValue
                    }))
                  )}
                  initialAdditionalText={answers?.v2?.additionalText}
                />
              }
            />
            <Route path="complete" element={<Complete />} />
          </Routes>
        </Box>
      </Container>
    </Content>
  );
}

const mutationV1 = gql<SubmitTestT184V1GQL>`
  mutation SubmitTestT184V1($input: SubmitT184V1Input!) {
    submitT184V1(input: $input)
  }
`;

const mutationV2 = gql<SubmitTestT184V2GQL>`
  mutation SubmitTestT184V2($input: SubmitT184V2Input!) {
    submitT184V2(input: $input)
  }
`;
