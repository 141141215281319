import { Box, Card, Divider, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { styled } from '@mui/material';
import { Countdown } from './Countdown';

interface Props {
  readonly title: string;
  readonly remainingSeconds: number;
  readonly testNumber?: string;
  readonly children: ReactNode;
}

export function PaperContent({ title, remainingSeconds, children, testNumber }: Props) {
  return (
    <Box my={3}>
      <StyledCard>
        <Box p={{ xs: 1, sm: 2, md: 3 }}>
          <Box mb={3} pr={5} position="relative">
            <Typography component="h1" variant="h2">
              {title}
            </Typography>
            {testNumber && <TestNumber>{testNumber}</TestNumber>}
            {remainingSeconds && <Countdown remainingSeconds={remainingSeconds} />}
          </Box>
          <Divider />
          <Box my={3}>{children}</Box>
        </Box>
      </StyledCard>
    </Box>
  );
}

const TestNumber = styled('div')`
  background: #ccc;
  color: #fff;
  padding: 0.5rem;
  position: absolute;
  right: 1rem;
  top: -1.5rem;
  @media (max-width: 768px) {
    top: -8px;
  }
`;

const StyledCard = styled(Card)`
  border-radius: 0;
`;
