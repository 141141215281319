import { ApolloClient, ApolloProvider } from '@apollo/client';
import { UiProvider, withScrollRestoration } from '@everlutionsk/ui';
import { Route, Router, Routes } from '@everlutionsk/ui-router';
import React from 'react';
import { routingPatterns } from '../Routes';
import { useTheme } from '../theme';
import { OTPR } from './Diagnostic/OTPR';
import { SMKZD } from './Diagnostic/SMKZD';
import { T184 } from './Diagnostic/T184';
import { T202 } from './Diagnostic/T202';
import { T21 } from './Diagnostic/T21';
import { T216 } from './Diagnostic/T216';
import { T239 } from './Diagnostic/T239';
import { T252 } from './Diagnostic/T252';
import { T309 } from './Diagnostic/T309';
import { T41 } from './Diagnostic/T41';
import { T8 } from './Diagnostic/T8';
import { T95 } from './Diagnostic/T95';
import { Home } from './Home';
import { styled } from '@mui/material';
import { environment } from '../environment';

interface Props {
  readonly apollo: ApolloClient<any>;
}

export function App({ apollo }: Props) {
  const theme = useTheme({ isProd: environment.isProd });

  return (
    <ApolloProvider client={apollo}>
      <UiProvider theme={theme} flashMessages historyState>
        <Page>
          <Main>
            <ContentWrapper>
              <Content>
                <Router>
                  <Routes>
                    <Route path={routingPatterns.home} element={<Home />} />
                    <Route path={routingPatterns.t8} element={<T8 />} />
                    <Route path={routingPatterns.t21} element={<T21 />} />
                    <Route path={routingPatterns.t252} element={<T252 />} />
                    <Route path={routingPatterns.t309} element={<T309 />} />
                    <Route path={routingPatterns.t239} element={<T239 />} />
                    <Route path={routingPatterns.t216} element={<T216 />} />
                    <Route path={routingPatterns.t184} element={<T184 />} />
                    <Route path={routingPatterns.t202} element={<T202 />} />
                    <Route path={routingPatterns.t41} element={<T41 />} />
                    <Route path={routingPatterns.smkzd} element={<SMKZD />} />
                    <Route path={routingPatterns.otpr} element={<OTPR />} />
                    <Route path={routingPatterns.otpr} element={<OTPR />} />
                    <Route path="t95/:token/*" element={<T95 />} />
                  </Routes>
                </Router>
              </Content>
            </ContentWrapper>
          </Main>
        </Page>
      </UiProvider>
    </ApolloProvider>
  );
}

const Page = styled('div')`
  overflow: hidden;
  display: flex;
  height: 100vh;
`;

const Main = styled('div')`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.background.default};
`;

const ContentWrapper = withScrollRestoration(styled('div')`
  overflow-y: auto;
  position: relative;
  flex: 1 1 auto;
`);

const Content = styled('div')`
  max-width: 1140px;
  padding: 24px;
  margin-left: auto;
  margin-right: auto;
`;
