import ChevronRight from '@mui/icons-material/ChevronRight';
import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PaperContent } from '../../../components/PaperContent';

interface Props {
  readonly instructions: string;
  readonly label: string;
  readonly code: string;
}

export function Instructions({ code, instructions, label }: Props) {
  const navigate = useNavigate();

  return (
    <>
      <PaperContent title={label} testNumber={code}>
        <Box mt={{ xs: 3, md: 9 }} mb={{ xs: 3, md: 6 }} position="relative">
          <Typography component="h3" variant="h4">
            Inštrukcie
          </Typography>
          <Box my={3}>
            <Typography
              component="p"
              variant="body2"
              dangerouslySetInnerHTML={{ __html: instructions }}
            ></Typography>
          </Box>
          <Box mt={4}>
            <Box my={3}>
              <Button
                variant="contained"
                color="primary"
                endIcon={<ChevronRight />}
                onClick={() => navigate('../identification')}
              >
                Ďalej
              </Button>
            </Box>
            <Box my={3}>
              <Button variant="outlined" onClick={() => navigate(`../`)} color="primary">
                Krok späť
              </Button>
            </Box>
          </Box>
        </Box>
      </PaperContent>
    </>
  );
}
