import { gql } from '@apollo/client';
import { toYupSchema } from '@everlutionsk/helpers-yup';
import {
  Box,
  Button,
  Table as MaterialTable,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import { TestPersist } from '../../../../components/TestPersist';
import { SubmitT309Page6Input, T309Page6Fragment } from '../../../../graphql/types';
import { T309ChoiceSelectorField } from '../components/ChoiceSelectorField';
import { CountdownChangeListener } from '../components/CountdownChangeListener';
import { PaperContent } from '../components/PaperContent';
import { useCountdown } from '../useCountdown';

interface Props {
  readonly token: string;
  readonly label: string;
  readonly code: string;
  readonly page: T309Page6Fragment;
  readonly onSubmit: (values: SubmitT309Page6Input) => void;
  readonly isDemo: boolean;
  readonly isReadOnly: boolean;
  readonly initialValues: Record<string, string>;
}

interface FormValues {
  readonly [name: string]: string;
}

export const t309Page6Fragment = gql`
  fragment T309Page6Fragment on T309Page6 {
    id
    label
    questions {
      id
      order
      label
      choices {
        id
        label
      }
    }
    timeLimitInSeconds
  }
`;

export function Diagnostic({
  token,
  code,
  label,
  page,
  onSubmit,
  isDemo,
  isReadOnly,
  initialValues
}: Props) {
  const remainingSeconds = useCountdown(page.timeLimitInSeconds);
  const validationSchema = toYupSchema({});

  function getResults(formValues: FormValues): SubmitT309Page6Input {
    return {
      token,
      answers: Object.keys(formValues).map(key => {
        return {
          id: key,
          answer: formValues[key]
        };
      })
    };
  }

  return (
    <PaperContent title={label} testNumber={code} remainingSeconds={remainingSeconds}>
      <div>{page.label}</div>
      <Box my={3} position="relative">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(formikValues: FormValues) => onSubmit(getResults(formikValues))}
        >
          <Form>
            <TableContainer component={Paper}>
              <MaterialTable sx={{ minWidth: 650 }}>
                <TableBody>
                  {page.questions.map(question => (
                    <TableRow key={question.id}>
                      <TableCell>{question.order}.</TableCell>
                      <TableCell>
                        <T309ChoiceSelectorField question={question} isReadOnly={isReadOnly} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </MaterialTable>
            </TableContainer>
            <Box my={3} textAlign="right">
              <Button
                variant="contained"
                type="submit"
                size="large"
                color="primary"
                style={{ marginLeft: '10px' }}
              >
                Ďalej
              </Button>
            </Box>
            <TestPersist isDemo={isDemo} keyName={`${token}${page.id}`} />
            <CountdownChangeListener remainingSeconds={remainingSeconds} isReadOnly={isReadOnly} />
          </Form>
        </Formik>
      </Box>
    </PaperContent>
  );
}
