import { Box, Container, FormHelperText } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material';
import { PaperContent } from '../../components/PaperContent';
import { Content } from './components';

export function Error() {
  return (
    <Content>
      <Container maxWidth="xl">
        <Box my={3}>
          <PaperContent title="Chyba" testNumber="404">
            <ErrorText>Nastala chyba!</ErrorText>
          </PaperContent>
        </Box>
      </Container>
    </Content>
  );
}

const ErrorText = styled(FormHelperText)`
  text-align: inherit;
  color: #f44336;
  margin: 0 14px;
`;
