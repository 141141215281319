import { Persist } from 'formik-persist';
import React from 'react';

interface Props {
  readonly isDemo: boolean;
  readonly keyName: string;
}

export function TestPersist({ isDemo, keyName }: Props) {
  return isDemo === true ? null : <Persist name={`${keyName}`} />;
}
