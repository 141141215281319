import { createApolloClient } from '@everlutionsk/helpers-apollo-react';
import React from 'react';
import { environment } from './src/environment';
import { App } from './src/pages/App';
import { createRoot } from 'react-dom/client';
import { applyYupLocalePreset } from '@everlutionsk/ui-formik';
import { possibleTypes } from './src/graphql/possibleUnionTypes';

const root = createRoot(document.getElementById('root')!);

const apollo = createApolloClient({
  url: environment.apiUrl,
  cacheConfig: { possibleTypes },
  batchInterval: 10
});

applyYupLocalePreset('en');

// if (!environment.stage === 'prod') {
// document.head.querySelector('#favicon')?.setAttribute('href', '/favicon-dev.png');
// }

root.render(<App apollo={apollo} />);
