import { gql, useMutation, useQuery } from '@apollo/client';
import { Loading } from '@everlutionsk/ui';
import { useNavigate, useParams } from '@everlutionsk/ui-router';
import { Box, Container } from '@mui/material';
import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Complete } from '../Complete';
import { Error } from '../Error';
import { OtprIdentification } from './Identification';
import { Instructions } from './Instructions';
import { DiagnosticV1 } from './v1';
import { DiagnosticV2 } from './v2';
import { DiagnosticV3 } from './v3';
import { Version } from './version';
import { Content } from '../components';

export function OTPR() {
  const location = useLocation();
  const { token } = useParams(['token']);

  const { data, refetch } = useQuery(query, {
    variables: { token }
  });

  const navigate = useNavigate();

  const [mutate] = useMutation(mutation);

  useEffect(() => {
    refetch();
  }, [location.pathname]);

  if (data == null) return <Loading />;

  const { otprDiagnostics } = data;
  if (otprDiagnostics == null) return <Error></Error>;

  const { code, status, originalToken, isDemo, isValid, label } = otprDiagnostics;

  if (!isValid) return <Error></Error>;
  if (status === 'finished') return <Complete />;

  const isReadOnly = status === 'readonly' && originalToken != null;

  return (
    <Content>
      <Container maxWidth="xl">
        <Box my={3}>
          <Routes>
            <Route
              path=""
              element={
                <OtprIdentification
                  token={token}
                  isDemo={isDemo}
                  code={code}
                  label={label}
                  isReadOnly={isReadOnly}
                />
              }
            />{' '}
            <Route
              path="selectVersion"
              element={
                <Version
                  code={code}
                  label={label}
                  onBackClick={() => navigate('')}
                  onSubmit={() => navigate('./test')}
                />
              }
            />
            <Route
              path="instructions"
              element={
                <Instructions
                  code={code}
                  instructions={''}
                  label={label}
                  onBackClick={() => navigate('')}
                  onSubmit={() => navigate('./test')}
                />
              }
            />
            <Route
              path="test/v1"
              element={
                <DiagnosticV1
                  code={code}
                  isDemo={isDemo}
                  label={label}
                  token={token}
                  isReadOnly={isReadOnly}
                  onBackClick={() => navigate('./selectVersion')}
                  onSubmit={input => {
                    mutate({ variables: { input } })
                      .then(() => navigate('./complete'))
                      .catch(error => console.error(error));
                  }}
                />
              }
            />
            <Route
              path="test/v2"
              element={
                <DiagnosticV2
                  code={code}
                  isDemo={isDemo}
                  label={label}
                  token={token}
                  isReadOnly={isReadOnly}
                  onBackClick={() => navigate('./selectVersion')}
                  onSubmit={input => {
                    mutate({ variables: { input } })
                      .then(() => navigate('./complete'))
                      .catch(error => console.error(error));
                  }}
                />
              }
            />
            <Route
              path="test/v3"
              element={
                <DiagnosticV3
                  code={code}
                  isDemo={isDemo}
                  label={label}
                  token={token}
                  isReadOnly={isReadOnly}
                  onBackClick={() => navigate('./selectVersion')}
                  onSubmit={input => {
                    mutate({ variables: { input } })
                      .then(() => navigate('./complete'))
                      .catch(error => console.error(error));
                  }}
                />
              }
            />
            <Route path="complete" element={<Complete />} />
          </Routes>
        </Box>
      </Container>
    </Content>
  );
}

const query = gql<OtprDiagnosticQueryGQL>`
  query OtprDiagnosticQuery($token: String!) {
    otprDiagnostics(token: $token) {
      isValid
      isDemo
      code
      label
      status
      originalToken
    }
  }
`;

const mutation = gql<SubmitTestOtprGQL>`
  mutation SubmitTestOtpr($input: SubmitOtprInput!) {
    submitOtpr(input: $input)
  }
`;
