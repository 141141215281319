import { gql, useQuery } from '@apollo/client';
import { Loading } from '@everlutionsk/ui';
import { Box, Container } from '@mui/material';
import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { T41DiagnosticVersion } from '../../../graphql/types';
import { Complete } from '../Complete';
import { Error } from '../Error';
import { ChooseVersion, t41ChooseVersionPageFragment } from './ChooseVersion';
import { Diagnostic, t41SectionFragment } from './Diagnostic';
import { FinishedResults } from './FinishedResults';
import { Identification } from './Identification';
import { InsertResults } from './InsertResults';
import { Content } from '../components';
import { useToken } from '../useToken';

export const t41Routing = (token: string, version: T41DiagnosticVersion) => ({
  chooseVersion: token,
  identification: `${token}/identification/${version}`,
  insertResults: `${token}/insert-results/${version}`,
  test: `${token}/test/${version}`,
  complete: `${token}/complete`
});

const query = gql<T41DiagnosticQueryGQL>`
  query T41DiagnosticQuery($token: String!) {
    t41Diagnostics(token: $token) {
      isValid
      isDemo
      code
      label
      status
      originalToken
      testingDate
      isForAdult
      answers {
        duration
        version
      }
      chooseVersionPage {
        ...T41ChooseVersionPageFragment
      }
      instructions {
        ...T4ISectionFragment
      }
    }
  }
  ${t41ChooseVersionPageFragment}
  ${t41SectionFragment}
`;

export function T41() {
  const navigate = useNavigate();
  const location = useLocation();

  const token = useToken();

  const { data, refetch } = useQuery(query, {
    variables: { token }
  });

  useEffect(() => {
    refetch();
  }, [location.pathname]);

  if (data == null) return <Loading />;
  const { t41Diagnostics } = data;
  if (t41Diagnostics == null) return <Error></Error>;

  const {
    code,
    isDemo,
    status,
    testingDate,
    originalToken,
    answers,
    chooseVersionPage,
    instructions,
    label,
    isForAdult
  } = t41Diagnostics;

  if (status === 'finished') return <Complete />;

  const isReadOnly = status === 'readonly' && originalToken != null;

  if (isReadOnly) {
    return (
      <FinishedResults
        token={token}
        isDemo={isDemo}
        isReadOnly={isReadOnly}
        answers={answers ?? undefined}
        code={code}
        label={label}
        testingDate={testingDate ?? undefined}
      />
    );
  }

  return (
    <Content>
      <Container maxWidth="xl">
        <Box my={3}>
          <Routes>
            <Route
              path=""
              element={
                <ChooseVersion
                  page={chooseVersionPage}
                  code={code}
                  label={label}
                  isForAdult={isForAdult}
                />
              }
            />
            <Route
              path="test/:version"
              element={
                <Diagnostic
                  code={code}
                  isDemo={isDemo}
                  instructions={instructions}
                  label={label}
                  token={token}
                />
              }
            />
            <Route
              path="identification/:version"
              element={
                <Identification
                  token={token}
                  isDemo={isDemo}
                  isReadOnly={isReadOnly}
                  code={code}
                  label={label}
                />
              }
            />
            <Route
              path="insert-results/:version"
              element={
                <InsertResults
                  token={token}
                  isDemo={isDemo}
                  isReadOnly={isReadOnly}
                  answers={answers ?? undefined}
                  code={code}
                  label={label}
                  onBackClick={() => navigate('')}
                />
              }
            />
            <Route path="complete" element={<Complete />} />
          </Routes>
        </Box>
      </Container>
    </Content>
  );
}
