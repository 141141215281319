import React from 'react';
import { styled } from '@mui/material';

interface Props {
  readonly remainingSeconds: number;
}

export function Countdown({ remainingSeconds }: Props) {
  const minutes = Math.floor(remainingSeconds / 60);
  const seconds = remainingSeconds - minutes * 60;
  return (
    <StyledCountdown>
      <span>
        {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
      </span>
    </StyledCountdown>
  );
}

const StyledCountdown = styled('div')`
  position: absolute;
  right: 1rem;
  top: 1rem;
  text-align: center;
  padding: 0.25rem 0.5rem;
  background: #c2deba;
  @media (max-width: 768px) {
    top: 5rem;
  }
`;
