import { useNavigate, useParams } from '@everlutionsk/ui-router';
import { useEffect } from 'react';

export function useToken() {
  const navigate = useNavigate();
  const { token } = useParams(['token']);
  const route = 'testing';

  useEffect(() => {
    if (token !== route) navigate(`../${route}`, { replace: true });
  }, []);

  if (token === route) {
    const storageToken = sessionStorage.getItem('token');
    if (storageToken === null) return route;
    return storageToken;
  }

  sessionStorage.setItem('token', token);
  return token;
}
