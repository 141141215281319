import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import { styled } from '@mui/material';

interface Props {
  readonly initialState?: Selected;
  readonly disabled?: boolean;
  readonly options: Array<{ label: string; value: string }>;
  readonly onChange: (value: Selected) => void;
}

interface Selected {
  a: string | undefined;
  b: string | undefined;
}

export function APlusBSelector({
  options,
  onChange,
  initialState = { a: undefined, b: undefined },
  disabled = false
}: Props) {
  const [selected, setSelected] = useState<Selected>(initialState);

  function isSelected(value: string) {
    if (selected.a === value) return true;
    if (selected.b === value) return true;

    return false;
  }

  function getLabel(value: string | undefined): string {
    if (value === undefined) return '';

    const option = options.find(option => option.value === value);

    if (option == null) return '';

    return option.label;
  }

  function getStateValue(value: string): Selected {
    const { a: first, b: second } = selected;

    if (first !== undefined && second === undefined) {
      return { a: first, b: value };
    }

    return { a: value, b: undefined };
  }

  function updateState(fieldValue: string): void {
    if (disabled) return;

    const state = getStateValue(fieldValue);
    setSelected(state);
    onChange(state);
  }

  return (
    <>
      <Wrapper>
        <strong>{getLabel(selected.a)}</strong> + <strong>{getLabel(selected.b)}</strong>
      </Wrapper>
      <Box display="flex">
        {options.map(({ label, value }, index) => (
          <StyledButton
            key={value}
            disabled={isSelected(value)}
            onClick={() => updateState(value)}
            variant="text"
            disableRipple
          >
            {`(${String.fromCharCode(65 + index)}) ${label}`}
          </StyledButton>
        ))}
      </Box>
    </>
  );
}

const Wrapper = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem 0;
  strong {
    margin-right: 1rem;
    min-height: 23px;
    display: block;
    font-weight: normal;
    color: #4f64f7;
    border-bottom: 1px solid #4f64f7;
    min-width: 125px;
    &:nth-child(2) {
      margin-left: 1rem;
    }
  }
`;

const StyledButton = styled(Button)`
  &.MuiButton-text.Mui-disabled {
    background: transparent;
    color: #4f64f7;
  }

  :hover {
    background: transparent;
  }
`;
