import { T41DiagnosticVersion } from '../../../graphql/types';

export function toVersionLabel(version: string): string {
  switch (version) {
    case T41DiagnosticVersion.childrenA:
      return 'Časť A, Pre deti';
    case T41DiagnosticVersion.adultsA:
      return 'Časť A, Pre dospelých';
    case T41DiagnosticVersion.childrenB:
      return 'Časť B, Pre deti';
    case T41DiagnosticVersion.adultsB:
      return 'Časť B, Pre dospelých';
    default:
      return '';
  }
}
