import { gql } from '@apollo/client';
import ChevronRight from '@mui/icons-material/ChevronRight';
import {
  Box,
  Button,
  Table as MaterialTable,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import React from 'react';
import { PaperContent } from '../../../components/PaperContent';
import { T184InstructionsFragment } from '../../../graphql/types';

interface Props {
  readonly instructions: T184InstructionsFragment;
  readonly label: string;
  readonly code: string;
  readonly onBackClick: () => void;
  readonly onSubmit: () => void;
}

export const t184InstructionsFragment = gql`
  fragment T184Instructions on T184Instructions {
    subtitle
    tableItems {
      order
      label1
      label2
    }
    label1
    label2
    label3
  }
`;

export function Instructions({ code, instructions, label, onBackClick, onSubmit }: Props) {
  return (
    <>
      <PaperContent title={label} testNumber={code}>
        <Box mt={{ xs: 3, md: 9 }} mb={{ xs: 3, md: 6 }} position="relative">
          <Box>
            <Typography component="h3" variant="h4">
              Inštrukcie
            </Typography>
          </Box>
          <Box my={3}>
            <Typography component="h4" variant="h5">
              {instructions.subtitle}
            </Typography>
          </Box>
          <MaterialTable>
            <TableBody>
              {instructions.tableItems.map((item, index) => (
                <TableRow key={`i-${index}`}>
                  <TableCell>{item.order}</TableCell>
                  <TableCell>{item.label1}</TableCell>
                  <TableCell>{item.label2}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </MaterialTable>
          <Box my={3}>
            <Typography component="span" variant="body2">
              {instructions.label1}
            </Typography>
          </Box>
          <Box my={3}>
            <Typography component="span" variant="body2">
              {instructions.label2}
            </Typography>
          </Box>
          <Box my={3}>
            <Typography component="span" variant="body2">
              {instructions.label3}
            </Typography>
          </Box>

          <Box mt={4}>
            <Box my={3}>
              <Button
                variant="contained"
                color="primary"
                endIcon={<ChevronRight />}
                onClick={onSubmit}
              >
                Ďalej
              </Button>
            </Box>
            <Box my={3}>
              <Button variant="outlined" onClick={onBackClick} color="primary">
                Krok späť
              </Button>
            </Box>
          </Box>
        </Box>
      </PaperContent>
    </>
  );
}
