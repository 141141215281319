// import { useConditionalField, useFieldError } from '@everlutionsk/ui-formik';
// import ClearIcon from '@mui/icons-material/Clear';
// // import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
// // import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// // import DatePicker from '@mui/lab/DatePicker';
// import { Box, IconButton, styled } from '@mui/material';
// // import 'date-fns';
// import { useField, useFormikContext } from 'formik';
// import React, { useState } from 'react';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// // import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// // import { sk } from 'date-fns/locale';
// import { Dayjs } from 'dayjs';
// import { DateField } from '@mui/x-date-pickers/DateField';
// import { InputDateField } from './InputDateField';

// TODO: cleanup, old component was not functioning so new component was copied in

import { useMemoizedField } from '@everlutionsk/ui-formik';
import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { InputProps } from '@mui/material';
import { InputDate } from './InputDate';
import { DateView } from '@mui/x-date-pickers/models';

type InputFieldProps = Omit<InputProps, 'name' | 'type'> & {
  /**
   * Name attribute of the `input` element.
   */
  readonly name: string;
  readonly type?: 'email' | 'password' | 'phone' | 'date' | 'text' | 'number' | 'switch';
  /**
   * Min date of the input
   */
  readonly maxDate?: Date | Dayjs;
  /**
   * Min date of the input
   */
  readonly minDate?: Date | Dayjs;
  /**
   * Control date picker open state
   */
  open?: boolean;
  onOpen?: () => void;
  onClose?: () => void;

  readonly format?: string;
  readonly views?: readonly DateView[];
};

export function DatePickerField({ name, type = 'text', format, views, ...props }: InputFieldProps) {
  const [field, { error }, { setValue }] = useMemoizedField(name);

  return (
    <InputDate
      {...props}
      format={format}
      views={views}
      value={field.value != null ? dayjs(field.value as any) : null}
      onChange={event => {
        const newValue = event;

        setValue(newValue);
      }}
      error={error}
      minDate={props.minDate}
      maxDate={props.maxDate}
    />
  );
}

// interface Props {
//   label?: string;
//   name: string;
//   helperText?: string;
//   inputVariant?: any;
//   disableFuture?: boolean;
//   disabled?: boolean;
//   minDate?: Date;
//   maxDate?: Date;
//   onSelect?: (value) => void;
//   format?: string;
//   views?: Array<'year' | 'date' | 'month'>;
//   openTo?: 'year' | 'date' | 'month';
//   size?: 'small' | 'medium';
//   disableToolbar?: boolean;
// }

// export function DatePickerField({
//   name,
//   label,
//   helperText,
//   inputVariant = 'standard',
//   disableFuture,
//   disabled = false,
//   minDate,
//   maxDate,
//   onSelect,
//   views = ['year', 'month', 'date'],
//   openTo = 'year',
//   format = 'dd/MM/yyyy',
//   size,
//   disableToolbar = false
// }: Props) {
//   useConditionalField(name, 'DatePickerField');

//   const [field] = useField(name);
//   const [selectedDate, setSelectedDate] = useState<Dayjs | string | null>(field.value);
//   const form = useFormikContext();
//   const error = useFieldError(name);

//   const handleDateChange = (date: Dayjs | null) => {
//     setSelectedDate(date);
//     form.setFieldValue(field.name, date);
//     onSelect && onSelect(date);
//   };

//   return (
//     // dateAdapter={AdapterDateFns}
//     <LocalizationProvider dateAdapter={AdapterDayjs}>
//       <Box position="relative">
//         {/* <DateField value={selectedDate} /> */}
//         {/* <DatePicker
//           // variant="inline"
//           // inputVariant={inputVariant}
//           format={format}
//           name={name}
//           label={label}
//           disabled={disabled}
//           value={selectedDate as Dayjs}
//           // helperText={error ?? helperText}
//           onChange={handleDateChange}
//           // error={error != null}
//           // fullWidth
//           disableFuture={disableFuture}
//           // disableToolbar={disableToolbar}
//           // minDate={minDate}
//           // maxDate={maxDate}
//           // views={views}
//           // openTo={openTo}
//           // autoOk
//           // size={size}
//         /> */}
//         <ButtonWrapper
//           display={!disabled && selectedDate ? `inline-block` : `none`}
//           top={error && '15%'}
//         >
//           <IconButton
//             edge="end"
//             size="small"
//             disabled={!selectedDate}
//             onClick={() => handleDateChange(null)}
//           >
//             <ClearIcon />
//           </IconButton>
//         </ButtonWrapper>
//       </Box>
//     </LocalizationProvider>
//   );
// }

// const ButtonWrapper = styled(Box)`
//   position: absolute;
//   right: 1rem;
//   top: calc(50% - 15px);
// `;
