import { gql } from '@apollo/client';
import {
  Box,
  Button,
  Table as MaterialTable,
  MenuItem,
  Paper,
  Select,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import { styled } from '@mui/material';
import { PaperContent } from '../../../../components/PaperContent';
import {
  SubmitT239Page4Input,
  T239AnswersFragment,
  T239Page4Fragment,
  T239Page4PerceptionValue
} from '../../../../graphql/types';
import { computePercentile, getMaxValue, sum } from '../helpers';

interface Props {
  readonly token: string;
  readonly label: string;
  readonly code: string;
  readonly page: T239Page4Fragment;
  readonly onSubmit: (values: SubmitT239Page4Input) => void;
  readonly onBackClick: () => void;
  readonly isDemo: boolean;
  readonly isReadOnly: boolean;
  readonly answers: T239AnswersFragment | undefined;
}

export const t239Page4Fragment = gql`
  fragment T239Page4Fragment on T239Page4 {
    id
    label
    header {
      label1
      label2
      label3
      label4
      label5
    }
    questions {
      perception {
        id
        label
        defaultValue
      }
      reproduction {
        id
        label
        defaultValue
      }
    }
    choices {
      value
      label
    }
  }
`;

export function Diagnostic({
  token,
  code,
  label,
  page,
  onSubmit,
  onBackClick,
  isDemo,
  isReadOnly,
  answers: inputAnswers
}: Props) {
  const [perception, setPerception] = useState<number[]>(() =>
    page.questions.perception.map(question =>
      isReadOnly
        ? (inputAnswers?.t239AnswerPage4?.perception.find(item => item.id === question.id)?.value ??
          question.defaultValue)
        : question.defaultValue
    )
  );
  const [answers, setAnswers] = useState<string[]>(() =>
    page.questions.perception.map(question => '')
  );
  const [reproduction, setReproduction] = useState<number[]>(() =>
    page.questions.reproduction.map(question =>
      isReadOnly
        ? (inputAnswers?.t239AnswerPage4?.reproduction.find(item => item.id === question.id)
            ?.value ?? question.defaultValue)
        : question.defaultValue
    )
  );

  function getPerceptionResults(
    specsArray: Array<{ id: string }>,
    answers: string[],
    values: number[]
  ): T239Page4PerceptionValue[] {
    return specsArray.map((spec, index) => {
      return {
        id: spec.id,
        value: values[index],
        answer: answers[index]
      };
    });
  }

  function getReproductionResults(
    specsArray: Array<{ id: string }>,
    values: number[]
  ): Array<{ id: string; value: number }> {
    return specsArray.map((spec, index) => {
      return {
        id: spec.id,
        value: values[index]
      };
    });
  }

  const maxValueOption = getMaxValue([...page.choices]);

  return (
    <PaperContent title={label} testNumber={code}>
      <div style={{ whiteSpace: 'pre' }}>{page.label}</div>
      <Box mt={{ xs: 3, md: 3 }} mb={{ xs: 3, md: 3 }} position="relative">
        <TableContainer component={Paper}>
          <MaterialTable>
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  <Box>
                    <Typography component="span" variant="h6">
                      {page.header.label1}
                    </Typography>
                  </Box>
                </StyledTableCell>
                <StyledTableCell>
                  <Box>
                    <Typography component="span" variant="h6">
                      {page.header.label2}
                    </Typography>
                  </Box>
                </StyledTableCell>
                <StyledTableCell>
                  <Box>
                    <Typography component="span" variant="h6">
                      {page.header.label3}
                    </Typography>
                  </Box>
                </StyledTableCell>
                <StyledTableCell>
                  <Box>
                    <Typography component="span" variant="h6">
                      {page.header.label4}
                    </Typography>
                  </Box>
                </StyledTableCell>
                <StyledTableCell>
                  <Box>
                    <Typography component="span" variant="h6">
                      {page.header.label5}
                    </Typography>
                  </Box>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {page.questions.perception.map((perceptionQuestion, index) => {
                const reproductionQuestion = page.questions.reproduction[index];

                return (
                  <TableRow key={perceptionQuestion.id}>
                    <TableCell>{perceptionQuestion.label}</TableCell>
                    <TableCell>
                      <TextField
                        id={`${perceptionQuestion.id}-text`}
                        name={`${perceptionQuestion.id}-text`}
                        disabled={isReadOnly}
                        type="text"
                        variant="standard"
                        multiline={false}
                        onChange={event => {
                          const value =
                            event.target.value && typeof event.target.value === 'string'
                              ? event.target.value
                              : '';

                          const mutatedAnswers = [...answers];
                          mutatedAnswers[index] = value;
                          setAnswers(mutatedAnswers);
                        }}
                      ></TextField>
                    </TableCell>
                    <TableCell>
                      <Select
                        id={perceptionQuestion.id}
                        variant="standard"
                        disabled={isReadOnly}
                        onChange={event => {
                          if (
                            event.target.value != null &&
                            typeof event.target.value === 'number'
                          ) {
                            const { value } = event.target;
                            const mutatedPerception = [...perception];
                            mutatedPerception[index] = value;
                            setPerception(mutatedPerception);
                          }
                        }}
                        value={perception[index]}
                      >
                        {page.choices.map((option, i) => {
                          return (
                            <MenuItem value={option.value} key={option.label + i}>
                              {option.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </TableCell>
                    <TableCell style={{ whiteSpace: 'pre' }}>
                      {reproductionQuestion.label}
                    </TableCell>
                    <TableCell>
                      <Select
                        id={reproductionQuestion.id}
                        variant="standard"
                        disabled={isReadOnly}
                        onChange={event => {
                          if (
                            event.target.value != null &&
                            typeof event.target.value === 'number'
                          ) {
                            const { value } = event.target;
                            const mutatedReproduction = [...reproduction];
                            mutatedReproduction[index] = value;
                            setReproduction(mutatedReproduction);
                          }
                        }}
                        value={reproduction[index]}
                      >
                        {page.choices.map((option, i) => {
                          return (
                            <MenuItem value={option.value} key={option.label + i}>
                              {option.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell>
                  <Box>
                    <Typography component="h6" variant="h6">
                      Úspešnosť: {computePercentile(perception, maxValueOption)}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <Box>
                    <Typography component="h6" variant="h6">
                      {sum(perception)}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box>
                    <Typography component="h6" variant="h6">
                      Úspešnosť: {computePercentile(reproduction, maxValueOption)}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box>
                    <Typography component="h6" variant="h6">
                      {sum(reproduction)}
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Box>
                    <Typography component="h6" variant="h6">
                      Celková úspešnosť:{' '}
                      {computePercentile([...perception, ...reproduction], maxValueOption)}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell colSpan={4}></TableCell>
              </TableRow>
            </TableBody>
          </MaterialTable>
        </TableContainer>
        <Box my={3} textAlign="right">
          <Button size="large" color="secondary" onClick={onBackClick}>
            Krok späť
          </Button>
          <Button
            onClick={() =>
              onSubmit({
                token,
                perception: getPerceptionResults(page.questions.perception, answers, reproduction),
                reproduction: getReproductionResults(page.questions.reproduction, reproduction)
              })
            }
            variant="contained"
            type="submit"
            size="large"
            color="primary"
            style={{ marginLeft: '10px' }}
          >
            Nasledujúca strana
          </Button>
        </Box>
      </Box>
    </PaperContent>
  );
}

const StyledTableCell = styled(TableCell)`
  background: #eee;
`;
