import { gql } from '@apollo/client';
import { isNotNil, populated } from '@everlutionsk/helpers';
import { toYupSchema } from '@everlutionsk/helpers-yup';
import { RadioField } from '@everlutionsk/ui-formik';
import {
  Box,
  Button,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';
import { DiagnosticErrorLabel } from '../../../components/DiagnosticErrorLabel';
import { PaperContent } from '../../../components/PaperContent';
import { StyledMaterialTable } from '../../../components/StyledMaterialTable';
import { TestPersist } from '../../../components/TestPersist';
import { scrollToTopOnFormError } from '../../../components/helpers';
import { SmkzdQuestionTableFragment, SubmitSmkzdInput } from '../../../graphql/types';

interface Props {
  readonly token: string;
  readonly table: SmkzdQuestionTableFragment;
  readonly label: string;
  readonly code: string;
  readonly isDemo: boolean;
  readonly isReadOnly: boolean;
  readonly onBackClick: () => void;
  readonly onSubmit: (input: SubmitSmkzdInput) => void;
  readonly initialValues: Record<string, number>;
}

export const smkzdTableFragment = gql`
  fragment SmkzdQuestionTable on Smkzd {
    questions {
      id
      order
      text
      defaultValue
      options {
        value
        label
      }
    }
  }
`;

export function Diagnostic({
  token,
  table,
  code,
  label,
  isDemo,
  onBackClick,
  onSubmit,
  initialValues,
  isReadOnly
}: Props) {
  const [wasSubmitted, setWasSubmitted] = useState(false);

  const fieldsSpecs = table.questions.reduce((spec, question) => {
    spec[`answer-${question.id}`] = yup.number().required('Prosím, vyber odpoveď');

    return spec;
  }, {});

  const validationSchema = toYupSchema(fieldsSpecs ?? {});

  return (
    <PaperContent title={label} testNumber={code}>
      <Box mt={{ xs: 3, md: 3 }} mb={{ xs: 3, md: 3 }} position="relative">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={values => {
            const keys = Object.keys(values);
            const answers = table.questions
              .map(question => {
                const radioValueKey = keys.find(item => item === `answer-${question.id}`);

                if (radioValueKey == null) return;

                return {
                  id: question.id,
                  radioValue: values[radioValueKey]
                };
              })
              .filter(isNotNil);

            return onSubmit({ token, answers: answers ?? [] });
          }}
        >
          {({ values, errors }) => {
            const complete = Object.values(values).length === table.questions.length;

            return (
              <Form>
                {wasSubmitted && populated(errors) && <DiagnosticErrorLabel />}
                <TableContainer component={Paper}>
                  <StyledMaterialTable>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Typography variant="body2">Číslo otázky</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">Otázka</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">Odpoveď</Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {table.questions.map((question, index) => (
                        <TableRow key={`tr-${index}`}>
                          <TableCell size="small" style={{ verticalAlign: 'top' }}>
                            <Box my={2}>{question.order}.</Box>
                          </TableCell>
                          <TableCell size="small" style={{ verticalAlign: 'top' }}>
                            <Box my={2}>{question.text}</Box>
                          </TableCell>
                          <TableCell size="small">
                            <RadioField
                              name={`answer-${question.id}`}
                              options={question.options}
                              disabled={isReadOnly}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </StyledMaterialTable>
                </TableContainer>

                <Box my={3} textAlign="right">
                  <Button size="large" color="secondary" onClick={onBackClick}>
                    Krok späť
                  </Button>
                  <Button
                    onClick={() => scrollToTopOnFormError(errors, setWasSubmitted)}
                    variant="contained"
                    type="submit"
                    size="large"
                    color="primary"
                    style={{ marginLeft: '10px' }}
                    disabled={isDemo || isReadOnly || !complete}
                  >
                    Vyhodnotiť
                  </Button>
                </Box>
                <TestPersist isDemo={isDemo} keyName={`${token}`} />
              </Form>
            );
          }}
        </Formik>
      </Box>
    </PaperContent>
  );
}
