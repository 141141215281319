import ArrowBack from '@mui/icons-material/ArrowBack';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { Box, Button, Typography } from '@mui/material';
import React from 'react';

type Step = 'first' | 'second' | 'third' | 'mistakes';

interface Props {
  allowBack: boolean;
  allowNext: boolean;
  onBackAction: () => void;
  onNextAction: () => void;
  step: Step;
  isDemo: boolean;
}

export function Stepper({ step, allowBack, allowNext, onBackAction, onNextAction, isDemo }: Props) {
  const { label, bgColor } = getOptions(step);
  return (
    <Box
      display="flex"
      width="100%"
      height="50px"
      alignItems="center"
      bgcolor={bgColor}
      border="2px solid black"
      mb={2}
    >
      <Box display="flex" justifyContent="center" width="20%">
        {allowBack ? (
          <Button onClick={onBackAction} startIcon={<ArrowBack />}>
            Späť
          </Button>
        ) : null}
      </Box>

      <Box width="60%" textAlign="center">
        <Typography variant="h5">{label}</Typography>
      </Box>

      <Box display="flex" justifyContent="center" width="20%">
        {allowNext ? (
          <Button
            disabled={isDemo && step === 'mistakes'}
            onClick={onNextAction}
            endIcon={<ChevronRight />}
          >
            Pokračovať
          </Button>
        ) : null}
      </Box>
    </Box>
  );
}

function getOptions(step: Step): { label: string; bgColor: string } {
  switch (step) {
    case 'first':
      return {
        label: 'Označte kliknutím posledné slovo za 1 minútu',
        bgColor: '#89eb34'
      };
    case 'second':
      return {
        label: 'Označte kliknutím posledné slovo za 2 minútu',
        bgColor: '#e5eb34'
      };
    case 'third':
      return {
        label: 'Označte kliknutím posledné slovo za 3 minútu',
        bgColor: '#349ceb'
      };
    case 'mistakes':
      return { label: 'Označte kliknutím všetky chyby v texte', bgColor: '#ebb46c' };
    default:
      return { label: 'undefined', bgColor: '#ffffff' };
  }
}
