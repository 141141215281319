import ip701X from './images/instructions/instructions-p7-1-X.png';
import ip701A from './images/instructions/instructions-p7-1-A.png';
import ip701B from './images/instructions/instructions-p7-1-B.png';
import ip701C from './images/instructions/instructions-p7-1-C.png';
import ip701D from './images/instructions/instructions-p7-1-D.png';
import ip701E from './images/instructions/instructions-p7-1-E.png';
import ip701F from './images/instructions/instructions-p7-1-F.png';
import ip701G from './images/instructions/instructions-p7-1-G.png';
import ip702X from './images/instructions/instructions-p7-2-X.png';
import ip702A from './images/instructions/instructions-p7-2-A.png';
import ip702B from './images/instructions/instructions-p7-2-B.png';
import ip702C from './images/instructions/instructions-p7-2-C.png';
import ip702D from './images/instructions/instructions-p7-2-D.png';
import ip702E from './images/instructions/instructions-p7-2-E.png';
import ip702F from './images/instructions/instructions-p7-2-F.png';
import ip702G from './images/instructions/instructions-p7-2-G.png';
import ip703X from './images/instructions/instructions-p7-3-X.png';
import ip703A from './images/instructions/instructions-p7-3-A.png';
import ip703B from './images/instructions/instructions-p7-3-B.png';
import ip703C from './images/instructions/instructions-p7-3-C.png';
import ip703D from './images/instructions/instructions-p7-3-D.png';
import ip703E from './images/instructions/instructions-p7-3-E.png';
import ip703F from './images/instructions/instructions-p7-3-F.png';
import ip703G from './images/instructions/instructions-p7-3-G.png';
import p701X from './images/tasks/p7-01-X.png';
import p701A from './images/tasks/p7-01-A.png';
import p701B from './images/tasks/p7-01-B.png';
import p701C from './images/tasks/p7-01-C.png';
import p701D from './images/tasks/p7-01-D.png';
import p701E from './images/tasks/p7-01-E.png';
import p701F from './images/tasks/p7-01-F.png';
import p701G from './images/tasks/p7-01-G.png';
import p702X from './images/tasks/p7-02-X.png';
import p702A from './images/tasks/p7-02-A.png';
import p702B from './images/tasks/p7-02-B.png';
import p702C from './images/tasks/p7-02-C.png';
import p702D from './images/tasks/p7-02-D.png';
import p702E from './images/tasks/p7-02-E.png';
import p702F from './images/tasks/p7-02-F.png';
import p702G from './images/tasks/p7-02-G.png';
import p703X from './images/tasks/p7-03-X.png';
import p703A from './images/tasks/p7-03-A.png';
import p703B from './images/tasks/p7-03-B.png';
import p703C from './images/tasks/p7-03-C.png';
import p703D from './images/tasks/p7-03-D.png';
import p703E from './images/tasks/p7-03-E.png';
import p703F from './images/tasks/p7-03-F.png';
import p703G from './images/tasks/p7-03-G.png';
import p704X from './images/tasks/p7-04-X.png';
import p704A from './images/tasks/p7-04-A.png';
import p704B from './images/tasks/p7-04-B.png';
import p704C from './images/tasks/p7-04-C.png';
import p704D from './images/tasks/p7-04-D.png';
import p704E from './images/tasks/p7-04-E.png';
import p704F from './images/tasks/p7-04-F.png';
import p704G from './images/tasks/p7-04-G.png';
import p705X from './images/tasks/p7-05-X.png';
import p705A from './images/tasks/p7-05-A.png';
import p705B from './images/tasks/p7-05-B.png';
import p705C from './images/tasks/p7-05-C.png';
import p705D from './images/tasks/p7-05-D.png';
import p705E from './images/tasks/p7-05-E.png';
import p705F from './images/tasks/p7-05-F.png';
import p705G from './images/tasks/p7-05-G.png';
import p706X from './images/tasks/p7-06-X.png';
import p706A from './images/tasks/p7-06-A.png';
import p706B from './images/tasks/p7-06-B.png';
import p706C from './images/tasks/p7-06-C.png';
import p706D from './images/tasks/p7-06-D.png';
import p706E from './images/tasks/p7-06-E.png';
import p706F from './images/tasks/p7-06-F.png';
import p706G from './images/tasks/p7-06-G.png';
import p707X from './images/tasks/p7-07-X.png';
import p707A from './images/tasks/p7-07-A.png';
import p707B from './images/tasks/p7-07-B.png';
import p707C from './images/tasks/p7-07-C.png';
import p707D from './images/tasks/p7-07-D.png';
import p707E from './images/tasks/p7-07-E.png';
import p707F from './images/tasks/p7-07-F.png';
import p707G from './images/tasks/p7-07-G.png';
import p708X from './images/tasks/p7-08-X.png';
import p708A from './images/tasks/p7-08-A.png';
import p708B from './images/tasks/p7-08-B.png';
import p708C from './images/tasks/p7-08-C.png';
import p708D from './images/tasks/p7-08-D.png';
import p708E from './images/tasks/p7-08-E.png';
import p708F from './images/tasks/p7-08-F.png';
import p708G from './images/tasks/p7-08-G.png';
import p709X from './images/tasks/p7-09-X.png';
import p709A from './images/tasks/p7-09-A.png';
import p709B from './images/tasks/p7-09-B.png';
import p709C from './images/tasks/p7-09-C.png';
import p709D from './images/tasks/p7-09-D.png';
import p709E from './images/tasks/p7-09-E.png';
import p709F from './images/tasks/p7-09-F.png';
import p709G from './images/tasks/p7-09-G.png';
import p710X from './images/tasks/p7-10-X.png';
import p710A from './images/tasks/p7-10-A.png';
import p710B from './images/tasks/p7-10-B.png';
import p710C from './images/tasks/p7-10-C.png';
import p710D from './images/tasks/p7-10-D.png';
import p710E from './images/tasks/p7-10-E.png';
import p710F from './images/tasks/p7-10-F.png';
import p710G from './images/tasks/p7-10-G.png';
import p711X from './images/tasks/p7-11-X.png';
import p711A from './images/tasks/p7-11-A.png';
import p711B from './images/tasks/p7-11-B.png';
import p711C from './images/tasks/p7-11-C.png';
import p711D from './images/tasks/p7-11-D.png';
import p711E from './images/tasks/p7-11-E.png';
import p711F from './images/tasks/p7-11-F.png';
import p711G from './images/tasks/p7-11-G.png';
import p712X from './images/tasks/p7-13-X.png';
import p712A from './images/tasks/p7-13-A.png';
import p712B from './images/tasks/p7-13-B.png';
import p712C from './images/tasks/p7-13-C.png';
import p712D from './images/tasks/p7-13-D.png';
import p712E from './images/tasks/p7-13-E.png';
import p712F from './images/tasks/p7-13-F.png';
import p712G from './images/tasks/p7-13-G.png';

const images = {
  ip701X,
  ip701A,
  ip701B,
  ip701C,
  ip701D,
  ip701E,
  ip701F,
  ip701G,
  ip702X,
  ip702A,
  ip702B,
  ip702C,
  ip702D,
  ip702E,
  ip702F,
  ip702G,
  ip703X,
  ip703A,
  ip703B,
  ip703C,
  ip703D,
  ip703E,
  ip703F,
  ip703G,
  p701X,
  p701A,
  p701B,
  p701C,
  p701D,
  p701E,
  p701F,
  p701G,
  p702X,
  p702A,
  p702B,
  p702C,
  p702D,
  p702E,
  p702F,
  p702G,
  p703X,
  p703A,
  p703B,
  p703C,
  p703D,
  p703E,
  p703F,
  p703G,
  p704X,
  p704A,
  p704B,
  p704C,
  p704D,
  p704E,
  p704F,
  p704G,
  p705X,
  p705A,
  p705B,
  p705C,
  p705D,
  p705E,
  p705F,
  p705G,
  p706X,
  p706A,
  p706B,
  p706C,
  p706D,
  p706E,
  p706F,
  p706G,
  p707X,
  p707A,
  p707B,
  p707C,
  p707D,
  p707E,
  p707F,
  p707G,
  p708X,
  p708A,
  p708B,
  p708C,
  p708D,
  p708E,
  p708F,
  p708G,
  p709X,
  p709A,
  p709B,
  p709C,
  p709D,
  p709E,
  p709F,
  p709G,
  p710X,
  p710A,
  p710B,
  p710C,
  p710D,
  p710E,
  p710F,
  p710G,
  p711X,
  p711A,
  p711B,
  p711C,
  p711D,
  p711E,
  p711F,
  p711G,
  p712X,
  p712A,
  p712B,
  p712C,
  p712D,
  p712E,
  p712F,
  p712G
};

export function toImageUrl(key: string): string {
  if (key in images === false) throw new Error(`Image with key: ${key} was not found`);
  return images[key];
}
