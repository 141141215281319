import React from 'react';
import { useMemoizedField } from '../../../../../components/forms/useMemoizedField';
import { APlusBSelector } from './APlusBSelector';

interface Props {
  readonly question: {
    choices: Array<{ label: string; id: string }>;
    id: string;
  };
  readonly isReadOnly: boolean;
}

export function APlusBFieldWrapper({ question, isReadOnly }: Props) {
  const [{ value }, , { setValue }] = useMemoizedField<{
    a: string | undefined;
    b: string | undefined;
  }>(question.id);

  return (
    <APlusBSelector
      initialState={value}
      options={question.choices.map(choice => ({
        label: choice.label,
        value: choice.id
      }))}
      onChange={setValue}
      disabled={isReadOnly}
    ></APlusBSelector>
  );
}
