import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material';
import { useFixedId } from '../helpers';

interface Props<T> {
  readonly label?: string;
  readonly options: Array<{ label: string; value: T; isDisabled: boolean }>;
  readonly value: number;
  readonly onValueChanged: (newValue: number) => void;
  /**
   * Provide "empty label" to include the default "empty" item at the beginning of
   * a list. Item value will `undefined`.
   */
  readonly emptyLabel?: string;
  readonly disabled?: boolean;
}

export function CustomSelectField<T>({
  label,
  options,
  value,
  emptyLabel,
  onValueChanged,
  disabled = false
}: Props<T>) {
  const id = useFixedId();

  return (
    <StyledFormControl fullWidth>
      <Label shrink>{label}</Label>
      <Select
        placeholder="Vyberte"
        id={id}
        variant="standard"
        onChange={event => {
          const value = event.target.value as number;
          onValueChanged(value);
        }}
        value={value}
        displayEmpty
        MenuProps={menuProps}
        disabled={disabled}
      >
        {emptyLabel && (
          <MenuItem value={0}>
            <em>{emptyLabel}</em>
          </MenuItem>
        )}
        {options.map((option, i) => {
          return (
            <MenuItem value={i + 1} key={option.label + i} disabled={option.isDisabled}>
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
    </StyledFormControl>
  );
}

const menuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left'
  },
  getContentAnchorEl: null
} as const;

const StyledFormControl = styled(FormControl)`
  width: 100%;
  &.disabled-select {
    .MuiInput-underline {
      &:before {
        border-bottom: 1px dotted rgba(0, 0, 0, 0.38);
      }
    }
  }
  .MuiSelect-select {
    &:focus {
      background: transparent;
    }
  }
`;

const Label = styled(InputLabel)`
  transform: translate(14px, -6px) scale(0.75);
  z-index: 1;
  background: white;
  padding: 0 0.25rem;
  &:before {
    content: '';
    height: 100%;
    top: 0;
    bottom: 0;
    left: -4px;
    width: 4px;
    position: absolute;
  }
  &:after {
    content: '';
    height: 100%;
    top: 0;
    bottom: 0;
    right: -4px;
    width: 4px;
    position: absolute;
  }
`;
