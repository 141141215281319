import { gql } from '@apollo/client';
import { Box, Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import { PaperContent } from '../../../../components/PaperContent';
import { T239Page6Fragment } from '../../../../graphql/types';
import { CrossedSelector } from './CrossedSelector';

interface Props {
  readonly token: string;
  readonly label: string;
  readonly code: string;
  readonly page: T239Page6Fragment;
  readonly onSubmit: (values) => void;
  readonly onBackClick: () => void;
  readonly isDemo: boolean;
  readonly isReadOnly: boolean;
  readonly answers: Array<{ id: string; crossed: boolean }>;
}

export const t239Page6Fragment = gql`
  fragment T239Page6Fragment on T239Page6 {
    id
    label
    subtitle
    options {
      id
      img
    }
  }
`;

export function Diagnostic({
  token,
  code,
  label,
  page,
  onSubmit,
  onBackClick,
  isDemo,
  isReadOnly,
  answers
}: Props) {
  const [values, setValues] = useState<Array<{ value: string; crossed: boolean; img: string }>>(
    () =>
      page.options.map(option => {
        return {
          value: option.id,
          img: option.img,
          crossed: isReadOnly
            ? answers.find(item => item.id === option.id)?.crossed ?? false
            : false
        };
      })
  );

  return (
    <PaperContent title={label} testNumber={code}>
      <Box>
        <div>{page.label}</div>
        <Typography component="span" variant="body2">
          {page.subtitle}
        </Typography>
      </Box>

      <Box mt={{ xs: 3, md: 3 }} mb={{ xs: 3, md: 3 }} position="relative">
        <CrossedSelector
          initialState={values}
          options={page.options.map(option => {
            return { value: option.id, img: option.img };
          })}
          onChange={setValues}
          disabled={isReadOnly}
        />
        <Box my={3} textAlign="right">
          <Button size="large" color="secondary" onClick={onBackClick}>
            Krok späť
          </Button>
          <Button
            onClick={() =>
              onSubmit({
                token,
                answers: values.map(option => {
                  return { id: option.value, crossed: option.crossed ?? false };
                })
              })
            }
            variant="contained"
            type="submit"
            size="large"
            color="primary"
            style={{ marginLeft: '10px' }}
          >
            Nasledujúca strana
          </Button>
        </Box>
      </Box>
    </PaperContent>
  );
}
