import { randomStr } from '@everlutionsk/helpers';
import { Box } from '@mui/material';
import React, { ReactNode, useEffect, useState } from 'react';
import { styled } from '@mui/material';

interface Props {
  readonly initialValue?: string;
  readonly disabled?: boolean;
  readonly sentenceText?: string;
  readonly options: Array<{ label: string; value: string }>;
  readonly onChange: (value: string) => void;
}

const placeholder = '#placeholder#';

function useFixedId(length = 16) {
  const [id] = useState(() => randomStr(length));
  return id;
}

export function T309ChoiceSelector({
  initialValue,
  sentenceText,
  options,
  onChange,
  disabled = false
}: Props) {
  const id = useFixedId();
  const [selected, setSelected] = useState<string | undefined>();
  const [state, setState] = useState({
    text: sentenceText,
    label: ''
  });

  useEffect(() => {
    const initialOption = options.find(option => option.value === initialValue);
    updateState(initialOption?.value, initialOption?.label);
  }, [initialValue]);

  function highlightPhrase(text: string, phrase: string) {
    return (
      <span className="sentence-wrapper">
        {text
          .split(placeholder)
          .reduce<Array<string | ReactNode>>((result, currentPart: string, index: number) => {
            if (result.length === 0) return [currentPart];

            return result.concat(
              <strong key={index}>{phrase === placeholder ? ' ' : phrase}</strong>,
              currentPart
            );
          }, [])}
      </span>
    );
  }

  function updateState(value: string | undefined, label: string | undefined) {
    if (value == null || label == null) return;
    setState({
      label,
      text: sentenceText
    });
    setSelected(value);
    onChange(value);
  }

  return (
    <RadioWrapper>
      {state.text && <Box my={2}>{highlightPhrase(state.text, state.label)}</Box>}

      <ul>
        {options.map((item, index) => (
          <li key={item.value}>
            <input
              name={id}
              disabled={disabled}
              type="radio"
              id={item.value}
              checked={selected === item.value}
              onChange={() => updateState(item.value, item.label)}
            />
            <label htmlFor={item.value}>{`(${String.fromCharCode(65 + index)}) ${
              item.label
            }`}</label>
          </li>
        ))}
      </ul>
    </RadioWrapper>
  );
}

const RadioWrapper = styled('div')`
  .sentence-wrapper {
    strong {
      font-weight: normal;
      color: #4f64f7;
      border-bottom: 1px solid #4f64f7;
      min-width: 125px;
      display: inline-block;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    list-style-type: none;
    li {
      label {
        font-size: 1rem;
      }
      margin-right: 2rem;
      [type='radio']:checked,
      [type='radio']:not(:checked) {
        position: absolute;
        left: -9999px;
      }
      [type='radio']:not(:checked) + label {
        position: relative;
        cursor: pointer;
        color: #000;
      }
      [type='radio']:checked + label {
        position: relative;
        cursor: pointer;
        color: #4f64f7;
      }
    }
  }
`;
