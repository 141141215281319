import { Typography } from '@mui/material';
import React from 'react';
import { PageWrapper } from '../components/PageWrapper';

export function Home() {
  return (
    <PageWrapper title="Welcome">
      <Typography variant="body1">Welcome!</Typography>
    </PageWrapper>
  );
}
