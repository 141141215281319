import { gql } from '@apollo/client';
import { isNotNil, populated } from '@everlutionsk/helpers';
import { toYupSchema } from '@everlutionsk/helpers-yup';
import { CheckboxField, RadioField, TextField } from '@everlutionsk/ui-formik';
import {
  Box,
  Button,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';
import { DiagnosticErrorLabel } from '../../../components/DiagnosticErrorLabel';
import { scrollToTopOnFormError } from '../../../components/helpers';
import { PaperContent } from '../../../components/PaperContent';
import { StyledMaterialTable } from '../../../components/StyledMaterialTable';
import { TestPersist } from '../../../components/TestPersist';
import {
  SubmitT8V1Input,
  SubmitT8V2Input,
  T8QuestionTable1Fragment,
  T8QuestionTable2Fragment
} from '../../../graphql/types';
import { hasError, TableRowWithError } from '../TableRowWithError';
import { Table } from '@app/admin/src/components/Table';

interface Props {
  readonly token: string;
  readonly table1: T8QuestionTable1Fragment;
  readonly table2: T8QuestionTable2Fragment | undefined;
  readonly label: string;
  readonly code: string;
  readonly version: 'v1' | 'v2';
  readonly isDemo: boolean;
  readonly onBackClick: () => void;
  readonly onSubmitV1: (values: SubmitT8V1Input) => void;
  readonly onSubmitV2: (values: SubmitT8V2Input) => void;
  readonly isReadOnly: boolean;
  readonly initialValues: Record<string, number | string>;
}

export const table1Fragment = gql`
  fragment T8QuestionTable1 on T8QuestionsTable1 {
    label
    instructions
    questions {
      id
      task
      instruction
      fields {
        rating {
          validation {
            rules
          }
          options {
            value
            label
          }
        }
        answer {
          validation {
            rules
          }
        }
      }
    }
  }
`;

export const table2Fragment = gql`
  fragment T8QuestionTable2 on T8QuestionsTable2 {
    label
    instructions
    questions {
      id
      task
      fields {
        match {
          validation {
            rules
          }
        }
        notMatch {
          validation {
            rules
          }
        }
      }
    }
  }
`;

export function Diagnostic({
  token,
  table1,
  table2,
  code,
  label,
  version,
  isDemo,
  onBackClick,
  onSubmitV1,
  onSubmitV2,
  isReadOnly,
  initialValues
}: Props) {
  const [wasSubmitted, setWasSubmitted] = useState(false);

  const fieldsSpecs = table1.questions.reduce((spec, question) => {
    spec[`rating-${question.id}`] = yup.number().required('Prosím, vyber hodnotenie');

    return spec;
  }, {});

  const validationSchema = toYupSchema(fieldsSpecs ?? {});

  return (
    <PaperContent title={label} testNumber={code}>
      <div>{table1.label}</div>
      <Box mt={2}>
        <Typography variant="body2">{table1.instructions}</Typography>

        <Typography variant="body2">
          Pi obr. 16 - 21 sa vyžaduje pomenovať v záhlaví uvedenú činosť: zámeny činnosti
          nezapočítavame.
        </Typography>
      </Box>

      <Box my={3} position="relative">
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={values => {
            if (version === 'v1') {
              return onSubmitV1(toV1Answers({ values, table: table1, token }));
            }
            return onSubmitV2(toV2Answers({ values, table1, table2, token }));
          }}
        >
          {({ errors }) => (
            <Form>
              {wasSubmitted && populated(errors) && <DiagnosticErrorLabel />}
              <TableContainer component={Paper}>
                <StyledMaterialTable>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography variant="body2">Úloha</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">Hodnotenie</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">Odpoveď</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">Inštrukcie pre skórovanie</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {table1.questions.map((question, index) => {
                      return (
                        <TableRowWithError
                          key={index}
                          data-has-error={wasSubmitted && hasError(errors, `rating-${question.id}`)}
                        >
                          <TableCell size="small">{question.task}</TableCell>
                          <TableCell size="small">
                            <RadioField
                              name={`rating-${question.id}`}
                              options={question.fields.rating.options}
                              disabled={isReadOnly}
                            ></RadioField>
                          </TableCell>
                          <TableCell size="small">
                            <TextField
                              variant="standard"
                              name={`answer-${question.id}`}
                              type="text"
                              disabled={isReadOnly}
                            ></TextField>
                          </TableCell>
                          <TableCell size="small">{question.instruction}</TableCell>
                        </TableRowWithError>
                      );
                    })}
                  </TableBody>
                </StyledMaterialTable>
              </TableContainer>

              {table2 && (
                <>
                  <h2>{table2.label}</h2>
                  <p>{table2.instructions}</p>

                  <Table
                    items={table2.questions}
                    rowKey={item => item.id}
                    columns={[
                      {
                        label: 'Úloha',
                        render: item => <div>{item.task}</div>
                      },
                      {
                        label: 'Presná',
                        render: item => (
                          <CheckboxField
                            name={`match-${item.id}`}
                            disabled={isReadOnly}
                            label={undefined}
                          />
                        )
                      },
                      {
                        label: 'Nepresná-detail',
                        render: item => (
                          <TextField
                            variant="standard"
                            name={`not-match-${item.id}`}
                            type="text"
                            disabled={isReadOnly}
                          />
                        )
                      }
                    ]}
                  />
                </>
              )}
              <Box my={3} textAlign="right">
                <Button size="large" color="secondary" onClick={onBackClick}>
                  Krok späť
                </Button>

                <Button
                  onClick={() => scrollToTopOnFormError(errors, setWasSubmitted)}
                  variant="contained"
                  type="submit"
                  size="large"
                  color="primary"
                  style={{ marginLeft: '10px' }}
                  disabled={isDemo || isReadOnly}
                >
                  Vyhodnotiť
                </Button>
              </Box>
              <TestPersist isDemo={isDemo} keyName={`${token}${version}`} />
            </Form>
          )}
        </Formik>
      </Box>
    </PaperContent>
  );
}

function toTable1Answers({ values, table }): SubmitT8V1Input['answersTable1'] {
  const keys = Object.keys(values);
  return table.questions
    .map(question => {
      const radioValueKey = keys.find(item => item === `rating-${question.id}`);
      const answerValueKey = keys.find(item => item === `answer-${question.id}`);

      if (radioValueKey == null) return;

      return {
        id: question.id,
        radioValue: values[radioValueKey],
        answer: answerValueKey ? values[answerValueKey] : ''
      };
    })
    .filter(isNotNil);
}

function toTable2Answers({ values, table }): SubmitT8V2Input['answersTable2'] {
  const keys = Object.keys(values);
  return table.questions
    .map(question => {
      const checkBoxValue = keys.find(item => item === `match-${question.id}`);
      const answerValueKey = keys.find(item => item === `not-match-${question.id}`);

      return {
        id: question.id,
        match: checkBoxValue ? values[checkBoxValue] : false,
        notMatch: answerValueKey ? values[answerValueKey] : ''
      };
    })
    .filter(isNotNil);
}

function toV1Answers({ values, table, token }): SubmitT8V1Input {
  return {
    token,
    answersTable1: toTable1Answers({ values, table })
  };
}
function toV2Answers({ values, table1, table2, token }): SubmitT8V2Input {
  return {
    token,
    answersTable1: toTable1Answers({ values, table: table1 }),
    answersTable2: toTable2Answers({ values, table: table2 })
  };
}
