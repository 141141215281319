import { gql, useQuery } from '@apollo/client';
import { toYupSchema } from '@everlutionsk/helpers-yup';
import { Loading } from '@everlutionsk/ui';
import { SelectField, TextField } from '@everlutionsk/ui-formik';
import { Box, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { DatePickerField } from '../../../components/forms/DatePickerField';
import { PaperContent } from '../../../components/PaperContent';
import { InputList } from '../Identification/InputList';
import { InputWrapper } from '../Identification/InputWrapper';
import { toVersionLabel } from './helpers';

interface Props {
  readonly token: string;
  readonly isDemo: boolean;
  readonly isReadOnly: boolean;
  readonly code: string;
  readonly label: string;
  readonly answers: { duration: number; version: string } | undefined;
  readonly testingDate: string | undefined;
}

const query = gql<T41FinishedStudentOneByTokenGQL>`
  query T41FinishedStudentOneByToken($token: String!) {
    studentOneByToken(token: $token) {
      id
      birthIdentificationNumber
      firstName
      lastName
      fullName
      dateOfBirth
      gender
    }
  }
`;

export function FinishedResults({
  code,
  isDemo,
  token,
  label,
  isReadOnly,
  answers,
  testingDate
}: Props) {
  const { data } = useQuery(query, { variables: { token } });

  if (data == null) return <Loading />;

  const minutes = Math.floor((answers?.duration ?? 0) / 60);
  const seconds = answers?.duration ?? 0 - minutes * 60;

  const validationSchema = toYupSchema({
    minutes: yup.string().required('Prosím, zadaj minúty'),
    seconds: yup.string().required('Prosím, zadaj sekundy')
  });
  const initialValues = {
    fullName: data.studentOneByToken.fullName,
    birthMonth: new Date(data.studentOneByToken.dateOfBirth) ?? undefined,
    birthYear: new Date(data.studentOneByToken.dateOfBirth) ?? undefined,
    gender: data.studentOneByToken.gender,
    date: testingDate != null ? new Date(testingDate) : undefined,
    diagnosticVersion: answers?.version != null ? toVersionLabel(answers.version) : undefined,
    minutes,
    seconds
  };

  return (
    <PaperContent title={label} testNumber={code}>
      <Typography component="h3" variant="h4">
        Výsledky testu
      </Typography>
      <Box my={3}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={() => {}}
        >
          <Form>
            <InputList>
              <InputWrapper label={`Identifikátor klienta`}>
                <TextField
                  disabled={Boolean(isDemo) || isReadOnly}
                  name="fullName"
                  type="firstName"
                  variant="standard"
                />
              </InputWrapper>
              <InputWrapper label={`Mesiac narodenia`}>
                <DatePickerField
                  disabled
                  name="birthMonth"
                  views={['month']}
                  size={'small'}
                  format={'MMMM'}
                />
              </InputWrapper>
              <InputWrapper label={`Rok narodenia`}>
                <DatePickerField
                  disabled
                  name="birthYear"
                  views={['year']}
                  size={'small'}
                  format={'YYYY'}
                />
              </InputWrapper>
              <InputWrapper label={`Pohlavie`}>
                <SelectField
                  disabled
                  options={genderOptions}
                  name="gender"
                  variant="standard"
                  className={`disabled-select`}
                />
              </InputWrapper>
              <InputWrapper label={`Dátum posudzovania žiaka`}>
                <DatePickerField
                  name="date"
                  size={'small'}
                  // views={['date']}
                  disabled={isReadOnly}
                />
              </InputWrapper>
              <InputWrapper label={`Verzia testu`}>
                <TextField disabled name="diagnosticVersion" type="text" variant="standard" />
              </InputWrapper>
              <InputWrapper label={`Čas testovania`}>
                <Box display="flex">
                  <Box mr={3}>
                    <TextField
                      disabled={isReadOnly}
                      name="minutes"
                      label="minút"
                      type="number"
                      variant="standard"
                    />
                  </Box>
                  <Box>
                    <TextField
                      disabled={isReadOnly}
                      name="seconds"
                      label="sekúnd"
                      type="number"
                      InputProps={{ inputProps: { min: 0, max: 60 } }}
                      variant="standard"
                    />
                  </Box>
                </Box>
              </InputWrapper>
            </InputList>
          </Form>
        </Formik>
      </Box>
    </PaperContent>
  );
}

const genderOptions = [
  { value: 'male', label: 'Mužské' },
  { value: 'female', label: 'Ženské' }
];
