import { useFormikContext } from 'formik';

export function useNestedFieldError(name: string): Record<string, string> | undefined {
  const form = useFormikContext();

  const fieldError: Record<string, string> | undefined = form.errors?.[name];

  if (typeof fieldError === 'string') {
    throw Error(`Please use useFieldError instead`);
  }

  if (form.submitCount > 0 || form.touched[name] === true) {
    return fieldError;
  }
}
