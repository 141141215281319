import { gql } from '@apollo/client';
import { toYupSchema } from '@everlutionsk/helpers-yup';
import { TextField } from '@everlutionsk/ui-formik';
import {
  Box,
  Button,
  Table as MaterialTable,
  Paper,
  styled,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import { TestPersist } from '../../../../components/TestPersist';
import { SubmitT309Page8Input, T309Page8Fragment } from '../../../../graphql/types';
import { CountdownChangeListener } from '../components/CountdownChangeListener';
import { PaperContent } from '../components/PaperContent';
import { useCountdown } from '../useCountdown';

interface Props {
  readonly token: string;
  readonly label: string;
  readonly code: string;
  readonly page: T309Page8Fragment;
  readonly onSubmit: (values: SubmitT309Page8Input) => void;
  readonly isDemo: boolean;
  readonly isReadOnly: boolean;
  readonly initialValues: Record<string, string>;
}

export const t309Page8Fragment = gql`
  fragment T309Page8Fragment on T309Page8 {
    id
    label
    questions {
      id
      order
      label
    }
    timeLimitInSeconds
  }
`;

interface FormValues {
  readonly [name: string]: string;
}

export function Diagnostic({
  token,
  code,
  label,
  page,
  onSubmit,
  isDemo,
  initialValues,
  isReadOnly
}: Props) {
  const remainingSeconds = useCountdown(page.timeLimitInSeconds);
  const validationSchema = toYupSchema({});
  const classes = useStyles;

  function getResults(formValues: FormValues): SubmitT309Page8Input {
    return {
      token,
      answers: Object.keys(formValues).map(key => {
        const value = parseInt(formValues[key]);
        return {
          id: key,
          answer: !isNaN(value) ? value : 0
        };
      })
    };
  }

  return (
    <PaperContent title={label} testNumber={code} remainingSeconds={remainingSeconds}>
      <div>{page.label}</div>
      <Box my={3} position="relative">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(formikValues: FormValues) => onSubmit(getResults(formikValues))}
        >
          <Form>
            <TableContainer component={Paper}>
              <MaterialTable sx={classes.table}>
                <TableBody>
                  {page.questions.map((question, idx) => (
                    <TableRow key={question.id}>
                      <TableCell>{question.order}.</TableCell>
                      <TableCell>
                        <QuestionWrapper>
                          <div className="field-label">{question.label}</div>
                          <div className="field-wrapper">
                            <label>Odpoveď:</label>
                            <TextField
                              sx={classes.input}
                              variant="standard"
                              name={`${question.id}`}
                              type="number"
                              disabled={isReadOnly}
                            ></TextField>
                          </div>
                        </QuestionWrapper>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </MaterialTable>
            </TableContainer>
            <Box my={3} textAlign="right">
              <Button
                variant="contained"
                type="submit"
                size="large"
                color="primary"
                style={{ marginLeft: '10px' }}
              >
                Ďalej
              </Button>
            </Box>
            <CountdownChangeListener remainingSeconds={remainingSeconds} isReadOnly={isReadOnly} />
            <TestPersist isDemo={isDemo} keyName={`${token}${page.id}`} />
          </Form>
        </Formik>
      </Box>
    </PaperContent>
  );
}

const useStyles = {
  table: {
    minWidth: 650
  },
  input: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    maxWidth: '150px'
  }
};

const QuestionWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  .field-label {
    margin-bottom: 10px;
  }
  .field-wrapper {
    display: flex;
    flex-wrap: no-wrap;
    align-items: center;
    width: 100%;
    label {
      margin-right: 5px;
    }
    .input-wrapper {
      max-width: 150px;
    }
  }
`;
