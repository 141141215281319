import ChevronRight from '@mui/icons-material/ChevronRight';
import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PaperContent } from '../../../components/PaperContent';

interface Props {
  readonly label: string;
  readonly code: string;
  readonly v1: { label: string };
  readonly v2: { label: string };
}

export function ChooseVersion({ label, code, v1, v2 }: Props) {
  const navigate = useNavigate();

  return (
    <PaperContent title={label} testNumber={code}>
      <Box mb={5}>
        <Typography component="h3" variant="h4">
          {v1.label}
        </Typography>
        <Box my={3}>
          <Button
            variant="contained"
            onClick={() => navigate('../v1/instructions')}
            color="primary"
            endIcon={<ChevronRight />}
          >
            Ďalej
          </Button>
        </Box>
      </Box>
      <Box mb={3}>
        <Typography component="h3" variant="h4">
          {v2.label}
        </Typography>
        <Box my={3}>
          <Button
            variant="contained"
            onClick={() => navigate('../v2/instructions')}
            color="primary"
            endIcon={<ChevronRight />}
          >
            Ďalej
          </Button>
        </Box>
        <Box my={3}>
          <Button variant="outlined" onClick={() => navigate(`../`)} color="primary">
            Krok späť
          </Button>
        </Box>
      </Box>
    </PaperContent>
  );
}
