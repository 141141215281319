import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { PaperContent } from '../../../../components/PaperContent';

interface Props {
  readonly label: string;
  readonly code: string;
  readonly onSubmit: () => void;
}

export function DiagnosticDone({ code, label, onSubmit }: Props) {
  return (
    <>
      <PaperContent title={label} testNumber={code}>
        <Box my={3}>
          <Typography component="h5" variant="h5">
            Zapamätávanie si tovarov (fáza vštepovania)
          </Typography>
        </Box>

        <Box my={3}>
          <Typography variant="body1">Zapamätávanie si tovarov (fáza vštepovania)</Typography>
        </Box>

        <Box my={3} textAlign="left">
          <Button onClick={onSubmit} variant="contained" type="submit" size="large" color="primary">
            Pokračovať
          </Button>
        </Box>
      </PaperContent>
    </>
  );
}
