import { gql, useMutation } from '@apollo/client';
import { project } from '@everlutionsk/helpers';
import { useParams } from '@everlutionsk/ui-router';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PaperContent } from '../../../components/PaperContent';
import { T41DiagnosticVersion, T4ISectionFragment } from '../../../graphql/types';
import { t41Mutation } from './InsertResults';
import { Timer } from './Timer';

interface Props {
  readonly token: string;
  readonly label: string;
  readonly code: string;
  readonly isDemo: boolean;
  readonly instructions: T4ISectionFragment[];
}

export const t41SectionFragment = gql`
  fragment T4ISectionFragment on T41Section {
    title
    text
  }
`;
export function Diagnostic({ token, instructions, code, label, isDemo }: Props) {
  const { version } = useParams(['version']);
  const navigate = useNavigate();
  const [mutate] = useMutation(t41Mutation);

  return (
    <PaperContent title={label} testNumber={code}>
      <Box my={3} position="relative">
        {instructions.map((section, index) => (
          <Box key={`section-${index}`} display="flex">
            <Box>
              <Box>
                <Typography component="h4" variant="h5">
                  {section.title}
                </Typography>
              </Box>
              <Box mb={3} pr={5}>
                <Typography
                  component="p"
                  variant="body2"
                  dangerouslySetInnerHTML={{ __html: section.text }}
                ></Typography>
              </Box>
            </Box>
            {index === 0 && !isDemo && (
              <Timer
                onSubmit={duration => {
                  mutate({
                    variables: {
                      input: {
                        token,
                        duration,
                        version: project(version, T41DiagnosticVersion)
                      }
                    }
                  })
                    .then(() => navigate('../../complete'))
                    .catch(error => console.error(error));
                }}
              ></Timer>
            )}
          </Box>
        ))}
      </Box>
    </PaperContent>
  );
}
