import { Box, TextField, Typography } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material';

export function Instructions() {
  return (
    <>
      <Box my={3}>
        <Typography component="h5" variant="h5">
          Praktické počty - inštrukcie
        </Typography>
      </Box>
      <Box my={3}>
        <Typography component="p" variant="body2">
          V nasledujúcom texte Vám predložíme niekoľko počtových úloh.
        </Typography>
      </Box>
      <Box my={3}>
        <Typography component="p" variant="body2">
          Príklad:
        </Typography>
      </Box>
      <TableDiv mt={3} display="flex">
        <Box className="cell">
          <Typography component="h5" variant="h5">
            B14.
          </Typography>
        </Box>
        <Box className="cell">
          <Box mb={2}>
            <Typography component="p" variant="body2">
              20 kapitol učebnice má spolu 240 strán. Koľko strán priemerne má jedna kapitola?
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Typography component="p" variant="body2">
              Odpoveď:
            </Typography>
            <TextField disabled variant="standard" />
          </Box>
        </Box>
      </TableDiv>
      <Box mt={2}>
        <Typography component="p" variant="body2">
          Riešenie je 12 strán (240 delené 20).
        </Typography>
      </Box>
      <Box mb={3} mt={5}>
        <Typography component="p" variant="body2">
          Ďalší príklad:
        </Typography>
      </Box>
      <TableDiv mt={3} display="flex">
        <Box className="cell">
          <Typography component="h5" variant="h5">
            B15.
          </Typography>
        </Box>
        <Box className="cell">
          <Box mb={2}>
            <Typography component="p" variant="body2">
              4 500 kg mosadze obsahuje 2 700 kg medi. Koľko kilogramov medi sa nachádza v 900 kg
              mosadze?
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Typography component="p" variant="body2">
              Odpoveď:
            </Typography>
            <TextField disabled variant="standard" />
          </Box>
        </Box>
      </TableDiv>
      <Box mt={2}>
        <Typography component="p" variant="body2">
          Správne riešenie je 540 kg. 900 kg mosadze je jedna pätina z 4 500 kg, z toho teda
          vyplýva, že aj 900 kg mosadze obsahuje jednu pätinu z 2 700 kg medi, čo je 540 kg.
        </Typography>
      </Box>
      <Box my={3}>
        <Typography component="p" variant="body2">
          Na riešenie nasledujúcich <strong>12 úloh</strong> máte čas <strong>11 minút</strong>.{' '}
          <strong>Počkajte na vyzvanie stlačiť tlačidlo ĎALEJ.</strong>
        </Typography>
      </Box>
    </>
  );
}

const TableDiv = styled(Box)`
  border: 1px solid #ccc;
  .cell {
    &:first-child {
      padding: 1rem;
    }
    &:last-child {
      border-left: 1px solid #ccc;
      padding: 1rem 1rem 1rem 1rem;
    }
  }
`;
