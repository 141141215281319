import { gql } from '@apollo/client';
import {
  Box,
  Button,
  Table as MaterialTable,
  MenuItem,
  Paper,
  Select,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import { styled } from '@mui/material';
import { PaperContent } from '../../../../components/PaperContent';
import { T239AnswersFragment, T239Page3Fragment } from '../../../../graphql/types';
import { computePercentile, getMaxValue, sum } from '../helpers';

interface Props {
  readonly token: string;
  readonly label: string;
  readonly code: string;
  readonly page: T239Page3Fragment;
  readonly onSubmit: (values) => void;
  readonly onBackClick: () => void;
  readonly isDemo: boolean;
  readonly isReadOnly: boolean;
  readonly answers: T239AnswersFragment | undefined;
}

export const t239Page3Fragment = gql`
  fragment T239Page3Fragment on T239Page3 {
    id
    label
    header {
      label1
      label2
      label3
      label4
    }
    questions {
      labels
      plhs {
        id
        defaultValue
      }
      phs {
        id
        defaultValue
      }
      lhs {
        id
        defaultValue
      }
    }
    choices {
      value
      label
    }
  }
`;

export function Diagnostic({
  token,
  code,
  label,
  page,
  onSubmit,
  onBackClick,
  isDemo,
  isReadOnly,
  answers
}: Props) {
  const [plhs, setPlhs] = useState<number[]>(() =>
    page.questions.plhs.map(question =>
      isReadOnly
        ? (answers?.t239AnswerPage3?.plhs.find(item => item.id === question.id)?.value ??
          question.defaultValue)
        : question.defaultValue
    )
  );
  const [phs, setPhs] = useState<number[]>(() =>
    page.questions.phs.map(question =>
      isReadOnly
        ? (answers?.t239AnswerPage3?.phs.find(item => item.id === question.id)?.value ??
          question.defaultValue)
        : question.defaultValue
    )
  );
  const [lhs, setLhs] = useState<number[]>(() =>
    page.questions.lhs.map(question =>
      isReadOnly
        ? (answers?.t239AnswerPage3?.lhs.find(item => item.id === question.id)?.value ??
          question.defaultValue)
        : question.defaultValue
    )
  );

  function getResults(
    specsArray: Array<{ id: string }>,
    values: number[]
  ): Array<{ id: string; value: number }> {
    return specsArray.map((spec, index) => {
      return {
        id: spec.id,
        value: values[index]
      };
    });
  }

  const maxValueOption = getMaxValue([...page.choices]);

  return (
    <PaperContent title={label} testNumber={code}>
      <div>{page.label}</div>
      <Box mt={{ xs: 3, md: 3 }} mb={{ xs: 3, md: 3 }} position="relative">
        <TableContainer component={Paper}>
          <MaterialTable>
            <TableHead>
              <TableRow>
                <StyledTableCell>{page.header.label1}</StyledTableCell>
                <StyledTableCell>{page.header.label2}</StyledTableCell>
                <StyledTableCell>{page.header.label3}</StyledTableCell>
                <StyledTableCell>{page.header.label4}</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {page.questions.plhs.map((plhsQuestion, index) => {
                const label = page.questions.labels[index];
                const phsQuestion = page.questions.phs[index];
                const lhsQuestion = page.questions.lhs[index];

                return (
                  <TableRow key={plhsQuestion.id}>
                    <TableCell>{label}</TableCell>
                    <TableCell>
                      <Select
                        id={plhsQuestion.id}
                        variant="standard"
                        onChange={event => {
                          if (
                            event.target.value != null &&
                            typeof event.target.value === 'number'
                          ) {
                            const { value } = event.target;
                            const mutatedPlhs = [...plhs];
                            mutatedPlhs[index] = value;
                            setPlhs(mutatedPlhs);
                          }
                        }}
                        disabled={isReadOnly}
                        value={plhs[index]}
                      >
                        {page.choices.map((option, i) => {
                          return (
                            <MenuItem
                              value={option.value}
                              key={option.label + i}
                              disabled={isReadOnly}
                            >
                              {option.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </TableCell>
                    <TableCell>
                      <Select
                        id={phsQuestion.id}
                        variant="standard"
                        onChange={event => {
                          if (
                            event.target.value != null &&
                            typeof event.target.value === 'number'
                          ) {
                            const { value } = event.target;
                            const mutatedPhs = [...phs];
                            mutatedPhs[index] = value;
                            setPhs(mutatedPhs);
                          }
                        }}
                        disabled={isReadOnly}
                        value={phs[index]}
                      >
                        {page.choices.map((option, i) => {
                          return (
                            <MenuItem
                              value={option.value}
                              key={option.label + i}
                              disabled={isReadOnly}
                            >
                              {option.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </TableCell>
                    <TableCell>
                      <Select
                        id={lhsQuestion.id}
                        variant="standard"
                        onChange={event => {
                          if (
                            event.target.value != null &&
                            typeof event.target.value === 'number'
                          ) {
                            const { value } = event.target;
                            const mutatedLhs = [...lhs];
                            mutatedLhs[index] = value;
                            setLhs(mutatedLhs);
                          }
                        }}
                        disabled={isReadOnly}
                        value={lhs[index]}
                      >
                        {page.choices.map((option, i) => {
                          return (
                            <MenuItem
                              value={option.value}
                              key={option.label + i}
                              disabled={isReadOnly}
                            >
                              {option.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell>
                  <Box>
                    <Typography component="h6" variant="h6">
                      Úspešnosť {computePercentile(plhs, maxValueOption)} X HS
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box>
                    <Typography component="h6" variant="h6">
                      {sum(plhs)}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box>
                    <Typography component="h6" variant="h6">
                      {sum(phs)}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box>
                    <Typography component="h6" variant="h6">
                      {sum(lhs)}
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </MaterialTable>
        </TableContainer>
        <Box my={3} textAlign="right">
          <Button size="large" color="secondary" onClick={onBackClick}>
            Krok späť
          </Button>
          <Button
            onClick={() =>
              onSubmit({
                token,
                plhs: getResults(page.questions.plhs, plhs),
                phs: getResults(page.questions.phs, phs),
                lhs: getResults(page.questions.lhs, lhs)
              })
            }
            variant="contained"
            type="submit"
            size="large"
            color="primary"
            style={{ marginLeft: '10px' }}
          >
            Nasledujúca strana
          </Button>
        </Box>
      </Box>
    </PaperContent>
  );
}

const StyledTableCell = styled(TableCell)`
  background: #eee;
`;
