import prvy_polrok_prvy_rocnik from './documents/prvy_polrok_prvy_rocnik.pdf';
import druhy_polrok_prvy_rocnik from './documents/druhy_polrok_prvy_rocnik.pdf';
import druhy_rocnik from './documents/druhy_rocnik.pdf';
import treti_piaty_rocnik from './documents/3-5_rocnik.pdf';
import druhy_stupen from './documents/druhy_stupen.pdf';

const documents = {
  prvy_polrok_prvy_rocnik,
  druhy_polrok_prvy_rocnik,
  druhy_rocnik,
  treti_piaty_rocnik,
  druhy_stupen
};

export function toDocumentUrl(key: string): string {
  if (key in documents === false) throw new Error(`Document with key: ${key} was not found`);
  return documents[key];
}
