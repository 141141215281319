import { gql, useQuery } from '@apollo/client';
import { isNotNil, populated } from '@everlutionsk/helpers';
import { toYupSchema } from '@everlutionsk/helpers-yup';
import { Loading } from '@everlutionsk/ui';
import { Fields, RadioField, TextField } from '@everlutionsk/ui-formik';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';
import { DiagnosticErrorLabel } from '../../../components/DiagnosticErrorLabel';
import { PaperContent } from '../../../components/PaperContent';
import { scrollToTopOnFormError } from '../../../components/helpers';
import { SubmitT95Input } from '../../../graphql/types';

interface Props {
  readonly token: string;
  readonly label: string;
  readonly code: string;
  readonly isDemo: boolean;
  readonly isReadOnly: boolean;
  readonly onSubmit: (input: SubmitT95Input) => void;
}

const query = gql<T95DiagnosticV1QueryGQL>`
  query T95DiagnosticV1Query($token: String!) {
    t95Diagnostics(token: $token) {
      isValid
      isDemo
      code
      label
      status
      originalToken
      instructions
      questions {
        id
        order
        columns {
          ... on T95Label {
            label
          }
          ... on T95Field {
            id
            options {
              value
              label
            }
          }
        }
      }
      answers {
        id
        radioValue
      }
      sightDisability
      leftEye
      rightEye
      hearingDisability
      attention
      attentionOptions {
        value
        label
      }
      ciq
      viq
      niq
    }
  }
`;

export function DiagnosticT95({ token, code, label, isDemo, onSubmit, isReadOnly }: Props) {
  const [wasSubmitted, setWasSubmitted] = useState(false);

  const { data } = useQuery(query, { variables: { token } });

  if (data?.t95Diagnostics == null) return <Loading />;

  const fieldsSpecs = {
    ...data.t95Diagnostics.questions
      .flatMap(item => item.columns)
      .reduce((spec, question) => {
        if (question.__typename === 'T95Label') return spec;
        spec[`answer-${question.id}`] = yup.number().required('Prosím, vyber odpoveď');

        return spec;
      }, {})
  };

  const validationSchema = toYupSchema({
    ...fieldsSpecs,
    sightDisability: yup.boolean().required('Prosím, vyber odpoveď'),
    leftEye: yup.string(),
    rightEye: yup.string(),
    hearingDisability: yup.boolean().required('Prosím, vyber odpoveď'),
    attention: yup.string().required('Prosím, vyber odpoveď'),
    ciq: yup.string(),
    viq: yup.string(),
    niq: yup.string(),
    notes: yup.string(),
    repetition1: yup.number().typeError('Prosím, zadaj číslo'),
    repetition2: yup.number().typeError('Prosím, zadaj číslo'),
    repetition3: yup.number().typeError('Prosím, zadaj číslo'),
    repetition4: yup.number().typeError('Prosím, zadaj číslo'),
    repetition5: yup.number().typeError('Prosím, zadaj číslo'),
    repetitionSetB: yup.number().typeError('Prosím, zadaj číslo'),
    repetition6: yup.number().typeError('Prosím, zadaj číslo'),
    repetitionAfter30minutes: yup.number().typeError('Prosím, zadaj číslo'),
    confabulation1: yup.number().typeError('Prosím, zadaj číslo'),
    confabulation2: yup.number().typeError('Prosím, zadaj číslo'),
    confabulation3: yup.number().typeError('Prosím, zadaj číslo'),
    confabulation4: yup.number().typeError('Prosím, zadaj číslo'),
    confabulation5: yup.number().typeError('Prosím, zadaj číslo'),
    confabulationSetB: yup.number().typeError('Prosím, zadaj číslo'),
    confabulation6: yup.number().typeError('Prosím, zadaj číslo'),
    confabulationAfter30minutes: yup.number().typeError('Prosím, zadaj číslo')
  });
  const initialValues = isDemo
    ? Object.assign(
        {},
        ...Array.from(
          data.t95Diagnostics.questions.flatMap(item => item.columns),
          (question, i) => {
            if (question.__typename === 'T95Label') return;

            return { [`answer-${question.id}`]: 0 };
          }
        )
      )
    : isReadOnly
      ? Object.assign(
          {},
          ...Array.from(
            data.t95Diagnostics.questions.flatMap(item => item.columns),
            (question, i) => {
              if (question.__typename === 'T95Label') return;
              return {
                [`answer-${question.id}`]: data.t95Diagnostics?.answers.find(
                  item => item.id === question.id
                )?.radioValue
              };
            }
          )
        )
      : {};

  return (
    <PaperContent title={label} testNumber={code}>
      <Box mt={{ xs: 3, md: 3 }} mb={{ xs: 3, md: 3 }} position="relative">
        <Formik
          initialValues={{
            ...initialValues,
            sightDisability: undefined,
            leftEye: '',
            rightEye: '',
            hearingDisability: undefined,
            attention: '',
            ciq: '',
            viq: '',
            niq: '',
            notes: '',
            repetition1: '',
            repetition2: '',
            repetition3: '',
            repetition4: '',
            repetition5: '',
            repetitionSetB: '',
            repetition6: '',
            repetitionAfter30minutes: '',
            confabulation1: '',
            confabulation2: '',
            confabulation3: '',
            confabulation4: '',
            confabulation5: '',
            confabulationSetB: '',
            confabulation6: '',
            confabulationAfter30minutes: ''
          }}
          validationSchema={validationSchema}
          onSubmit={values => {
            const keys = Object.keys(values);

            const answers = data?.t95Diagnostics?.questions
              .flatMap(item => item.columns)
              .filter(item => item.__typename === 'T95Field')
              .map(question => {
                const radioValueKey = keys.find(item => item === `answer-${question.id}`);

                if (radioValueKey == null) return;

                return {
                  id: question.id,
                  radioValue: values[radioValueKey]
                };
              })
              .filter(isNotNil);

            onSubmit({
              answers: answers ? answers : [],
              attention: { id: String(values.attention), radioValue: values.attention },
              confabulations: [
                values.confabulation1 ? Number(values.confabulation1) : 0,
                values.confabulation2 ? Number(values.confabulation2) : 0,
                values.confabulation3 ? Number(values.confabulation3) : 0,
                values.confabulation4 ? Number(values.confabulation4) : 0,
                values.confabulation5 ? Number(values.confabulation5) : 0,
                values.confabulationSetB ? Number(values.confabulationSetB) : 0,
                values.confabulation6 ? Number(values.confabulation6) : 0,
                values.confabulationAfter30minutes ? Number(values.confabulationAfter30minutes) : 0
              ],
              hearingDisability: values.hearingDisability,
              notes: values.notes,
              repetitions: [
                values.repetition1 ? Number(values.repetition1) : 0,
                values.repetition2 ? Number(values.repetition2) : 0,
                values.repetition3 ? Number(values.repetition3) : 0,
                values.repetition4 ? Number(values.repetition4) : 0,
                values.repetition5 ? Number(values.repetition5) : 0,
                values.repetitionSetB ? Number(values.repetitionSetB) : 0,
                values.repetition6 ? Number(values.repetition6) : 0,
                values.repetitionAfter30minutes ? Number(values.repetitionAfter30minutes) : 0
              ],
              sightDisability: values.sightDisability,
              token,
              ciq: values.ciq,
              leftEye: values.leftEye,
              niq: values.niq,
              rightEye: values.rightEye,
              viq: values.viq
            });
          }}
        >
          {values => (
            <Form>
              {wasSubmitted && populated(values.errors) == null && <DiagnosticErrorLabel />}
              {data.t95Diagnostics?.instructions && (
                <Box my={3}>
                  <Typography variant="h5">Inštrukcie</Typography>
                  <Box>
                    <Typography
                      component="p"
                      variant="body2"
                      dangerouslySetInnerHTML={{
                        __html: data.t95Diagnostics.instructions
                      }}
                    />
                  </Box>
                </Box>
              )}

              <Paper style={{ marginBottom: '40px', padding: '20px' }}>
                <Fields>
                  <RadioField
                    row
                    label="Poruchy sluchu"
                    name="hearingDisability"
                    options={[
                      {
                        label: ' áno',
                        value: true
                      },
                      {
                        label: 'nie',
                        value: false
                      }
                    ]}
                  />
                  <Grid container direction="row" spacing={3}>
                    <Grid item xs={2}>
                      <RadioField
                        row
                        label="Poruchy zraku"
                        name="sightDisability"
                        options={[
                          {
                            label: ' áno',
                            value: true
                          },
                          {
                            label: 'nie',
                            value: false
                          }
                        ]}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField type="number" label="Počet dioptrií Ľ" name="leftEye" />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField type="number" label="Počet dioptrií P" name="rightEye" />
                    </Grid>
                  </Grid>
                  {data.t95Diagnostics && (
                    <RadioField
                      row
                      label="Koncentrácia pozornosti pri vyšetrení"
                      name="attention"
                      options={data.t95Diagnostics?.attentionOptions}
                    />
                  )}
                  <Typography variant="body1">Momentálna medikácia</Typography>
                  <Grid container direction="row" spacing={3}>
                    <Grid item xs={4}>
                      <TextField label="CIQ" name="ciq" />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField label="VIQ" name="viq" />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField label="NIQ" name="niq" />
                    </Grid>
                  </Grid>
                </Fields>
              </Paper>
              <Paper style={{ marginBottom: '40px' }}>
                {data.t95Diagnostics?.questions.map((item, index) => {
                  return (
                    <Box display="flex" key={index}>
                      {item.columns.map((question, index) => {
                        return (
                          <Grid
                            key={index}
                            container
                            alignItems="center"
                            style={{
                              paddingLeft: '15px',
                              borderBottom: '1px solid rgba(224, 224, 224, 1)'
                            }}
                          >
                            {question.__typename === 'T95Label' ? (
                              <Grid item xs={2}>
                                <Typography variant="body2" key={index}>
                                  <strong>{question.label}</strong>
                                </Typography>
                              </Grid>
                            ) : (
                              <Grid item xs={2}>
                                <RadioField
                                  key={index}
                                  row
                                  name={`answer-${question.id}`}
                                  options={question.options}
                                  disabled={isDemo || isReadOnly}
                                />
                              </Grid>
                            )}
                          </Grid>
                        );
                      })}
                    </Box>
                  );
                })}

                <Box p={2}>
                  <Box mt={2} mb={2}>
                    <Box mb={2}>
                      <Typography variant="body1">Počet opakovaní</Typography>
                    </Box>
                    <Grid container direction="row" spacing={1}>
                      {[...Array(5)].map((_, index) => (
                        <Grid item xs={2} key={`repetition${index}`}>
                          <TextField
                            type="text"
                            name={`repetition${index + 1}`}
                            label={`${index + 1}. pokus`}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                    <Box mt={2}>
                      <Grid container direction="row" spacing={1}>
                        <Grid item xs={2}>
                          <TextField
                            type="text"
                            name="repetitionSetB"
                            label="sada B"
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            type="text"
                            name="repetition6"
                            label="6. pokus"
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            type="text"
                            name="repetitionAfter30minutes"
                            label="po 30 minútach"
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                  <Box mb={2}>
                    <Typography variant="body1">Konfabulácia</Typography>
                  </Box>
                  <Box mt={2} mb={2}>
                    <Grid container direction="row" spacing={1}>
                      {[...Array(5)].map((_, index) => (
                        <Grid item xs={2} key={`confabulation${index}`}>
                          <TextField
                            type="text"
                            name={`confabulation${index + 1}`}
                            label={`${index + 1}. pokus`}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                    <Box mt={2}>
                      <Grid container direction="row" spacing={1}>
                        <Grid item xs={2}>
                          <TextField
                            type="text"
                            name="confabulationSetB"
                            label="sada B"
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            type="text"
                            name="confabulation6"
                            label="6. pokus"
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            type="text"
                            name="confabulationAfter30minutes"
                            label="po 30 minútach"
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                  <TextField
                    fullWidth
                    label="Ďalšie postrehy z priebehu testovania"
                    name="notes"
                    multiline
                    minRows={20}
                  />
                </Box>
              </Paper>
              <Box my={3} textAlign="right">
                <Button
                  onClick={() => scrollToTopOnFormError(values.errors, setWasSubmitted)}
                  variant="contained"
                  type="submit"
                  size="large"
                  color="primary"
                  style={{ marginLeft: '10px' }}
                  disabled={isDemo || isReadOnly}
                >
                  Vyhodnotiť
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </PaperContent>
  );
}
