import { populated, randomStr } from '@everlutionsk/helpers';
import { FormikErrors } from 'formik';
import { Dispatch, useState } from 'react';

export function scrollToTopOnFormError(
  errors: FormikErrors<any>,
  setSubmitted: Dispatch<React.SetStateAction<boolean>>
): void {
  setSubmitted(true);
  if (populated(errors)) {
    window.scrollTo(0, 0);
  }
}

export function useFixedId(length = 16) {
  const [id] = useState(() => randomStr(length));
  return id;
}
