import React from 'react';
import { useMemoizedField } from '../../../../components/forms/useMemoizedField';
import { T309ChoiceSelector } from './ChoiceSelector';

interface Props {
  readonly question: {
    choices: Array<{ label: string; id: string }>;
    id: string;
    label?: string;
  };
  readonly isReadOnly?: boolean;
}

export function T309ChoiceSelectorField({ question, isReadOnly }: Props) {
  const [{ value }, , { setValue }] = useMemoizedField<string | undefined>(question.id);

  return (
    <T309ChoiceSelector
      initialValue={value}
      options={question.choices.map(choice => ({
        label: choice.label,
        value: choice.id
      }))}
      sentenceText={question.label}
      onChange={setValue}
      disabled={isReadOnly}
    ></T309ChoiceSelector>
  );
}
