import { useField, useFormikContext } from 'formik';
import { useMemo } from 'react';

export function useMemoizedField<T>(name: string) {
  const [field, meta] = useField<T>(name);
  const { setFieldTouched, setFieldValue, setFieldError } = useFormikContext();

  const helpers = useMemo(
    () => ({
      setValue: (value: any, shouldValidate?: boolean) =>
        setFieldValue(field.name, value, shouldValidate),
      setTouched: (isTouched: boolean, shouldValidate?: boolean) =>
        setFieldTouched(field.name, isTouched, shouldValidate),
      setError: (message: string) => setFieldError(field.name, message)
    }),
    [field.name, setFieldTouched, setFieldValue, setFieldError]
  );

  return [field, meta, helpers] as const;
}
