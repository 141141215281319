import { Box, Typography } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material';
import { T309ChoiceSelector } from '../components/ChoiceSelector';

export function Instructions() {
  const choices1 = [
    { value: 'i-3-1', label: 'čerešna' },
    { value: 'i-3-2', label: 'slivka' },
    { value: 'i-3-3', label: 'tulipán' },
    { value: 'i-3-4', label: 'jablko' },
    { value: 'i-3-5', label: 'hruška' }
  ];
  const choices2 = [
    { value: 'i-4-1', label: 'kladivo' },
    { value: 'i-4-2', label: 'varecha' },
    { value: 'i-4-3', label: 'pilník' },
    { value: 'i-4-4', label: 'kliešte' },
    { value: 'i-4-5', label: 'skrutkovač' }
  ];

  return (
    <>
      <Box my={3}>
        <Typography component="h5" variant="h5">
          Hľadanie spoločných znakov - inštrukcie
        </Typography>
      </Box>
      <Box my={3}>
        <Typography component="p" variant="body2">
          V nasledujúcej skupine úloh je uvedených vždy päť slov. Štyri z nich majú niečo spoločné.
          Vašou úlohou je nájsť piate slovo, ktoré sa k ostatným štyrom nehodí.
        </Typography>
      </Box>
      <Box my={3}>
        <Typography component="p" variant="body2">
          Príklad:
        </Typography>
      </Box>
      <TableDiv my={3} display="flex">
        <Box className="cell">
          <Typography component="h5" variant="h5">
            B3.
          </Typography>
        </Box>
        <Box className="cell">
          <T309ChoiceSelector
            initialValue="i-3-3"
            options={choices1}
            disabled
            onChange={() => {}}
          ></T309ChoiceSelector>
        </Box>
      </TableDiv>
      <Box mb={3} mt={1}>
        <Typography component="p" variant="body2">
          A, B, D a E sú druhy ovocia; C nie je ovocie, ale druh kvetiny. Preto je vo Vašom
          odpoveďovom hárku označené v príklade B 3 písmeno <span>C</span>.
        </Typography>
      </Box>
      <Box mb={3} mt={5}>
        <Typography component="p" variant="body2">
          Ďalší príklad:
        </Typography>
      </Box>
      <TableDiv my={3} display="flex">
        <Box className="cell">
          <Typography component="h5" variant="h5">
            B4.
          </Typography>
        </Box>
        <Box className="cell">
          <T309ChoiceSelector
            initialValue="i-4-2"
            options={choices2}
            disabled
            onChange={() => {}}
          ></T309ChoiceSelector>
        </Box>
      </TableDiv>
      <Box my={3}>
        <Typography component="p" variant="body2">
          A, C, D a E sú nástroje, B nie je nástroj. Správnym riešením je preto písmeno{' '}
          <span>B</span>.
        </Typography>
      </Box>
      <Box my={3}>
        <Typography component="p" variant="body2">
          Vonkajšie, resp. formálne spoločné znaky predložených slov (napr. rovnaké počiatočné
          písmená alebo rovnaký počet slabík) nemajú pre riešenie úloh žiadny význam.
        </Typography>
      </Box>
      <Box my={3}>
        <Typography component="p" variant="body2">
          Na riešenie nasledujúcich <strong>12 úloh</strong> máte čas <strong>4 minúty</strong>.{' '}
          <strong>Počkajte na vyzvanie stlačiť tlačidlo ĎALEJ.</strong>
        </Typography>
      </Box>
    </>
  );
}

const TableDiv = styled(Box)`
  border: 1px solid #ccc;
  .cell {
    &:first-child {
      padding: 1rem;
    }
    &:last-child {
      border-left: 1px solid #ccc;
      padding: 1rem 1rem 1rem 1rem;
    }
  }
`;
