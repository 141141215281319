import { Box, Typography } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material';
import { APlusBSelector } from './components/APlusBSelector';

export function Instructions() {
  const choices1 = [
    { value: 'i-3-1', label: 'kancelária' },
    { value: 'i-3-2', label: 'počítač' },
    { value: 'i-3-3', label: 'písací stôl' },
    { value: 'i-3-4', label: 'papier' },
    { value: 'i-3-5', label: 'telefón' },
    { value: 'i-3-6', label: 'sekretárka' }
  ];
  const choices2 = [
    { value: 'i-4-1', label: 'oko' },
    { value: 'i-4-2', label: 'okuliare' },
    { value: 'i-4-3', label: 'nos' },
    { value: 'i-4-4', label: 'žehlička' },
    { value: 'i-4-5', label: 'ucho' },
    { value: 'i-4-6', label: 'lupa' }
  ];

  return (
    <>
      <Box my={3}>
        <Typography component="h5" variant="h5">
          Tvorenie pojmov - inštrukcie
        </Typography>
      </Box>
      <Box my={3}>
        <Typography component="p" variant="body2">
          V tejto skupine úloh Vám predložíme vždy šesť slov. Vašou úlohou bude označiť tie dve z
          nich, ktoré možno zaradiť pod jeden nadradený pojem.
        </Typography>
      </Box>
      <Box my={3}>
        <Typography component="p" variant="body2">
          Príklad:
        </Typography>
      </Box>
      <TableDiv mt={3} display="flex">
        <Box className="cell">
          <Typography component="h5" variant="h5">
            B16.
          </Typography>
        </Box>
        <Box className="cell">
          <APlusBSelector
            initialState={{ a: 'i-3-2', b: 'i-3-5' }}
            disabled
            options={choices1}
            onChange={() => {}}
          ></APlusBSelector>
        </Box>
      </TableDiv>
      <Box mt={2}>
        <Typography component="p" variant="body2">
          V tomto príklade sú riešeniami B a E, pretože „počítač“ a „telefón“ sú technické alebo
          „elektronicky poháňané“ prístroje.
        </Typography>
      </Box>
      <Box mb={3} mt={5}>
        <Typography component="p" variant="body2">
          Ďalší príklad:
        </Typography>
      </Box>
      <TableDiv mt={3} display="flex">
        <Box className="cell">
          <Typography component="h5" variant="h5">
            B17.
          </Typography>
        </Box>
        <Box className="cell">
          <APlusBSelector
            initialState={{ a: 'i-4-2', b: 'i-4-6' }}
            disabled
            options={choices2}
            onChange={() => {}}
          ></APlusBSelector>
        </Box>
      </TableDiv>
      <Box mt={2}>
        <Typography component="p" variant="body2">
          Riešením sú tu písmená B a F, pojem (ktorý nemusíte uviesť, resp. ho poznať) môže znieť
          napr. "pomôcky na videnie” alebo "optické pomôcky”.
        </Typography>
      </Box>
      <Box my={3}>
        <Typography component="p" variant="body2">
          Hoci sa „nos“ a „ucho“ takisto dajú zaradiť pod nadradený pojem („časť tela“, „zmyslový
          orgán“), museli by ste k nim pridať ešte aj „oko“. Takto by ste však nemali dva, ale až
          tri pojmy. Pri tvorení nadradeného pojmu nezohľadňujte žiadne „vonkajšie“ znaky
          -nezačiarknite napr. A a B preto, lebo tieto slová začínajú rovnakým počiatočným písmenom
          ("O").
        </Typography>
      </Box>
      <Box my={3}>
        <Typography component="p" variant="body2">
          Na riešenie nasledujúcich <strong>12 úloh</strong> máte čas <strong>6 minút</strong>.{' '}
          <strong>Počkajte na vyzvanie stlačiť tlačidlo ĎALEJ.</strong>
        </Typography>
      </Box>
    </>
  );
}

const TableDiv = styled(Box)`
  border: 1px solid #ccc;
  .cell {
    &:first-child {
      padding: 1rem;
    }
    &:last-child {
      border-left: 1px solid #ccc;
      padding: 1rem 1rem 1rem 1rem;
    }
  }
`;
