import { gql } from '@apollo/client';
import {
  Box,
  Button,
  Table as MaterialTable,
  MenuItem,
  Paper,
  Select,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import { styled } from '@mui/material';
import { PaperContent } from '../../../../components/PaperContent';
import { SubmitT239Page5Input, T239Page5Fragment } from '../../../../graphql/types';
import { computePercentile, getMaxValue, sum } from '../helpers';

interface Props {
  readonly token: string;
  readonly label: string;
  readonly code: string;
  readonly page: T239Page5Fragment;
  readonly onSubmit: (values: SubmitT239Page5Input) => void;
  readonly onBackClick: () => void;
  readonly isDemo: boolean;
  readonly isReadOnly: boolean;
  readonly answers: Array<{ id: string; value: number }>;
}

export const t239Page5Fragment = gql`
  fragment T239Page5Fragment on T239Page5 {
    id
    label
    header {
      label1
      label2
      label3
    }
    questions {
      id
      label
      defaultValue
      subtest {
        size
        label
      }
    }
    choices {
      value
      label
    }
  }
`;

export function Diagnostic({
  token,
  code,
  label,
  page,
  onSubmit,
  onBackClick,
  isDemo,
  isReadOnly,
  answers
}: Props) {
  const [plo, setPlo] = useState<number[]>(() =>
    page.questions.map(question =>
      isReadOnly
        ? (answers.find(item => item.id === question.id)?.value ?? question.defaultValue)
        : question.defaultValue
    )
  );

  function getPloResults(
    specsArray: Array<{ id: string }>,
    values: number[]
  ): Array<{ id: string; value: number }> {
    return specsArray.map((spec, index) => {
      return {
        id: spec.id,
        value: values[index]
      };
    });
  }

  const maxValueOption = getMaxValue([...page.choices]);

  return (
    <PaperContent title={label} testNumber={code}>
      <div>{page.label}</div>
      <Box mt={{ xs: 3, md: 3 }} mb={{ xs: 3, md: 3 }} position="relative">
        <TableContainer component={Paper}>
          <MaterialTable>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Box>
                    <Typography component="span" variant="h6">
                      {page.header.label1}
                    </Typography>
                  </Box>
                </TableCell>
                <StyledTableCell>
                  <Box>
                    <Typography component="span" variant="h6">
                      {page.header.label2}
                    </Typography>
                  </Box>
                </StyledTableCell>
                <StyledTableCell>
                  <Box>
                    <Typography component="span" variant="h6">
                      {page.header.label3}
                    </Typography>
                  </Box>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {page.questions.map((ploQuestion, index) => (
                <TableRow key={ploQuestion.id}>
                  {ploQuestion.subtest && (
                    <StyledTh rowSpan={ploQuestion.subtest.size}>
                      {ploQuestion.subtest.label}
                    </StyledTh>
                  )}
                  <TableCell>{ploQuestion.label}</TableCell>
                  <TableCell>
                    <Select
                      id={ploQuestion.id}
                      variant="standard"
                      onChange={event => {
                        if (event.target.value != null && typeof event.target.value === 'number') {
                          const { value } = event.target;
                          const mutatedPlo = [...plo];
                          mutatedPlo[index] = value;
                          setPlo(mutatedPlo);
                        }
                      }}
                      disabled={isReadOnly}
                      value={plo[index]}
                    >
                      {page.choices.map((option, i) => {
                        return (
                          <MenuItem value={option.value} key={option.label + i}>
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={2}>
                  <Box>
                    <Typography component="h6" variant="h6">
                      Úspešnosť {computePercentile(plo, maxValueOption)} X HS
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box>
                    <Typography component="h6" variant="h6">
                      {sum(plo)}
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </MaterialTable>
        </TableContainer>
        <Box my={3} textAlign="right">
          <Button size="large" color="secondary" onClick={onBackClick}>
            Krok späť
          </Button>
          <Button
            onClick={() =>
              onSubmit({
                token,
                answers: getPloResults(page.questions, plo)
              })
            }
            variant="contained"
            type="submit"
            size="large"
            color="primary"
            style={{ marginLeft: '10px' }}
          >
            Nasledujúca strana
          </Button>
        </Box>
      </Box>
    </PaperContent>
  );
}

const StyledTableCell = styled(TableCell)`
  background: #eee;
`;

const StyledTh = styled('th')`
  background: #eee;
`;
