const oneOnlyGradeOptions = [{ label: '1.', value: 1 }];

const twoOnlyGradeOptions = [{ label: '2.', value: 2 }];

const oneToTwoGradeOptions = [
  { label: '1.', value: 1 },
  { label: '2.', value: 2 }
];

const threeOnlyGradeOptions = [{ label: '3.', value: 3 }];

const fourOnlyGradeOptions = [{ label: '4.', value: 4 }];

const threeToFourGradeOptions = [
  { label: '3.', value: 3 },
  { label: '4.', value: 4 }
];

const oneToFiveGradeOptions = [
  { label: '1.', value: 1 },
  { label: '2.', value: 2 },
  { label: '3.', value: 3 },
  { label: '4.', value: 4 },
  { label: '5.', value: 5 }
];

const fiveToNineGradeOptions = [
  { label: '5.', value: 5 },
  { label: '6.', value: 6 },
  { label: '7.', value: 7 },
  { label: '8.', value: 8 },
  { label: '9.', value: 9 }
];

export const gradeOptionsMatch = {
  alena: oneOnlyGradeOptions,
  zajacik: twoOnlyGradeOptions,
  'kvocka-a-kocur': threeOnlyGradeOptions,
  kapor: fourOnlyGradeOptions,
  sykorky: threeToFourGradeOptions,
  'o-krtkovi': fiveToNineGradeOptions,
  'jedla-a-dub': fiveToNineGradeOptions,
  latys: fiveToNineGradeOptions,
  eiaoeuay: oneOnlyGradeOptions,
  maciatko: oneToTwoGradeOptions,
  doma: oneToFiveGradeOptions
};
