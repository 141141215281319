/* eslint-disable */

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// DO NOT EDIT THIS FILE!
//
// GENERATED BY @everlutionsk/graphql-types-generator package!
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  Void: void;
  DateTime: string;
};

export type SignInInput = {
  login: Scalars['String'];
  password: Scalars['String'];
};

export type RefreshIdentityInput = {
  refreshToken: Scalars['String'];
};

export type UserGroupManyInput = {
  pagination?: Maybe<PaginationInput>;
};

export type DiagnosticsIdentification202Input = {
  clientIdentifier: Scalars['String'];
  birthMonth: Scalars['Int'];
  birthYear: Scalars['Int'];
  gender: Gender;
  token: Scalars['String'];
  articleId: Scalars['String'];
  elementarySchoolClass: Scalars['Int'];
  postponementOfSchool: Scalars['Boolean'];
  repeatedYearOfSchool: Scalars['Boolean'];
  ratingSlovakLanguage: Scalars['Int'];
  ratingMathematics: Scalars['Int'];
  ratingForeignLanguage?: Maybe<Scalars['Int']>;
  ratingOfReading?: Maybe<ReaderRating>;
  motherEducation?: Maybe<Education>;
  fatherEducation?: Maybe<Education>;
};

export const Gender = {
  male: 'male',
  female: 'female'
} as const;

export type Gender = (typeof Gender)[keyof typeof Gender];
export const ReaderRating = {
  excellent: 'excellent',
  veryGood: 'veryGood',
  average: 'average',
  belowAverage: 'belowAverage',
  deeplyBelowAverage: 'deeplyBelowAverage'
} as const;

export type ReaderRating = (typeof ReaderRating)[keyof typeof ReaderRating];
export const Education = {
  basicIncomplete: 'basicIncomplete',
  basicUnskilled: 'basicUnskilled',
  basicSkilled: 'basicSkilled',
  highSchool: 'highSchool',
  university: 'university'
} as const;

export type Education = (typeof Education)[keyof typeof Education];
export type DiagnosticsIdentificationInput = {
  clientIdentifier: Scalars['String'];
  birthMonth: Scalars['Int'];
  birthYear: Scalars['Int'];
  gender: Gender;
  date: Scalars['Date'];
  token: Scalars['String'];
};

export type SubmitT8V1Input = {
  token: Scalars['String'];
  answersTable1: Array<T8Table1Answer>;
};

export type T8Table1Answer = {
  id: Scalars['String'];
  radioValue: Scalars['Int'];
  answer?: Maybe<Scalars['String']>;
};

export type SubmitT8V2Input = {
  token: Scalars['String'];
  answersTable1: Array<T8Table1Answer>;
  answersTable2: Array<T8Table2Answer>;
};

export type T8Table2Answer = {
  id: Scalars['String'];
  match: Scalars['Boolean'];
  notMatch: Scalars['String'];
};

export type SubmitT21Input = {
  token: Scalars['String'];
  answers: Array<T21Answer>;
};

export type T21Answer = {
  id: Scalars['String'];
  radioValue: Scalars['Int'];
};

export const T41Route = {
  identificationChildrenA: 'identificationChildrenA',
  resultsChildrenA: 'resultsChildrenA',
  identificationAdultsA: 'identificationAdultsA',
  resultsAdultsA: 'resultsAdultsA',
  identificationChildrenB: 'identificationChildrenB',
  resultsChildrenB: 'resultsChildrenB',
  identificationAdultsB: 'identificationAdultsB',
  resultsAdultsB: 'resultsAdultsB'
} as const;

export type T41Route = (typeof T41Route)[keyof typeof T41Route];

export type SubmitT41Input = {
  token: Scalars['String'];
  duration: Scalars['Int'];
  version: T41DiagnosticVersion;
  grade?: Maybe<Scalars['Int']>;
};

export const T41DiagnosticVersion = {
  childrenA: 'childrenA',
  childrenB: 'childrenB',
  adultsA: 'adultsA',
  adultsB: 'adultsB'
} as const;

export type T41DiagnosticVersion = (typeof T41DiagnosticVersion)[keyof typeof T41DiagnosticVersion];

export type SubmitT184V1Input = {
  token: Scalars['String'];
  answersTable: Array<T184TableAnswer>;
  additionalText: Scalars['String'];
};

export type SubmitT184V2Input = {
  token: Scalars['String'];
  answersTable: Array<T184TableAnswer>;
  additionalText: Scalars['String'];
};

export type T184TableAnswer = {
  id: Scalars['String'];
  radioValue: Scalars['Int'];
};

export type SubmitT202Page2Input = {
  token: Scalars['String'];
  wayOfReading: Scalars['Int'];
  readingReproduction: Scalars['Int'];
  readingReproductionScale: Scalars['Int'];
  readingSense: Scalars['Int'];
  readingLocalizationOne: Scalars['String'];
  readingLocalizationTwo: Scalars['String'];
  readingLocalizationThree: Scalars['String'];
  readingLocalizationFour: Scalars['String'];
  readingLocalizationFive: Scalars['String'];
  readingLocalizationSix: Scalars['String'];
  readingLocalizationSeven: Scalars['String'];
  errorShapes: Array<Scalars['Int']>;
  durationEntry?: Maybe<Scalars['Int']>;
};

export type SubmitT202Page1Input = {
  token: Scalars['String'];
  articleId: Scalars['String'];
  firstMinuteWord: Scalars['Int'];
  secondMinuteWord: Scalars['Int'];
  thirdMinuteWord: Scalars['Int'];
  duration: Scalars['Int'];
  mistakes: Array<Scalars['Int']>;
};

export type SubmitT216Input = {
  token: Scalars['String'];
  answers: Array<T216Answer>;
};

export type T216Answer = {
  id: Scalars['String'];
  radioValue: Scalars['Int'];
};

export type DiagnosticsIdentification216Input = {
  token: Scalars['String'];
  clientIdentifier: Scalars['String'];
  birthMonth: Scalars['Int'];
  birthYear: Scalars['Int'];
  gender: Gender;
  ratingSlovakLanguage: Scalars['Int'];
  ratingMaths: Scalars['Int'];
  ratingTechnicalEducation?: Maybe<Scalars['Int']>;
  ratingArtEducation?: Maybe<Scalars['Int']>;
};

export type SubmitT239Page6Input = {
  token: Scalars['String'];
  answers: Array<T239Page6Value>;
};

export type T239Page6Value = {
  id: Scalars['String'];
  crossed: Scalars['Boolean'];
};

export type SubmitT239Page8Input = {
  token: Scalars['String'];
  table1: Array<T239Page8TableValue>;
  table2: Array<T239Page8TableValue>;
  conclusion: T239TextValue;
  answers2: Array<T239TextValue>;
};

export type T239Page8TableValue = {
  id: Scalars['String'];
  crossed: Scalars['Boolean'];
};

export type SubmitT239Page1Input = {
  token: Scalars['String'];
  differentiation: Array<T239ChoiceValue>;
  synthesis: Array<T239ChoiceValue>;
  analysis: Array<T239ChoiceValue>;
};

export type SubmitT239Page2Input = {
  token: Scalars['String'];
  dropdowns: Array<T239ChoiceValue>;
  textInputs: Array<T239TextValue>;
};

export type SubmitT239Page3Input = {
  token: Scalars['String'];
  plhs: Array<T239ChoiceValue>;
  phs: Array<T239ChoiceValue>;
  lhs: Array<T239ChoiceValue>;
};

export type SubmitT239Page4Input = {
  token: Scalars['String'];
  perception: Array<T239Page4PerceptionValue>;
  reproduction: Array<T239ChoiceValue>;
};

export type T239Page4PerceptionValue = {
  id: Scalars['String'];
  value: Scalars['Int'];
  answer: Scalars['String'];
};

export type SubmitT239Page5Input = {
  token: Scalars['String'];
  answers: Array<T239ChoiceValue>;
};

export type T239ChoiceValue = {
  id: Scalars['String'];
  value: Scalars['Int'];
};

export type T239TextValue = {
  id: Scalars['String'];
  value: Scalars['String'];
};

export type SubmitT252Input = {
  token: Scalars['String'];
  answers: Array<T252Answer>;
};

export type T252Answer = {
  id: Scalars['String'];
  choices: Array<T252AnswerChoice>;
};

export type T252AnswerChoice = {
  id: Scalars['String'];
  value: Scalars['Int'];
};

export type DiagnosticsIdentification184Input = {
  token: Scalars['String'];
  clientIdentifier: Scalars['String'];
  birthMonth: Scalars['Int'];
  birthYear: Scalars['Int'];
  gender: Gender;
  subject: Scalars['String'];
};

export type SubmitT309Page1Input = {
  token: Scalars['String'];
  answers: Array<T309Page1Answer>;
};

export type T309Page1Answer = {
  id: Scalars['String'];
  answer: Scalars['String'];
};

export type SubmitT309Page2Input = {
  token: Scalars['String'];
  answers: Array<T309Page2Answer>;
};

export type T309Page2Answer = {
  id: Scalars['String'];
  answer: Scalars['String'];
};

export type SubmitT309Page3Input = {
  token: Scalars['String'];
};

export type SubmitT309Page4Input = {
  token: Scalars['String'];
  answers: Array<T309Page4Answer>;
};

export type T309Page4Answer = {
  id: Scalars['String'];
  answer: Scalars['Int'];
};

export type SubmitT309Page5Input = {
  token: Scalars['String'];
  answers: Array<T309Page5Answer>;
};

export type T309Page5Answer = {
  id: Scalars['String'];
  answer: Scalars['String'];
};

export type SubmitT309Page6Input = {
  token: Scalars['String'];
  answers: Array<T309Page6Answer>;
};

export type T309Page6Answer = {
  id: Scalars['String'];
  answer: Scalars['String'];
};

export type SubmitT309Page7Input = {
  token: Scalars['String'];
  answers: Array<T309Page7Answer>;
};

export type T309Page7Answer = {
  id: Scalars['String'];
  answer: Scalars['String'];
};

export type SubmitT309Page8Input = {
  token: Scalars['String'];
  answers: Array<T309Page8Answer>;
};

export type T309Page8Answer = {
  id: Scalars['String'];
  answer: Scalars['Int'];
};

export type SubmitT309Page9Input = {
  token: Scalars['String'];
  answers: Array<T309Page9Answer>;
};

export type T309Page9Answer = {
  id: Scalars['String'];
  a: Scalars['String'];
  b: Scalars['String'];
};

export type SubmitT309Page10Input = {
  token: Scalars['String'];
  answers: Array<T309Page10Answer>;
};

export type T309Page10Answer = {
  id: Scalars['String'];
  answer: Scalars['String'];
};

export type SubmitOtprInput = {
  token: Scalars['String'];
  version: Scalars['String'];
  answers1: Array<OtprAnswer>;
  answers2: Array<OtprAnswer>;
  answers3: Array<OtprAnswer>;
  answers4: Array<OtprAnswer>;
  answers5: Array<OtprAnswer>;
  interrupted?: Maybe<Scalars['Boolean']>;
  interruptionReason?: Maybe<Scalars['String']>;
  ended?: Maybe<Scalars['Boolean']>;
  endingReason?: Maybe<Scalars['String']>;
  downgraded?: Maybe<Scalars['Boolean']>;
  communication?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type OtprAnswer = {
  id: Scalars['String'];
  radioValue: Scalars['Int'];
};

export type SubmitT95Input = {
  token: Scalars['String'];
  answers: Array<T95Answer>;
  sightDisability: Scalars['Boolean'];
  leftEye?: Maybe<Scalars['String']>;
  rightEye?: Maybe<Scalars['String']>;
  hearingDisability: Scalars['Boolean'];
  attention: T95Answer;
  ciq?: Maybe<Scalars['String']>;
  viq?: Maybe<Scalars['String']>;
  niq?: Maybe<Scalars['String']>;
  repetitions: Array<Scalars['Int']>;
  confabulations: Array<Scalars['Int']>;
  notes: Scalars['String'];
};

export type T95Answer = {
  id: Scalars['String'];
  radioValue: Scalars['Int'];
};

export type SubmitSmkzdInput = {
  token: Scalars['String'];
  answers: Array<SmkzdAnswer>;
};

export type SmkzdAnswer = {
  id: Scalars['String'];
  radioValue: Scalars['Int'];
};

export type PaginationInput = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type StudentFilterInput = {
  term?: Maybe<Scalars['String']>;
  pagination?: Maybe<PaginationInput>;
  sort?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  status?: Maybe<StudentStatus>;
};

export const StudentStatus = {
  active: 'active',
  inactive: 'inactive'
} as const;

export type StudentStatus = (typeof StudentStatus)[keyof typeof StudentStatus];

export type AddStudentInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  gender: Gender;
  dateOfBirth: Scalars['String'];
  birthIdentificationNumber: Scalars['String'];
  school: Scalars['String'];
  line1: Scalars['String'];
  postCode: Scalars['String'];
  city: Scalars['String'];
  fullName: Scalars['String'];
  phoneNumber: Scalars['String'];
  email: Scalars['String'];
  userId?: Maybe<Scalars['ID']>;
  language: Scalars['String'];
};

export type EditStudentInput = {
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  dateOfBirth: Scalars['String'];
  birthIdentificationNumber: Scalars['String'];
  school: Scalars['String'];
  line1: Scalars['String'];
  postCode: Scalars['String'];
  city: Scalars['String'];
  fullName: Scalars['String'];
  phoneNumber: Scalars['String'];
  email: Scalars['String'];
  gender?: Maybe<Gender>;
  grade?: Maybe<Scalars['Int']>;
  postponement?: Maybe<Scalars['Int']>;
  repeatedGrade?: Maybe<Scalars['Int']>;
  ratingSlovakLanguage?: Maybe<Scalars['Int']>;
  ratingMaths?: Maybe<Scalars['Int']>;
  ratingForeignLanguage?: Maybe<Scalars['Int']>;
  ratingArtEducation?: Maybe<Scalars['Int']>;
  ratingTechnicalEducation?: Maybe<Scalars['Int']>;
  readingEvaluation?: Maybe<Scalars['Int']>;
  motherEducation?: Maybe<Scalars['Int']>;
  fatherEducation?: Maybe<Scalars['Int']>;
  otherData?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
};

export type EditStudentDiagnosisInput = {
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type DiagnosticFilterInput = {
  term?: Maybe<Scalars['String']>;
  pagination?: Maybe<PaginationInput>;
  sort?: Maybe<Scalars['String']>;
};

export const DiagnosticForm = {
  individual: 'individual',
  remote: 'remote',
  group: 'group'
} as const;

export type DiagnosticForm = (typeof DiagnosticForm)[keyof typeof DiagnosticForm];
export type TestingInput = {
  code: Scalars['String'];
  studentId: Scalars['ID'];
  expiresAt?: Maybe<Scalars['String']>;
};

export type TestingFilterInput = {
  term?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  studentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  pagination?: Maybe<PaginationInput>;
  sort?: Maybe<Scalars['String']>;
  groupTestingId?: Maybe<Scalars['String']>;
  status?: Maybe<TestingStatus>;
};

export const TestingStatus = {
  ready: 'ready',
  inProgress: 'inProgress',
  finished: 'finished',
  expired: 'expired',
  readonly: 'readonly'
} as const;

export type TestingStatus = (typeof TestingStatus)[keyof typeof TestingStatus];

export type NewGroupTestingInput = {
  code: Scalars['String'];
  groupName: Scalars['String'];
  expiresAt: Scalars['String'];
  studentIds: Array<Scalars['ID']>;
};

export type SelectedGroupTestingInput = {
  code: Scalars['String'];
  groupId: Scalars['String'];
  expiresAt: Scalars['String'];
};

export const UserStatus = {
  active: 'active',
  inactive: 'inactive',
  deleted: 'deleted',
  requested: 'requested',
  onHold: 'onHold',
  rejected: 'rejected'
} as const;

export type UserStatus = (typeof UserStatus)[keyof typeof UserStatus];
export const Role = {
  superAdmin: 'superAdmin',
  admin: 'admin',
  psychologist: 'psychologist',
  analyst: 'analyst',
  owner: 'owner'
} as const;

export type Role = (typeof Role)[keyof typeof Role];
export type InviteInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  organisationId?: Maybe<Scalars['String']>;
  position: Array<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  role: Role;
};

export type AcceptInvitationInput = {
  password: Scalars['String'];
  email: Scalars['String'];
  token: Scalars['String'];
};

export type UserFilterInput = {
  term?: Maybe<Scalars['String']>;
  pagination?: Maybe<PaginationInput>;
  sort?: Maybe<Scalars['String']>;
  status?: Maybe<UserStatus>;
  withoutUserGroup?: Maybe<Scalars['Boolean']>;
  hasRequestedPosition?: Maybe<Scalars['Boolean']>;
  requestedChanges?: Maybe<Scalars['Boolean']>;
  standardisation?: Maybe<Scalars['Boolean']>;
};

export type EditUserInput = {
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  organisationId?: Maybe<Scalars['String']>;
  position?: Maybe<Array<Scalars['String']>>;
  phoneNumber?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
};

export type RequestedUserChangesInput = {
  id: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type DemoTestingInput = {
  code: Scalars['String'];
};

export type SendTestingInput = {
  to: Scalars['String'];
  from: Scalars['String'];
  message: Scalars['String'];
};

export type AddGroupInput = {
  name: Scalars['String'];
  studentIds: Array<Scalars['ID']>;
};

export type GroupFilterInput = {
  term?: Maybe<Scalars['String']>;
  pagination?: Maybe<PaginationInput>;
  sort?: Maybe<Scalars['String']>;
};

export type UpdateStudentByTokenInput = {
  token: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  birthMonth?: Maybe<Scalars['Int']>;
  birthYear?: Maybe<Scalars['Int']>;
  gender?: Maybe<Gender>;
  date?: Maybe<Scalars['Date']>;
  grade?: Maybe<Scalars['Int']>;
  education?: Maybe<Scalars['String']>;
  postponement?: Maybe<Scalars['Int']>;
  repeatedGrade?: Maybe<Scalars['Int']>;
  ratingSlovakLanguage?: Maybe<Scalars['Int']>;
  ratingMaths?: Maybe<Scalars['Int']>;
  ratingForeignLanguage?: Maybe<Scalars['Int']>;
  ratingArtEducation?: Maybe<Scalars['Int']>;
  ratingTechnicalEducation?: Maybe<Scalars['Int']>;
  readingEvaluation?: Maybe<Scalars['Int']>;
  motherEducation?: Maybe<Scalars['Int']>;
  fatherEducation?: Maybe<Scalars['Int']>;
  t184subject?: Maybe<Scalars['String']>;
};

export type CompleteResetPasswordInput = {
  email: Scalars['String'];
  token: Scalars['String'];
  newPassword: Scalars['String'];
};

export type ManualManyInput = {
  limit: Scalars['Int'];
  code: Scalars['String'];
};

export type AcceptOrganisationChangesInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
};

export type RequestedOrganisationChangesInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
};

export type AddOrganisationInput = {
  name: Scalars['String'];
  line1: Scalars['String'];
  city: Scalars['String'];
  region: Scalars['String'];
  district: Scalars['String'];
};

export type OrganisationFilterInput = {
  term?: Maybe<Scalars['String']>;
  pagination?: Maybe<PaginationInput>;
  status?: Maybe<OrganisationStatus>;
  requestedChanges?: Maybe<Scalars['Boolean']>;
  withoutUserGroup?: Maybe<Scalars['Boolean']>;
};

export type EditOrganisationInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  status: OrganisationStatus;
  line1: Scalars['String'];
  city: Scalars['String'];
  region: Scalars['String'];
  district: Scalars['String'];
};

export const OrganisationStatus = {
  enable: 'enable',
  disabled: 'disabled'
} as const;

export type OrganisationStatus = (typeof OrganisationStatus)[keyof typeof OrganisationStatus];

export type UserRequestCreateInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  position: Array<Scalars['String']>;
  phoneNumber: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  role: Role;
  organisationId: Scalars['String'];
};

export type UserGroupInput = {
  name: Scalars['String'];
  organisationIds: Array<Scalars['String']>;
  userIds: Array<Scalars['String']>;
  videoIds: Array<Scalars['String']>;
  testIds: Array<Scalars['String']>;
};

export type PositionGroupInput = {
  id: Scalars['ID'];
  videoIds: Array<Scalars['String']>;
  testIds: Array<Scalars['String']>;
};

export const UserOrderBy = {
  fullNameAsc: 'fullNameAsc',
  fullNameDesc: 'fullNameDesc',
  createdAtAsc: 'createdAtAsc',
  createdAtDesc: 'createdAtDesc',
  organisationAsc: 'organisationAsc',
  organisationDesc: 'organisationDesc',
  statusAsc: 'statusAsc',
  statusDesc: 'statusDesc'
} as const;

export type UserOrderBy = (typeof UserOrderBy)[keyof typeof UserOrderBy];
export const OrganisationOrderBy = {
  nameAsc: 'nameAsc',
  nameDesc: 'nameDesc',
  regionAsc: 'regionAsc',
  regionDesc: 'regionDesc',
  districtAsc: 'districtAsc',
  districtDesc: 'districtDesc'
} as const;

export type OrganisationOrderBy = (typeof OrganisationOrderBy)[keyof typeof OrganisationOrderBy];

export type ChangePasswordInput = {
  password: Scalars['String'];
  newPassword: Scalars['String'];
};

export const StandardisationRole = {
  coordinator: 'coordinator',
  analyst: 'analyst'
} as const;

export type StandardisationRole = (typeof StandardisationRole)[keyof typeof StandardisationRole];

export type StandardisationAccessInput = {
  userId: Scalars['ID'];
  role?: Maybe<StandardisationRole>;
};

export type SubmitIdentificationMutationVariables = Exact<{
  input: DiagnosticsIdentificationInput;
}>;

export type SubmitIdentificationMutation = {
  diagnosticsIdentification: {
    __typename: 'DiagnosticsIdentity';
    clientIdentifier: string;
    birthMonth: number;
    birthYear: number;
    gender: Gender;
    date: any;
  };
};

export type StudentOneByTokenQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type StudentOneByTokenQuery = {
  studentOneByToken: {
    __typename: 'Student';
    id: string;
    birthIdentificationNumber?: Maybe<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    dateOfBirth?: Maybe<any>;
    gender?: Maybe<Gender>;
  };
};

export type SubmitOtprIdentificationMutationVariables = Exact<{
  input: UpdateStudentByTokenInput;
}>;

export type SubmitOtprIdentificationMutation = {
  updateStudentByToken: {
    __typename: 'Student';
    id: string;
    birthIdentificationNumber?: Maybe<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    dateOfBirth?: Maybe<any>;
    gender?: Maybe<Gender>;
  };
};

export type OtprStudentOneByTokenQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type OtprStudentOneByTokenQuery = {
  studentOneByToken: {
    __typename: 'Student';
    id: string;
    birthIdentificationNumber?: Maybe<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    dateOfBirth?: Maybe<any>;
    gender?: Maybe<Gender>;
  };
};

export type OtprDiagnosticQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type OtprDiagnosticQuery = {
  otprDiagnostics?: Maybe<{
    __typename: 'Otpr';
    isValid: boolean;
    isDemo: boolean;
    code: string;
    label: string;
    status: TestingStatus;
    originalToken?: Maybe<string>;
  }>;
};

export type SubmitTestOtprMutationVariables = Exact<{
  input: SubmitOtprInput;
}>;

export type SubmitTestOtprMutation = { submitOtpr: string };

export type OtprV1Fragment = {
  __typename: 'OtprV1';
  instructions1: string;
  instructions2: string;
  instructions3: string;
  interrupted?: Maybe<boolean>;
  interruptionReason?: Maybe<string>;
  ended?: Maybe<boolean>;
  endingReason?: Maybe<string>;
  communication?: Maybe<string>;
  notes?: Maybe<string>;
  questions1: Array<{
    __typename: 'OtprQuestion';
    id: string;
    order: number;
    text: string;
    defaultValue: number;
    options: Array<{ __typename: 'RadioOption'; value: number; label: string }>;
  }>;
  questions2: Array<{
    __typename: 'OtprQuestion';
    id: string;
    order: number;
    text: string;
    defaultValue: number;
    options: Array<{ __typename: 'RadioOption'; value: number; label: string }>;
  }>;
  questions3: Array<{
    __typename: 'OtprQuestion';
    id: string;
    order: number;
    text: string;
    defaultValue: number;
    options: Array<{ __typename: 'RadioOption'; value: number; label: string }>;
  }>;
};

export type OtprDiagnosticV1QueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type OtprDiagnosticV1Query = {
  otprDiagnostics?: Maybe<{
    __typename: 'Otpr';
    isValid: boolean;
    isDemo: boolean;
    code: string;
    label: string;
    status: TestingStatus;
    originalToken?: Maybe<string>;
    v1: {
      __typename: 'OtprV1';
      answers1: Array<{ __typename: 'OtprAnswerMany'; id: string; radioValue: number }>;
      answers2: Array<{ __typename: 'OtprAnswerMany'; id: string; radioValue: number }>;
      answers3: Array<{ __typename: 'OtprAnswerMany'; id: string; radioValue: number }>;
    } & OtprV1Fragment;
  }>;
};

export type OtprV2Fragment = {
  __typename: 'OtprV2';
  instructions1: string;
  instructions2: string;
  instructions3: string;
  interrupted?: Maybe<boolean>;
  interruptionReason?: Maybe<string>;
  ended?: Maybe<boolean>;
  endingReason?: Maybe<string>;
  downgraded?: Maybe<boolean>;
  communication?: Maybe<string>;
  notes?: Maybe<string>;
  questions1: Array<{
    __typename: 'OtprQuestion';
    id: string;
    order: number;
    text: string;
    defaultValue: number;
    options: Array<{ __typename: 'RadioOption'; value: number; label: string }>;
  }>;
  questions2: Array<{
    __typename: 'OtprQuestion';
    id: string;
    order: number;
    text: string;
    defaultValue: number;
    options: Array<{ __typename: 'RadioOption'; value: number; label: string }>;
  }>;
  questions3: Array<{
    __typename: 'OtprQuestion';
    id: string;
    order: number;
    text: string;
    defaultValue: number;
    options: Array<{ __typename: 'RadioOption'; value: number; label: string }>;
  }>;
};

export type OtprDiagnosticV2QueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type OtprDiagnosticV2Query = {
  otprDiagnostics?: Maybe<{
    __typename: 'Otpr';
    isValid: boolean;
    isDemo: boolean;
    code: string;
    label: string;
    status: TestingStatus;
    originalToken?: Maybe<string>;
    v2: {
      __typename: 'OtprV2';
      answers1: Array<{ __typename: 'OtprAnswerMany'; id: string; radioValue: number }>;
      answers2: Array<{ __typename: 'OtprAnswerMany'; id: string; radioValue: number }>;
      answers3: Array<{ __typename: 'OtprAnswerMany'; id: string; radioValue: number }>;
    } & OtprV2Fragment;
  }>;
};

export type OtprV3Fragment = {
  __typename: 'OtprV3';
  instructions1: string;
  instructions2: string;
  instructions3: string;
  instructions4: string;
  instructions5: string;
  interrupted?: Maybe<boolean>;
  interruptionReason?: Maybe<string>;
  ended?: Maybe<boolean>;
  endingReason?: Maybe<string>;
  downgraded?: Maybe<boolean>;
  communication?: Maybe<string>;
  notes?: Maybe<string>;
  questions1: Array<{
    __typename: 'OtprQuestion';
    id: string;
    order: number;
    text: string;
    defaultValue: number;
    options: Array<{ __typename: 'RadioOption'; value: number; label: string }>;
  }>;
  questions2: Array<{
    __typename: 'OtprQuestion';
    id: string;
    order: number;
    text: string;
    defaultValue: number;
    options: Array<{ __typename: 'RadioOption'; value: number; label: string }>;
  }>;
  questions3: Array<{
    __typename: 'OtprQuestion';
    id: string;
    order: number;
    text: string;
    defaultValue: number;
    options: Array<{ __typename: 'RadioOption'; value: number; label: string }>;
  }>;
  questions4: Array<{
    __typename: 'OtprQuestion';
    id: string;
    order: number;
    text: string;
    defaultValue: number;
    options: Array<{ __typename: 'RadioOption'; value: number; label: string }>;
  }>;
  questions5: Array<{
    __typename: 'OtprQuestion';
    id: string;
    order: number;
    text: string;
    defaultValue: number;
    options: Array<{ __typename: 'RadioOption'; value: number; label: string }>;
  }>;
};

export type OtprDiagnosticV3QueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type OtprDiagnosticV3Query = {
  otprDiagnostics?: Maybe<{
    __typename: 'Otpr';
    isValid: boolean;
    isDemo: boolean;
    code: string;
    label: string;
    status: TestingStatus;
    originalToken?: Maybe<string>;
    v3: {
      __typename: 'OtprV3';
      answers1: Array<{ __typename: 'OtprAnswerMany'; id: string; radioValue: number }>;
      answers2: Array<{ __typename: 'OtprAnswerMany'; id: string; radioValue: number }>;
      answers3: Array<{ __typename: 'OtprAnswerMany'; id: string; radioValue: number }>;
      answers4: Array<{ __typename: 'OtprAnswerMany'; id: string; radioValue: number }>;
      answers5: Array<{ __typename: 'OtprAnswerMany'; id: string; radioValue: number }>;
    } & OtprV3Fragment;
  }>;
};

export type SmkzdQuestionTableFragment = {
  __typename: 'Smkzd';
  questions: Array<{
    __typename: 'SmkzdQuestion';
    id: string;
    order: number;
    text: string;
    defaultValue: number;
    options: Array<{ __typename: 'RadioOption'; value: number; label: string }>;
  }>;
};

export type SubmitSmkzdIdentificationMutationVariables = Exact<{
  input: UpdateStudentByTokenInput;
}>;

export type SubmitSmkzdIdentificationMutation = {
  updateStudentByToken: {
    __typename: 'Student';
    id: string;
    birthIdentificationNumber?: Maybe<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    dateOfBirth?: Maybe<any>;
    gender?: Maybe<Gender>;
  };
};

export type SmkzdStudentOneByTokenQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type SmkzdStudentOneByTokenQuery = {
  studentOneByToken: {
    __typename: 'Student';
    id: string;
    birthIdentificationNumber?: Maybe<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    dateOfBirth?: Maybe<any>;
    gender?: Maybe<Gender>;
  };
};

export type SmkzdDiagnosticQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type SmkzdDiagnosticQuery = {
  smkzdDiagnostics?: Maybe<
    {
      __typename: 'Smkzd';
      isValid: boolean;
      isDemo: boolean;
      code: string;
      label: string;
      instructions: string;
      status: TestingStatus;
      originalToken?: Maybe<string>;
      answers: Array<{ __typename: 'SmkzdAnswerMany'; id: string; radioValue: number }>;
    } & SmkzdQuestionTableFragment
  >;
};

export type SubmitTestSmkzdMutationVariables = Exact<{
  input: SubmitSmkzdInput;
}>;

export type SubmitTestSmkzdMutation = { submitSmkzd: string };

export type T184TableFragment = {
  __typename: 'T184Version';
  label: string;
  textAreaLabel: string;
  instructions: { __typename: 'T184Instructions' } & T184InstructionsFragment;
  questions: Array<{
    __typename: 'T184Question';
    id: string;
    order: number;
    text: string;
    defaultValue: number;
    options: Array<{ __typename: 'RadioOption'; value: number; label: string }>;
  }>;
};

export type SubmitT184IdentificationMutationVariables = Exact<{
  input: UpdateStudentByTokenInput;
}>;

export type SubmitT184IdentificationMutation = {
  updateStudentByToken: { __typename: 'Student'; id: string; t184subject?: Maybe<string> };
};

export type T184StudentOneByTokenQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type T184StudentOneByTokenQuery = {
  studentOneByToken: { __typename: 'Student'; id: string; t184subject?: Maybe<string> };
};

export type T184AnswerFragment = {
  __typename: 'T184Answer';
  additionalText: string;
  answersTable: Array<{ __typename: 'T184AnswerTable'; id: string; radioValue: number }>;
};

export type T184DiagnosticQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type T184DiagnosticQuery = {
  t184Diagnostics?: Maybe<{
    __typename: 'T184';
    isValid: boolean;
    isDemo: boolean;
    code: string;
    label: string;
    status: TestingStatus;
    originalToken?: Maybe<string>;
    answers?: Maybe<{
      __typename: 'T184AnswerMany';
      v1?: Maybe<{ __typename: 'T184Answer' } & T184AnswerFragment>;
      v2?: Maybe<{ __typename: 'T184Answer' } & T184AnswerFragment>;
    }>;
    v1: { __typename: 'T184Version' } & T184TableFragment;
    v2: { __typename: 'T184Version' } & T184TableFragment;
  }>;
};

export type SubmitTestT184V1MutationVariables = Exact<{
  input: SubmitT184V1Input;
}>;

export type SubmitTestT184V1Mutation = { submitT184V1: string };

export type SubmitTestT184V2MutationVariables = Exact<{
  input: SubmitT184V2Input;
}>;

export type SubmitTestT184V2Mutation = { submitT184V2: string };

export type T184InstructionsFragment = {
  __typename: 'T184Instructions';
  subtitle: string;
  label1: string;
  label2: string;
  label3?: Maybe<string>;
  tableItems: Array<{
    __typename: 'T184InstructionsTableItem';
    order: string;
    label1: string;
    label2: string;
  }>;
};

export type T202ArticleFragment = {
  __typename: 'T202Article';
  id: string;
  order: number;
  title: string;
  label: string;
  description: string;
  wordsCount: number;
  rows: Array<{
    __typename: 'T202ArticleRow';
    length: number;
    words: Array<{ __typename: 'T202ArticleWord'; index: number; value: string }>;
  }>;
  documents: {
    __typename: 'T202Documents';
    printTemplate: { __typename: 'T202Document'; key: string; label: string };
    printControlText: { __typename: 'T202Document'; key: string; label: string };
  };
};

export type T202Page2TableFragment = {
  __typename: 'T202Page2Table';
  title?: Maybe<string>;
  header: { __typename: 'T202Page2Row'; labels: Array<Maybe<string>> };
  body: Array<{ __typename: 'T202Page2Row'; labels: Array<Maybe<string>> }>;
};

export type T202Page2Table4Fragment = {
  __typename: 'T202Page2Table4';
  title: string;
  header: Array<{ __typename: 'T202Page2Table4HeaderItem'; label: string; legend?: Maybe<string> }>;
};

export type T202DiagnosticPage2QueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type T202DiagnosticPage2Query = {
  t202DiagnosticsPage2?: Maybe<{
    __typename: 'T202Page2';
    isValid: boolean;
    isDemo: boolean;
    code: string;
    label: string;
    answers?: Maybe<{
      __typename: 'T202Page2Answer';
      wayOfReading: number;
      readingReproduction: number;
      readingReproductionScale: number;
      readingSense: number;
      readingLocalizationOne: string;
      readingLocalizationTwo: string;
      readingLocalizationThree: string;
      readingLocalizationFour: string;
      readingLocalizationFive: string;
      readingLocalizationSix: string;
      readingLocalizationSeven: string;
      errorShapes: Array<number>;
      durationEntry?: Maybe<number>;
    }>;
    table3: { __typename: 'T202Page2Table' } & T202Page2TableFragment;
    table4: { __typename: 'T202Page2Table4' } & T202Page2Table4Fragment;
  }>;
};

export type SubmitT202IdentificationMutationVariables = Exact<{
  input: UpdateStudentByTokenInput;
}>;

export type SubmitT202IdentificationMutation = {
  updateStudentByToken: {
    __typename: 'Student';
    id: string;
    birthIdentificationNumber?: Maybe<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    dateOfBirth?: Maybe<any>;
    gender?: Maybe<Gender>;
    grade?: Maybe<number>;
    postponement?: Maybe<number>;
    repeatedGrade?: Maybe<number>;
    ratingSlovakLanguage?: Maybe<number>;
    ratingMaths?: Maybe<number>;
    ratingForeignLanguage?: Maybe<number>;
    readingEvaluation?: Maybe<number>;
    motherEducation?: Maybe<number>;
    fatherEducation?: Maybe<number>;
  };
};

export type T202StudentOneByTokenQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type T202StudentOneByTokenQuery = {
  studentOneByToken: {
    __typename: 'Student';
    id: string;
    birthIdentificationNumber?: Maybe<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    dateOfBirth?: Maybe<any>;
    gender?: Maybe<Gender>;
    grade?: Maybe<number>;
    postponement?: Maybe<number>;
    repeatedGrade?: Maybe<number>;
    ratingSlovakLanguage?: Maybe<number>;
    ratingMaths?: Maybe<number>;
    ratingForeignLanguage?: Maybe<number>;
    readingEvaluation?: Maybe<number>;
    motherEducation?: Maybe<number>;
    fatherEducation?: Maybe<number>;
  };
};

export type T202DiagnosticQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type T202DiagnosticQuery = {
  t202Diagnostics?: Maybe<{
    __typename: 'T202';
    isValid: boolean;
    isDemo: boolean;
    code: string;
    label: string;
    labelTop1: string;
    labelTop2: string;
    instructions: string;
    firstInterval: number;
    secondInterval: number;
    thirdInterval: number;
    status: TestingStatus;
    originalToken?: Maybe<string>;
    answers?: Maybe<{
      __typename: 'T202Page1Answer';
      articleId: string;
      firstMinuteWord: number;
      secondMinuteWord: number;
      thirdMinuteWord: number;
      duration: number;
      mistakes: Array<number>;
    }>;
    articles: Array<{ __typename: 'T202Article' } & T202ArticleFragment>;
  }>;
};

export type SubmitT202Page1MutationVariables = Exact<{
  input: SubmitT202Page1Input;
}>;

export type SubmitT202Page1Mutation = { submitT202Page1: string };

export type SubmitT202Page2MutationVariables = Exact<{
  input: SubmitT202Page2Input;
}>;

export type SubmitT202Page2Mutation = { submitT202Page2: string };

export type T21QuestionTableFragment = {
  __typename: 'T21';
  questions: Array<{
    __typename: 'T21Question';
    id: string;
    task: string;
    fields: {
      __typename: 'T21QuestionFields';
      answer: {
        __typename: 'RadioField';
        validation: { __typename: 'Validation'; rules: Array<string> };
        options: Array<{ __typename: 'RadioOption'; value: number; label: string }>;
      };
    };
  }>;
};

export type SubmitT21IdentificationMutationVariables = Exact<{
  input: UpdateStudentByTokenInput;
}>;

export type SubmitT21IdentificationMutation = {
  updateStudentByToken: {
    __typename: 'Student';
    id: string;
    birthIdentificationNumber?: Maybe<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    dateOfBirth?: Maybe<any>;
    gender?: Maybe<Gender>;
  };
};

export type T21StudentOneByTokenQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type T21StudentOneByTokenQuery = {
  studentOneByToken: {
    __typename: 'Student';
    id: string;
    birthIdentificationNumber?: Maybe<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    dateOfBirth?: Maybe<any>;
    gender?: Maybe<Gender>;
  };
};

export type T21DiagnosticQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type T21DiagnosticQuery = {
  t21Diagnostics?: Maybe<
    {
      __typename: 'T21';
      isValid: boolean;
      isDemo: boolean;
      code: string;
      label: string;
      instructions: string;
      status: TestingStatus;
      originalToken?: Maybe<string>;
      answers: Array<{ __typename: 'T21AnswerMany'; id: string; radioValue: number }>;
    } & T21QuestionTableFragment
  >;
};

export type SubmitTestT21MutationVariables = Exact<{
  input: SubmitT21Input;
}>;

export type SubmitTestT21Mutation = { submitT21: string };

export type T216QuestionTableFragment = {
  __typename: 'T216';
  questions: Array<{
    __typename: 'T216Question';
    id: string;
    task: string;
    fields: {
      __typename: 'T216QuestionFields';
      answer: {
        __typename: 'RadioField';
        validation: { __typename: 'Validation'; rules: Array<string> };
        options: Array<{ __typename: 'RadioOption'; value: number; label: string }>;
      };
    };
  }>;
};

export type SubmitTestT216MutationVariables = Exact<{
  input: SubmitT216Input;
}>;

export type SubmitTestT216Mutation = { submitT216: string };

export type SubmitT216IdentificationMutationVariables = Exact<{
  input: UpdateStudentByTokenInput;
}>;

export type SubmitT216IdentificationMutation = {
  updateStudentByToken: {
    __typename: 'Student';
    id: string;
    birthIdentificationNumber?: Maybe<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    dateOfBirth?: Maybe<any>;
    gender?: Maybe<Gender>;
    ratingSlovakLanguage?: Maybe<number>;
    ratingMaths?: Maybe<number>;
    ratingArtEducation?: Maybe<number>;
    ratingTechnicalEducation?: Maybe<number>;
  };
};

export type T216StudentOneByTokenQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type T216StudentOneByTokenQuery = {
  studentOneByToken: {
    __typename: 'Student';
    id: string;
    birthIdentificationNumber?: Maybe<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    dateOfBirth?: Maybe<any>;
    gender?: Maybe<Gender>;
    ratingSlovakLanguage?: Maybe<number>;
    ratingMaths?: Maybe<number>;
    ratingArtEducation?: Maybe<number>;
    ratingTechnicalEducation?: Maybe<number>;
  };
};

export type T216DiagnosticQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type T216DiagnosticQuery = {
  t216Diagnostics?: Maybe<
    {
      __typename: 'T216';
      isValid: boolean;
      isDemo: boolean;
      code: string;
      label: string;
      instructions: string;
      status: TestingStatus;
      originalToken?: Maybe<string>;
      answers: Array<{ __typename: 'T216AnswerMany'; id: string; radioValue: number }>;
    } & T216QuestionTableFragment
  >;
};

export type SubmitT239IdentificationMutationVariables = Exact<{
  input: UpdateStudentByTokenInput;
}>;

export type SubmitT239IdentificationMutation = {
  updateStudentByToken: {
    __typename: 'Student';
    id: string;
    birthIdentificationNumber?: Maybe<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    dateOfBirth?: Maybe<any>;
    gender?: Maybe<Gender>;
    grade?: Maybe<number>;
  };
};

export type T239StudentOneByTokenQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type T239StudentOneByTokenQuery = {
  studentOneByToken: {
    __typename: 'Student';
    id: string;
    birthIdentificationNumber?: Maybe<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    dateOfBirth?: Maybe<any>;
    gender?: Maybe<Gender>;
    grade?: Maybe<number>;
  };
};

export type T239AnswersFragment = {
  __typename: 'T239Answers';
  t239AnswerPage1?: Maybe<{
    __typename: 'T239AnswerPage1';
    differentiation: Array<{ __typename: 'T239NumberAnswer'; id: string; value: number }>;
    synthesis: Array<{ __typename: 'T239NumberAnswer'; id: string; value: number }>;
    analysis: Array<{ __typename: 'T239NumberAnswer'; id: string; value: number }>;
  }>;
  t239AnswerPage2?: Maybe<{
    __typename: 'T239AnswerPage2';
    dropdowns: Array<{ __typename: 'T239NumberAnswer'; id: string; value: number }>;
    textInputs: Array<{ __typename: 'T239StringAnswer'; id: string; value: string }>;
  }>;
  t239AnswerPage3?: Maybe<{
    __typename: 'T239AnswerPage3';
    plhs: Array<{ __typename: 'T239NumberAnswer'; id: string; value: number }>;
    phs: Array<{ __typename: 'T239NumberAnswer'; id: string; value: number }>;
    lhs: Array<{ __typename: 'T239NumberAnswer'; id: string; value: number }>;
  }>;
  t239AnswerPage4?: Maybe<{
    __typename: 'T239AnswerPage4';
    perception: Array<{ __typename: 'T239Answer'; id: string; value: number; answer: string }>;
    reproduction: Array<{ __typename: 'T239NumberAnswer'; id: string; value: number }>;
  }>;
  t239AnswerPage5?: Maybe<{
    __typename: 'T239AnswerPage5';
    answers: Array<{ __typename: 'T239NumberAnswer'; id: string; value: number }>;
  }>;
  t239AnswerPage6?: Maybe<{
    __typename: 'T239AnswerPage6';
    answers: Array<{ __typename: 'T239BooleanAnswer'; id: string; crossed: boolean }>;
  }>;
  t239AnswerPage8?: Maybe<{
    __typename: 'T239AnswerPage8';
    table1: Array<{ __typename: 'T239BooleanAnswer'; id: string; crossed: boolean }>;
    table2: Array<{ __typename: 'T239BooleanAnswer'; id: string; crossed: boolean }>;
    conclusion: { __typename: 'T239StringAnswer'; id: string; value: string };
    answers2: Array<{ __typename: 'T239StringAnswer'; id: string; value: string }>;
  }>;
};

export type T239DiagnosticQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type T239DiagnosticQuery = {
  t239Diagnostics?: Maybe<{
    __typename: 'T239';
    isValid: boolean;
    isDemo: boolean;
    code: string;
    label: string;
    status: TestingStatus;
    originalToken?: Maybe<string>;
    page1: { __typename: 'T239Page1' } & T239Page1Fragment;
    page2: { __typename: 'T239Page2' } & T239Page2Fragment;
    page3: { __typename: 'T239Page3' } & T239Page3Fragment;
    page4: { __typename: 'T239Page4' } & T239Page4Fragment;
    page5: { __typename: 'T239Page5' } & T239Page5Fragment;
    page6: { __typename: 'T239Page6' } & T239Page6Fragment;
    page7: { __typename: 'T239Page7' } & T239Page7Fragment;
    page8: { __typename: 'T239Page8' } & T239Page8Fragment;
    answers?: Maybe<{ __typename: 'T239Answers' } & T239AnswersFragment>;
  }>;
};

export type SubmitT239Page1MutationVariables = Exact<{
  input: SubmitT239Page1Input;
}>;

export type SubmitT239Page1Mutation = { submitT239Page1: string };

export type SubmitT239Page2MutationVariables = Exact<{
  input: SubmitT239Page2Input;
}>;

export type SubmitT239Page2Mutation = { submitT239Page2: string };

export type SubmitT239Page3MutationVariables = Exact<{
  input: SubmitT239Page3Input;
}>;

export type SubmitT239Page3Mutation = { submitT239Page3: string };

export type SubmitT239Page4MutationVariables = Exact<{
  input: SubmitT239Page4Input;
}>;

export type SubmitT239Page4Mutation = { submitT239Page4: string };

export type SubmitT239Page5MutationVariables = Exact<{
  input: SubmitT239Page5Input;
}>;

export type SubmitT239Page5Mutation = { submitT239Page5: string };

export type SubmitT239Page6MutationVariables = Exact<{
  input: SubmitT239Page6Input;
}>;

export type SubmitT239Page6Mutation = { submitT239Page6: string };

export type SubmitT239Page8MutationVariables = Exact<{
  input: SubmitT239Page8Input;
}>;

export type SubmitT239Page8Mutation = { submitT239Page8: string };

export type T239Page1Fragment = {
  __typename: 'T239Page1';
  id: string;
  label: string;
  header1: {
    __typename: 'T239TableHeader1';
    label1: string;
    label2: string;
    label3: string;
    label4: string;
    label5: string;
  };
  header2: {
    __typename: 'T239TableHeader2';
    label1: string;
    label2: string;
    label3: string;
    label4: string;
    label5: string;
    label6: string;
    label7: string;
  };
  questions: {
    __typename: 'T239Page1Questions';
    differentiation: Array<{
      __typename: 'T239SelectQuestion';
      id: string;
      defaultValue: number;
      label: string;
    }>;
    synthesis: Array<{
      __typename: 'T239SelectQuestion';
      id: string;
      defaultValue: number;
      label: string;
    }>;
    analysis: Array<{
      __typename: 'T239SelectQuestion';
      id: string;
      defaultValue: number;
      label: string;
    }>;
  };
  choices: Array<{ __typename: 'RadioOption'; value: number; label: string }>;
};

export type T239Page2Fragment = {
  __typename: 'T239Page2';
  id: string;
  label: string;
  questions: {
    __typename: 'T239Page2Questions';
    dropdowns: Array<{
      __typename: 'T239SelectQuestion';
      id: string;
      defaultValue: number;
      label: string;
    }>;
    textInputs: Array<{ __typename: 'T239Page2Question'; id: string; label: string }>;
  };
  header: { __typename: 'T239Page2Header'; label1: string; label2: string };
  choices: Array<{ __typename: 'RadioOption'; value: number; label: string }>;
};

export type T239Page3Fragment = {
  __typename: 'T239Page3';
  id: string;
  label: string;
  header: {
    __typename: 'T239Page3Header';
    label1: string;
    label2: string;
    label3: string;
    label4: string;
  };
  questions: {
    __typename: 'T239Page3Questions';
    labels: Array<string>;
    plhs: Array<{ __typename: 'T239Page3Question'; id: string; defaultValue: number }>;
    phs: Array<{ __typename: 'T239Page3Question'; id: string; defaultValue: number }>;
    lhs: Array<{ __typename: 'T239Page3Question'; id: string; defaultValue: number }>;
  };
  choices: Array<{ __typename: 'RadioOption'; value: number; label: string }>;
};

export type T239Page4Fragment = {
  __typename: 'T239Page4';
  id: string;
  label: string;
  header: {
    __typename: 'T239Page4Header';
    label1: string;
    label2: string;
    label3: string;
    label4: string;
    label5: string;
  };
  questions: {
    __typename: 'T239Page4Questions';
    perception: Array<{
      __typename: 'T239Page4Question';
      id: string;
      label: string;
      defaultValue: number;
    }>;
    reproduction: Array<{
      __typename: 'T239Page4Question';
      id: string;
      label: string;
      defaultValue: number;
    }>;
  };
  choices: Array<{ __typename: 'RadioOption'; value: number; label: string }>;
};

export type T239Page5Fragment = {
  __typename: 'T239Page5';
  id: string;
  label: string;
  header: { __typename: 'T239Page5Header'; label1: string; label2: string; label3: string };
  questions: Array<{
    __typename: 'T239Page5Question';
    id: string;
    label: string;
    defaultValue: number;
    subtest?: Maybe<{ __typename: 'T239Page5Subtest'; size: number; label: string }>;
  }>;
  choices: Array<{ __typename: 'RadioOption'; value: number; label: string }>;
};

export type T239Page6Fragment = {
  __typename: 'T239Page6';
  id: string;
  label: string;
  subtitle: string;
  options: Array<{ __typename: 'T239Page6Option'; id: string; img: string }>;
};

export type T239Page7Fragment = {
  __typename: 'T239Page7';
  id: string;
  label: string;
  items: Array<{
    __typename: 'T239Page7Item';
    label1: string;
    label2: string;
    labelPrint: string;
    document: string;
  }>;
};

export type T239Page8TableFragment = {
  __typename: 'T239Page8Table';
  topLabels: Array<{ __typename: 'T239Page8TableLabel'; label: string; colSpan: number }>;
  bottomLabels: Array<{ __typename: 'T239Page8TableLabel'; label: string; colSpan: number }>;
  rows: Array<{
    __typename: 'T239Page8TableRow';
    label: string;
    options: Array<{
      __typename: 'T239Page8TableOption';
      optionId: string;
      rowIndex: number;
      columnIndex: number;
      crossed: boolean;
      rowSpan: number;
    }>;
  }>;
};

export type T239Page8Fragment = {
  __typename: 'T239Page8';
  id: string;
  label: string;
  questions: {
    __typename: 'T239Page8Questions';
    table1: { __typename: 'T239Page8Table' } & T239Page8TableFragment;
    table2: { __typename: 'T239Page8Table' } & T239Page8TableFragment;
    conclusion: { __typename: 'T239Page8Question'; id: string; label: string };
    section2: Array<{ __typename: 'T239Page8Question'; id: string; label: string }>;
  };
};

export type T239DiagnosticsResultsQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type T239DiagnosticsResultsQuery = {
  t239DiagnosticsResults?: Maybe<{
    __typename: 'T239Results';
    resultsTable: { __typename: 'T239Page8Table' } & T239Page8TableFragment;
    errorStats: Array<{ __typename: 'T239StatsRow'; labels: Array<string> }>;
  }>;
};

export type T252PageFragment = {
  __typename: 'T252Page';
  id: string;
  label: string;
  instructions: string;
  questions: Array<{
    __typename: 'T252Question';
    id: string;
    label: string;
    choicesCount: number;
    choices: Array<{ __typename: 'T252QuestionChoice'; id: string; label: string; letter: string }>;
  }>;
};

export type SubmitT252IdentificationMutationVariables = Exact<{
  input: UpdateStudentByTokenInput;
}>;

export type SubmitT252IdentificationMutation = {
  updateStudentByToken: {
    __typename: 'Student';
    id: string;
    birthIdentificationNumber?: Maybe<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    dateOfBirth?: Maybe<any>;
    gender?: Maybe<Gender>;
    education?: Maybe<string>;
  };
};

export type T252StudentOneByTokenQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type T252StudentOneByTokenQuery = {
  studentOneByToken: {
    __typename: 'Student';
    id: string;
    birthIdentificationNumber?: Maybe<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    dateOfBirth?: Maybe<any>;
    gender?: Maybe<Gender>;
    education?: Maybe<string>;
  };
};

export type T252AnswerFragment = {
  __typename: 'T252PageAnswerMany';
  id: string;
  choices: Array<{ __typename: 'T252PageAnswerChoiceMany'; id: string; value: number }>;
};

export type T252DiagnosticQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type T252DiagnosticQuery = {
  t252Diagnostics?: Maybe<{
    __typename: 'T252';
    isValid: boolean;
    isDemo: boolean;
    code: string;
    label: string;
    status: TestingStatus;
    originalToken?: Maybe<string>;
    answers?: Maybe<{
      __typename: 'T252AnswerMany';
      page1: Array<{ __typename: 'T252PageAnswerMany' } & T252AnswerFragment>;
      page2: Array<{ __typename: 'T252PageAnswerMany' } & T252AnswerFragment>;
      page3: Array<{ __typename: 'T252PageAnswerMany' } & T252AnswerFragment>;
    }>;
    page1: { __typename: 'T252Page' } & T252PageFragment;
    page2: { __typename: 'T252Page' } & T252PageFragment;
    page3: { __typename: 'T252Page' } & T252PageFragment;
  }>;
};

export type SubmitT252Page1MutationVariables = Exact<{
  input: SubmitT252Input;
}>;

export type SubmitT252Page1Mutation = { submitT252Page1: string };

export type SubmitT252Page2MutationVariables = Exact<{
  input: SubmitT252Input;
}>;

export type SubmitT252Page2Mutation = { submitT252Page2: string };

export type SubmitT252Page3MutationVariables = Exact<{
  input: SubmitT252Input;
}>;

export type SubmitT252Page3Mutation = { submitT252Page3: string };

export type SubmitT309IdentificationMutationVariables = Exact<{
  input: UpdateStudentByTokenInput;
}>;

export type SubmitT309IdentificationMutation = {
  updateStudentByToken: {
    __typename: 'Student';
    id: string;
    birthIdentificationNumber?: Maybe<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    dateOfBirth?: Maybe<any>;
    gender?: Maybe<Gender>;
  };
};

export type T309StudentOneByTokenQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type T309StudentOneByTokenQuery = {
  studentOneByToken: {
    __typename: 'Student';
    id: string;
    birthIdentificationNumber?: Maybe<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    dateOfBirth?: Maybe<any>;
    gender?: Maybe<Gender>;
  };
};

export type T309DiagnosticQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type T309DiagnosticQuery = {
  t309Diagnostics?: Maybe<{
    __typename: 'T309';
    isValid: boolean;
    isDemo: boolean;
    code: string;
    label: string;
    status: TestingStatus;
    originalToken?: Maybe<string>;
    answers?: Maybe<{
      __typename: 'T309AnswerMany';
      page1: Array<{ __typename: 'T309StringAnswer'; id: string; answer: string }>;
      page2: Array<{ __typename: 'T309StringAnswer'; id: string; answer: string }>;
      page3: Array<{ __typename: 'T309StringAnswer'; id: string; answer: string }>;
      page4: Array<{ __typename: 'T309IntAnswer'; id: string; answer: number }>;
      page5: Array<{ __typename: 'T309StringAnswer'; id: string; answer: string }>;
      page6: Array<{ __typename: 'T309StringAnswer'; id: string; answer: string }>;
      page7: Array<{ __typename: 'T309StringAnswer'; id: string; answer: string }>;
      page8: Array<{ __typename: 'T309IntAnswer'; id: string; answer: number }>;
      page9: Array<{ __typename: 'T309ABAnswer'; id: string; a: string; b: string }>;
      page10: Array<{ __typename: 'T309StringAnswer'; id: string; answer: string }>;
    }>;
    page1: { __typename: 'T309Page1' } & T309Page1Fragment;
    page2: { __typename: 'T309Page2' } & T309Page2Fragment;
    page3: { __typename: 'T309Page3' } & T309Page3Fragment;
    page4: { __typename: 'T309Page4' } & T309Page4Fragment;
    page5: { __typename: 'T309Page5' } & T309Page5Fragment;
    page6: { __typename: 'T309Page6' } & T309Page6Fragment;
    page7: { __typename: 'T309Page7' } & T309Page7Fragment;
    page8: { __typename: 'T309Page8' } & T309Page8Fragment;
    page9: { __typename: 'T309Page9' } & T309Page9Fragment;
    page10: { __typename: 'T309Page10' } & T309Page10Fragment;
  }>;
};

export type SubmitT309Page1MutationVariables = Exact<{
  input: SubmitT309Page1Input;
}>;

export type SubmitT309Page1Mutation = { submitT309Page1: string };

export type SubmitT309Page2MutationVariables = Exact<{
  input: SubmitT309Page2Input;
}>;

export type SubmitT309Page2Mutation = { submitT309Page2: string };

export type SubmitT309Page3MutationVariables = Exact<{
  input: SubmitT309Page3Input;
}>;

export type SubmitT309Page3Mutation = { submitT309Page3: string };

export type SubmitT309Page4MutationVariables = Exact<{
  input: SubmitT309Page4Input;
}>;

export type SubmitT309Page4Mutation = { submitT309Page4: string };

export type SubmitT309Page5MutationVariables = Exact<{
  input: SubmitT309Page5Input;
}>;

export type SubmitT309Page5Mutation = { submitT309Page5: string };

export type SubmitT309Page6MutationVariables = Exact<{
  input: SubmitT309Page6Input;
}>;

export type SubmitT309Page6Mutation = { submitT309Page6: string };

export type SubmitT309Page7MutationVariables = Exact<{
  input: SubmitT309Page7Input;
}>;

export type SubmitT309Page7Mutation = { submitT309Page7: string };

export type SubmitT309Page8MutationVariables = Exact<{
  input: SubmitT309Page8Input;
}>;

export type SubmitT309Page8Mutation = { submitT309Page8: string };

export type SubmitT309Page9MutationVariables = Exact<{
  input: SubmitT309Page9Input;
}>;

export type SubmitT309Page9Mutation = { submitT309Page9: string };

export type SubmitT309Page10MutationVariables = Exact<{
  input: SubmitT309Page10Input;
}>;

export type SubmitT309Page10Mutation = { submitT309Page10: string };

export type T309Page1Fragment = {
  __typename: 'T309Page1';
  id: string;
  label: string;
  timeLimitInSeconds: number;
  questions: Array<{
    __typename: 'T309Question';
    id: string;
    label: string;
    order: number;
    choices: Array<{ __typename: 'T309QuestionChoice'; id: string; label: string }>;
  }>;
};

export type T309Page10Fragment = {
  __typename: 'T309Page10';
  id: string;
  label: string;
  timeLimitInSeconds: number;
  questions: Array<{
    __typename: 'T309Page10Question';
    id: string;
    order: number;
    templateFigure: { __typename: 'TemplateFigure'; img: string };
    choices: Array<{
      __typename: 'T309Page10QuestionChoice';
      id: string;
      img: string;
      letter: string;
    }>;
  }>;
};

export type T309Page2Fragment = {
  __typename: 'T309Page2';
  id: string;
  label: string;
  timeLimitInSeconds: number;
  questions: Array<{
    __typename: 'T309Page2Question';
    id: string;
    order: number;
    choices: Array<{ __typename: 'T309QuestionChoice'; id: string; label: string }>;
  }>;
};

export type T309Page3Fragment = {
  __typename: 'T309Page3';
  id: string;
  label: string;
  table: string;
  timeLimitInSeconds: number;
  questions: Array<{ __typename: 'T309Page3Question'; id: string }>;
};

export type T309Page4Fragment = {
  __typename: 'T309Page4';
  id: string;
  label: string;
  timeLimitInSeconds: number;
  questions: Array<{
    __typename: 'T309Page4Question';
    id: string;
    order: number;
    set: Array<number>;
  }>;
};

export type T309Page5Fragment = {
  __typename: 'T309Page5';
  id: string;
  label: string;
  timeLimitInSeconds: number;
  questions: Array<{
    __typename: 'T309Page5Question';
    id: string;
    order: number;
    label: string;
    choices: Array<{ __typename: 'T309Page5QuestionChoice'; id: string; label: string }>;
  }>;
};

export type T309Page6Fragment = {
  __typename: 'T309Page6';
  id: string;
  label: string;
  timeLimitInSeconds: number;
  questions: Array<{
    __typename: 'T309Page6Question';
    id: string;
    order: number;
    label: string;
    choices: Array<{ __typename: 'T309Page6QuestionChoice'; id: string; label: string }>;
  }>;
};

export type T309Page7Fragment = {
  __typename: 'T309Page7';
  id: string;
  label: string;
  timeLimitInSeconds: number;
  questions: Array<{
    __typename: 'T309Page7Question';
    id: string;
    order: number;
    searchedCube: { __typename: 'SearchedCube'; img: string; letter: string };
    choices: Array<{
      __typename: 'T309Page7QuestionChoice';
      id: string;
      img: string;
      letter: string;
    }>;
  }>;
};

export type T309Page8Fragment = {
  __typename: 'T309Page8';
  id: string;
  label: string;
  timeLimitInSeconds: number;
  questions: Array<{ __typename: 'T309Page8Question'; id: string; order: number; label: string }>;
};

export type T309Page9Fragment = {
  __typename: 'T309Page9';
  id: string;
  label: string;
  timeLimitInSeconds: number;
  questions: Array<{
    __typename: 'T309Page9Question';
    id: string;
    order: number;
    choices: Array<{ __typename: 'T309Page9QuestionChoice'; id: string; label: string }>;
  }>;
};

export type T41TableFragment = {
  __typename: 'T41Table';
  img: { __typename: 'T41Image'; key: string; alt: string };
  rows: Array<{
    __typename: 'T41TableRow';
    label: string;
    printLink: { __typename: 'T41PrintLink'; label: string; document: string };
    buttons: Array<{ __typename: 'T41TableButton'; label: string; key: T41Route }>;
  }>;
};

export type T41ChooseVersionPageFragment = {
  __typename: 'T41ChooseVersionPage';
  labelTop1: string;
  labelTop2: string;
  tables: Array<{ __typename: 'T41Table' } & T41TableFragment>;
};

export type T4ISectionFragment = { __typename: 'T41Section'; title: string; text: string };

export type T41FinishedStudentOneByTokenQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type T41FinishedStudentOneByTokenQuery = {
  studentOneByToken: {
    __typename: 'Student';
    id: string;
    birthIdentificationNumber?: Maybe<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    dateOfBirth?: Maybe<any>;
    gender?: Maybe<Gender>;
  };
};

export type SubmitT41IdentificationMutationVariables = Exact<{
  input: UpdateStudentByTokenInput;
}>;

export type SubmitT41IdentificationMutation = {
  updateStudentByToken: {
    __typename: 'Student';
    id: string;
    birthIdentificationNumber?: Maybe<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    dateOfBirth?: Maybe<any>;
    gender?: Maybe<Gender>;
    grade?: Maybe<number>;
  };
};

export type T41StudentOneByTokenQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type T41StudentOneByTokenQuery = {
  studentOneByToken: {
    __typename: 'Student';
    id: string;
    birthIdentificationNumber?: Maybe<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    dateOfBirth?: Maybe<any>;
    gender?: Maybe<Gender>;
    grade?: Maybe<number>;
  };
};

export type T41DiagnosticQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type T41DiagnosticQuery = {
  t41Diagnostics?: Maybe<{
    __typename: 'T41';
    isValid: boolean;
    isDemo: boolean;
    code: string;
    label: string;
    status: TestingStatus;
    originalToken?: Maybe<string>;
    testingDate?: Maybe<string>;
    isForAdult: boolean;
    answers?: Maybe<{ __typename: 'T41Answer'; duration: number; version: string }>;
    chooseVersionPage: { __typename: 'T41ChooseVersionPage' } & T41ChooseVersionPageFragment;
    instructions: Array<{ __typename: 'T41Section' } & T4ISectionFragment>;
  }>;
};

export type SubmitT41MutationVariables = Exact<{
  input: SubmitT41Input;
}>;

export type SubmitT41Mutation = { submitT41: string };

export type T8QuestionTable1Fragment = {
  __typename: 'T8QuestionsTable1';
  label: string;
  instructions: string;
  questions: Array<{
    __typename: 'T8QuestionGroup1';
    id: string;
    task: string;
    instruction?: Maybe<string>;
    fields: {
      __typename: 'T8QuestionFieldsTable1';
      rating: {
        __typename: 'RadioField';
        validation: { __typename: 'Validation'; rules: Array<string> };
        options: Array<{ __typename: 'RadioOption'; value: number; label: string }>;
      };
      answer: {
        __typename: 'TextField';
        validation: { __typename: 'Validation'; rules: Array<string> };
      };
    };
  }>;
};

export type T8QuestionTable2Fragment = {
  __typename: 'T8QuestionsTable2';
  label: string;
  instructions: string;
  questions: Array<{
    __typename: 'T8QuestionGroup2';
    id: string;
    task: string;
    fields: {
      __typename: 'T8QuestionFieldsTable2';
      match: {
        __typename: 'CheckboxField';
        validation: { __typename: 'Validation'; rules: Array<string> };
      };
      notMatch: {
        __typename: 'TextField';
        validation: { __typename: 'Validation'; rules: Array<string> };
      };
    };
  }>;
};

export type T8AnswerManyFragment = {
  __typename: 'T8AnswerMany';
  v1?: Maybe<{
    __typename: 'T8V1Answers';
    answersTable1: Array<{
      __typename: 'T8AnswersTable1';
      id: string;
      radioValue: number;
      answer?: Maybe<string>;
    }>;
  }>;
  v2?: Maybe<{
    __typename: 'T8V2Answers';
    answersTable1: Array<{
      __typename: 'T8AnswersTable1';
      id: string;
      radioValue: number;
      answer?: Maybe<string>;
    }>;
    answersTable2: Array<{
      __typename: 'T8V2AnswersTable2';
      id: string;
      match: boolean;
      notMatch: string;
    }>;
  }>;
};

export type T8DiagnosticQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type T8DiagnosticQuery = {
  t8Diagnostics?: Maybe<{
    __typename: 'T8';
    isValid: boolean;
    isDemo: boolean;
    code: string;
    label: string;
    status: TestingStatus;
    originalToken?: Maybe<string>;
    answers?: Maybe<{ __typename: 'T8AnswerMany' } & T8AnswerManyFragment>;
    v1: {
      __typename: 'T8V1';
      label: string;
      instructions: string;
      questionGroups: {
        __typename: 'T8V1QuestionGroups';
        table1: { __typename: 'T8QuestionsTable1' } & T8QuestionTable1Fragment;
      };
    };
    v2: {
      __typename: 'T8V2';
      label: string;
      instructions: string;
      questionGroups: {
        __typename: 'T8V2QuestionGroups';
        table1: { __typename: 'T8QuestionsTable1' } & T8QuestionTable1Fragment;
        table2: { __typename: 'T8QuestionsTable2' } & T8QuestionTable2Fragment;
      };
    };
  }>;
};

export type SubmitTestT8V1MutationVariables = Exact<{
  input: SubmitT8V1Input;
}>;

export type SubmitTestT8V1Mutation = { submitT8V1: string };

export type SubmitTestT8V2MutationVariables = Exact<{
  input: SubmitT8V2Input;
}>;

export type SubmitTestT8V2Mutation = { submitT8V2: string };

export type T95DiagnosticV1QueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type T95DiagnosticV1Query = {
  t95Diagnostics?: Maybe<{
    __typename: 'T95';
    isValid: boolean;
    isDemo: boolean;
    code: string;
    label: string;
    status: TestingStatus;
    originalToken?: Maybe<string>;
    instructions: string;
    sightDisability: boolean;
    leftEye?: Maybe<string>;
    rightEye?: Maybe<string>;
    hearingDisability: boolean;
    attention?: Maybe<string>;
    ciq?: Maybe<string>;
    viq?: Maybe<string>;
    niq?: Maybe<string>;
    questions: Array<{
      __typename: 'T95Question';
      id: string;
      order: number;
      columns: Array<
        | {
            __typename: 'T95Field';
            id: string;
            options: Array<{ __typename: 'RadioOption'; value: number; label: string }>;
          }
        | { __typename: 'T95Label'; label: string }
      >;
    }>;
    answers: Array<{ __typename: 'T95AnswerMany'; id: string; radioValue: number }>;
    attentionOptions: Array<{ __typename: 'RadioOption'; value: number; label: string }>;
  }>;
};

export type SubmitT95IdentificationMutationVariables = Exact<{
  input: UpdateStudentByTokenInput;
}>;

export type SubmitT95IdentificationMutation = {
  updateStudentByToken: {
    __typename: 'Student';
    id: string;
    birthIdentificationNumber?: Maybe<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    dateOfBirth?: Maybe<any>;
    gender?: Maybe<Gender>;
  };
};

export type T95StudentOneByTokenQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type T95StudentOneByTokenQuery = {
  studentOneByToken: {
    __typename: 'Student';
    id: string;
    birthIdentificationNumber?: Maybe<string>;
    firstName: string;
    lastName: string;
    fullName: string;
    dateOfBirth?: Maybe<any>;
    gender?: Maybe<Gender>;
  };
};

export type T95DiagnosticQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type T95DiagnosticQuery = {
  t95Diagnostics?: Maybe<{
    __typename: 'T95';
    isValid: boolean;
    isDemo: boolean;
    code: string;
    label: string;
    status: TestingStatus;
    originalToken?: Maybe<string>;
  }>;
};

export type SubmitTestT95MutationVariables = Exact<{
  input: SubmitT95Input;
}>;

export type SubmitTestT95Mutation = { submitT95?: Maybe<string> };
