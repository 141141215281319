import { gql } from '@apollo/client';
import {
  Box,
  Button,
  Table as MaterialTable,
  MenuItem,
  Paper,
  Select,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import { styled } from '@mui/material';
import { PaperContent } from '../../../../components/PaperContent';
import { T239AnswersFragment, T239Page2Fragment } from '../../../../graphql/types';

interface Props {
  readonly token: string;
  readonly label: string;
  readonly code: string;
  readonly page: T239Page2Fragment;
  readonly onSubmit: (values) => void;
  readonly onBackClick: () => void;
  readonly isDemo: boolean;
  readonly isReadOnly: boolean;
  readonly answers: T239AnswersFragment | undefined;
}

export const t239Page2Fragment = gql`
  fragment T239Page2Fragment on T239Page2 {
    id
    label
    questions {
      dropdowns {
        id
        defaultValue
        label
      }
      textInputs {
        id
        label
      }
    }
    header {
      label1
      label2
    }
    choices {
      value
      label
    }
  }
`;

export function Diagnostic({
  token,
  code,
  label,
  page,
  onSubmit,
  onBackClick,
  isDemo,
  isReadOnly,
  answers
}: Props) {
  const [dropdowns, setDropdowns] = useState<number[]>(() =>
    page.questions.dropdowns.map(question =>
      isReadOnly
        ? (answers?.t239AnswerPage2?.dropdowns.find(item => item.id === question.id)?.value ??
          question.defaultValue)
        : question.defaultValue
    )
  );

  const [textInputs, setTextInputs] = useState<string[]>(() =>
    page.questions.textInputs.map(question =>
      isReadOnly
        ? (answers?.t239AnswerPage2?.textInputs.find(item => item.id === question.id)?.value ?? '')
        : ''
    )
  );

  function getResults(
    specsArray: Array<{ id: string }>,
    values: number[] | string[]
  ): Array<{ id: string; value: number | string }> {
    return specsArray.map((spec, index) => {
      return {
        id: spec.id,
        value: values[index]
      };
    });
  }

  return (
    <PaperContent title={label} testNumber={code}>
      <div>{page.label}</div>
      <Box mt={{ xs: 3, md: 3 }} mb={{ xs: 3, md: 3 }} position="relative">
        <TableContainer component={Paper}>
          <MaterialTable>
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  <Box>
                    <Typography component="span" variant="h6">
                      {page.header.label1}
                    </Typography>
                  </Box>
                </StyledTableCell>
                <StyledTableCell>
                  <Box>
                    <Typography component="span" variant="h6">
                      {page.header.label2}
                    </Typography>
                  </Box>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {page.questions.dropdowns.map((selectQuestion, index) => (
                <TableRow key={selectQuestion.id}>
                  <TableCell style={{ whiteSpace: 'pre-line' }}>{selectQuestion.label}</TableCell>
                  <TableCell>
                    <Select
                      id={selectQuestion.id}
                      variant="standard"
                      disabled={isReadOnly}
                      onChange={event => {
                        if (event.target.value != null && typeof event.target.value === 'number') {
                          const { value } = event.target;
                          const mutatedDropdowns = [...dropdowns];
                          mutatedDropdowns[index] = value;
                          setDropdowns(mutatedDropdowns);
                        }
                      }}
                      value={dropdowns[index]}
                    >
                      {page.choices.map((option, i) => {
                        return (
                          <MenuItem
                            value={option.value}
                            key={option.label + i}
                            disabled={isReadOnly}
                          >
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </TableCell>
                </TableRow>
              ))}
              {page.questions.textInputs.map((textQuestion, index) => (
                <TableRow key={textQuestion.id}>
                  <TableCell>{textQuestion.label}</TableCell>
                  <TableCell>
                    <TextField
                      id={textQuestion.id}
                      disabled={isReadOnly}
                      name={textQuestion.id}
                      type="text"
                      variant="standard"
                      multiline={false}
                      onChange={event => {
                        const value =
                          event.target.value && typeof event.target.value === 'string'
                            ? event.target.value
                            : '';

                        const mutatedTextInputs = [...textInputs];
                        mutatedTextInputs[index] = value;
                        setTextInputs(mutatedTextInputs);
                      }}
                    ></TextField>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </MaterialTable>
        </TableContainer>
        <Box my={3} textAlign="right">
          <Button size="large" color="secondary" onClick={onBackClick}>
            Krok späť
          </Button>
          <Button
            onClick={() =>
              onSubmit({
                token,
                dropdowns: getResults(page.questions.dropdowns, dropdowns),
                textInputs: getResults(page.questions.textInputs, textInputs)
              })
            }
            variant="contained"
            type="submit"
            size="large"
            color="primary"
            style={{ marginLeft: '10px' }}
          >
            Nasledujúca strana
          </Button>
        </Box>
      </Box>
    </PaperContent>
  );
}

const StyledTableCell = styled(TableCell)`
  background: #eee;
`;
