import { gql } from '@apollo/client';
import ChevronRight from '@mui/icons-material/ChevronRight';
import PrintIcon from '@mui/icons-material/Print';
import {
  Box,
  Button,
  Table as MaterialTable,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material';
import { PaperContent } from '../../../components/PaperContent';
import { T41ChooseVersionPageFragment, T41Route } from '../../../graphql/types';
import { toDocumentUrl } from './documents';
import { toImageUrl } from './images';

interface Props {
  readonly label: string;
  readonly code: string;
  readonly page: T41ChooseVersionPageFragment;
  readonly isForAdult: boolean;
}

const t41TableFragment = gql`
  fragment T41TableFragment on T41Table {
    img {
      key
      alt
    }
    rows {
      label
      printLink {
        label
        document
      }
      buttons {
        label
        key
      }
    }
  }
`;

export const t41ChooseVersionPageFragment = gql`
  fragment T41ChooseVersionPageFragment on T41ChooseVersionPage {
    labelTop1
    labelTop2
    tables {
      ...T41TableFragment
    }
  }
  ${t41TableFragment}
`;

export function ChooseVersion({ page, label, code, isForAdult }: Props) {
  const navigate = useNavigate();
  const borderStyle = '1px solid #e2e4e6';

  function toTargetUrl(key: T41Route): string {
    switch (key) {
      case T41Route.identificationChildrenA:
        return './identification/childrenA';
      case T41Route.resultsChildrenA:
        return './insert-results/childrenA';
      case T41Route.identificationAdultsA:
        return './identification/adultsA';
      case T41Route.resultsAdultsA:
        return './insert-results/adultsA';
      case T41Route.identificationChildrenB:
        return './identification/childrenB';
      case T41Route.resultsChildrenB:
        return './insert-results/childrenB';
      case T41Route.identificationAdultsB:
        return './identification/adultsB';
      case T41Route.resultsAdultsB:
        return './insert-results/adultsB';
    }
  }

  return (
    <PaperContent title={label} testNumber={code}>
      <Box mb={3} display="flex">
        <Box mr={6} width="65%">
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{ __html: page.labelTop1 }}
          ></Typography>
        </Box>
        <Box width="35%">
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{ __html: page.labelTop2 }}
          ></Typography>
        </Box>
      </Box>

      <Box display="flex">
        {page.tables.map((table, tableIndex) => (
          <Box key={`table-${tableIndex}`} mr={3} width="50%" border={borderStyle}>
            <TableContainer>
              <MaterialTable>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography>Časť {table.img.key.replace('imgVersion', '')}</Typography>
                      <img src={toImageUrl(table.img.key)} alt={table.img.alt} width="100%" />
                    </TableCell>
                  </TableRow>
                  {table.rows.map((tableRow, rowIndex) => (
                    <TableRow key={`row-${rowIndex}`}>
                      <TableCell>
                        <Box display="flex" flexWrap="wrap" justifyContent="center">
                          <Box mb={2} width="100%">
                            <Typography variant="body1">
                              <strong>{tableRow.label}</strong>
                            </Typography>
                          </Box>
                          <Box mx={1} minWidth="145px">
                            <PrintLink
                              href={`${toDocumentUrl(tableRow.printLink.document)}`}
                              rel="noopener"
                              target="_blank"
                            >
                              <PrintIcon />
                              {tableRow.printLink.label}
                            </PrintLink>
                          </Box>
                          {tableRow.buttons.map((button, buttonIndex) => (
                            <Box
                              key={`btn-${tableIndex}-${rowIndex}-${buttonIndex}`}
                              mr={buttonIndex === 0 ? 1 : 0}
                            >
                              <StyledButton
                                onClick={() => navigate(toTargetUrl(button.key))}
                                endIcon={<ChevronRight />}
                                disabled={
                                  (tableRow.label === 'Pre dospelých:' && !isForAdult) ||
                                  (tableRow.label === 'Pre deti:' && isForAdult)
                                }
                              >
                                {button.label}
                              </StyledButton>
                            </Box>
                          ))}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </MaterialTable>
            </TableContainer>
          </Box>
        ))}
      </Box>
    </PaperContent>
  );
}

const StyledButton = styled(Button)`
  ,
  :hover {
    text-transform: none;
    background-color: #e2fde9;
    white-space: nowrap;
  }
  :disabled {
    background-color: white;
  }
`;

const PrintLink = styled('a')`
  text-decoration-line: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  padding-top: 0.5rem;
  white-space: nowrap;
  background-color: #e2e4e6;
  padding: 5px 12px;
  height: 25px;
  svg {
    font-size: 1rem;
    padding-right: 0.25rem;
    display: block;
  }
`;
