import { Box, Typography } from '@mui/material';
import React from 'react';
import { styled } from '@mui/material';
import { T309ChoiceSelector } from '../components/ChoiceSelector';

export function Instructions() {
  const choices1 = [
    { value: 'i-1-1', label: 'zeme' },
    { value: 'i-1-2', label: 'hnojiva' },
    { value: 'i-1-3', label: 'ošetrovania' },
    { value: 'i-1-4', label: 'opeľovania' },
    { value: 'i-1-5', label: 'svetla' }
  ];
  const choices2 = [
    { value: 'i-2-1', label: 'rám' },
    { value: 'i-2-2', label: 'plátno' },
    { value: 'i-2-3', label: 'maliar' },
    { value: 'i-2-4', label: 'cena' },
    { value: 'i-2-5', label: 'múzeum' }
  ];

  return (
    <>
      <Box my={3}>
        <Typography component="h5" variant="h5">
          Doplňovanie viet - inštrukcie
        </Typography>
      </Box>
      <Box my={3}>
        <Typography component="p" variant="body2">
          Nasledujúce úlohy obsahujú vždy jednu vetu, v ktorej je jedno alebo viacero slov nahradené
          čiarou. Nájdite medzi piatimi ponúknutými návrhmi A až E ten, ktorý vetu správne dopĺňa.
        </Typography>
      </Box>
      <Box my={3}>
        <Typography component="p" variant="body2">
          Príklad:
        </Typography>
      </Box>
      <TableDiv my={3} display="flex">
        <Box className="cell">
          <Typography component="h5" variant="h5">
            B1.
          </Typography>
        </Box>
        <Box className="cell">
          <T309ChoiceSelector
            initialValue="i-1-5"
            options={choices1}
            disabled
            sentenceText="Bez #placeholder# nemôže zelená rastlina dlho žiť."
            onChange={() => {}}
          ></T309ChoiceSelector>
        </Box>
      </TableDiv>
      <Box my={3}>
        <Typography component="p" variant="body2">
          Správne doplnenie tejto vety je <span>"svetlo” (E)</span>. Vo Vašom Odpoveďovom hárku je
          preto v prvom riadku pri úlohe B 1 označené písmeno E.
        </Typography>
      </Box>
      <Box mb={3} mt={5}>
        <Typography component="p" variant="body2">
          Ďalší príklad:
        </Typography>
      </Box>
      <TableDiv my={3} display="flex">
        <Box className="cell">
          <Typography component="h5" variant="h5">
            B2.
          </Typography>
        </Box>
        <Box className="cell">
          <T309ChoiceSelector
            initialValue="i-2-3"
            options={choices2}
            disabled
            sentenceText="K maľovanému obrazu patrí vždy #placeholder#."
            onChange={() => {}}
          ></T309ChoiceSelector>
        </Box>
      </TableDiv>
      <Box my={3}>
        <Typography component="p" variant="body2">
          Túto vetu správne dopĺňa slovo <span>"maliar” (C)</span>. V odpoveďovom hárku preto
          zaznačte pri tejto úlohe písmeno C.
        </Typography>
      </Box>
      <Box my={3}>
        <Typography component="p" variant="body2">
          Zaznačte teda vždy to písmeno, ktoré označuje správnu odpoveď. <br />
          Pamätajte si, že zaznačovať a písať môžete iba do odpoveďového hárku. Do Testového zošita
          nie je dovolené písať.
        </Typography>
      </Box>
      <Box my={3}>
        <Typography component="p" variant="body2">
          Na riešenie nasledujúcich <strong>12 úloh</strong> máte čas <strong>4 minúty</strong>.{' '}
          <strong>Počkajte na vyzvanie stlačiť tlačidlo ĎALEJ.</strong>
        </Typography>
      </Box>
    </>
  );
}

const TableDiv = styled(Box)`
  border: 1px solid #ccc;
  .cell {
    &:first-child {
      padding: 1rem;
    }
    &:last-child {
      border-left: 1px solid #ccc;
      padding: 0 1rem 1rem 1rem;
    }
  }
`;
