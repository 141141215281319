import { useParams } from '@everlutionsk/ui-router';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { PaperContent } from '../../../components/PaperContent';
import { T202ArticleFragment } from '../../../graphql/types';
import { Error } from '../Error';

interface Props {
  readonly code: string;
  readonly onBackClick: () => void;
  readonly onSubmit: (articleId: string) => void;
  readonly instructions: string;
  readonly articles: T202ArticleFragment[];
}

export function Instructions({ code, onBackClick, onSubmit, articles, instructions }: Props) {
  const { articleId } = useParams(['articleId']);

  const article = articles.find(article => article.id === articleId);
  if (article == null) return <Error></Error>;

  return (
    <PaperContent title={article.title} testNumber={code}>
      <Box my={3} position="relative">
        <Box my={3}>
          <Typography
            component="p"
            variant="body2"
            dangerouslySetInnerHTML={{ __html: instructions }}
          ></Typography>
        </Box>
        <Box mt={4}>
          <Box my={3}>
            <Button size="large" color="secondary" onClick={onBackClick}>
              Krok späť
            </Button>
            <Button
              variant="contained"
              color="primary"
              endIcon={<ChevronRight />}
              style={{ marginLeft: '10px' }}
              onClick={() => onSubmit(articleId)}
            >
              Pokračovať
            </Button>
          </Box>
        </Box>
      </Box>
    </PaperContent>
  );
}
