import { Box } from '@mui/material';
import React, { ReactNode } from 'react';
import { styled } from '@mui/material';

interface Props {
  readonly children: ReactNode;
}

export function InputList({ children }: Props) {
  return <Wrapper>{children}</Wrapper>;
}

const Wrapper = styled(Box)`
  .input-wrapper {
    &:first-child {
      border-top: 1px solid #ccc;
    }
    &:last-child {
      border-bottom: 1px solid #ccc;
    }
  }
`;
