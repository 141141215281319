import { gql, useMutation, useQuery } from '@apollo/client';
import { Button, Loading } from '@everlutionsk/ui';
import { createFormSpec, SelectField } from '@everlutionsk/ui-formik';
import { useNavigate } from '@everlutionsk/ui-router';
import Check from '@mui/icons-material/Check';
import { Box, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { PaperContent } from '../../../components/PaperContent';
import { InputList } from '../Identification/InputList';
import { InputWrapper } from '../Identification/InputWrapper';

interface Props {
  readonly code: string;
  readonly isDemo: boolean;
  readonly isReadOnly: boolean;
  readonly label: string;
  readonly token: string;
}

const mutation = gql<SubmitT239IdentificationGQL>`
  mutation SubmitT239Identification($input: UpdateStudentByTokenInput!) {
    updateStudentByToken(input: $input) {
      id
      birthIdentificationNumber
      firstName
      lastName
      fullName
      dateOfBirth
      gender
      grade
    }
  }
`;

const query = gql<T239StudentOneByTokenGQL>`
  query T239StudentOneByToken($token: String!) {
    studentOneByToken(token: $token) {
      id
      birthIdentificationNumber
      firstName
      lastName
      fullName
      dateOfBirth
      gender
      grade
    }
  }
`;

interface FormValues {
  grade: number | undefined;
}

const gradeOptions = [
  { label: '1.', value: 1 },
  { label: '2.', value: 2 },
  { label: '3.', value: 3 },
  { label: '4.', value: 4 },
  { label: '5.', value: 5 },
  { label: '6.', value: 6 },
  { label: '7.', value: 7 },
  { label: '8.', value: 8 },
  { label: '9.', value: 9 }
];

export function Identification({ isDemo, code, label, token, isReadOnly }: Props) {
  const navigate = useNavigate();

  const { data } = useQuery(query, { variables: { token } });
  const [mutate] = useMutation(mutation);

  if (data == null) return <Loading />;

  const spec = createFormSpec<FormValues>({
    grade: yup
      .string()
      .notRequired()
      .when('demo', () => {
        if (isDemo) return yup.mixed();
        return yup.string().required('Prosím zadajte triedu');
      })
  });

  return (
    <PaperContent title={label} testNumber={code}>
      <Typography component="h3" variant="h4">
        Identifikácia
      </Typography>
      <Typography component="p" variant="body2">
        Prosíme, aby ste vyplnili nasledujúce údaje:
      </Typography>
      <Box my={3}>
        <Formik
          {...spec({
            grade: data.studentOneByToken.grade ?? undefined
          })}
          onSubmit={async ({ grade }) => {
            await mutate({
              variables: {
                input: {
                  token,
                  grade
                }
              }
            });
            navigate('../pages/1/test');
          }}
        >
          <Form>
            <InputList>
              <InputWrapper label={`Ročník ZŠ`}>
                <SelectField
                  options={gradeOptions}
                  name="grade"
                  variant="standard"
                  disabled={Boolean(isDemo) || isReadOnly}
                />
              </InputWrapper>
            </InputList>
            <Box my={3}>
              <Button type="submit" size="medium" startIcon={<Check />}>
                Pokračovať na test
              </Button>
            </Box>
          </Form>
        </Formik>
      </Box>
    </PaperContent>
  );
}
