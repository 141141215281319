import { gql } from '@apollo/client';
import { populated } from '@everlutionsk/helpers';
import { toYupSchema } from '@everlutionsk/helpers-yup';
import Paper from '@mui/material/Paper';
import { Box, Button, Table, TableContainer } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import * as yup from 'yup';
import { DiagnosticErrorLabel } from '../../../components/DiagnosticErrorLabel';
import { scrollToTopOnFormError } from '../../../components/helpers';
import { PaperContent } from '../../../components/PaperContent';
import { TestPersist } from '../../../components/TestPersist';
import { T252PageFragment } from '../../../graphql/types';
import { T252QuestionField } from './T252QuestionField';

interface Props {
  readonly token: string;
  readonly label: string;
  readonly code: string;
  readonly page: T252PageFragment;
  readonly onSubmit: (values) => void;
  readonly onBackClick: () => void;
  readonly isDemo: boolean;
  readonly isReadOnly: boolean;
  readonly initialValues: Record<string, any>;
  readonly disabled?: boolean;
  readonly lastPage?: boolean;
}

export const t252PageFragment = gql`
  fragment T252PageFragment on T252Page {
    id
    label
    instructions
    questions {
      id
      label
      choices {
        id
        label
        letter
      }
      choicesCount
    }
  }
`;

export function Diagnostic({
  token,
  code,
  label,
  page,
  onSubmit,
  onBackClick,
  isDemo,
  isReadOnly,
  initialValues,
  disabled,
  lastPage
}: Props) {
  const [wasSubmitted, setWasSubmitted] = useState(false);
  const validationSchema = toYupSchema(
    page.questions.reduce((spec, question) => {
      spec[question.id] = yup.object().shape(
        question.choices.reduce((shape, choice) => {
          shape[choice.id] = yup.number().required().positive().integer();

          return shape;
        }, {})
      );

      return spec;
    }, {})
  );

  return (
    <PaperContent title={label} testNumber={code}>
      <div>{page.label}</div>

      <Box mt={{ xs: 3, md: 3 }} mb={{ xs: 3, md: 3 }} position="relative">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ errors }) => (
            <Form>
              {wasSubmitted && populated(errors) && <DiagnosticErrorLabel />}
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  {page.questions.map(question => (
                    <T252QuestionField
                      key={question.id}
                      name={question.id}
                      label={question.label}
                      choices={question.choices}
                      choicesCount={question.choicesCount}
                      isReadOnly={isReadOnly}
                    />
                  ))}
                </Table>
              </TableContainer>

              <Box my={3} textAlign="right">
                <Button size="large" color="secondary" onClick={onBackClick}>
                  Krok späť
                </Button>
                <Button
                  onClick={() => scrollToTopOnFormError(errors, setWasSubmitted)}
                  variant="contained"
                  type="submit"
                  size="large"
                  color="primary"
                  style={{ marginLeft: '10px' }}
                  disabled={disabled}
                >
                  {lastPage ? 'Vyhodnotiť' : 'Ďalej'}
                </Button>
              </Box>
              <TestPersist isDemo={isDemo || isReadOnly} keyName={`${token}${page.id}`} />
            </Form>
          )}
        </Formik>
      </Box>
    </PaperContent>
  );
}
