import { isNotNil, toggleIn } from '@everlutionsk/helpers';
import PrintIcon from '@mui/icons-material/Print';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material';
import { SubmitT202Page1Input } from '../../../../graphql/types';
import { Stepper } from './Stepper';
import { Timer } from './Timer';
import { Word } from './Word';

type Step = 'initial' | 'first' | 'second' | 'third' | 'mistakes';

interface Props {
  readonly token: string;
  readonly firstInterval: number;
  readonly secondInterval: number;
  readonly thirdInterval: number;
  readonly rows: Array<{ words: Array<{ value: string; index: number }>; length: number }>;
  readonly printTemplate: { label: string; key: string };
  readonly printControlText: { label: string; key: string };
  readonly toDocumentUrl: (key: string) => string;
  readonly onSubmit: (values: Omit<SubmitT202Page1Input, 'articleId'>) => void;
  readonly isReadOnly: boolean;
  readonly answers:
    | {
        firstMinuteWord: number;
        secondMinuteWord: number;
        thirdMinuteWord: number;
        duration: number;
        mistakes: number[];
      }
    | undefined;
  isDemo: boolean;
}

export function WordsSelectorWrapper({
  token,
  firstInterval,
  secondInterval,
  thirdInterval,
  rows,
  printTemplate,
  printControlText,
  toDocumentUrl,
  onSubmit,
  answers,
  isReadOnly,
  isDemo
}: Props) {
  const [step, setStep] = useState<Step>('initial');
  const [secondsElapsed, setSecondsElapsed] = useState<number>(0);
  const [firstIntervalElapsed, setFirstIntervalElapsed] = useState<boolean>(false);
  const [secondIntervalElapsed, setSecondIntervalElapsed] = useState<boolean>(false);
  const [timerFinished, setTimerFinished] = useState<boolean>(Boolean(isReadOnly));

  const [firstIntervalWord, setFirstIntervalWord] = useState<number | undefined>(
    isReadOnly ? answers?.firstMinuteWord : undefined
  );
  const [secondIntervalWord, setSecondIntervalWord] = useState<number | undefined>(
    isReadOnly ? answers?.secondMinuteWord : undefined
  );
  const [thirdIntervalWord, setThirdIntervalWord] = useState<number | undefined>(
    isReadOnly ? answers?.thirdMinuteWord : undefined
  );

  const [mistakes, setMistakes] = useState<number[]>(isReadOnly ? (answers?.mistakes ?? []) : []);

  useEffect(() => {
    if (firstIntervalElapsed && !secondIntervalElapsed) {
      setStep('first');
      return;
    }
    if (secondIntervalElapsed && firstIntervalWord && !timerFinished) {
      setStep('second');
      return;
    }
    if (timerFinished) {
      onTimerSubmitted();
    }
  }, [firstIntervalElapsed, secondIntervalElapsed, timerFinished]);

  function onTimerSubmitted() {
    if (firstIntervalWord == null) {
      setStep('first');
    } else if (secondIntervalWord == null) {
      setStep('second');
    } else if (thirdIntervalWord == null) {
      setStep('third');
    } else {
      setStep('mistakes');
    }
  }

  function stepBack() {
    if (step === 'first' && firstIntervalWord) {
      setFirstIntervalWord(undefined);
      return;
    }
    if (step === 'second') {
      if (secondIntervalWord != null) {
        setSecondIntervalWord(undefined);
      } else {
        setStep('first');
      }
      return;
    }
    if (step === 'third') {
      if (thirdIntervalWord != null) {
        setThirdIntervalWord(undefined);
      } else {
        setStep('second');
      }
      return;
    }
    if (step === 'mistakes') {
      setMistakes([]);
      setStep('third');
    }
  }

  function stepNext() {
    if (step === 'first') {
      setStep('second');
      return;
    }
    if (step === 'second') {
      setStep('third');
      return;
    }
    if (step === 'third') {
      setStep('mistakes');
      return;
    }
    if (step === 'mistakes' && !isDemo) {
      onSubmit({
        token,
        firstMinuteWord: firstIntervalWord ?? -1,
        secondMinuteWord: secondIntervalWord ?? -1,
        thirdMinuteWord: thirdIntervalWord ?? -1,
        duration: secondsElapsed,
        mistakes
      });
    }
  }

  const allowNext = [
    step === ('first' as const) && firstIntervalWord,
    step === ('second' as const) && secondIntervalWord,
    step === ('third' as const) && thirdIntervalWord,
    step === 'mistakes'
  ].some(Boolean);

  const allowBack = [
    step === ('first' as const) && firstIntervalWord,
    step === ('second' as const),
    step === ('third' as const),
    step === 'mistakes'
  ].some(Boolean);

  return (
    <Box>
      {step !== 'initial' && (
        <Stepper
          isDemo={isDemo}
          step={step}
          allowBack={allowBack}
          allowNext={allowNext}
          onBackAction={stepBack}
          onNextAction={stepNext}
        />
      )}
      <Box display="flex">
        <Box width="80%">
          {rows.map(({ words, length: offset }, rowIndex) => {
            return (
              <Box display="flex" key={rowIndex}>
                <Box width={600}>
                  {words.map(({ index, value }, wordIndex) => {
                    const splitWord = words.length === wordIndex + 1 && value.endsWith('-');

                    const state = [
                      firstIntervalWord === index && !splitWord ? ('first' as const) : undefined,
                      secondIntervalWord === index && !splitWord ? ('second' as const) : undefined,
                      thirdIntervalWord === index && !splitWord ? ('third' as const) : undefined,
                      mistakes.includes(index) ? ('mistake' as const) : undefined
                    ].filter(isNotNil);

                    return (
                      <Word
                        key={`${value}-${index}`}
                        value={value}
                        state={state}
                        onClick={() => {
                          if (isReadOnly) return;
                          if (step === 'first') setFirstIntervalWord(index);
                          if (step === 'second') setSecondIntervalWord(index);
                          if (step === 'third') setThirdIntervalWord(index);
                          if (step === 'mistakes') setMistakes(toggleIn(mistakes, index));
                        }}
                      ></Word>
                    );
                  })}
                </Box>
                <Box>
                  <span style={{ color: 'red' }}>{offset}</span>
                </Box>
              </Box>
            );
          })}
        </Box>
        <Box>
          <Box mb={1}>
            <PrintLink href={`${toDocumentUrl(printTemplate.key)}`} rel="noopener" target="_blank">
              <PrintIcon />
              {printTemplate.label}
            </PrintLink>
          </Box>
          <Box mb={5}>
            <PrintLink
              href={`${toDocumentUrl(printControlText.key)}`}
              rel="noopener"
              target="_blank"
            >
              <PrintIcon />
              {printControlText.label}
            </PrintLink>
          </Box>
          <Timer
            firstInterval={firstInterval}
            secondInterval={secondInterval}
            thirdInterval={thirdInterval}
            onFirstIntervalElapsed={() => setFirstIntervalElapsed(true)}
            onSecondIntervalElapsed={() => setSecondIntervalElapsed(true)}
            onSubmit={duration => {
              setSecondsElapsed(duration);
              setTimerFinished(true);
            }}
            disabled={isReadOnly}
          />
        </Box>
      </Box>
    </Box>
  );
}

const PrintLink = styled('a')`
  text-decoration-line: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.75rem;
  padding-top: 0.5rem;
  white-space: nowrap;
  background-color: #e2e4e6;
  padding: 5px 12px;
  height: 20px;
  svg {
    font-size: 1rem;
    padding-right: 0.25rem;
    display: block;
  }
`;
