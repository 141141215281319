import { Box, Typography } from '@mui/material';
import React from 'react';

export function Instructions() {
  return (
    <>
      <Box my={3}>
        <Typography component="h5" variant="h5">
          Zapamätávanie si tovarov (úlohy) - inštrukcie
        </Typography>
        <Box my={3}>
          <Typography component="p" variant="body2">
            Pred chvíľou ste sa pokúsili zapamätať si údaje o tovaroch.
          </Typography>
          <Typography component="p" variant="body2">
            Teraz bude Vašou úlohou zodpovedať niekoľko otázok. Označte Vaše riešenie.
          </Typography>
        </Box>
        <Box my={3}>
          <Typography component="p" variant="body2">
            Prosím, okamžite začnite s odpovedaním.
          </Typography>
          <Typography component="p" variant="body2">
            Na riešenie nasledujúcich <strong>12 úloh</strong> máte čas <strong>4 minúty</strong>.{' '}
            <strong>Počkajte na vyzvanie stlačiť tlačidlo ĎALEJ.</strong>
          </Typography>
        </Box>
      </Box>
    </>
  );
}
