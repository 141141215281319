import { Box, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { styled } from '@mui/material';

interface Props {
  readonly label: string;
  readonly children: ReactNode;
}

export function InputWrapper({ label, children }: Props) {
  return (
    <Wrapper
      display="flex"
      alignItems={{ xs: 'flex-start', sm: 'center' }}
      flexDirection={{ xs: 'column', sm: 'row' }}
      className="input-wrapper"
    >
      <Label p={2}>
        <Typography component="p" variant="body2">
          {label}
        </Typography>
      </Label>
      <Content p={2}>{children}</Content>
    </Wrapper>
  );
}

const Wrapper = styled(Box)`
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  .MuiSelect-select.Mui-disabled {
    color: rgba(0, 0, 0, 0.38);
  }
`;
const Content = styled(Box)`
  min-width: 200px;
`;

const Label = styled(Box)`
  min-width: 200px;
`;
