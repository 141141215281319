import { gql } from '@apollo/client';
import { isNotNil } from '@everlutionsk/helpers';
import { RadioField } from '@everlutionsk/ui-formik';
import {
  Box,
  Button,
  Table as MaterialTable,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { styled } from '@mui/material';
import { PaperContent } from '../../../components/PaperContent';
import { TestPersist } from '../../../components/TestPersist';
import { SubmitT184V1Input, SubmitT184V2Input, T184TableFragment } from '../../../graphql/types';
import { Error } from '../Error';
import { t184InstructionsFragment } from './Instructions';

interface Props {
  readonly token: string;
  readonly table1: T184TableFragment | undefined;
  readonly table2: T184TableFragment | undefined;
  readonly code: string;
  readonly version: 'v1' | 'v2';
  readonly isDemo: boolean;
  readonly onBackClick: () => void;
  readonly onSubmit: (input: SubmitT184V1Input | SubmitT184V2Input) => void;
  readonly isReadOnly: boolean;
  readonly initialValues: Record<string, number>;
  readonly initialAdditionalText: string | undefined;
}

export const t184TableFragment = gql`
  fragment T184TableFragment on T184Version {
    label
    instructions {
      ...T184Instructions
    }
    questions {
      id
      order
      text
      defaultValue
      options {
        value
        label
      }
    }
    textAreaLabel
  }
  ${t184InstructionsFragment}
`;

export function Diagnostic({
  token,
  table1,
  table2,
  code,
  version,
  isDemo,
  onBackClick,
  onSubmit,
  isReadOnly,
  initialValues,
  initialAdditionalText
}: Props) {
  const [additionalText, setAdditionalText] = useState<string>(initialAdditionalText ?? '');

  const table = version === 'v1' ? table1 : table2;
  if (table == null) return <Error></Error>;

  function getResults(values: Record<string, number>): SubmitT184V1Input | SubmitT184V2Input {
    const keys = Object.keys(values);
    const answers = table?.questions
      .map(question => {
        const radioValueKey = keys.find(item => item === question.id);

        if (radioValueKey == null) return;

        return {
          id: question.id,
          radioValue: values[radioValueKey]
        };
      })
      .filter(isNotNil);

    return {
      token,
      answersTable: answers ?? [],
      additionalText
    };
  }

  return (
    <PaperContent title={table?.label} testNumber={code}>
      <Box mt={{ xs: 3, md: 3 }} mb={{ xs: 3, md: 3 }} position="relative">
        {version === 'v1' ? (
          <Typography>Ako často hodnotený žiak...</Typography>
        ) : (
          <Typography>Ako často sledovaný žiak...</Typography>
        )}
        <Formik
          initialValues={initialValues}
          onSubmit={(values: Record<string, number>) => onSubmit(getResults(values))}
        >
          <Form>
            <TableContainer component={Paper}>
              <MaterialTable>
                <TableBody>
                  {table.questions.map((question, index) => (
                    <TableRow key={`tr-${index}`}>
                      {version === 'v2' && question.order === -1 ? (
                        <>
                          <TableCell size="medium" style={{ verticalAlign: 'top' }} colSpan={3}>
                            <Box my={2}>{question.text}</Box>
                          </TableCell>
                        </>
                      ) : (
                        <>
                          <TableCell size="small" style={{ verticalAlign: 'top' }}>
                            <Box my={2}>{question.order}.</Box>
                          </TableCell>
                          <TableCell size="small" style={{ verticalAlign: 'top' }}>
                            <Box my={2}>{question.text}</Box>
                          </TableCell>
                          <TableCell size="small">
                            <RadioField
                              name={question.id}
                              options={question.options}
                              disabled={isReadOnly}
                            />
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </MaterialTable>
            </TableContainer>

            <TextAreaWrapper my={3}>
              <Box mb={1}>
                <Typography>{table.textAreaLabel}</Typography>
              </Box>
              <TextField
                style={{ width: '100%' }}
                id={'additionalText'}
                name={'additionalText'}
                type="text"
                variant="outlined"
                multiline
                onChange={event => {
                  const value =
                    event.target.value && typeof event.target.value === 'string'
                      ? event.target.value
                      : '';
                  setAdditionalText(value);
                }}
                value={additionalText}
                disabled={isReadOnly}
              ></TextField>
            </TextAreaWrapper>

            <Box my={3} textAlign="right">
              <Button size="large" color="secondary" onClick={onBackClick}>
                Krok späť
              </Button>

              <Button
                variant="contained"
                type="submit"
                size="large"
                color="primary"
                style={{ marginLeft: '10px' }}
                disabled={isDemo || isReadOnly}
              >
                Vyhodnotiť
              </Button>
            </Box>
            <TestPersist isDemo={isDemo || isReadOnly} keyName={`${token}`} />
          </Form>
        </Formik>
      </Box>
    </PaperContent>
  );
}

export const TextAreaWrapper = styled(Box)`
  textarea {
    min-height: 77px;
    max-height: 77px;
  }
`;
